
import { defineComponent, nextTick } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import IconCloseCircle from '@svg/icon-close-circle.svg';

export default defineComponent({
  name: 'BaseDrawerModal',
  components: {
    IconCloseCircle
  },
  props: {},
  data() {
    return {
      overlayRendered: false
    };
  },
  computed: {
    showNewFeaturesLayout(): boolean {
      return this.drawerComponent === 'NewFeatures';
    },
    ...mapGetters(['drawerOpen', 'drawerComponent'])
  },
  watch: {
    drawerOpen(isDrawerOpen) {
      if (isDrawerOpen) {
        this.blockBodyScroll();
      } else {
        this.unblockBodyScroll();
        this.overlayRendered = false;
      }
    }
  },
  methods: {
    slideDrawerClose(): void {
      this.overlayRendered = false;
      this.closeDrawer();
    },
    setOverlayRendered(): void {
      this.overlayRendered = true;
      this.focusModal();
    },
    async focusModal() {
      await nextTick();
      this.$refs.drawer?.focus();
    },
    ...mapActions(['closeDrawer', 'blockBodyScroll', 'unblockBodyScroll'])
  }
});
