import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-418d8ac2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card-circle-icon" }
const _hoisted_2 = {
  key: 0,
  class: "card-border-top"
}
const _hoisted_3 = { class: "card-circle-icon" }
const _hoisted_4 = {
  key: 0,
  class: "card-border-top"
}
const _hoisted_5 = { class: "card-circle-icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CardContent = _resolveComponent("CardContent")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.routeLocation)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 0,
          class: "card-wrapper",
          to: _ctx.routeLocation
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(["card", _ctx.cardClass])
            }, [
              (_ctx.borderTop)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                class: _normalizeClass(["card-circle", _ctx.cardCircleColorClass]),
                style: _normalizeStyle(_ctx.cardCircleStyle)
              }, [
                _createElementVNode("div", _hoisted_3, [
                  _renderSlot(_ctx.$slots, "icon", {}, undefined, true)
                ])
              ], 6),
              _createVNode(_component_CardContent, {
                title: _ctx.title,
                heading: _ctx.heading,
                description: _ctx.description
              }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "default", {}, undefined, true)
                ]),
                _: 3
              }, 8, ["title", "heading", "description"])
            ], 2)
          ]),
          _: 3
        }, 8, ["to"]))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["card", _ctx.cardClass])
        }, [
          (_ctx.borderTop)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass(["card-circle", _ctx.cardCircleColorClass]),
            style: _normalizeStyle(_ctx.cardCircleStyle)
          }, [
            _createElementVNode("div", _hoisted_5, [
              _renderSlot(_ctx.$slots, "icon", {}, undefined, true)
            ])
          ], 6),
          _createVNode(_component_CardContent, {
            title: _ctx.title,
            heading: _ctx.heading,
            description: _ctx.description
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ]),
            _: 3
          }, 8, ["title", "heading", "description"])
        ], 2))
  ]))
}