import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-07199f80"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "crisis-footer-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_i18n_t = _resolveComponent("i18n-t")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_i18n_t, {
      keypath: "body",
      tag: "p",
      for: "linkText"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_router_link, { to: { name: 'HelpCrisis' } }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('linkText')), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}