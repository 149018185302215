export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "es",
    "resource": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurar tu cuenta"])},
      "form": {
        "firstNameField": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Cuál es tu primer nombre?"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["first name"])}
        },
        "lastNameField": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Cual es tu apellido?"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["last name"])}
        },
        "screenNameField": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elige un nombre de pantalla"])},
          "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este es el nombre que verán otros en myStrength"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["screen name"])}
        },
        "emailField": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Cuál es su dirección de correo electrónico?"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email address"])},
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parece que este correo electrónico ya está en uso."])}
        },
        "passwordField": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea una contraseña"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["password"])},
          "subText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al menos 8 caracteres con un número, una letra mayúscula y una letra minúscula"])}
        },
        "gender": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu género"])},
          "inline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este campo es opcional. Si se completa, esta información ayuda a myStrength a personalizar su experiencia y encontrar los recursos adecuados. También se incluye en los informes generales de datos de uso de myStrength."])},
          "options": {
            "MALE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masculino"])},
            "FEMALE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mujer"])},
            "NON_BINARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No binario"])},
            "NO_ANSWER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prefiero no responder"])}
          }
        },
        "validAgeCheckbox": {
          "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tengo al menos ", _interpolate(_named("minAge")), " años"])},
          "error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Debes tener al menos ", _interpolate(_named("minAge")), " años para usar myStrength"])}
        },
        "emailOptinCheckbox": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiero recibir comunicaciones por correo electrónico de myStrength"])}
        },
        "termsCheckbox": {
          "labelPrefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estoy de acuerdo con el de myStrength"])},
          "termsOfUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condiciones de uso"])},
          "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de privacidad"])},
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debes aceptar los términos de uso y la política de privacidad."])}
        },
        "continueBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
        "requiredValidationFailure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este campo es obligatorio"])}
      }
    }
  })
}
