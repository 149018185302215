import { createApp, h } from 'vue';

import '@/registerServiceWorker';
import router from '@/router';
import store from '@/store';

import App from '@/App.vue';

import VueMixpanel from '@/vueMixpanel';

// Third party global plugins
import { createI18n } from 'vue-i18n';

// Third party configuration
import { runVeeConfiguration } from '@/helpers/ConfigureVeeValidate';

// Global locale
import { FALLBACK_LOCALE } from '@/services/localizationService';

import globalEn from '@/locale/global/en.json';
import globalEs from '@/locale/global/es.json';

// Vue i18n config
const i18n = createI18n({
  locale: FALLBACK_LOCALE, // Default locale
  fallbackLocale: FALLBACK_LOCALE, // Fallback locale
  silentTranslationWarn: true,
  silentFallbackWarn: true,
  messages: {
    en: globalEn,
    es: globalEs
  }
});

// Configure Vee-validate
runVeeConfiguration();

// Add global styles - un-scoped
require('@styles/global.scss');

const app = createApp({
  store,
  render: () => h(App)
});

// Set global properties
app.config.globalProperties.brandName = 'myStrength';
app.config.globalProperties.companyName = 'Teladoc Health, Inc.';
app.config.globalProperties.privacyPolicyLink =
  'https://www.teladoc.com/notice-of-privacy-practices/';

// Add base components
import registerGlobalComponents from '@/components/base/baseComponents';
registerGlobalComponents(app);

// Add plugins and mount
app.use(router).use(store).use(i18n).use(VueMixpanel);
app.mount('#bh-web-ui');
