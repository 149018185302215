
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import PulseButton from '@common/PulseButton.vue';
import CameraIcon from '@svg/conference/camera.svg';

export default defineComponent({
  name: 'JoinConferenceButton',
  components: {
    PulseButton,
    CameraIcon
  },
  computed: {
    displayButton() {
      return this.$route.name !== 'Conference' && this.meetingOn;
    },
    ...mapGetters(['meetingOn'])
  }
});
