import HTTP from '@/services/HttpService';
import apiRoutes from '@config/api-routes';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '@/types/storeTypes';

import { ActivitySlim } from '@/types/ActivityTypes';

export interface InTheMoment {
  imtActivities: Array<[ActivitySlim]> | null;
}

const state: InTheMoment = {
  imtActivities: null
};

const getters: GetterTree<InTheMoment, RootState> = {
  imtActivities: (state: InTheMoment) => state.imtActivities
};

const actions: ActionTree<InTheMoment, RootState> = {
  getIMTActivities({ commit }): void {
    HTTP.get(apiRoutes.activity.moment).then((response) => {
      commit('setIMTActivities', response.data);
    });
  }
};

const mutations: MutationTree<InTheMoment> = {
  setIMTActivities(state: InTheMoment, imtActivities: Array<[ActivitySlim]>) {
    state.imtActivities = imtActivities;
  }
};

export const InTheMomentStore: Module<InTheMoment, RootState> = {
  state,
  actions,
  mutations,
  getters
};

export default InTheMomentStore;
