import { Provider } from '@/types/PartnerCollateralTypes';

interface ApiRoutes {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const apiRoutes: ApiRoutes = {
  config: '/config?useNewFormat=true',
  home: {
    cards: '/user/home/cards',
    memberMessages: '/user/member/messages'
  },
  onboarding: {
    survey: '/onboarding/survey',
    surveyResponse: '/onboarding/survey-responses',
    status: '/user/onboarding-status'
  },
  user: {
    login: '/public/user/login',
    loginWithLivongoToken: '/public/v3/auth/web',
    loginWithClinicalPortalToken: '/public/v3/auth/clinical',
    memberVersion: '/public/users/member-version/WEB',
    getUser: '/user',
    getMFAType: '/user/multifactor/send',
    mfaVerify: '/user/multifactor/verify',
    reAuth: '/public/v3/reauth/web',
    passwordReset: '/public/user/password/reset/request',
    resetExpiredPassword: '/public/user/password/reset/expired/WEB',
    resetPassword: '/public/user/password/reset',
    userChangePassword: '/user/password',
    userChangeEmail: '/user/credentials',
    update: '/user/update',
    migrationSaml: '/membermigration/samlresponse',
    language(type: string) {
      return `/public/user/session/${type}`;
    },
    deleteAccount: '/user/request/accountdelete',
    register: '/public/user',
    profile: '/user/metadata/profile',
    logout: '/user/v3/logout',
    tags(tag: string, enabled: boolean) {
      if (enabled) {
        return `/user/metadata/tags?add=${tag}`;
      } else {
        return `/user/metadata/tags?remove=${tag}`;
      }
    },
    getTeletherapyToken: '/user/teletherapy/auth',
    livongoRegStatus: '/user/livongo/registration/status',
    livongoIdpRequest: '/user/livongo/registration/idprequest',
    conference: '/user/conference',
    adolescence: '/user/adolescence'
  },
  logging: {
    log: '/log',
    clinicalLog: '/log/clinical'
  },
  mobile: {
    reAuth: '/public/v3/reauth/mobile',
    convertSession: '/mobile/user/convertSession'
  },
  registration: {
    validateAccessCode() {
      return `/collateral/signups/access_codes/validate`;
    },
    submit(userTimeZone: string) {
      return `/public/user?tz=${userTimeZone}`;
    }
  },
  activity: {
    moment: '/member/activities/inthemoment',
    recommend: '/member/activities/recommend',
    favorites: '/member/activities/favorites',
    alterFavorites: (activitySlug: string): string =>
      `/member/activities/favorites/${activitySlug}`,
    getActivity(activitySlug: string): string {
      return `/member/activities/${activitySlug}`;
    },
    assignedPrograms: '/member/activities/programs/assigned',
    program: (programId: string): string =>
      `/member/activities/programs/${programId}`,
    responses(activitySlug: string): string {
      return `/member/activities/${activitySlug}/responses`;
    },
    getCompletedActivityResponse(
      activitySlug: string,
      activityId: number
    ): string {
      return `/member/activities/${activitySlug}/responses/${activityId}`;
    },
    getCompletedActivity(responseId: number) {
      return `member/activities/history/${responseId}`;
    },
    feedback(activitySlug: string): string {
      return `/member/activities/feedback/${activitySlug}`;
    },
    history: 'member/activities/history',
    search: '/member/activities/search',
    daCast: {
      metadata: (contentId: string): string =>
        `https://playback.dacast.com/content/info?contentId=${contentId}&provider=universe`
    }
  },
  eventAudit: {
    communicationEvent: '/public/audit/user/communications'
  },
  library: '/member/library',
  landingPage: {
    getPartnerBrandingBase: '/collateral/headers/organizations/'
  },
  partnerCollateral: {
    validateProvider: (provider: Provider) => {
      const queryString =
        (provider.org ? 'organization=' + provider.org + '&' : '') +
        (provider.group ? 'group=' + provider.group + '&' : '');
      return `/public/providers?${queryString}`;
    },
    getCustomSignUpFields: (provider: Provider) => {
      const allProviderFieldsSet =
        provider.org && provider.group && provider.accessCode;
      let urlSuffix = '';

      if (provider.org && !allProviderFieldsSet) {
        urlSuffix += '/organizations/' + provider.org;
      }
      if (provider.group) {
        urlSuffix += '/groups/' + provider.group;
      }
      if (provider.accessCode) {
        urlSuffix += '/accesscodes';
      }
      urlSuffix += '?include=group&include=organization';
      if (provider.referralToken) {
        urlSuffix += '&referralTicket=' + provider.referralToken;
      }

      return `/collateral/signups${urlSuffix}`;
    }
  },
  tracker: '/user/mood/newmoods',
  headers: '/collateral/headers',
  sleep: {
    schedule: '/user/sleepdiary/schedule?forWeeklyCheckin=false',
    getDiary: '/user/sleepdiary/schedule',
    saveSchedule: '/user/sleepdiary/schedule',
    saveSleepCheckin: '/user/sleep/checkin'
  },
  guide: {
    guideMessages: `/coaching/messages/`,
    unreadMessageCount: '/coaching/messages/unreadcount',
    markMessageAsRead(messageId: string): string {
      return `/coaching/messages/read/${messageId}`;
    },
    coachEvents: '/coaching/events',
    guideProfiles: '/coaching/coaches/profiles',
    sendMessage: '/coaching/messages'
  },
  clinicalCoaching: {
    cancelSessionAppointment(sessionId: number): string {
      return `/clinical-coaching/session/${sessionId}`;
    },
    getUsersCoach: '/clinical-coaching/coach',
    getStatus: '/clinical-coaching/status',
    getCards: '/clinical-coaching/cards',
    getTrack: '/clinical-coaching/track',
    joinSessionAppointment(sessionId: number): string {
      return `/clinical-coaching/session/${sessionId}/join`;
    }
  },
  conference: {
    member: '/user/conference',
    coach: '/user/clinical/conference',
    delete: (id: number): string => `/user/clinical/conference/${id}`,
    record: (id: number): string => `/user/clinical/conference/${id}/record`,
    trackEndCall: (conferenceId: number): string => {
      return `/user/conference/${conferenceId}/leave`;
    }
  },
  crisis: {
    protocol: '/crisis',
    request: '/crisis/outreach/request',
    contactInfo: '/crisis/outreach/contactInfo'
  }
};

export default apiRoutes;
