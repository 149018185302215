import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-404424c3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CameraIcon = _resolveComponent("CameraIcon")!
  const _component_PulseButton = _resolveComponent("PulseButton")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.displayButton)
    ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        class: "elevate remove-underline",
        to: { name: 'Conference' }
      }, {
        default: _withCtx(() => [
          _createVNode(_component_PulseButton, { class: "pulse-float success" }, {
            default: _withCtx(() => [
              _createVNode(_component_CameraIcon, { class: "icon" }),
              _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t('join')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}