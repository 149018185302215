
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ErrorView',
  props: {
    status: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      iconPath: '',
      translationKey: '',
      isAPIError: false
    };
  },
  mounted() {
    const status = this.status || this.$route?.query?.status;
    switch (status) {
      case '404':
        this.iconPath = '/img/svg/icon-error-ghost.svg';
        this.translationKey = '404';
        break;
      case '500':
        this.iconPath = '/img/svg/icon-error-bang.svg';
        this.translationKey = '500';
        break;
      default:
        this.iconPath = '/img/svg/icon-error-face.svg';
        this.translationKey = 'API';
        this.isAPIError = true;
    }
  }
});
