/**
 * @fileoverview gRPC-Web generated client stub for com.livongo.protobuf.grpc.external.bh.wellness_assessments
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.11.4
// source: livongo/protobuf/grpc/external/bh/wellness_assessments/wellness_assessments_service.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var livongo_protobuf_grpc_external_bh_wellness_assessments_wellness_assessments_pb = require('../../../../../../livongo/protobuf/grpc/external/bh/wellness_assessments/wellness_assessments_pb.js')
const proto = {};
proto.com = {};
proto.com.livongo = {};
proto.com.livongo.protobuf = {};
proto.com.livongo.protobuf.grpc = {};
proto.com.livongo.protobuf.grpc.external = {};
proto.com.livongo.protobuf.grpc.external.bh = {};
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments = require('./wellness_assessments_service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsRequest,
 *   !proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsResponse>}
 */
const methodDescriptor_AssessmentService_ListAssessments = new grpc.web.MethodDescriptor(
  '/com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentService/ListAssessments',
  grpc.web.MethodType.UNARY,
  livongo_protobuf_grpc_external_bh_wellness_assessments_wellness_assessments_pb.ListAssessmentsRequest,
  livongo_protobuf_grpc_external_bh_wellness_assessments_wellness_assessments_pb.ListAssessmentsResponse,
  /**
   * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  livongo_protobuf_grpc_external_bh_wellness_assessments_wellness_assessments_pb.ListAssessmentsResponse.deserializeBinary
);


/**
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentServiceClient.prototype.listAssessments =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentService/ListAssessments',
      request,
      metadata || {},
      methodDescriptor_AssessmentService_ListAssessments,
      callback);
};


/**
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsResponse>}
 *     Promise that resolves to the response
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentServicePromiseClient.prototype.listAssessments =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentService/ListAssessments',
      request,
      metadata || {},
      methodDescriptor_AssessmentService_ListAssessments);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesRequest,
 *   !proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesResponse>}
 */
const methodDescriptor_AssessmentService_CreateAssessmentsResponses = new grpc.web.MethodDescriptor(
  '/com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentService/CreateAssessmentsResponses',
  grpc.web.MethodType.UNARY,
  livongo_protobuf_grpc_external_bh_wellness_assessments_wellness_assessments_pb.CreateAssessmentsResponsesRequest,
  livongo_protobuf_grpc_external_bh_wellness_assessments_wellness_assessments_pb.CreateAssessmentsResponsesResponse,
  /**
   * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  livongo_protobuf_grpc_external_bh_wellness_assessments_wellness_assessments_pb.CreateAssessmentsResponsesResponse.deserializeBinary
);


/**
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentServiceClient.prototype.createAssessmentsResponses =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentService/CreateAssessmentsResponses',
      request,
      metadata || {},
      methodDescriptor_AssessmentService_CreateAssessmentsResponses,
      callback);
};


/**
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesResponse>}
 *     Promise that resolves to the response
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentServicePromiseClient.prototype.createAssessmentsResponses =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentService/CreateAssessmentsResponses',
      request,
      metadata || {},
      methodDescriptor_AssessmentService_CreateAssessmentsResponses);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentRetakesRequest,
 *   !proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentRetakesResponse>}
 */
const methodDescriptor_AssessmentService_ListAssessmentRetakes = new grpc.web.MethodDescriptor(
  '/com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentService/ListAssessmentRetakes',
  grpc.web.MethodType.UNARY,
  livongo_protobuf_grpc_external_bh_wellness_assessments_wellness_assessments_pb.ListAssessmentRetakesRequest,
  livongo_protobuf_grpc_external_bh_wellness_assessments_wellness_assessments_pb.ListAssessmentRetakesResponse,
  /**
   * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentRetakesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  livongo_protobuf_grpc_external_bh_wellness_assessments_wellness_assessments_pb.ListAssessmentRetakesResponse.deserializeBinary
);


/**
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentRetakesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentRetakesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentRetakesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentServiceClient.prototype.listAssessmentRetakes =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentService/ListAssessmentRetakes',
      request,
      metadata || {},
      methodDescriptor_AssessmentService_ListAssessmentRetakes,
      callback);
};


/**
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentRetakesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentRetakesResponse>}
 *     Promise that resolves to the response
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentServicePromiseClient.prototype.listAssessmentRetakes =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentService/ListAssessmentRetakes',
      request,
      metadata || {},
      methodDescriptor_AssessmentService_ListAssessmentRetakes);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeRequest,
 *   !proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeResponse>}
 */
const methodDescriptor_AssessmentService_SkipAssessmentRetake = new grpc.web.MethodDescriptor(
  '/com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentService/SkipAssessmentRetake',
  grpc.web.MethodType.UNARY,
  livongo_protobuf_grpc_external_bh_wellness_assessments_wellness_assessments_pb.SkipAssessmentRetakeRequest,
  livongo_protobuf_grpc_external_bh_wellness_assessments_wellness_assessments_pb.SkipAssessmentRetakeResponse,
  /**
   * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  livongo_protobuf_grpc_external_bh_wellness_assessments_wellness_assessments_pb.SkipAssessmentRetakeResponse.deserializeBinary
);


/**
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentServiceClient.prototype.skipAssessmentRetake =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentService/SkipAssessmentRetake',
      request,
      metadata || {},
      methodDescriptor_AssessmentService_SkipAssessmentRetake,
      callback);
};


/**
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeResponse>}
 *     Promise that resolves to the response
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentServicePromiseClient.prototype.skipAssessmentRetake =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentService/SkipAssessmentRetake',
      request,
      metadata || {},
      methodDescriptor_AssessmentService_SkipAssessmentRetake);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryRequest,
 *   !proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryResponse>}
 */
const methodDescriptor_AssessmentService_ListSubmissionHistory = new grpc.web.MethodDescriptor(
  '/com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentService/ListSubmissionHistory',
  grpc.web.MethodType.UNARY,
  livongo_protobuf_grpc_external_bh_wellness_assessments_wellness_assessments_pb.ListSubmissionHistoryRequest,
  livongo_protobuf_grpc_external_bh_wellness_assessments_wellness_assessments_pb.ListSubmissionHistoryResponse,
  /**
   * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  livongo_protobuf_grpc_external_bh_wellness_assessments_wellness_assessments_pb.ListSubmissionHistoryResponse.deserializeBinary
);


/**
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentServiceClient.prototype.listSubmissionHistory =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentService/ListSubmissionHistory',
      request,
      metadata || {},
      methodDescriptor_AssessmentService_ListSubmissionHistory,
      callback);
};


/**
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryResponse>}
 *     Promise that resolves to the response
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentServicePromiseClient.prototype.listSubmissionHistory =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentService/ListSubmissionHistory',
      request,
      metadata || {},
      methodDescriptor_AssessmentService_ListSubmissionHistory);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentRequest,
 *   !proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentResponse>}
 */
const methodDescriptor_AssessmentService_ListRetakeResultContent = new grpc.web.MethodDescriptor(
  '/com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentService/ListRetakeResultContent',
  grpc.web.MethodType.UNARY,
  livongo_protobuf_grpc_external_bh_wellness_assessments_wellness_assessments_pb.ListRetakeResultContentRequest,
  livongo_protobuf_grpc_external_bh_wellness_assessments_wellness_assessments_pb.ListRetakeResultContentResponse,
  /**
   * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  livongo_protobuf_grpc_external_bh_wellness_assessments_wellness_assessments_pb.ListRetakeResultContentResponse.deserializeBinary
);


/**
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentServiceClient.prototype.listRetakeResultContent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentService/ListRetakeResultContent',
      request,
      metadata || {},
      methodDescriptor_AssessmentService_ListRetakeResultContent,
      callback);
};


/**
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentResponse>}
 *     Promise that resolves to the response
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentServicePromiseClient.prototype.listRetakeResultContent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentService/ListRetakeResultContent',
      request,
      metadata || {},
      methodDescriptor_AssessmentService_ListRetakeResultContent);
};


module.exports = proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments;

