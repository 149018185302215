import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withKeys as _withKeys, renderSlot as _renderSlot, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7cd4bc2a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tooltip-wrapper" }
const _hoisted_2 = ["aria-label"]
const _hoisted_3 = { class: "icon" }
const _hoisted_4 = ["aria-label", "aria-live", "aria-expanded"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CircleClose = _resolveComponent("CircleClose")!
  const _component_CircleQuestion = _resolveComponent("CircleQuestion")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      type: "button",
      "aria-label": _ctx.$t('buttonLabel'),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleOpen && _ctx.toggleOpen(...args)))
    }, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.isOpen)
          ? (_openBlock(), _createBlock(_component_CircleClose, { key: 0 }))
          : _createCommentVNode("", true),
        (!_ctx.isOpen)
          ? (_openBlock(), _createBlock(_component_CircleQuestion, { key: 1 }))
          : _createCommentVNode("", true)
      ])
    ], 8, _hoisted_2),
    (_ctx.isOpen)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", {
            class: "modal-mask",
            role: "button",
            tabindex: "0",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isOpen = false)),
            onKeyup: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.isOpen = false), ["enter"]))
          }, null, 32),
          _createElementVNode("div", {
            class: "tip-content info-live-region",
            role: "dialog",
            "aria-label": _ctx.$t('tooltipLabel'),
            "aria-live": _ctx.isOpen ? 'polite' : 'off',
            "aria-expanded": _ctx.isOpen
          }, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ], 8, _hoisted_4)
        ], 64))
      : _createCommentVNode("", true)
  ]))
}