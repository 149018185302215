export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
      "loginForm": {
        "emailField": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name", "@", "domain.com"])}
        },
        "passwordField": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["password"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])}
        },
        "signInBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
        "nextBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
        "loginFailureMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We weren’t able to sign you in. Please check your email and password."])}
      },
      "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password?"])},
      "createNewAccountLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New user?"])},
      "createNewAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an account"])},
      "dupAccountErrorHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It looks like you already have an account"])},
      "dupAccountErrorDescr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An account already exists in myStrength that cannot be merged at this time. Please call member support at (800) 945-4355."])},
      "errorHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong"])},
      "errorDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal error. Please call member support at (800) 945-4355."])},
      "accountUpgradeCompleteDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account update is complete!. Please sign in to continue."])},
      "oneapp": {
        "dupAccountErrorHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We haven’t merged your accounts just yet"])},
        "mobile": {
          "dupAccountErrorDescr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You’ll need to sign in to your myStrength app to access the Mental Health program. If you’re still having trouble, call 1-800-835-2362 or "])}
        },
        "web": {
          "dupAccountErrorDescr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You’ll need to sign in to your myStrength account to access the Mental Health program. If you’re still having trouble, call 1-800-835-2362 or "])}
        },
        "emailText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email Member Support."])},
        "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to myStrength"])}
      },
      "redirect": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We’re taking you to Teladoc"])},
        "Teladoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in there and you’ll be on your way!"])}
      }
    }
  })
}
