import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, TransitionGroup as _TransitionGroup, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Notification = _resolveComponent("Notification")!

  return (_openBlock(), _createBlock(_TransitionGroup, {
    id: "notifications",
    tag: "div",
    name: "notification"
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notifications, (notification) => {
        return (_openBlock(), _createBlock(_component_Notification, {
          key: notification.id,
          id: notification.id,
          heading: notification.heading,
          description: notification.description,
          type: notification.type
        }, null, 8, ["id", "heading", "description", "type"]))
      }), 128))
    ]),
    _: 1
  }))
}