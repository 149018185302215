export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["If you are in a crisis or need to talk to someone right away, ", _interpolate(_list(0))])},
      "linkText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["help is here."])}
    }
  })
}
