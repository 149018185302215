
import { defineComponent } from 'vue';

import InternalLinkService from '@/services/InternalLinkService';
import Home from '@svg/Guide/home.svg';

export default defineComponent({
  name: 'AttachmentInternalLink',
  emits: ['linkClicked'],
  components: {
    Home
  },
  props: {
    attachment: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      linkHref: null as string | null,
      fallbackLinkHref: '/whoops'
    };
  },
  computed: {
    internalLinkTitle(): string {
      return this.attachment.attachmentMeta &&
        this.attachment.attachmentMeta.title
        ? this.attachment.attachmentMeta.title
        : this.attachment.content;
    },
    routerLinkLocation(): string {
      return this.linkHref || this.fallbackLinkHref;
    }
  },
  mounted() {
    const linkHref = InternalLinkService.getWebPathForInternalLink(
      this.attachment.content
    );
    this.linkHref = linkHref === '/' ? this.fallbackLinkHref : linkHref;
  },
  updated() {
    const linkHref = InternalLinkService.getWebPathForInternalLink(
      this.attachment.content
    );
    this.linkHref = linkHref === '/' ? this.fallbackLinkHref : linkHref;
  },
  methods: {
    linkClick() {
      this.$emit('linkClicked');
    }
  }
});
