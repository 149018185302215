export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "validBirthDateMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid date of birth"])},
      "validDateMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A valid date is required"])},
      "minimumAgeMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must be at least 13 years old to use this service."])}
    }
  })
}
