
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CardContent',
  props: {
    title: {
      type: String,
      default: null
    },
    heading: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    }
  }
});
