export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "es",
    "resource": {
      "recommended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recomendado"])},
      "recommended-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recomendado para ti"])},
      "justForYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo para ti"])},
      "moment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toma un descanso"])},
      "favorites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favoritos"])},
      "viewAllFavorites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver todas las favorites"])}
    }
  })
}
