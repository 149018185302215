import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toHandlerKey as _toHandlerKey, withModifiers as _withModifiers, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1f73adcc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "description" }
const _hoisted_2 = { class: "actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_BaseModal = _resolveComponent("BaseModal")!

  return (_openBlock(), _createBlock(_component_BaseModal, {
    class: "history-choice-modal",
    title: _ctx.$t('modal.title'),
    keepScrollPosition: true,
    showCloseButton: true,
    showModal: _ctx.modalShown,
    "onUpdate:showModal": _ctx.updateShowModal
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('modal.body')), 1),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _mergeProps({ class: "action retake-activity-button-wrapper" }, {
          [(_toHandlerKey(_ctx.historyCardEvent)) + "Capture"]: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.historyCardClick(_ctx.activitySlug)), ["prevent"]))
        }), [
          _createVNode(_component_router_link, {
            class: "btn-primary",
            to: { name: _ctx.activityRouteName, params: { activitySlug: _ctx.activitySlug }}
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('modal.retakeActivityButtonText')), 1)
            ]),
            _: 1
          }, 8, ["to"])
        ], 16),
        _createVNode(_component_router_link, {
          class: "action btn-ghost",
          to: _ctx.activityResponseLocation || _ctx.defaultActivityResponseLocation
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('modal.viewSavedAnswersButtonText')), 1)
          ]),
          _: 1
        }, 8, ["to"])
      ])
    ]),
    _: 1
  }, 8, ["title", "showModal", "onUpdate:showModal"]))
}