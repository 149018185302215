import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-78ffe37d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "telehealth-card-wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "cancel-modal"
}
const _hoisted_3 = {
  class: "cancel-modal-content",
  "data-cy": "guide-modal"
}
const _hoisted_4 = { class: "heading-xl" }
const _hoisted_5 = { class: "btn-group" }
const _hoisted_6 = { class: "time-text strong" }
const _hoisted_7 = { class: "description" }
const _hoisted_8 = {
  key: 1,
  class: "phone-call"
}
const _hoisted_9 = { class: "audio-message" }
const _hoisted_10 = { class: "time-text strong" }
const _hoisted_11 = { class: "description" }
const _hoisted_12 = { class: "consultation-ctas" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_CardCircleIcon = _resolveComponent("CardCircleIcon")!
  const _component_IconTherapy = _resolveComponent("IconTherapy")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_IconVideo = _resolveComponent("IconVideo")!
  const _component_IconPhone = _resolveComponent("IconPhone")!
  const _component_IconCalendar = _resolveComponent("IconCalendar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_Transition, {
      name: "fade",
      duration: 200
    }, {
      default: _withCtx(() => [
        (_ctx.showModal)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t('cancelHeader')), 1),
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('cancelDialog')), 1),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("a", {
                    class: "cancel-link action-label",
                    href: "#",
                    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.showModal = false), ["prevent"]))
                  }, _toDisplayString(_ctx.$t('no')), 1),
                  _createElementVNode("a", {
                    class: "cancel-link action-label",
                    href: "#",
                    onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.cancelConsultation && _ctx.cancelConsultation(...args)), ["prevent"]))
                  }, _toDisplayString(_ctx.$t('confirmCancel')), 1)
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (!_ctx.isLoaded)
      ? (_openBlock(), _createBlock(_component_CardCircleIcon, {
          key: 0,
          class: "card-telehealth"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_LoadingSpinner)
          ]),
          _: 1
        }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.teletherapyConsultation === null)
            ? (_openBlock(), _createBlock(_component_CardCircleIcon, {
                key: 0,
                class: "card-telehealth",
                title: _ctx.title,
                heading: _ctx.$t('default.header'),
                description: _ctx.$t('default.description')
              }, {
                icon: _withCtx(() => [
                  _createVNode(_component_IconTherapy)
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_router_link, {
                    class: "btn-tele btn-primary destination-button",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleRequestEvent(_ctx.paths.RAV))),
                    to: { name: 'Teletherapy', query: { screen: _ctx.paths.RAV}}
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('default.actionLabel')), 1)
                    ]),
                    _: 1
                  }, 8, ["to"])
                ]),
                _: 1
              }, 8, ["title", "heading", "description"]))
            : (_ctx.teletherapyConsultation.status === 'Requested')
              ? (_openBlock(), _createBlock(_component_CardCircleIcon, {
                  key: 1,
                  class: "card-telehealth",
                  title: _ctx.title,
                  heading: _ctx.$t('requested.header')
                }, {
                  icon: _withCtx(() => [
                    _createVNode(_component_IconCalendar, { class: "icon-calendar" })
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("p", _hoisted_6, [
                      (_ctx.isVideoConsultation)
                        ? (_openBlock(), _createBlock(_component_IconVideo, {
                            key: 0,
                            class: "communication-icon"
                          }))
                        : (_ctx.isPhoneConsultation)
                          ? (_openBlock(), _createBlock(_component_IconPhone, {
                              key: 1,
                              class: "communication-icon"
                            }))
                          : _createCommentVNode("", true),
                      _createTextVNode(_toDisplayString(_ctx.format(_ctx.requestedDate, "EEEE, MMM d 'at' K:mm a")) + " " + _toDisplayString(_ctx.requestedDate.toLocaleString('en', {timeZoneName: 'short'}).split(' ').pop()), 1)
                    ]),
                    _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('requested.description', )), 1)
                  ]),
                  _: 1
                }, 8, ["title", "heading"]))
              : (_ctx.teletherapyConsultation.status === 'Proposed')
                ? (_openBlock(), _createBlock(_component_CardCircleIcon, {
                    key: 2,
                    class: "card-telehealth",
                    title: _ctx.title,
                    heading: _ctx.$t('proposed.header'),
                    description: _ctx.$t('proposed.description')
                  }, {
                    icon: _withCtx(() => [
                      _createVNode(_component_IconCalendar, { class: "icon-calendar" })
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_router_link, {
                        class: "btn-tele btn-secondary destination-button",
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleReviewEvent(_ctx.paths.MESSAGE_CENTER))),
                        to: { name: 'Teletherapy', query: { screen: _ctx.paths.MESSAGE_CENTER}}
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('proposed.actionLabel')), 1)
                        ]),
                        _: 1
                      }, 8, ["to"])
                    ]),
                    _: 1
                  }, 8, ["title", "heading", "description"]))
                : (_ctx.canJoinWaitingRoom)
                  ? (_openBlock(), _createBlock(_component_CardCircleIcon, {
                      key: 3,
                      class: "card-telehealth",
                      title: _ctx.title,
                      heading: _ctx.isVideoConsultation ? _ctx.$t('join.video.header',{provider: `${_ctx.teletherapyConsultation.provider.first_name} ${_ctx.teletherapyConsultation.provider.last_name}`,time: _ctx.format(new Date(), 'K:mm a')}) : _ctx.$t('join.audio.header') 
                    }, {
                      icon: _withCtx(() => [
                        _createVNode(_component_IconTherapy)
                      ]),
                      default: _withCtx(() => [
                        (_ctx.isVideoConsultation)
                          ? (_openBlock(), _createBlock(_component_router_link, {
                              key: 0,
                              class: "btn-tele btn-primary destination-button",
                              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.handleJoinVideoCallEvent(_ctx.paths.JOIN_WAITING_ROOM))),
                              to: { name: 'Teletherapy', query: { screen: _ctx.paths.JOIN_WAITING_ROOM}}
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('join.video.actionLabel')), 1)
                              ]),
                              _: 1
                            }, 8, ["to"]))
                          : _createCommentVNode("", true),
                        (_ctx.isPhoneConsultation)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                              _createVNode(_component_IconPhone, { class: "communication-icon" }),
                              _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('join.audio.description', {provider: `${_ctx.teletherapyConsultation.provider.first_name} ${_ctx.teletherapyConsultation.provider.last_name}`,time: _ctx.format(new Date(), 'K:mm a')})), 1)
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["title", "heading"]))
                  : (_ctx.teletherapyConsultation && _ctx.teletherapyConsultation.status === 'Locked')
                    ? (_openBlock(), _createBlock(_component_CardCircleIcon, {
                        key: 4,
                        class: "card-telehealth",
                        title: _ctx.title,
                        heading: _ctx.$t('locked.header')
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_IconCalendar, { class: "icon-calendar" })
                        ]),
                        default: _withCtx(() => [
                          _createElementVNode("p", _hoisted_10, [
                            (_ctx.isVideoConsultation)
                              ? (_openBlock(), _createBlock(_component_IconVideo, {
                                  key: 0,
                                  class: "communication-icon"
                                }))
                              : (_ctx.isPhoneConsultation)
                                ? (_openBlock(), _createBlock(_component_IconPhone, {
                                    key: 1,
                                    class: "communication-icon"
                                  }))
                                : _createCommentVNode("", true),
                            _createTextVNode(_toDisplayString(_ctx.format(_ctx.requestedDate, "EEEE, MMM d 'at' K:mm a")) + " " + _toDisplayString(_ctx.requestedDate.toLocaleString('en', {timeZoneName: 'short'}).split(' ').pop()), 1)
                          ]),
                          _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('locked.description',{member: _ctx.teletherapyConsultation.member.first_name, provider: `${_ctx.teletherapyConsultation.provider.first_name} ${_ctx.teletherapyConsultation.provider.last_name}`})), 1),
                          _createElementVNode("div", _hoisted_12, [
                            (_ctx.isOneApp)
                              ? (_openBlock(), _createBlock(_component_router_link, {
                                  key: 0,
                                  class: "btn-tele btn-secondary destination-button",
                                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.handleRescheduleEvent(_ctx.paths.RESCHEDULE_MH_VISIT))),
                                  to: { name: 'Teletherapy', query: { screen: _ctx.paths.RESCHEDULE_MH_VISIT}}
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('locked.actionLabel')), 1)
                                  ]),
                                  _: 1
                                }, 8, ["to"]))
                              : _createCommentVNode("", true),
                            _createElementVNode("a", {
                              class: "cancel-link action-label",
                              href: "#",
                              onClick: _cache[6] || (_cache[6] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.showCancelModal && _ctx.showCancelModal(...args)), ["prevent"]))
                            }, _toDisplayString(_ctx.$t('cancel')), 1)
                          ])
                        ]),
                        _: 1
                      }, 8, ["title", "heading"]))
                    : _createCommentVNode("", true)
        ], 64))
  ]))
}