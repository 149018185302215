import { dynamicComponent } from '@/helpers/Webpack';

const migrationRoutes = [
  {
    path: '/migration/session-conversion/:otc?',
    name: 'SessionConversion',
    component: dynamicComponent('ClientMigration/SessionConversion'),
    meta: { requiresAuth: false, hideNav: true }
  },
  {
    path: '/migration/dob-collection',
    name: 'DobCollection',
    component: dynamicComponent('ClientMigration/DobCollection'),
    meta: { requiresAuth: true, hideNav: true }
  },
  {
    path: '/one-app-transition',
    name: 'OneAppTransition',
    component: dynamicComponent('OneAppTransition'),
    meta: { requiresAuth: true, hideNav: true }
  },
  {
    path: '/user/oneapp/migration',
    name: 'OneAppMigration',
    component: dynamicComponent('OneAppMigration'),
    meta: { requiresAuth: true, hideNav: true }
  }
];

export default migrationRoutes;
