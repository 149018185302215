import Cookies from 'js-cookie';
import decode, { JwtPayload } from 'jwt-decode';
import PathLookupService from '@/services/PathLookupService';

class Token {
  private static BH_USER_IDENTITY = 'userid';

  private tokenName: string;
  private domain: string;
  private userIdentity: number | null;
  private value: string;

  constructor(
    tokenName: string,
    domain: string,
    userIdentity = null,
    value = ''
  ) {
    this.tokenName = tokenName;
    this.domain = domain;
    this.userIdentity = userIdentity;
    this.value = value;
  }

  private isFalsyOrEmpty = (string: string): boolean => {
    return !string || string.trim().length === 0;
  };

  private getTokenExpirationDate = (encodedToken: string): Date | null => {
    try {
      const token = decode<JwtPayload>(encodedToken);
      const date = new Date(0);

      if (!token.exp) {
        return null;
      }
      date.setUTCSeconds(token.exp);
      return date;
    } catch {
      return null;
    }
  };

  private hasTokenExpired = (encodedToken: string): boolean => {
    const expirationDate = this.getTokenExpirationDate(encodedToken);
    if (!expirationDate) {
      return true;
    }
    return expirationDate < new Date();
  };

  public getToken = (): string | null => {
    let token;
    if (this.userIdentity) {
      token = this.value;
    } else {
      token = Cookies.get(this.tokenName);
    }
    return token && !this.isFalsyOrEmpty(token) ? token : null;
  };

  public setToken = (tokenValue: string): void => {
    if (this.isFalsyOrEmpty(tokenValue)) {
      return;
    }

    const expirationDate = this.getTokenExpirationDate(tokenValue);
    const expires = expirationDate ? expirationDate : undefined;
    if (this.isClinicalPortal(tokenValue)) {
      const userIdentity = this.getUserIdentityFromToken(tokenValue);
      this.setUserIdentity(userIdentity);
      this.value = tokenValue;
      return;
    }

    const isLocalDev = window.location.hostname.endsWith('.dev');
    const secure = window.location.protocol === 'https:' && !isLocalDev;
    const cookieOptions = {
      domain: this.domain,
      path: '/',
      expires: expires,
      secure: secure
    };

    if (secure) {
      Cookies.set(this.tokenName, tokenValue, cookieOptions);
    } else {
      // For local dev
      Cookies.set(this.tokenName, tokenValue);
    }
  };

  public clearToken = (): void => {
    const secure = window.location.protocol === 'https:';
    if (secure) {
      Cookies.remove(this.tokenName, { path: '/' });
      Cookies.remove(this.tokenName, { path: '/', domain: this.domain });
      Cookies.remove(this.tokenName, {
        path: '/',
        domain: this.domain.replace('mystrength', '')
      });
      Cookies.remove(this.tokenName, {
        path: '/',
        domain: this.domain.replace('mentalhealth', '')
      });
    } else {
      Cookies.remove(this.tokenName);
    }
  };

  public getUserId = (): string | null => {
    const token = this.getToken();

    if (token) {
      try {
        return decode<JwtPayload>(token).sub || null;
      } catch (error) {
        return null;
      }
    }

    return null;
  };

  public isClinicalPortal = (token: string): boolean => {
    try {
      const jwt = decode<Bh10JwtPayload>(token);

      if (!jwt || !jwt.permissions) {
        return false;
      }

      //Clinical portal tokens contain the legacy 1.0
      //permissions and role_permissions elements, whereas a
      //2.0/3.0 user does not.  A new role: CLINICAL_PORTAL
      //is added to both to distinguish legacy MYS users from CP users, i.e. coaches
      const permissions = jwt.permissions;
      const isClinicalPortalUser = permissions.indexOf('CLINICAL_PORTAL');

      return isClinicalPortalUser !== -1;
    } catch (error) {
      return false;
    }
  };

  /**
   *
   * In order to prevent CP from reusing the same cookie
   * for different tabs, a different cookie name will be used,
   * this returns the differentiator as the CP token contains a userid
   *
   * @returns
   */
  public getUserIdentityFromToken = (token: string): number | null => {
    try {
      const jwt = decode<never>(token);

      if (!jwt || !jwt[Token.BH_USER_IDENTITY]) {
        return null;
      }

      return jwt[Token.BH_USER_IDENTITY];
    } catch (error) {
      return null;
    }
  };

  public setUserIdentity = (userIdentity: number | null): void => {
    this.userIdentity = userIdentity;
  };

  public getClinicalPortalUser = (): boolean => {
    const token = this.getToken();

    if (!token) {
      return false;
    }

    return this.isClinicalPortal(token);
  };
}

class AuthService {
  private static BH_AUTH_TOKEN = 'token';
  private static BH_REAUTH_TOKEN = 'reauth_token';
  private static LIVONGO_AUTH_TOKEN = 'access_token';
  private static LIVONGO_REAUTH_TOKEN = 'refresh_token';
  private static LIVONGO_JWT_ISSUER = 'platform-apis';
  private static BH_JWT_ISSUER = 'bh-api';

  private bhAuthToken: TokenClass;
  private bhReAuthToken: TokenClass;
  private livongoAuthToken: TokenClass;
  private livongoReAuthToken: TokenClass;

  constructor() {
    this.bhAuthToken = new Token(
      AuthService.BH_AUTH_TOKEN,
      PathLookupService.getRootDomain()
    );
    this.bhReAuthToken = new Token(
      AuthService.BH_REAUTH_TOKEN,
      PathLookupService.getRootDomain()
    );
    this.livongoAuthToken = new Token(
      AuthService.LIVONGO_AUTH_TOKEN,
      PathLookupService.getParentDomain()
    );
    this.livongoReAuthToken = new Token(
      AuthService.LIVONGO_REAUTH_TOKEN,
      PathLookupService.getParentDomain()
    );
  }

  // Public methods
  //
  public getAuthToken = (): string | null => {
    return this.bhAuthToken.getToken();
  };

  public setAuthToken = (token: string): void => {
    this.bhAuthToken.setToken(token);
  };

  public clearAuthToken = (): void => {
    this.bhAuthToken.clearToken();
  };

  public getAuthTokenPermissions = (): string[] => {
    const token = this.bhAuthToken.getToken();
    if (token) {
      try {
        const decodedToken = JSON.parse(atob(token.split('.')[1]));
        if (decodedToken.iss === AuthService.BH_JWT_ISSUER) {
          return decodedToken.permissions;
        }
      } catch (error) {
        return [];
      }
    }
    return [];
  };

  public hasAuthToken = (): boolean => {
    return this.getAuthToken() !== null;
  };

  public hasReAuthToken = (): boolean => {
    return this.getReAuthToken() !== null;
  };

  public getUserId = (): string | null => {
    return this.bhAuthToken.getUserId();
  };

  public getClinicalPortalUser = (): boolean => {
    return this.bhAuthToken.getClinicalPortalUser();
  };

  public getReAuthToken = (): string | null => {
    // Get reauth_token set from authentication process stored at the root domain
    return this.bhReAuthToken.getToken();
  };

  public setReAuthToken = (reAuthToken: string): void => {
    this.bhReAuthToken.setToken(reAuthToken);
  };

  public clearReAuthToken = (): void => {
    this.bhReAuthToken.clearToken();
  };

  public getReAuthUserId = (): string | null => {
    return this.bhReAuthToken.getUserId();
  };

  public getLivongoAuthToken = (): string | null => {
    return this.livongoAuthToken.getToken();
  };

  public setLivongoAuthToken = (token: string): void => {
    this.livongoAuthToken.setToken(token);
  };

  public clearLivongoAuthToken = (): void => {
    this.livongoAuthToken.clearToken();
  };

  public getMyStrengthUserId = (): number | null => {
    const token = this.bhAuthToken.getToken();
    return token ? this.bhAuthToken.getUserIdentityFromToken(token) : null;
  };

  public getLivongoUserId = (): string | null => {
    return this.livongoAuthToken.getUserId() || this.bhAuthToken.getUserId();
  };

  public getLivongoReAuthToken = (): string | null => {
    return this.livongoReAuthToken.getToken();
  };

  public setLivongoReAuthToken = (token: string): void => {
    this.livongoReAuthToken.setToken(token);
  };

  public clearLivongoReAuthToken = (): void => {
    this.livongoReAuthToken.clearToken();
  };
  public clearAllTokens = (): void => {
    this.clearAuthToken();
    this.clearReAuthToken();
    this.clearLivongoAuthToken();
    this.clearLivongoReAuthToken();
  };

  public getLivongoReAuthUserId = (): string | null => {
    return this.livongoReAuthToken.getUserId();
  };

  public isLivongoToken = (token: string): boolean => {
    if (!token) {
      return false;
    }
    const segments = token.split('.');

    if (segments.length < 2) {
      return false;
    }
    const payload = segments[1];

    try {
      const decodedToken = JSON.parse(atob(payload));
      return (
        decodedToken.iss && decodedToken.iss === AuthService.LIVONGO_JWT_ISSUER
      );
    } catch (error) {
      return false;
    }
  };

  public getClinicalPortalObo = (encodedToken: string): string => {
    if (!encodedToken) {
      return '';
    }
    try {
      const token = decode<clinicalPortalJwtPayload>(encodedToken);
      if (!token || !Array.isArray(token.obo) || token.obo.length === 0) {
        return '';
      }
      return token.obo[0];
    } catch {
      return '';
    }
  };
}

// Exports
//

export type TokenClass = InstanceType<typeof Token>;

export interface Bh10JwtPayload extends JwtPayload {
  permissions: string[];
  userid: number;
}

export interface clinicalPortalJwtPayload extends JwtPayload {
  obo: string[];
}

export default new AuthService();
