export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your crisis plan"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recognize your own distress, and learn strategies to get the help you need and the care you deserve."])},
      "history": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Answers saved ", _interpolate(_named("date"))])},
      "view_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View crisis plan"])},
      "create_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create crisis plan"])},
      "dismiss_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dismiss"])}
    }
  })
}
