export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available on the app"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Want to track your sleep?"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download the myStrength app to log entries in your sleep diary and get a personalized sleep schedule."])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
      "appStoreAlt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download on the App Store"])},
      "playStoreAlt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get it on Google Play"])},
      "phoneImageDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The myStrength app on a mobile device"])},
      "oneAppLabel": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download the Teladoc Health app to log entries in your sleep diary and get a personalized sleep schedule."])}
      }
    }
  })
}
