
import { defineComponent, PropType } from 'vue';
import { RouteLocation } from 'vue-router';

import ImgixSrcService, { ImageFormats } from '@/services/ImgixSrcService';

import CardContent from '@common/cards/CardContent.vue';

enum CARD_VARIANTS {
  MEDIUM = 'MEDIUM',
  SLIDER = 'SLIDER'
}

export default defineComponent({
  name: 'CardCircleImg',
  components: { CardContent },
  props: {
    imgUrl: {
      type: String,
      required: false,
      default: null
    },
    imgDescription: {
      type: String,
      required: false,
      default: null
    },
    title: {
      type: String,
      required: false,
      default: null
    },
    heading: {
      type: String,
      required: false,
      default: null
    },
    description: {
      type: String,
      required: false,
      default: null
    },
    cardCircleColor: {
      type: String,
      required: false,
      default: null
    },
    routeLocation: {
      type: Object as PropType<RouteLocation>,
      required: false,
      default: null
    },
    variantType: {
      type: String,
      required: false,
      default: CARD_VARIANTS.MEDIUM,
      validator: (value: string) => Object.keys(CARD_VARIANTS).includes(value)
    },
    onNavigate: {
      type: Function,
      required: false,
      default: () => null
    }
  },
  data() {
    return {
      paddingHeight: 0
    };
  },
  computed: {
    cardClass(): string {
      return `card-circle-img-${this.variantType}`.toLowerCase();
    },
    cardCircleStyle(): { [key: string]: string } | null {
      return this.cardCircleColor
        ? { 'background-color': this.cardCircleColor }
        : null;
    },
    thumbnailURL(): string {
      return ImgixSrcService.getImageSource(
        this.imgUrl,
        ImageFormats.cardCircleMain
      );
    }
  }
});
