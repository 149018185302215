import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-acd4d89c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input-group-phone-wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "country-selector"
}
const _hoisted_3 = { class: "country-selector" }
const _hoisted_4 = { class: "phone-input-calling-code" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CountrySelector = _resolveComponent("CountrySelector")!
  const _component_BaseInputText = _resolveComponent("BaseInputText")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BaseInputText, {
      class: "phone-input-calling-code",
      ref: "PhoneNumberInput",
      dataCy: _ctx.dataCy || 'phone-input',
      labelText: _ctx.labelText,
      inputName: _ctx.inputName,
      inputModel: _ctx.inputModel,
      validationRules: _ctx.phoneNumberValidationRules,
      "onUpdate:inputModel": _cache[1] || (_cache[1] = ($event: any) => (_ctx.phone = $event))
    }, {
      iconLeft: _withCtx(() => [
        (_ctx.staticCountry)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, "+" + _toDisplayString(_ctx.countryCallingCode), 1))
          : _createCommentVNode("", true),
        (!_ctx.staticCountry)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_CountrySelector, {
                  ref: "CountrySelector",
                  inputName: _ctx.$t('countryCode.name'),
                  inputModel: _ctx.countryCode,
                  "onUpdate:inputModel": _cache[0] || (_cache[0] = ($event: any) => (_ctx.countryCode = $event))
                }, null, 8, ["inputName", "inputModel"])
              ]),
              _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.countryCallingCode), 1)
            ], 64))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["dataCy", "labelText", "inputName", "inputModel", "validationRules"])
  ]))
}