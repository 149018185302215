export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "es",
    "resource": {
      "heading": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu cuenta se ha movido"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilice la aplicación o el sitio web de Teladoc Health para acceder a sus programas."])}
      },
      "nextBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir a Teladoc Health"])},
      "logosDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logotipo de Teladoc y logotipo de myStrength"])}
    }
  })
}
