export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "es",
    "resource": {
      "survey": {
        "intro": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empecemos por conocerte mejor"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizaremos una serie de preguntas; solo tomará unos minutos. Cuanto más aprendamos sobre usted, mejor podremos crear un plan que se adapte a sus necesidades."])},
          "imageDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hojas de arbol verde"])}
        },
        "questions": [
          {
            "prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Qué tienes en mente?"])},
            "answers": [
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reduce el estres"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mejorar el estado de ánimo"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duerme mejor"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relaciones"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uso de sustancias"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algo más"])}
            ]
          },
          {
            "prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Cómo te has sentido últimamente?"])}
          },
          {
            "prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Estás experimentando alguno de estos?"])}
          },
          {
            "prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Está lidiando con algún evento reciente de la vida?"])}
          }
        ],
        "transition": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estamos recopilando sus respuestas"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esto puede llevar un momento."])}
        },
        "highAcuityOutro": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gracias por ser tan abierto"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parece que estás pasando por muchas cosas. Estamos aquí para escuchar, aprender y asegurarnos de que obtenga el nivel de asistencia adecuado. "])}
        },
        "lowAcuityOutro": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gracias por compartir"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esto nos ayuda a comprender por lo que está pasando y en qué le gustaría trabajar. Estamos aquí para asegurarnos de que obtenga el nivel de asistencia adecuado."])}
        },
        "outro": {
          "imageDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una taza de café y flores de aliento de bebé"])}
        }
      },
      "assessment": {
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargando..."])},
        "intro": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profundicemos un poco más"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estas siguientes preguntas están validadas clínicamente para ayudarnos a comprender cómo se ha sentido y realizar un seguimiento de su progreso en las próximas semanas."])},
          "imageDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una lámpara contra una pared verde"])}
        },
        "questionnaire": {
          "PHQ9": {
            "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durante las últimas 2 semanas, ¿qué tan seguido ha tenido molestias debido a los siguientes problemas?"])},
            "questions": {
              "PHQ9:1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poco interés o placer en hacer cosas"])},
              "PHQ9:2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha sentido decaído(a), deprimido(a) o sin esperanzas"])},
              "PHQ9:3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha tenido dificultad para quedarse o permanecer dormido(a), o ha dormido demasiado"])},
              "PHQ9:4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha sentido cansado(a) o con poca energía"])},
              "PHQ9:5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin apetito o ha comido en exceso"])},
              "PHQ9:6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha sentido mal con usted mismo(a) – o que es un fracaso o que ha quedado mal con usted mismo(a) o con su familia"])},
              "PHQ9:7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha tenido dificultad para concentrarse en ciertas actividades, tales como leer el periódico o ver la televisión"])},
              "PHQ9:8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Se ha movido o hablado tan lento que otras personas podrían haberlo notado? o lo contrario – muy inquieto(a) o agitado(a) que ha estado moviéndose mucho más de lo normal"])},
              "PHQ9:9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pensamientos de que estaría mejor muerto(a) o de lastimarse de alguna manera"])}
            },
            "answers": [
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ningún día"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varios días"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más de la mitad de los días"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Casi todos los días"])}
            ]
          },
          "GAD7": {
            "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durante las últimas 2 semanas, ¿con qué frecuencia le han molestado los siguientes problemas?"])},
            "questions": {
              "GAD7:1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha sentido nervioso(a), ansioso(a) o con los nervios de punta"])},
              "GAD7:2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No ha sido capaz de parar o controlar su preocupación"])},
              "GAD7:3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha preocupado demasiado por motivos diferentes"])},
              "GAD7:4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha tenido dificultad para relajarse"])},
              "GAD7:5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha sentido tan inquieto(a) que no ha podido quedarse quieto(a)"])},
              "GAD7:6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha molestado o irritado fácilmente"])},
              "GAD7:7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha tenido miedo de que algo terrible fuera a pasar"])}
            },
            "answers": [
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ningún día"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varios días"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más de la mitad de los días"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Casi todos los días"])}
            ]
          },
          "functionalImpairment": {
            "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durante las últimas 2 semanas"])},
            "questions": {
              "FI1:1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿qué tanta dificultad le han dado estos problemas para hacer su trabajo, encargarse de las tareas del hogar, o llevarse bien con otras personas?"])}
            },
            "answers": [
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No ha sido difícil"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un poco difícil"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muy difícil"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extremadamente difícil"])}
            ]
          },
          "WHO5": {
            "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durante las últimas 2 semanas"])},
            "questions": {
              "WHO5:1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Me he sentido alegre y de buen humor"])},
              "WHO5:2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Me he sentido tranquilo y relajado"])},
              "WHO5:3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Me he sentido activo y enérgico"])},
              "WHO5:4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Me he despertado fresco y descandado"])},
              "WHO5:5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi vida cotidiana ha estado llena de cosas que me interesan"])}
            },
            "answers": [
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todo el tiempo"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La mayor parte del tiempo"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más de la mitad del tiempo"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menos de la mitad del tiempo"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De vez en cuando"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nunca"])}
            ]
          }
        },
        "transition": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estamos elaborando un plan solo para ti..."])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todo lo que ha compartido hoy lo ayudará a guiar sus próximos pasos."])},
          "reassessmentTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estamos recopilando sus respuestas"])},
          "reassessmentText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esto puede tomar un momento"])}
        }
      },
      "programRecommendation": {
        "chiefComplaintHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["También te ayudaremos a navegar:"])},
        "buttonChangePrimaryNeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trabajar en otra cosa"])}
      },
      "planRecommendation": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aquí hay formas en que podemos ayudar"])},
        "primaryStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recomendado"])},
        "otherSteps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["También tendrás acceso a"])},
        "errorHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algo salió mal."])},
        "errorEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Comuníquese con el soporte en "])},
        "errorPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" o por teléfono al "])}
      },
      "crisisIntervention": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No estás solo"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Independientemente de los desafíos por los que esté atravesando en este momento, hay esperanza. Obtenga soporte confidencial gratuito las 24 horas del día, los 7 días de la semana."])},
        "getHelpButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consigue ayuda ahora"])},
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si actualmente está pensando o planea hacerse daño a sí mismo o a otra persona, por favor "])},
        "call911": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["llama al 911"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([", vaya a la sala de emergencias del hospital más cercano o llame a la línea de ayuda para casos de crisis y suicidio al "])},
        "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  o al "])},
        "closeButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar"])}
      },
      "ineligible": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estamos recopilando toda tu información"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gracias por su paciencia mientras confirmamos su elegibilidad; sabremos más en 48 horas. Vuelve entonces."])}
      },
      "error": {
        "404": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Ups! Falta algo."])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No pudimos encontrar la página que buscaba."])}
        },
        "500": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algo salió mal"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estamos teniendo un problema y estamos trabajando duro para volver a encarrilar las cosas. Intente actualizar su navegador y, si el problema persiste, envíe un correo electrónico a Asistencia para miembros a: "])},
          "memberSupportEmailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["membersupport", "@", "livongo.com"])}
        },
        "API": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Oh, oh, algo salió mal!"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubo un error al acceder a esta página. Actualiza tu navegador o contacta "])},
          "memberSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soporte para miembros"])},
          "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" si el problema persiste."])}
        },
        "supportMailTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Necesito%20ayuda%20con%20myStrength"])}
      },
      "progressBarLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progreso de incorporación"])},
      "wrapper": {
        "alertText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este capítulo ya está completo. Haga clic aquí para volver al capítulo correcto."])}
      }
    }
  })
}
