export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "es",
    "resource": {
      "coachLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guía"])},
      "coachImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["imagen de la guía myStrength"])},
      "welcomeMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Encantado de conocerte, ", _interpolate(_named("firstName")), "!"])},
      "guideIntroMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Soy ", _interpolate(_named("guideName")), " y estoy aquí para apoyarte en tu viaje por la salud emocional. Puedo recomendar actividades que apoyen sus objetivos y le den un impulso si se siente estancado. Envíeme un mensaje en cualquier momento; por lo general, respondo dentro de las 24 horas."])},
      "inputIntroPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuéntame sobre ti y cómo esperas poder ayudarte."])},
      "showBio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar bio"])},
      "hideBio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocultar bio"])},
      "noGuideProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay perfil disponible para esta guía"])},
      "sendMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar mensaje"])},
      "messageSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Mensaje enviado!"])},
      "messageSentDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su guía generalmente responde dentro de las 24 horas."])},
      "messageFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El mensaje no se pudo enviar."])},
      "messageFailedDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inténtelo de nuevo en un momento"])},
      "retrieveMessagesError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los mensajes de guía no se pudieron recuperar en este momento."])},
      "attachmentTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Título del archivo adjunto: ", _interpolate(_named("title"))])},
      "closeGuideDrawer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar Guía"])}
    }
  })
}
