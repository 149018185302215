import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-290ac436"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "explore-title-wrapper" }
const _hoisted_2 = { class: "title-lockup" }
const _hoisted_3 = { class: "title-wrapper" }
const _hoisted_4 = {
  key: 0,
  class: "first-row"
}
const _hoisted_5 = {
  key: 0,
  class: "description"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BackButton = _resolveComponent("BackButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BackButton, {
        class: "back-button",
        backPath: _ctx.backPath || _ctx.fromPath,
        highlighted: true,
        onClick: _ctx.goBack
      }, null, 8, ["backPath", "onClick"]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", {
          class: "heading-xl title",
          tabindex: "-1",
          ref: "title"
        }, _toDisplayString(_ctx.title), 513)
      ]),
      (_ctx.$slots.firstRow)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, "firstRow", {}, undefined, true)
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.description)
      ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.description), 1))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ]))
}