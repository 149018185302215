export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "es",
    "resource": {
      "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Si tiene una crisis o necesita hablar con alguien de inmediato, ", _interpolate(_list(0))])},
      "linkText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["puede encontrar ayuda aquí."])}
    }
  })
}
