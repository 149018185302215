import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

export default defineComponent({
  computed: {
    displayLogo(): boolean {
      return !this.isOneApp;
    },
    ...mapGetters(['isOneApp'])
  },
  methods: {
    getOneAppDestination(param: string) {
      if (this.isOneApp && param === 'guide') {
        return `${param}:oneapp`;
      }
      return param;
    }
  }
});
