export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
      "personalization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalization"])},
      "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
      "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
      "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect"])},
      "helpResources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help resources"])},
      "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore"])},
      "browse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browse"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
      "legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal"])},
      "termsOfUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Use"])},
      "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])}
    }
  })
}
