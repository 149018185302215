
import { defineComponent } from 'vue';

import CircleQuestion from '@svg/circle-question.svg';
import CircleClose from '@svg/circle-close.svg';

export default defineComponent({
  name: 'Tooltip',
  components: {
    CircleQuestion,
    CircleClose
  },
  data() {
    return {
      isOpen: false
    };
  },
  methods: {
    toggleOpen() {
      this.isOpen = !this.isOpen;
    }
  }
});
