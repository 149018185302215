import { ActionTree, GetterTree, MutationTree } from 'vuex';

import HTTP from '@/services/HttpService';
import apiRoutes from '@config/api-routes';

import { TimeObject, TIME_PICKER_LIMIT } from '@/types/SleepTypes';
import { RootState } from '@/types/storeTypes';
import { AxiosPromise } from 'axios';

const SLEEP_PROGRAM_ID = 29978;
const DEFAULT_RISE_TIME = {
  hour: 6,
  minute: 15
};
const TIME_PICKER_LIMIT_MINUTES = 120;
export interface SleepState {
  sleepProgramId: number;
  diary: {
    recommendedMinutesInBed: number;
    minimumMinutesAsleep: number;
    inBedTime: number[];
    wakeUpTime: number[];
  };
  sleepSchedule: {
    bedTime: null | TimeObject;
    riseTime: null | TimeObject;
    timeInBed: null;
  };
  sleepCheckInResponse: number[];
}
/**
 * This store module is for Sleep related data
 */
const initialState = {
  sleepProgramId: SLEEP_PROGRAM_ID,
  diary: null,
  sleepSchedule: {
    bedTime: null,
    riseTime: null,
    timeInBed: null
  },
  sleepCheckInResponse: []
};

const state = { ...initialState };

const getters: GetterTree<SleepState, RootState> = {
  sleepProgramId: (state: SleepState) => state.sleepProgramId,
  diary: (state: SleepState) => state.diary,
  diaryLoaded: (state) =>
    typeof state.diary?.recommendedMinutesInBed !== 'undefined',
  sleepSchedule: (state) => {
    return state.sleepSchedule;
  },
  fallBackBedTime: (state) => {
    const minutesAsleep: number = state.diary.recommendedMinutesInBed
      ? state.diary.recommendedMinutesInBed
      : state.diary.minimumMinutesAsleep;
    const riseTime: TimeObject = state.sleepSchedule.riseTime as TimeObject;
    // Calculates a fallback bed time
    // - based on riseTime and recommended minutes asleep
    const hoursToSubtract = Math.floor(minutesAsleep / 60);
    const minutesToSubtract = minutesAsleep % 60;
    let hour = riseTime.hour - hoursToSubtract;
    let minute = riseTime.minute - minutesToSubtract;
    //round minute to 15 minute intervals
    minute = minute / 15 < 0.5 ? 0 : Math.ceil(minute / 15) * 15;

    if (minute < 0) {
      minute = 60 - Math.abs(minute);
      hour = hour - 1;
    }
    // Rollover minute >= 60
    if (minute >= 60) {
      minute = minute - 60;
      hour = hour + 1;
    }
    if (hour < 0) {
      hour = 24 - Math.abs(hour);
    }

    return {
      hour,
      minute
    };
  },
  riseTimeBoundary: (state) => {
    const riseTime = state.sleepSchedule?.riseTime;
    const diaryRiseTime = state.diary?.wakeUpTime;
    if (!riseTime || !diaryRiseTime) {
      return null;
    }
    const riseTimeMinutes = riseTime.hour * 60 + riseTime.minute;
    const diaryRiseTimeMinutes = diaryRiseTime[0] * 60 + diaryRiseTime[1];
    const diffMinutes = riseTimeMinutes - diaryRiseTimeMinutes;
    if (diffMinutes >= TIME_PICKER_LIMIT_MINUTES) {
      return TIME_PICKER_LIMIT.MORE_THAN_LIMIT;
    } else if (-diffMinutes >= TIME_PICKER_LIMIT_MINUTES) {
      return TIME_PICKER_LIMIT.LESS_THAN_LIMIT;
    } else {
      return TIME_PICKER_LIMIT.WITHIN_LIMIT;
    }
  },
  bedTimeBoundary: (state) => {
    const bedTime = state.sleepSchedule?.bedTime;
    const diaryBedTime = state.diary?.inBedTime;
    if (!bedTime || !diaryBedTime) {
      return null;
    }
    let bedTimeMinutes = bedTime.hour * 60 + bedTime.minute;
    let diaryBedTimeMinutes = diaryBedTime[0] * 60 + diaryBedTime[1];

    //if before 12pm assume it's the next day
    if (bedTimeMinutes < 624) {
      bedTimeMinutes += 1440;
    }
    //if before 12pm assume it's the next day
    if (diaryBedTimeMinutes < 624) {
      diaryBedTimeMinutes += 1440;
    }

    const diffMinutes = bedTimeMinutes - diaryBedTimeMinutes;
    if (diffMinutes >= TIME_PICKER_LIMIT_MINUTES) {
      return TIME_PICKER_LIMIT.MORE_THAN_LIMIT;
    } else if (-diffMinutes >= TIME_PICKER_LIMIT_MINUTES) {
      return TIME_PICKER_LIMIT.LESS_THAN_LIMIT;
    } else {
      return TIME_PICKER_LIMIT.WITHIN_LIMIT;
    }
  },
  sleepCheckInResponse: (state) => {
    return state.sleepCheckInResponse;
  },
  sleepCheckInResponseScore: (state) => {
    return state.sleepCheckInResponse.length > 0
      ? state.sleepCheckInResponse.reduce(
          (prevValue, curValue) => prevValue + curValue
        )
      : 0;
  }
};

const actions: ActionTree<SleepState, RootState> = {
  enableSleepDiary() {
    return HTTP.post(apiRoutes.user.update, {
      optedIntoSleepDiary: true
    });
  },
  getSleepDiary({ commit }, forWeeklyCheckin = false) {
    return HTTP.get(
      `${apiRoutes.sleep.getDiary}?forWeeklyCheckin=${forWeeklyCheckin}`
    ).then(async (response) => {
      await commit('setDiary', response.data);
    });
  },
  setInitialRiseTime({ state, commit }) {
    const wakeUpTime = state.diary?.wakeUpTime;
    const riseTime =
      wakeUpTime && wakeUpTime.length == 2
        ? {
            hour: wakeUpTime[0],
            minute: wakeUpTime[1]
          }
        : DEFAULT_RISE_TIME;
    commit('setRiseTime', riseTime);
  },
  async setInitialBedTime({ state, commit, dispatch, getters }) {
    //check if rise time is set before bed time is se
    if (!state.sleepSchedule.riseTime) {
      await dispatch('setInitialRiseTime');
    }
    const inBedTime = state.diary?.inBedTime ?? undefined;
    const bedTime =
      inBedTime && inBedTime.length == 2
        ? {
            hour: inBedTime[0],
            minute: inBedTime[1]
          }
        : getters.fallBackBedTime;

    commit('setBedTime', bedTime);
    dispatch('updateTimeInBedTime');
  },
  setSleepCheckinResponse({ commit }, { score, index }) {
    commit('setSleepCheckinResponse', { score, index });
  },

  async setRiseTime({ dispatch, commit }, riseTime) {
    await commit('setRiseTime', riseTime);
    dispatch('updateTimeInBedTime');
  },
  async setBedTime({ dispatch, commit }, bedTime) {
    await commit('setBedTime', bedTime);
    dispatch('updateTimeInBedTime');
  },
  updateTimeInBedTime({ state, commit }) {
    const bedTime = state.sleepSchedule.bedTime;
    const riseTime = state.sleepSchedule.riseTime;
    //exit if bedTime or riseTime aren't set
    if (!bedTime || !riseTime) {
      return;
    }

    let minutes;
    if (riseTime.hour > bedTime.hour) {
      const riseTimeMinutes = riseTime.hour * 60 + riseTime.minute;
      const bedTimeMinutes = bedTime.hour * 60 + bedTime.minute;
      minutes = riseTimeMinutes - bedTimeMinutes;
    } else if (bedTime.hour > riseTime.hour) {
      //if bedTimeHour is greater than riseTimeHour, assume it's the next day and add 24 hours.
      const riseTimeMinutes = 24 * 60 + riseTime.hour * 60 + riseTime.minute;
      const bedTimeMinutes = bedTime.hour * 60 + bedTime.minute;
      minutes = riseTimeMinutes - bedTimeMinutes;
    } else if (bedTime.minute > riseTime.minute) {
      //if bedTimeHour and riseTimeHour equals, check the minutes.
      // If bedTimeMinute is greater than riseTimeMinute assume it's the next day and add 24 hours.
      minutes = 24 * 60 + riseTime.minute - bedTime.minute;
    } else {
      minutes = bedTime.minute - riseTime.minute;
    }

    //round time to nearest 15 minute
    minutes = minutes / 15 < 0.5 ? 0 : Math.ceil(minutes / 15) * 15;

    commit('setTimeInBed', {
      hour: Math.floor(minutes / 60),
      minute: minutes % 60
    });
  },
  saveSleepSchedule(store, payload) {
    return HTTP.post(apiRoutes.sleep.saveSchedule, payload);
  },
  saveSleepCheckin({ getters }): Promise<AxiosPromise | void> {
    const payload = {
      checkInStatus: 'COMPLETE',
      quizScore: {
        score: getters.sleepCheckInResponseScore
      }
    };
    return HTTP.post(apiRoutes.sleep.saveSleepCheckin, payload);
  }
};

const mutations: MutationTree<SleepState> = {
  setDiary(state, diary) {
    // Happens when a user has not completed pre-requisite sleep setup steps
    if (diary.averages === null) {
      diary.averages = {
        minutesAsleep: 0,
        sleepEfficiencyPercent: 0
      };
    }
    // Mark if the user has completed pre-requisite steps
    diary.readyForSleepSetup = diary.averages !== null;

    // Set state
    state.diary = diary;
  },
  setRiseTime(state, riseTime) {
    state.sleepSchedule.riseTime = riseTime;
    if (!state.diary?.wakeUpTime) {
      state.diary.wakeUpTime = [riseTime.hour, riseTime.minute];
    }
  },
  setBedTime(state, bedTime) {
    state.sleepSchedule.bedTime = bedTime;
    if (!state.diary.inBedTime) {
      state.diary.inBedTime = [bedTime.hour, bedTime.minute];
    }
  },
  setTimeInBed(state, timeInBed) {
    state.sleepSchedule.timeInBed = timeInBed;
  },
  setSleepCheckinResponse(state, { score, index }) {
    state.sleepCheckInResponse[index] = score;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
