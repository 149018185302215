export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "es",
    "resource": {
      "guideButtonAriaOpen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abra el panel de guía de myStrength"])},
      "guideButtonAriaClose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar el panel de guía de myStrength"])},
      "guideLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guía"])},
      "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menú"])},
      "menuAriaOpen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abre el menú"])},
      "menuAriaClose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar el menú"])},
      "guideImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["imagen de myStrength Guide"])},
      "oneAppLabel": {
        "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salud mental"])},
        "Connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectar"])},
        "Explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explorar"])},
        "Progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progreso"])},
        "Personalization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferencias"])},
        "HelpResources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recursos"])},
        "History": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historia"])},
        "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar"])},
        "Browse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navegar"])},
        "Program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programa"])},
        "Series": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serie"])},
        "Activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividad"])},
        "Topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tema"])},
        "ActivityResponses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respuestas a la actividad"])},
        "Category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoría"])},
        "Channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal"])},
        "Teletherapy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teleterapia"])},
        "Guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["guía"])},
        "Favorites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favoritas"])},
        "ConnectClinicalCoaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectar"])},
        "ClinicalCoachingGetStarted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching: empezar"])},
        "ClinicalCoachingSchedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programar sesiones de coaching"])},
        "ClinicalCoachingAbout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acerca del coaching"])},
        "ClinicalCoachingCoachBio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrenador Bio"])},
        "ClinicalPortalConference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferencia del Portal Clínico"])}
      }
    }
  })
}
