
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import NavigationProfile from '@/components/navigation/NavigationProfile.vue';
export default defineComponent({
  name: 'ProfileLayout',
  components: {
    NavigationProfile
  },
  computed: {
    title() {
      return this.$t(`${this.$route.name?.toLowerCase()}Title`);
    },
    subtitle() {
      const subTitleKey = `${this.$route.name?.toLowerCase()}Subtitle`;
      return this.$te(subTitleKey) ? this.$t(subTitleKey) : null;
    },
    profileBh10() {
      return this.$route.name === 'Profile' && this.isLegacyUser;
    },
    ...mapGetters(['isLegacyUser', 'isMobileWebView'])
  }
});
