export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "instructions": {
        "sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new password that includes:"])},
        "lowerCaseRule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least one lowercase letter"])},
        "capitalRule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least one capital letter"])},
        "numberRule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least one number"])},
        "sizeRule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least 8 characters"])}
      },
      "passwordResetForm": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset your password"])},
        "currentPassword": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["currentPassword"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Password"])}
        },
        "newPassword": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["newPassword"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])}
        },
        "confirmPassword": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["confirmPassword"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])}
        },
        "resetBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Password"])},
        "passwordMatchError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This password does not match"])},
        "matchesPrevPasswordError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password cannot match a previously used password"])}
      },
      "passwordExpiredForm": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password is expired"])},
        "updateBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Password"])}
      },
      "confirmationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Successful"])},
      "confirmationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password has been changed. You can now use your new password to sign in to myStrength."])},
      "backToLoginBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to Sign In"])},
      "updateFailureHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong"])},
      "updateProfileFailureDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We were unable to update your profile"])},
      "incorrectCurrentPasswordDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your current password must be correct before you can create a new password."])}
    }
  })
}
