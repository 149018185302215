import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "one-app-error" }
const _hoisted_2 = { class: "content-wrapper" }
const _hoisted_3 = ["alt"]
const _hoisted_4 = { class: "action-title" }
const _hoisted_5 = {
  key: 0,
  class: "action-description"
}
const _hoisted_6 = ["href"]
const _hoisted_7 = {
  key: 1,
  class: "action-description"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: "alert-icon one-app-icon-error",
        src: "/img/one_app/error.png",
        alt: _ctx.title
      }, null, 8, _hoisted_3),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.title), 1),
      (_ctx.emailAddress)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createTextVNode(_toDisplayString(_ctx.description), 1),
            _createElementVNode("a", { href: _ctx.email }, _toDisplayString(_ctx.emailText), 9, _hoisted_6)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.description), 1)),
      (_ctx.navigate)
        ? (_openBlock(), _createBlock(_component_BaseButton, {
            key: 2,
            class: "base-btn-solid-primary btn-primary active",
            onClick: _cache[0] || (_cache[0] = () => _ctx.window.parent.postMessage(_ctx.navigate))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.buttonLabel), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}