export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find something you love?"])},
      "headerToggled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You haven’t added any favorites yet."])},
      "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tap the heart icon to add it to your favorites."])},
      "instructionsToggled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tap the heart icon to add something to your favorites. It will show up here for easy access."])}
    }
  })
}
