// source: livongo/protobuf/grpc/external/bh/wellness_assessments/wellness_assessments.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var livongo_protobuf_common_bh_language_pb = require('../../../../../../livongo/protobuf/common/bh/language_pb.js');
goog.object.extend(proto, livongo_protobuf_common_bh_language_pb);
goog.exportSymbol('proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Answer', null, global);
goog.exportSymbol('proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Assessment', null, global);
goog.exportSymbol('proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResponse', null, global);
goog.exportSymbol('proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResult', null, global);
goog.exportSymbol('proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetake', null, global);
goog.exportSymbol('proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetakeResultContent', null, global);
goog.exportSymbol('proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentSubmission', null, global);
goog.exportSymbol('proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentType', null, global);
goog.exportSymbol('proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentType.Values', null, global);
goog.exportSymbol('proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesRequest', null, global);
goog.exportSymbol('proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesResponse', null, global);
goog.exportSymbol('proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Iconography', null, global);
goog.exportSymbol('proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Iconography.Values', null, global);
goog.exportSymbol('proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentRetakesRequest', null, global);
goog.exportSymbol('proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentRetakesResponse', null, global);
goog.exportSymbol('proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsRequest', null, global);
goog.exportSymbol('proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsResponse', null, global);
goog.exportSymbol('proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentRequest', null, global);
goog.exportSymbol('proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentResponse', null, global);
goog.exportSymbol('proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryRequest', null, global);
goog.exportSymbol('proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryResponse', null, global);
goog.exportSymbol('proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.RetakeResultAssessmentType', null, global);
goog.exportSymbol('proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.RetakeResultAssessmentType.Values', null, global);
goog.exportSymbol('proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeRequest', null, global);
goog.exportSymbol('proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeResponse', null, global);
goog.exportSymbol('proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeResponse.ResponseStatus', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsRequest.displayName = 'proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsResponse.repeatedFields_, null);
};
goog.inherits(proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsResponse.displayName = 'proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesRequest.repeatedFields_, null);
};
goog.inherits(proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesRequest.displayName = 'proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesResponse.displayName = 'proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentRetakesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentRetakesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentRetakesRequest.displayName = 'proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentRetakesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentRetakesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentRetakesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentRetakesResponse.displayName = 'proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentRetakesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeRequest.displayName = 'proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeResponse.displayName = 'proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentRequest.displayName = 'proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentResponse.repeatedFields_, null);
};
goog.inherits(proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentResponse.displayName = 'proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetakeResultContent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetakeResultContent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetakeResultContent.displayName = 'proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetakeResultContent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.RetakeResultAssessmentType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.RetakeResultAssessmentType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.RetakeResultAssessmentType.displayName = 'proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.RetakeResultAssessmentType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Iconography = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Iconography, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Iconography.displayName = 'proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Iconography';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Assessment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Assessment.repeatedFields_, null);
};
goog.inherits(proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Assessment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Assessment.displayName = 'proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Assessment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Answer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Answer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Answer.displayName = 'proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Answer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResponse.repeatedFields_, null);
};
goog.inherits(proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResponse.displayName = 'proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentType.displayName = 'proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetake = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetake, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetake.displayName = 'proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetake';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryRequest.displayName = 'proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryResponse.repeatedFields_, null);
};
goog.inherits(proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryResponse.displayName = 'proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentSubmission = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentSubmission.repeatedFields_, null);
};
goog.inherits(proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentSubmission, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentSubmission.displayName = 'proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentSubmission';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResult.displayName = 'proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResult';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    parent: jspb.Message.getFieldWithDefault(msg, 1, ""),
    language: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsRequest}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsRequest;
  return proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsRequest}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setParent(value);
      break;
    case 2:
      var value = /** @type {!proto.com.livongo.protobuf.common.bh.Language.Values} */ (reader.readEnum());
      msg.setLanguage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string parent = 1;
 * @return {string}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsRequest.prototype.getParent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsRequest} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsRequest.prototype.setParent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional com.livongo.protobuf.common.bh.Language.Values language = 2;
 * @return {!proto.com.livongo.protobuf.common.bh.Language.Values}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsRequest.prototype.getLanguage = function() {
  return /** @type {!proto.com.livongo.protobuf.common.bh.Language.Values} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.com.livongo.protobuf.common.bh.Language.Values} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsRequest} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsRequest.prototype.setLanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    assessmentList: jspb.Message.toObjectList(msg.getAssessmentList(),
    proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Assessment.toObject, includeInstance),
    fullPhq9Required: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    fullGad7Required: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsResponse}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsResponse;
  return proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsResponse}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Assessment;
      reader.readMessage(value,proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Assessment.deserializeBinaryFromReader);
      msg.addAssessment(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFullPhq9Required(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFullGad7Required(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssessmentList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Assessment.serializeBinaryToWriter
    );
  }
  f = message.getFullPhq9Required();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getFullGad7Required();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * repeated Assessment assessment = 1;
 * @return {!Array<!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Assessment>}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsResponse.prototype.getAssessmentList = function() {
  return /** @type{!Array<!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Assessment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Assessment, 1));
};


/**
 * @param {!Array<!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Assessment>} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsResponse} returns this
*/
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsResponse.prototype.setAssessmentList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Assessment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Assessment}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsResponse.prototype.addAssessment = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Assessment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsResponse} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsResponse.prototype.clearAssessmentList = function() {
  return this.setAssessmentList([]);
};


/**
 * optional bool full_phq9_required = 2;
 * @return {boolean}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsResponse.prototype.getFullPhq9Required = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsResponse} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsResponse.prototype.setFullPhq9Required = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool full_gad7_required = 3;
 * @return {boolean}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsResponse.prototype.getFullGad7Required = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsResponse} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentsResponse.prototype.setFullGad7Required = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    parent: jspb.Message.getFieldWithDefault(msg, 1, ""),
    responsesList: jspb.Message.toObjectList(msg.getResponsesList(),
    proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesRequest}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesRequest;
  return proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesRequest}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setParent(value);
      break;
    case 2:
      var value = new proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResponse;
      reader.readMessage(value,proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResponse.deserializeBinaryFromReader);
      msg.addResponses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getResponsesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional string parent = 1;
 * @return {string}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesRequest.prototype.getParent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesRequest} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesRequest.prototype.setParent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated AssessmentResponse responses = 2;
 * @return {!Array<!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResponse>}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesRequest.prototype.getResponsesList = function() {
  return /** @type{!Array<!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResponse, 2));
};


/**
 * @param {!Array<!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResponse>} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesRequest} returns this
*/
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesRequest.prototype.setResponsesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResponse}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesRequest.prototype.addResponses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesRequest} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesRequest.prototype.clearResponsesList = function() {
  return this.setResponsesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    hasSi: (f = msg.getHasSi()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesResponse}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesResponse;
  return proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesResponse}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setHasSi(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHasSi();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.BoolValue has_si = 1;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesResponse.prototype.getHasSi = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 1));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesResponse} returns this
*/
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesResponse.prototype.setHasSi = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesResponse} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesResponse.prototype.clearHasSi = function() {
  return this.setHasSi(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.CreateAssessmentsResponsesResponse.prototype.hasHasSi = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentRetakesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentRetakesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentRetakesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentRetakesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    parent: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentRetakesRequest}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentRetakesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentRetakesRequest;
  return proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentRetakesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentRetakesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentRetakesRequest}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentRetakesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setParent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentRetakesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentRetakesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentRetakesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentRetakesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string parent = 1;
 * @return {string}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentRetakesRequest.prototype.getParent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentRetakesRequest} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentRetakesRequest.prototype.setParent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentRetakesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentRetakesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentRetakesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentRetakesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    assessmentRetake: (f = msg.getAssessmentRetake()) && proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetake.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentRetakesResponse}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentRetakesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentRetakesResponse;
  return proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentRetakesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentRetakesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentRetakesResponse}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentRetakesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetake;
      reader.readMessage(value,proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetake.deserializeBinaryFromReader);
      msg.setAssessmentRetake(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentRetakesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentRetakesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentRetakesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentRetakesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssessmentRetake();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetake.serializeBinaryToWriter
    );
  }
};


/**
 * optional AssessmentRetake assessment_retake = 1;
 * @return {?proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetake}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentRetakesResponse.prototype.getAssessmentRetake = function() {
  return /** @type{?proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetake} */ (
    jspb.Message.getWrapperField(this, proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetake, 1));
};


/**
 * @param {?proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetake|undefined} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentRetakesResponse} returns this
*/
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentRetakesResponse.prototype.setAssessmentRetake = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentRetakesResponse} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentRetakesResponse.prototype.clearAssessmentRetake = function() {
  return this.setAssessmentRetake(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListAssessmentRetakesResponse.prototype.hasAssessmentRetake = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeRequest}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeRequest;
  return proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeRequest}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeRequest} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeResponse}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeResponse;
  return proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeResponse}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeResponse.ResponseStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeResponse.ResponseStatus = {
  INVALID: 0,
  ACCEPTED: 1,
  MANDATORY_RETAKE: 2,
  NO_ACTIVE_RETAKE_FOUND: 3
};

/**
 * optional string name = 1;
 * @return {string}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeResponse} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ResponseStatus status = 2;
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeResponse.ResponseStatus}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeResponse.prototype.getStatus = function() {
  return /** @type {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeResponse.ResponseStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeResponse.ResponseStatus} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeResponse} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.SkipAssessmentRetakeResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    parent: jspb.Message.getFieldWithDefault(msg, 1, ""),
    language: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentRequest}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentRequest;
  return proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentRequest}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setParent(value);
      break;
    case 2:
      var value = /** @type {!proto.com.livongo.protobuf.common.bh.Language.Values} */ (reader.readEnum());
      msg.setLanguage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string parent = 1;
 * @return {string}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentRequest.prototype.getParent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentRequest} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentRequest.prototype.setParent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional com.livongo.protobuf.common.bh.Language.Values language = 2;
 * @return {!proto.com.livongo.protobuf.common.bh.Language.Values}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentRequest.prototype.getLanguage = function() {
  return /** @type {!proto.com.livongo.protobuf.common.bh.Language.Values} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.com.livongo.protobuf.common.bh.Language.Values} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentRequest} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentRequest.prototype.setLanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    mainHeadline: jspb.Message.getFieldWithDefault(msg, 1, ""),
    mainFeedback: jspb.Message.getFieldWithDefault(msg, 2, ""),
    assessmentRetakeResultsList: jspb.Message.toObjectList(msg.getAssessmentRetakeResultsList(),
    proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetakeResultContent.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentResponse}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentResponse;
  return proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentResponse}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMainHeadline(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMainFeedback(value);
      break;
    case 3:
      var value = new proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetakeResultContent;
      reader.readMessage(value,proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetakeResultContent.deserializeBinaryFromReader);
      msg.addAssessmentRetakeResults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMainHeadline();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMainFeedback();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAssessmentRetakeResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetakeResultContent.serializeBinaryToWriter
    );
  }
};


/**
 * optional string main_headline = 1;
 * @return {string}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentResponse.prototype.getMainHeadline = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentResponse} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentResponse.prototype.setMainHeadline = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string main_feedback = 2;
 * @return {string}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentResponse.prototype.getMainFeedback = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentResponse} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentResponse.prototype.setMainFeedback = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated AssessmentRetakeResultContent assessment_retake_results = 3;
 * @return {!Array<!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetakeResultContent>}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentResponse.prototype.getAssessmentRetakeResultsList = function() {
  return /** @type{!Array<!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetakeResultContent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetakeResultContent, 3));
};


/**
 * @param {!Array<!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetakeResultContent>} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentResponse} returns this
*/
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentResponse.prototype.setAssessmentRetakeResultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetakeResultContent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetakeResultContent}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentResponse.prototype.addAssessmentRetakeResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetakeResultContent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentResponse} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListRetakeResultContentResponse.prototype.clearAssessmentRetakeResultsList = function() {
  return this.setAssessmentRetakeResultsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetakeResultContent.prototype.toObject = function(opt_includeInstance) {
  return proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetakeResultContent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetakeResultContent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetakeResultContent.toObject = function(includeInstance, msg) {
  var f, obj = {
    assessmentType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    assessmentHeadline: jspb.Message.getFieldWithDefault(msg, 2, ""),
    assessmentFeedback: jspb.Message.getFieldWithDefault(msg, 3, ""),
    assessmentIconography: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetakeResultContent}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetakeResultContent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetakeResultContent;
  return proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetakeResultContent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetakeResultContent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetakeResultContent}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetakeResultContent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.RetakeResultAssessmentType.Values} */ (reader.readEnum());
      msg.setAssessmentType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssessmentHeadline(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssessmentFeedback(value);
      break;
    case 4:
      var value = /** @type {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Iconography.Values} */ (reader.readEnum());
      msg.setAssessmentIconography(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetakeResultContent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetakeResultContent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetakeResultContent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetakeResultContent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssessmentType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getAssessmentHeadline();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAssessmentFeedback();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAssessmentIconography();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional RetakeResultAssessmentType.Values assessment_type = 1;
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.RetakeResultAssessmentType.Values}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetakeResultContent.prototype.getAssessmentType = function() {
  return /** @type {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.RetakeResultAssessmentType.Values} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.RetakeResultAssessmentType.Values} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetakeResultContent} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetakeResultContent.prototype.setAssessmentType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string assessment_headline = 2;
 * @return {string}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetakeResultContent.prototype.getAssessmentHeadline = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetakeResultContent} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetakeResultContent.prototype.setAssessmentHeadline = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string assessment_feedback = 3;
 * @return {string}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetakeResultContent.prototype.getAssessmentFeedback = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetakeResultContent} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetakeResultContent.prototype.setAssessmentFeedback = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Iconography.Values assessment_iconography = 4;
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Iconography.Values}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetakeResultContent.prototype.getAssessmentIconography = function() {
  return /** @type {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Iconography.Values} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Iconography.Values} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetakeResultContent} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetakeResultContent.prototype.setAssessmentIconography = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.RetakeResultAssessmentType.prototype.toObject = function(opt_includeInstance) {
  return proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.RetakeResultAssessmentType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.RetakeResultAssessmentType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.RetakeResultAssessmentType.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.RetakeResultAssessmentType}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.RetakeResultAssessmentType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.RetakeResultAssessmentType;
  return proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.RetakeResultAssessmentType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.RetakeResultAssessmentType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.RetakeResultAssessmentType}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.RetakeResultAssessmentType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.RetakeResultAssessmentType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.RetakeResultAssessmentType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.RetakeResultAssessmentType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.RetakeResultAssessmentType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.RetakeResultAssessmentType.Values = {
  INVALID: 0,
  PHQ9: 1,
  GAD7: 2,
  WHO5: 3
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Iconography.prototype.toObject = function(opt_includeInstance) {
  return proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Iconography.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Iconography} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Iconography.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Iconography}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Iconography.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Iconography;
  return proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Iconography.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Iconography} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Iconography}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Iconography.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Iconography.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Iconography.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Iconography} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Iconography.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Iconography.Values = {
  INVALID: 0,
  GAUGE_POINTING_RIGHT: 1,
  GAUGE_POINTING_UP: 2,
  GAUGE_POINTING_LEFT: 3
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Assessment.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Assessment.prototype.toObject = function(opt_includeInstance) {
  return proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Assessment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Assessment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Assessment.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    assessmentType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    prompt: jspb.Message.getFieldWithDefault(msg, 3, ""),
    questionsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    answersList: jspb.Message.toObjectList(msg.getAnswersList(),
    proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Answer.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Assessment}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Assessment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Assessment;
  return proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Assessment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Assessment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Assessment}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Assessment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentType.Values} */ (reader.readEnum());
      msg.setAssessmentType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrompt(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addQuestions(value);
      break;
    case 5:
      var value = new proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Answer;
      reader.readMessage(value,proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Answer.deserializeBinaryFromReader);
      msg.addAnswers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Assessment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Assessment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Assessment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Assessment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAssessmentType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getPrompt();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getQuestionsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getAnswersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Answer.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Assessment.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Assessment} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Assessment.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional AssessmentType.Values assessment_type = 2;
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentType.Values}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Assessment.prototype.getAssessmentType = function() {
  return /** @type {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentType.Values} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentType.Values} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Assessment} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Assessment.prototype.setAssessmentType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string prompt = 3;
 * @return {string}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Assessment.prototype.getPrompt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Assessment} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Assessment.prototype.setPrompt = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string questions = 4;
 * @return {!Array<string>}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Assessment.prototype.getQuestionsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Assessment} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Assessment.prototype.setQuestionsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Assessment} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Assessment.prototype.addQuestions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Assessment} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Assessment.prototype.clearQuestionsList = function() {
  return this.setQuestionsList([]);
};


/**
 * repeated Answer answers = 5;
 * @return {!Array<!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Answer>}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Assessment.prototype.getAnswersList = function() {
  return /** @type{!Array<!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Answer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Answer, 5));
};


/**
 * @param {!Array<!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Answer>} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Assessment} returns this
*/
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Assessment.prototype.setAnswersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Answer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Answer}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Assessment.prototype.addAnswers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Answer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Assessment} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Assessment.prototype.clearAnswersList = function() {
  return this.setAnswersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Answer.prototype.toObject = function(opt_includeInstance) {
  return proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Answer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Answer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Answer.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: jspb.Message.getFieldWithDefault(msg, 1, ""),
    weight: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Answer}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Answer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Answer;
  return proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Answer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Answer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Answer}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Answer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWeight(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Answer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Answer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Answer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Answer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWeight();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string text = 1;
 * @return {string}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Answer.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Answer} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Answer.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 weight = 2;
 * @return {number}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Answer.prototype.getWeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Answer} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.Answer.prototype.setWeight = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    assessmentType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    answersList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResponse}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResponse;
  return proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResponse}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentType.Values} */ (reader.readEnum());
      msg.setAssessmentType(value);
      break;
    case 3:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setAnswersList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAssessmentType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getAnswersList();
  if (f.length > 0) {
    writer.writePackedInt32(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResponse} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional AssessmentType.Values assessment_type = 2;
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentType.Values}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResponse.prototype.getAssessmentType = function() {
  return /** @type {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentType.Values} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentType.Values} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResponse} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResponse.prototype.setAssessmentType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * repeated int32 answers = 3;
 * @return {!Array<number>}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResponse.prototype.getAnswersList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResponse} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResponse.prototype.setAnswersList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResponse} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResponse.prototype.addAnswers = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResponse} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResponse.prototype.clearAnswersList = function() {
  return this.setAnswersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentType.prototype.toObject = function(opt_includeInstance) {
  return proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentType.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentType}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentType;
  return proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentType}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentType.Values = {
  INVALID: 0,
  PHQ9: 1,
  GAD7: 2,
  FUNCTIONAL_IMPAIRMENT: 3,
  WHO5: 4
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetake.prototype.toObject = function(opt_includeInstance) {
  return proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetake.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetake} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetake.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isRequired: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetake}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetake.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetake;
  return proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetake.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetake} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetake}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetake.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsRequired(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetake.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetake.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetake} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetake.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsRequired();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetake.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetake} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetake.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool is_required = 2;
 * @return {boolean}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetake.prototype.getIsRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetake} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentRetake.prototype.setIsRequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    parent: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryRequest}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryRequest;
  return proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryRequest}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setParent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string parent = 1;
 * @return {string}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryRequest.prototype.getParent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryRequest} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryRequest.prototype.setParent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    submissionsList: jspb.Message.toObjectList(msg.getSubmissionsList(),
    proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentSubmission.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryResponse}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryResponse;
  return proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryResponse}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentSubmission;
      reader.readMessage(value,proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentSubmission.deserializeBinaryFromReader);
      msg.addSubmissions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubmissionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentSubmission.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AssessmentSubmission submissions = 1;
 * @return {!Array<!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentSubmission>}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryResponse.prototype.getSubmissionsList = function() {
  return /** @type{!Array<!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentSubmission>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentSubmission, 1));
};


/**
 * @param {!Array<!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentSubmission>} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryResponse} returns this
*/
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryResponse.prototype.setSubmissionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentSubmission=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentSubmission}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryResponse.prototype.addSubmissions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentSubmission, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryResponse} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.ListSubmissionHistoryResponse.prototype.clearSubmissionsList = function() {
  return this.setSubmissionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentSubmission.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentSubmission.prototype.toObject = function(opt_includeInstance) {
  return proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentSubmission.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentSubmission} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentSubmission.toObject = function(includeInstance, msg) {
  var f, obj = {
    createdOnUtc: (f = msg.getCreatedOnUtc()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    isSkip: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    assessmentResultsList: jspb.Message.toObjectList(msg.getAssessmentResultsList(),
    proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResult.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentSubmission}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentSubmission.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentSubmission;
  return proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentSubmission.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentSubmission} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentSubmission}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentSubmission.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedOnUtc(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSkip(value);
      break;
    case 3:
      var value = new proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResult;
      reader.readMessage(value,proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResult.deserializeBinaryFromReader);
      msg.addAssessmentResults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentSubmission.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentSubmission.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentSubmission} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentSubmission.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreatedOnUtc();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getIsSkip();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getAssessmentResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResult.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp created_on_utc = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentSubmission.prototype.getCreatedOnUtc = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentSubmission} returns this
*/
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentSubmission.prototype.setCreatedOnUtc = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentSubmission} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentSubmission.prototype.clearCreatedOnUtc = function() {
  return this.setCreatedOnUtc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentSubmission.prototype.hasCreatedOnUtc = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool is_skip = 2;
 * @return {boolean}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentSubmission.prototype.getIsSkip = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentSubmission} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentSubmission.prototype.setIsSkip = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * repeated AssessmentResult assessment_results = 3;
 * @return {!Array<!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResult>}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentSubmission.prototype.getAssessmentResultsList = function() {
  return /** @type{!Array<!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResult>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResult, 3));
};


/**
 * @param {!Array<!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResult>} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentSubmission} returns this
*/
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentSubmission.prototype.setAssessmentResultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResult=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResult}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentSubmission.prototype.addAssessmentResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResult, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentSubmission} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentSubmission.prototype.clearAssessmentResultsList = function() {
  return this.setAssessmentResultsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResult.prototype.toObject = function(opt_includeInstance) {
  return proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    assessmentType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    score: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResult}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResult;
  return proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResult}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentType.Values} */ (reader.readEnum());
      msg.setAssessmentType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssessmentType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getScore();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional AssessmentType.Values assessment_type = 1;
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentType.Values}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResult.prototype.getAssessmentType = function() {
  return /** @type {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentType.Values} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentType.Values} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResult} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResult.prototype.setAssessmentType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int32 score = 2;
 * @return {number}
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResult.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResult} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments.AssessmentResult.prototype.setScore = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


goog.object.extend(exports, proto.com.livongo.protobuf.grpc.external.bh.wellness_assessments);
