
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ProgressBar',
  props: {
    progress: {
      type: Number,
      required: true
    },
    labelText: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    percentProgressStyle(): string {
      return `width: ${this.progress}%;`;
    },
    progressInt(): number {
      return parseInt(this.progress);
    }
  }
});
