
import { defineComponent } from 'vue';
import BackArrow from '@svg/icon-arrow-left.svg';

export default defineComponent({
  name: 'BackButton',
  emits: ['click', 'clicked-previous'],
  components: {
    BackArrow
  },
  props: {
    disabled: {
      type: Boolean
    },
    highlighted: {
      type: Boolean,
      required: false,
      default: false
    },
    backPath: {
      type: [String, Object],
      required: false,
      default: ''
    }
  },
  methods: {
    emitEvents(): void {
      if (!this.backPath || this.backPath === '') {
        this.$emit('click');
        this.$emit('clicked-previous');
      }
    }
  }
});
