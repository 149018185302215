import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import WebViewService from '@/services/WebViewService';
import OneAppService from '@/services/OneAppService';

type requestData = { [key: string]: string | boolean };
type payload = {
  action: string;
  params?: { [key: string]: string | number | requestData };
};

export default defineComponent({
  computed: mapGetters([
    'isOneApp',
    'isMobileWebView',
    'teletherapyConsultation'
  ]),
  methods: {
    oneAppRedirectToParent(screen: string) {
      if (this.isMobileWebView) {
        WebViewService.sendUserToMobileOrCallback(this.mapPayload(screen));
      } else {
        switch (screen) {
          case this.paths.RAV:
            OneAppService.sendWebSDKPathEvent(screen, [
              {
                url_request_data: {
                  health_category: 'Mental Health',
                  dfdDashboard: true
                }
              }
            ]);
            break;
          case this.paths.RESCHEDULE_MH_VISIT:
          case this.paths.JOIN_WAITING_ROOM:
            OneAppService.sendWebSDKPathEvent(screen, [
              { consult_id: this.teletherapyConsultation?.consult_id }
            ]);
            break;
          default:
            OneAppService.sendWebSDKPathEvent(screen);
        }
      }
    },
    mapPayload(screen: string): payload | null {
      const consult_id = this.teletherapyConsultation?.consult_id;
      if (!Array.isArray(screen)) {
        switch (screen) {
          case this.paths.RAV:
            return {
              action: 'request-a-visit',
              params: {
                url_request_data: {
                  health_category: 'Mental Health',
                  dfdDashboard: true
                }
              }
            };
          case this.paths.RESCHEDULE_MH_VISIT:
            return { action: 'reschedule-consult', params: { consult_id } };
          case this.paths.MESSAGE_CENTER:
            return { action: 'review-visit' };
          case this.paths.JOIN_WAITING_ROOM:
            return { action: 'mh-start-video', params: { consult_id } };
          case this.paths.MEDICAL_HISTORY:
            return { action: 'medical-history' };
        }
      }
      return null;
    }
  }
});
