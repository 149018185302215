
import { defineComponent } from 'vue';

import CountryService from '@/services/CountryService';
import BaseInput from '@/components/base/mixin/BaseInput';

import ChevronDown from '@svg/navigation/chevron-down.svg';

export default defineComponent({
  name: 'CountrySelector',
  components: {
    ChevronDown
  },
  mixins: [BaseInput],
  data() {
    return {
      countries: CountryService.getCountries(),
      inputValue: ''
    };
  },
  computed: {
    countryCode() {
      return this.inputValue || 'US';
    },
    flag() {
      const flag = CountryService.getFlagImage(this.countryCode);
      return flag || '';
    },
    countryName() {
      const name = CountryService.getCountryName(this.countryCode);
      return name || '';
    }
  }
});
