import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';

import HTTP from '@/services/HttpService';
import apiRoutes from '@config/api-routes';

import { RootState } from '@/types/storeTypes';
import { AxiosPromise } from 'axios';

export interface GuideState {
  unreadMessages: boolean;
  guideMessages: Record<string, unknown>;
  guideProfiles: Record<string, unknown>[];
  msgPollingIntervalId: null | number;
}

const state: GuideState = {
  unreadMessages: false,
  guideMessages: {},
  guideProfiles: [],
  msgPollingIntervalId: null
};

const POLLING_INTERVAL = 60000;

const getters: GetterTree<GuideState, RootState> = {
  unreadMessages: (state: GuideState): boolean => state.unreadMessages,
  guideMessages: (state: GuideState): Record<string, unknown> =>
    state.guideMessages,
  guideProfiles: (state: GuideState): Record<string, unknown>[] =>
    state.guideProfiles
};

const actions: ActionTree<GuideState, RootState> = {
  getUnreadMessageCount({ commit }): Promise<AxiosPromise | void> {
    return HTTP.get<{ count: number }>(apiRoutes.guide.unreadMessageCount).then(
      (response) => {
        commit('unreadMessage', response?.data?.count);
      }
    );
  },
  getGuideMessages({ commit }): Promise<AxiosPromise | void> {
    return HTTP.get(apiRoutes.guide.guideMessages).then((response) => {
      commit('setGuideMessages', response.data);
    });
  },
  getGuideProfiles({ commit }): Promise<AxiosPromise | void> {
    return HTTP.get(apiRoutes.guide.guideProfiles).then((response) => {
      commit('setGuideProfiles', response.data);
    });
  },
  markMessageRead(store, messageId: string): Promise<AxiosPromise | void> {
    return HTTP.post(apiRoutes.guide.markMessageAsRead(messageId));
  },
  sendMessageToGuide(
    store,
    message: Record<string, unknown>
  ): Promise<AxiosPromise | void> {
    return HTTP.post(apiRoutes.guide.sendMessage, {
      message
    }).then(() => {
      this.dispatch('getGuideMessages');
    });
  },
  startMsgPolling({ commit, state, dispatch }): void {
    this.dispatch('getUnreadMessageCount');
    if (!state.msgPollingIntervalId) {
      const msgPollingIntervalId = setInterval(
        () => dispatch('getUnreadMessageCount'),
        POLLING_INTERVAL
      );
      commit('setMsgPollingIntervalId', msgPollingIntervalId);
    }
  },
  stopMsgPolling({ commit, state }): void {
    if (state.msgPollingIntervalId) {
      clearInterval(state.msgPollingIntervalId);
    }
    commit('setMsgPollingIntervalId', null);
  },
  sendCoachingEvent(
    context,
    message: Record<string, unknown>
  ): Promise<AxiosPromise | void> {
    return HTTP.post(apiRoutes.guide.coachEvents, {
      eventType: message.eventType,
      description: message.description
    });
  }
};

const mutations: MutationTree<GuideState> = {
  unreadMessage(state: GuideState, unreadMessageCount: number): void {
    state.unreadMessages = unreadMessageCount > 0;
  },
  setGuideMessages(
    state: GuideState,
    guideMessages: GuideState['guideMessages']
  ): void {
    state.guideMessages = guideMessages;
  },
  setGuideProfiles(
    state: GuideState,
    guideProfiles: GuideState['guideProfiles']
  ): void {
    state.guideProfiles = guideProfiles;
  },
  setMsgPollingIntervalId(
    state: GuideState,
    msgPollingIntervalId: number
  ): void {
    state.msgPollingIntervalId = msgPollingIntervalId;
  }
};

export const guideStore: Module<GuideState, RootState> = {
  state,
  getters,
  actions,
  mutations
};

export default guideStore;
