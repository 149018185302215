import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "card-content" }
const _hoisted_2 = {
  key: 0,
  class: "small-eyebrow"
}
const _hoisted_3 = {
  key: 1,
  class: "heading heading-sm"
}
const _hoisted_4 = {
  key: 2,
  class: "description"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.title), 1))
      : _createCommentVNode("", true),
    (_ctx.heading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.heading), 1))
      : _createCommentVNode("", true),
    (_ctx.description)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.description), 1))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ]))
}