export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "es",
    "resource": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de nacimiento"])},
      "monthLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes"])},
      "dayLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Día"])},
      "yearLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Año"])},
      "months": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enero"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Febrero"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["March"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abril"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mayo"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Junio"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Julio"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agosto"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Septiembre"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Octubre"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noviembre"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diciembre"])}
      ],
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, introduzca su fecha de nacimiento"])}
    }
  })
}
