export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "recommended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommended"])},
      "recommended-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommended for you"])},
      "justForYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Just for you"])},
      "moment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take a moment"])},
      "favorites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorites"])},
      "viewAllFavorites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View all favorites"])}
    }
  })
}
