import { ActionTree, GetterTree, MutationTree } from 'vuex';

import { RootState } from '@/types/storeTypes';
import { Notification, NOTIFICATION_TYPES } from '@/types/NotificationTypes';

export interface NotificationState {
  notifications: Notification[];
}

/**
 * This store module is for Notification related data
 */
const initialState = {
  notifications: []
};

const state = { ...initialState };

const getters: GetterTree<NotificationState, RootState> = {
  notifications: (state: NotificationState): Notification[] =>
    state.notifications
};

const actions: ActionTree<NotificationState, RootState> = {
  addInfoNotification({ dispatch }, { heading, description }) {
    dispatch('addNotification', {
      heading,
      description,
      type: NOTIFICATION_TYPES.INFO
    });
  },
  addSuccessNotification({ dispatch }, { heading, description }) {
    dispatch('addNotification', {
      heading,
      description,
      type: NOTIFICATION_TYPES.SUCCESS
    });
  },
  addLongLivedSuccessNotification({ dispatch }, { heading, description }) {
    dispatch('addNotification', {
      heading,
      description,
      type: NOTIFICATION_TYPES.SUCCESS,
      timeout: 30000
    });
  },
  addWarningNotification({ dispatch }, { heading, description }) {
    dispatch('addNotification', {
      heading,
      description,
      type: NOTIFICATION_TYPES.WARNING
    });
  },
  addErrorNotification({ dispatch }, { heading, description }) {
    dispatch('addNotification', {
      heading,
      description,
      type: NOTIFICATION_TYPES.ERROR
    });
  },
  addNotification({ commit }, { heading, description, type, timeout = 5000 }) {
    //set timestamp as id
    const id = new Date().getTime();
    commit('addNotification', { id, heading, description, type });
    setTimeout(() => {
      commit('removeNotification', id);
    }, timeout);
  }
};

const mutations: MutationTree<NotificationState> = {
  addNotification(state, { id, heading, description, type }) {
    state.notifications.push({ id, heading, description, type });
  },
  removeNotification(state, id) {
    state.notifications = state.notifications.filter(
      (notification) => notification.id !== id
    );
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
