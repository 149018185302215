export interface Country {
  code: string;
  value: string;
  src?: string;
  callingCode?: number;
}

const countries: Country[] = [
  {
    code: 'US',
    value: 'United States',
    callingCode: 1
  }
];

const countriesMap = new Map<string, Country>();

countries.forEach((country) => {
  if (!country.src) {
    // TODO FIX ME - Do not load images from domains outside of company control
    // Instead we should add a SVG asset to public and refrence by its absolute path
    // country.src = `https://purecatamphetamine.github.io/country-flag-icons/3x2/${country.code}.svg`;
  }
  countriesMap.set(country.code, country);
});

class CountryService {
  public getDefaultCountry(): Country {
    return {
      code: 'US',
      value: 'United States',
      callingCode: 1
    };
  }
  public getCountries() {
    return countries;
  }
  public getFlagImage = (code: string) => {
    const country = countriesMap.get(code);
    return country?.src;
  };
  public getCountryName = (code: string) => {
    const country = countriesMap.get(code);
    return country?.value;
  };

  public getCallingCode = (code: string) => {
    const country = countriesMap.get(code);
    return country?.callingCode;
  };
}

export default new CountryService();
