import { createStore } from 'vuex';

import CoreStore from '@/store/CoreStore';
import UserStore from '@/store/userStore';
import HomeStore from '@/store/homeStore';
import SleepStore from '@/store/SleepStore';
import GuideStore from '@/store/GuideStore';
import SurveyStore from '@/store/SurveyStore';
import CrisisStore from '@/store/CrisisStore';
import ProfileStore from '@/store/profileStore';
import LibraryStore from '@/store/LibraryStore';
import ProgramStore from '@/store/ProgramStore';
import WebviewStore from '@/store/WebviewStore';
import ActivityStore from '@/store/ActivityStore';
import FavoriteStore from '@/store/FavoritesStore';
import InTheMoment from '@/store/InTheMomentStore';
import OnboardingStore from '@/store/OnboardingStore';
import ConferenceStore from '@/store/ConferenceStore';
import SteppedCareStore from '@/store/steppedCareStore';
import RecommendedStore from '@/store/RecommendedStore';
import DrawerModalStore from '@/store/DrawerModalStore';
import TeletherapyStore from '@/store/TeletherapyStore';
import NotificationStore from '@/store/NotificationStore';
import ClinicalCoachingStore from '@/store/ClinicalCoachingStore';
import PartnerCollateralStore from '@/store/PartnerCollateralStore';
import WellnessAssessmentStore from '@/store/wellnessAssessmentStore';

const isProductionBuild = process.env.NODE_ENV !== 'production';

export default createStore({
  strict: isProductionBuild,
  modules: {
    CoreStore,
    UserStore,
    HomeStore,
    SleepStore,
    GuideStore,
    CrisisStore,
    SurveyStore,
    InTheMoment,
    ProfileStore,
    ProgramStore,
    LibraryStore,
    WebviewStore,
    FavoriteStore,
    ActivityStore,
    OnboardingStore,
    ConferenceStore,
    RecommendedStore,
    SteppedCareStore,
    DrawerModalStore,
    TeletherapyStore,
    NotificationStore,
    ClinicalCoachingStore,
    PartnerCollateralStore,
    WellnessAssessmentStore
  }
});
