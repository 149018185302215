import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

export default defineComponent({
  computed: mapGetters([
    'teletherapyConsultationPromise',
    'teletherapyMessagePromise',
    'hasTeletherapyAuth'
  ]),
  methods: {
    async waitForTeletherapyRequests() {
      if (this.teletherapyConsultationPromise) {
        await this.teletherapyConsultationPromise;
      }
      if (this.teletherapyMessagePromise) {
        await this.teletherapyMessagePromise;
      }
    },
    async waitForTeletherapyAuth() {
      if (!this.hasTeletherapyAuth) {
        await this.getTeletherapyAuthorization();
      }
    },
    ...mapActions(['getTeletherapyAuthorization'])
  }
});
