import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0714e013"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]
const _hoisted_2 = {
  key: 0,
  class: "text-tooltip-wrapper"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  key: 1,
  class: "small"
}
const _hoisted_5 = { class: "input-wrap" }
const _hoisted_6 = {
  key: 0,
  class: "slot-icon-left"
}
const _hoisted_7 = ["autofocus", "autocomplete", "id", "type", "name", "aria-label", "aria-invalid", "data-cy", "disabled", "placeholder"]
const _hoisted_8 = {
  key: 1,
  class: "slot-icon-right"
}
const _hoisted_9 = { class: "validation-error-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_InlineError = _resolveComponent("InlineError")!
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["input-group", `input-group-${_ctx.inputName}`])
  }, [
    _createVNode(_component_Field, {
      class: "validation-provider",
      ref: "field",
      as: "span",
      type: "text",
      modelValue: _ctx.inputValue,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.inputValue) = $event)),
      label: _ctx.inputNiceName || _ctx.inputName,
      rules: _ctx.validationRules,
      name: _ctx.inputName,
      validateOnMount: _ctx.validateOnMount,
      validateOnChange: _ctx.validateOnChange,
      validateOnBlur: _ctx.validateOnBlur,
      standalone: _ctx.isStandalone
    }, {
      default: _withCtx((fieldProps) => [
        (_ctx.labelText)
          ? (_openBlock(), _createElementBlock("label", {
              key: 0,
              for: _ctx.computedInputId,
              class: _normalizeClass([_ctx.labelClass])
            }, [
              _createTextVNode(_toDisplayString(_ctx.labelText), 1),
              (_ctx.tooltipHTML)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(_component_Tooltip, { class: "text-tooltip" }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          class: "content",
                          innerHTML: _ctx.tooltipHTML
                        }, null, 8, _hoisted_3)
                      ]),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true)
            ], 10, _hoisted_1))
          : _createCommentVNode("", true),
        (_ctx.labelSubText)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.labelSubText), 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_5, [
          (_ctx.$slots.iconLeft)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _renderSlot(_ctx.$slots, "iconLeft", {}, undefined, true)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("input", _mergeProps({ autofocus: _ctx.autoFocusInput }, fieldProps.field, {
            ref: "validatedInputGroupInput",
            autocomplete: _ctx.autocomplete,
            id: _ctx.computedInputId,
            type: _ctx.inputType,
            name: _ctx.inputName,
            "aria-label": _ctx.inputAriaLabel,
            "aria-invalid": !fieldProps.meta.valid,
            class: _ctx.inputClass(fieldProps.meta),
            "data-cy": _ctx.dataCy,
            disabled: _ctx.disabled,
            placeholder: _ctx.placeholder,
            onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateModel && _ctx.updateModel(...args))),
            onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('blur', $event)))
          }), null, 16, _hoisted_7),
          (_ctx.$slots.iconRight)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _renderSlot(_ctx.$slots, "iconRight", {}, undefined, true)
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_9, [
          (fieldProps.errors[0] && _ctx.displayValidationError && _ctx.shouldShowErrorForValidateMode)
            ? _renderSlot(_ctx.$slots, "error-message", { key: 0 }, () => [
                _createVNode(_component_InlineError, {
                  "data-cy": `${_ctx.dataCy}-errors`,
                  message: fieldProps.errors[0]
                }, null, 8, ["data-cy", "message"])
              ], true)
            : _createCommentVNode("", true)
        ])
      ]),
      _: 3
    }, 8, ["modelValue", "label", "rules", "name", "validateOnMount", "validateOnChange", "validateOnBlur", "standalone"])
  ], 2))
}