import store from '@/store';
import { ClientOfferingVersion } from '@/types/UserTypes';

const getNextMigrationRoute = (): string | null => {
  const user = store.getters.user;
  if (
    user?.clientOfferingVersion === ClientOfferingVersion.MY1_0 &&
    user?.requiresOneAppMigration
  ) {
    return getNextMigrationRouteCov1(!user.dateOfBirth);
  }
  return null;
};

const getNextMigrationRouteCov1 = (missingDOB: boolean): string | null => {
  // if dob is missing we cannot calculate the userIsUnderAge property
  // missingDOB check needs to happen first
  if (missingDOB) {
    return '/migration/dob-collection';
  }
  return '/one-app-transition';
};

export default getNextMigrationRoute;
