import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3bc2c6ae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BrandLogo = _resolveComponent("BrandLogo")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["base-wrapper", _ctx.wrapperClass])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.logoDisplay)
        ? (_openBlock(), _createBlock(_component_BrandLogo, {
            key: 0,
            class: "logo",
            role: "img",
            "aria-label": _ctx.$t('logoDescription')
          }, null, 8, ["aria-label"]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ], 2))
}