export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "countryCode": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country Code"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["country code"])}
      }
    }
  })
}
