import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createBlock(_component_BaseButton, {
    id: "next",
    onClick: _ctx.emitEvents,
    type: _ctx.type,
    disabled: _ctx.disabled,
    class: _normalizeClass([{ disabled: _ctx.disabled }, _ctx.buttonClass])
  }, {
    default: _withCtx(() => [
      (_ctx.buttonText)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createTextVNode(_toDisplayString(_ctx.buttonText), 1)
          ], 64))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createTextVNode(_toDisplayString(_ctx.$t('buttons.next')), 1)
          ], 64))
    ]),
    _: 1
  }, 8, ["onClick", "type", "disabled", "class"]))
}