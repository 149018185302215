import { ActionTree, GetterTree, MutationTree } from 'vuex';

import HTTP from '@/services/HttpService';
import apiRoutes from '@config/api-routes';

import { RootState } from '@/types/storeTypes';
import { Category, Program, Programs, Topic } from '@/types/LibraryTypes';

export interface LibraryState {
  library: Category[] | null;
  programs: Programs | null;
  isLibraryLoading: boolean;
  isProgramLoading: boolean;
}

/**
 * This store module is for Library related data
 */
const initialState = {
  library: null,
  programs: null,
  isLibraryLoading: false,
  isProgramLoading: false
};

const state = { ...initialState };

const getters: GetterTree<LibraryState, RootState> = {
  library: (state: LibraryState): Category[] | null => state.library,
  programs: (state: LibraryState): Programs | null => state.programs,
  category: (state: LibraryState) => {
    return (categoryId: number): Category | null => {
      const category = state.library?.find(
        (category: Category) => category.id === categoryId
      );
      return category ? category : null;
    };
  },
  topic:
    (state: LibraryState) =>
    (categoryId: number, topicId: number): Topic | null => {
      const category = state.library?.find(
        (category: Category) => category.id === categoryId
      );
      const topic = category?.topics.find((topic) => topic.id === topicId);
      return topic ? topic : null;
    },
  isLibraryLoading: (state: LibraryState): boolean => state.isLibraryLoading,
  isProgramLoading: (state: LibraryState): boolean => state.isProgramLoading
};

const actions: ActionTree<LibraryState, RootState> = {
  async getLibrary({ commit }) {
    await commit('setLibraryLoading');
    return HTTP.get(apiRoutes.library)
      .then((resp) => {
        commit('setLibrary', resp.data);
      })
      .finally(async () => {
        await commit('setLibraryLoading', false);
      });
  },
  async getProgram({ commit }, programId: number) {
    await commit('setProgramLoading');
    return HTTP.get(apiRoutes.activity.program(programId))
      .then(async (resp) => {
        const program = resp.data;
        await commit('setPrograms', { programId, program });
      })
      .finally(async () => {
        await commit('setProgramLoading', false);
      });
  }
};

const mutations: MutationTree<LibraryState> = {
  setLibrary(state, library: Category[]) {
    state.library = library;
  },
  setLibraryLoading(state, libraryLoading = true) {
    state.isLibraryLoading = libraryLoading;
  },
  setPrograms(state, program: Program) {
    if (!state.programs) {
      state.programs = {};
    }
    state.programs[program.programId] = program;
  },
  setProgramLoading(state, programsLoading = true) {
    state.isProgramLoading = programsLoading;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
