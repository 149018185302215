export enum ENV_VARIABLES {
  VUE_APP_API_URL = 'VUE_APP_API_URL',
  VUE_APP_MIXPANEL_TOKEN = 'VUE_APP_MIXPANEL_TOKEN',
  VUE_APP_GRPC_URL = 'VUE_APP_GRPC_URL',
  VUE_APP_CCM_LOGIN_URL = 'VUE_APP_CCM_LOGIN_URL',
  VUE_APP_CCM_REGISTRATION_URL = 'VUE_APP_CCM_REGISTRATION_URL',
  VUE_APP_CCM_COACHING_URL = 'VUE_APP_CCM_COACHING_URL',
  VUE_APP_CCM_COACHING_URL_ONEAPP = 'VUE_APP_CCM_COACHING_URL_ONEAPP',
  VUE_APP_KEYCLOAK_URL = 'VUE_APP_KEYCLOAK_URL',
  VUE_APP_WEB_SDK_URL = 'VUE_APP_WEB_SDK_URL',
  VUE_APP_WEB_SDK_URL_ONEAPP = 'VUE_APP_WEB_SDK_URL_ONEAPP',
  VUE_APP_TDOC_SIGNIN_URL = 'VUE_APP_TDOC_SIGNIN_URL',
  VUE_APP_TDOC_REG_URL = 'VUE_APP_TDOC_REG_URL',
  VUE_APP_WEB_SDK_TOKEN = 'VUE_APP_WEB_SDK_TOKEN',
  VUE_APP_WEB_SDK_TOKEN_ONEAPP = 'VUE_APP_WEB_SDK_TOKEN_ONEAPP',
  VUE_APP_WEB_SDK_GOOGLE_TOKEN = 'VUE_APP_WEB_SDK_GOOGLE_TOKEN',
  VUE_APP_WEB_SDK_BUNDLE_ID = 'VUE_APP_WEB_SDK_BUNDLE_ID',
  VUE_APP_WEB_SDK_BUNDLE_ID_ONEAPP = 'VUE_APP_WEB_SDK_BUNDLE_ID_ONEAPP'
}
