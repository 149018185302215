export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "es",
    "resource": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AUTENTICACIÓN DE DOS FACTORES"])},
      "emailNoticeCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acabamos de enviarle un correo electrónico con su código de autenticación. Ingrese el código a continuación para verificar su identidad."])},
      "authenticatorNoticeCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilice la aplicación Google Authenticator en su dispositivo para recuperar su código de autenticación. Ingrese el código en el campo a continuación para verificar su identidad."])},
      "emailSentHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Éxito!"])},
      "emailSentCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un código ha sido enviado a su correo electrónico"])},
      "verificationForm": {
        "verificationCodeField": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor ingrese su código de verificación"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])}
        },
        "submitBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificar"])},
        "sendCodeBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar Código De nuevo"])}
      },
      "returnToApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regresa a myStrength"])},
      "invalidVerificationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["código de verificación invalido"])}
    }
  })
}
