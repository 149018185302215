/**
 *
 * @returns current time in UTC in the format HH.MM.SS.FFF (F is milliseconds (fraction))
 */
const formattedISOTimeWithMilliseconds = (date: Date): string => {
  return date
    .toISOString() // e.g. "2021-07-20T15:56:42.145Z"
    .split('T')[1] // split on 'T' and take second element => "15:56:42.145Z"
    .slice(0, -1) // remove 'Z' on the end => "15:56:42.145"
    .replace(/:/g, '.');
};

const getTwoDigitUTCMonth = (date: Date): string => {
  const monthPlusOne = date.getUTCMonth() + 1; // the return value of Date.getUTCMonth() is zero-indexed
  if (monthPlusOne < 10) {
    return '0' + monthPlusOne;
  }
  return monthPlusOne.toString();
};

const getTwoDigitUTCDay = (date: Date): string => {
  const dayOfTheMonth = date.getUTCDate();
  if (dayOfTheMonth < 10) {
    return '0' + dayOfTheMonth;
  }
  return dayOfTheMonth.toString();
};

const formattedUTCDate = (date: Date): string => {
  const monthNumber = getTwoDigitUTCMonth(date);
  const dayNumber = getTwoDigitUTCDay(date);
  const yearNumber = date.getUTCFullYear();
  return `${monthNumber}/${dayNumber}/${yearNumber}`;
};

/**
 *
 * @returns string including both date and time in the format MM/DD/YYYY HH.MM.SS.FFF
 */
export const currentDateTimeUTC = (): string => {
  const currentDate = new Date();
  return `${formattedUTCDate(currentDate)} ${formattedISOTimeWithMilliseconds(
    currentDate
  )}`;
};

export const currentURLIncludesActivity = (): boolean =>
  window.location.href.includes('activity');

export const currentURLWithNoActivitySlug = (): string => {
  const arrayFromUrl = window.location.href.split('/');
  arrayFromUrl.splice(arrayFromUrl.indexOf('activity') + 1);
  return arrayFromUrl.join('/') + '/';
};
