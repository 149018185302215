
import { defineComponent } from 'vue';

import { ALERT_TYPES } from '@common/Alert.vue';

import IconAlertInfo from '@svg/icon-alert-info.svg';
import IconAlertWarn from '@svg/icon-alert-warn.svg';
import IconAlertDanger from '@svg/icon-alert-danger.svg';

export default defineComponent({
  name: 'AlertIcon',
  components: {
    IconAlertInfo,
    IconAlertWarn,
    IconAlertDanger
  },
  props: {
    type: {
      type: String,
      required: true,
      default: null,
      validator: (value: string) => Object.keys(ALERT_TYPES).includes(value)
    }
  },
  data() {
    return {
      ALERT_TYPES: ALERT_TYPES
    };
  }
});
