
import { defineComponent } from 'vue';

import { mapGetters } from 'vuex';

export default defineComponent({
  name: 'SiteMap',
  computed: {
    connectNavName(): string {
      if (this.isClinicalCoachingUser) {
        return 'ConnectClinicalCoaching';
      } else if (this.isTeletherapyUser) {
        return 'Teletherapy';
      } else {
        return 'Connect';
      }
    },
    ...mapGetters(['isClinicalCoachingUser', 'isTeletherapyUser'])
  }
});
