import { defineComponent } from 'vue';

import { FieldMeta } from 'vee-validate';

export default defineComponent({
  emits: ['input', 'validityUpdate', 'update:inputModel'],
  props: {
    validateMode: {
      type: String,
      required: false,
      default: 'aggressive'
    },
    autocomplete: {
      type: String,
      required: false,
      default: 'off'
    },
    labelText: {
      type: String,
      required: false,
      default: ''
    },
    labelSubText: {
      type: String,
      required: false,
      default: ''
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    inputAriaLabel: {
      type: String,
      required: false,
      default: null
    },
    inputType: {
      type: String,
      default: 'text'
    },
    inputName: {
      type: String,
      required: true
    },
    inputNiceName: {
      type: String,
      required: false,
      default: null
    },
    inputModel: {
      type: [String, Number, Boolean, Function],
      default: ''
    },
    validationRules: {
      type: [String, Object],
      required: false,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    readonly: {
      type: Boolean,
      default: false,
      required: false
    },
    dataCy: {
      type: String,
      required: false,
      default: ''
    },
    labelLocation: {
      type: String,
      required: false,
      default: 'left'
    },
    displayValidationError: {
      type: Boolean,
      required: false,
      default: true
    },
    getAllErrors: {
      type: Boolean,
      required: false,
      default: false
    },
    validatesOnRender: {
      type: Boolean,
      required: false,
      default: false
    },
    autoFocusInput: {
      type: Boolean,
      required: false,
      default: false
    },
    tooltipHTML: {
      type: String,
      required: false,
      default: ''
    },
    isStandalone: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      inputValue: '' as string | number | ConstrainBooleanParameters,
      // Passed to vee-validate Field
      validateOnMount: false,
      validateOnChange: true,
      validateOnBlur: true
    };
  },
  computed: {
    computedInputId(): string {
      return 'base-input-' + this.inputName.replace('/s/', '');
    },
    labelClass(): Record<string, boolean> {
      return {
        'label-top': this.labelLocation == 'top',
        'label-left': this.labelLocation == 'left'
      };
    },
    shouldShowErrorForValidateMode(): boolean {
      const isLazyMode = this.validateMode === 'lazy';

      if (!isLazyMode) {
        return true;
      }

      return isLazyMode && this.inputValue !== '';
    }
  },
  watch: {
    inputModel: {
      deep: true,
      handler(newValue: string | number | boolean) {
        this.inputValue = newValue;
      }
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.inputValue = this.inputModel;

      this.setOptionsForMode();
    },
    setOptionsForMode() {
      if (this.validateMode == 'lazy') {
        this.validateOnMount = false;
        this.validateOnBlur = false;
        this.validateOnChange = true;
      }

      if (this.validatesOnRender) {
        this.validateOnMount = true;
      }
    },
    updateModel(ev: InputEvent) {
      const target = ev.target as HTMLInputElement;

      this.$emit('update:inputModel', target.value);
      this.$emit('input', target.value);

      this.$refs.field.validate().then(({ valid }: { valid: boolean }) => {
        this.$emit('validityUpdate', valid);
      });
    },
    inputClass({ valid, touched }: FieldMeta<string>): Record<string, boolean> {
      const isLazyMode = this.validateMode === 'lazy';
      const lazyModeIsValid = isLazyMode && this.inputValue === '';
      const isInvalid = !lazyModeIsValid && touched && !valid;

      return {
        'is-valid': valid,
        'is-invalid': isInvalid
      };
    }
  }
});
