/**
 * This store module is for microservice service-stepped-care related data
 * - using gRPC for server client communication
 */
import { ActionTree, GetterTree } from 'vuex';

import UserStore from '@/store/userStore';

import { RootState } from '@/types/storeTypes';
import { User } from '@/types/UserTypes';

import { Language } from '@/generated-proto/livongo/protobuf/common/bh/language_pb';
import { Condition } from '@/generated-proto/livongo/protobuf/common/bh/programRecommendation_pb';

import {
  GetProgramRecommendationContentRequest,
  GetAllPrimaryNeedsRequest,
  ProgramRecommendationContent,
  SelectPrimaryNeedRequest,
  AllPrimaryNeedsContent
} from '@/generated-proto/livongo/protobuf/grpc/external/bh/stepped_care/stepped_care_pb';

import {
  getParentUser,
  steppedCareServicePromiseClient
} from '@/services/gRPCService';

interface SteppedCareState {
  programRecommendationContent: ProgramRecommendationContent.AsObject | null;
  allPrimaryNeedsContent: AllPrimaryNeedsContent.AsObject | null;
  selectedPrimaryNeed: Condition.Values | null;
}

// Utilities
const getParentLanguage = (language: string): number => {
  let parentLanguage: Language.Values;
  switch (language) {
    case 'en-US':
      parentLanguage = 1;
      break;
    case 'es-MX':
      parentLanguage = 2;
      break;
    // default language is English (1)
    default:
      parentLanguage = 1;
  }

  return parentLanguage;
};

const initialState: SteppedCareState = {
  programRecommendationContent: null,
  allPrimaryNeedsContent: null,
  selectedPrimaryNeed: null
};

const state: SteppedCareState = { ...initialState };

const getters: GetterTree<SteppedCareState, RootState> = {
  programRecommendationContent: (state: SteppedCareState) =>
    state.programRecommendationContent,
  allPrimaryNeedsContent: (state: SteppedCareState) => {
    return state.allPrimaryNeedsContent;
  },
  planStepsList: (state: SteppedCareState) =>
    state.programRecommendationContent?.planStepsList,
  selectedPrimaryNeed: (state: SteppedCareState) => state.selectedPrimaryNeed
};

const actions: ActionTree<SteppedCareState, RootState> = {
  async getProgramRecommendationContent({ commit }) {
    const parentUser = await getParentUser();
    const { language }: { language: string } = UserStore.state.user as User;

    if (!parentUser) {
      const message = `Parent user is not defined - parentUser : ${parentUser}`;
      console.warn(message);
      return Promise.reject(message);
    }

    return steppedCareServicePromiseClient
      .getProgramRecommendationContent(
        new GetProgramRecommendationContentRequest()
          .setParent(parentUser)
          .setLanguage(getParentLanguage(language))
      )
      .then((resp) => {
        commit('setProgramRecommendationContent', resp.toObject());
      });
  },
  async getAllPrimaryNeeds({ commit }) {
    const parentUser = await getParentUser();
    const { language }: { language: string } = UserStore.state.user as User;

    if (!parentUser) {
      const message = `Parent user is not defined - parentUser : ${parentUser}`;
      console.warn(message);
      return Promise.reject(message);
    }

    return steppedCareServicePromiseClient
      .getAllPrimaryNeeds(
        new GetAllPrimaryNeedsRequest()
          .setParent(parentUser)
          .setLanguage(getParentLanguage(language))
      )
      .then((resp) => {
        commit('setAllPrimaryNeeds', resp.toObject());
      });
  },
  updateSelectedPrimaryNeed({ commit }, needId: number) {
    commit('setSelectedPrimaryNeed', needId);
  },
  async savePrimaryNeed({ state }) {
    const parentUser = await getParentUser();

    if (!parentUser || !state.selectedPrimaryNeed) {
      const message = `Parent user or selected primary need is not defined - parentUser, selectedPrimaryNeed : ${parentUser}, ${state.selectedPrimaryNeed}`;
      console.warn(message);
      return Promise.reject(message);
    }

    return steppedCareServicePromiseClient.selectPrimaryNeed(
      new SelectPrimaryNeedRequest()
        .setId(state.selectedPrimaryNeed)
        .setParent(parentUser)
    );
  }
};

const mutations = {
  setSelectedPrimaryNeed(state: SteppedCareState, primaryNeedId: number) {
    state.selectedPrimaryNeed = primaryNeedId;
  },
  setProgramRecommendationContent(
    state: SteppedCareState,
    programRecommendationContent: ProgramRecommendationContent.AsObject
  ) {
    state.programRecommendationContent = programRecommendationContent;
  },
  setAllPrimaryNeeds(
    state: SteppedCareState,
    allPrimaryNeedsContent: AllPrimaryNeedsContent.AsObject
  ) {
    state.allPrimaryNeedsContent = allPrimaryNeedsContent;
    state.selectedPrimaryNeed =
      allPrimaryNeedsContent.topRecommendedPrimaryNeed?.id || null;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
