import {
  createRouter,
  createWebHistory,
  RouteLocation,
  RouteRecordRaw
} from 'vue-router';

import authService from '@/services/AuthService';
import WebViewService from '@/services/WebViewService';

import store from '@/store';

// Route modules
import webviewRoutes from '@/router/webviewRoutes';
import exploreRoutes from '@/router/exploreRoutes';
import profileRoutes from '@/router/profileRoutes';
import connectRoutes from '@/router/connectRoutes';
import activityRoutes from '@/router/activityRoutes';
import onboardingRoutes from '@/router/onboardingRoutes';
import reassessmentRoutes from '@/router/reassessmentRoutes';
import migrationRoutes from '@/router/migrationRoutes';

// Removed for r0 release
// import sleepRoutes from '@/router/sleepRoutes';

// View components to be attached to route(s)
import Home from '@/views/Home.vue';
import Login from '@/views/Login.vue';
import Logout from '@/views/Logout.vue';
import SignUp from '@/views/SignUp.vue';
import Styles from '@/views/Styles.vue';
import AgingUp from '@/views/AgingUp.vue';
import ErrorView from '@/views/Error.vue';
import SiteMap from '@/views/SiteMap.vue';
import DeepLink from '@/views/DeepLink/DeepLink.vue';
import MHProgress from '@/views/Progress.vue';
import GdprTerms from '@/views/GdprTerms.vue';
import MFAVerify from '@/views/MFAVerify.vue';
import HelpCrisis from '@/views/HelpCrisis.vue';
import NewFeatures from '@/views/NewFeatures.vue';
import PlaceHolder from '@/views/PlaceHolder.vue';
import AccountSetup from '@/views/AccountSetup.vue';
import AccountMoved from '@/views/AccountMoved.vue';
import HelpResources from '@/views/HelpResources.vue';
import PasswordReset from '@/views/PasswordReset.vue';
import ChangePassword from '@/views/ChangePassword.vue';
import ProfileLayout from '@/views/Profile/ProfileLayout.vue';
import DestinationChooser from '@/views/DestinationChooser.vue';
import UpgradeRegistration from '@/views/UpgradeRegistration.vue';
import SleepMobileRedirect from '@/views/Sleep/SleepMobileRedirect.vue';
import Guide from '@/views/Guide/Guide.vue';
import OneAppService from '@/services/OneAppService';
import { dynamicComponent } from '@/helpers/Webpack';

// Record the initial document URL for use with CSP refresh
sessionStorage.setItem(
  'initial-document-url-path',
  new URL(document.URL).pathname
);

const routes: RouteRecordRaw[] = [
  {
    path: '/:pathMatch(.*)*',
    redirect: '/home'
  },
  {
    path: '/whoops',
    name: '404Error',
    component: ErrorView,
    props: {
      status: '404'
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      baseWrapperSize: 'lg',
      requiresAuth: true
    }
  },
  {
    path: '/destination-chooser',
    name: 'DestinationChooser',
    component: DestinationChooser,
    meta: { requiresAuth: true }
  },
  {
    path: '/mfa-verify',
    name: 'MFAVerify',
    component: MFAVerify,
    meta: { requiresAuth: true }
  },
  {
    path: '/dl',
    name: 'DeepLink',
    component: DeepLink
  },
  // Style guide
  {
    path: '/styles',
    name: 'Styles',
    component: Styles
  },
  // Registration
  {
    path: '/signup',
    redirect: '/sign-up'
  },
  {
    path: '/sign-up/:organization?/:group?',
    name: 'SignUp',
    props: true,
    component: SignUp
  },
  {
    path: '/sign-up/gdpr-terms',
    name: 'GdprTerms',
    component: GdprTerms
  },
  {
    path: '/sign-up/account',
    name: 'AccountSetup',
    component: AccountSetup
  },
  {
    path: '/account-moved',
    name: 'AccountMoved',
    component: AccountMoved
  },
  {
    path: '/upgrade-registration',
    name: 'UpgradeRegistration',
    component: UpgradeRegistration,
    meta: { requiresAuth: true }
  },
  {
    path: '/connect/error',
    name: 'ConnectError',
    component: ErrorView,
    meta: { requiresAuth: true }
  },
  {
    path: '/progress',
    name: 'Progress',
    component: MHProgress
  },
  {
    path: '/help-resources',
    name: 'HelpResources',
    component: HelpResources,
    meta: { requiresAuth: true }
  },
  {
    path: '/help-crisis',
    name: 'HelpCrisis',
    component: HelpCrisis,
    meta: { requiresAuth: true }
  },
  // Auth
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/login/reset',
    name: 'PasswordReset',
    component: PasswordReset
  },
  {
    path: '/login/password',
    name: 'ChangePassword',
    component: ChangePassword
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },
  // Profile
  {
    path: '/profile',
    component: ProfileLayout,
    children: profileRoutes,
    meta: { requiresAuth: true }
  },
  // Partner landing page bundle
  {
    path: '/go/:organization?/:secondary?',
    name: 'PartnerLandingPage',
    component: dynamicComponent('PartnerLanding/StandardLandingPage')
  },
  // WIP pages
  {
    path: '/placeholder',
    name: 'PlaceHolder',
    component: PlaceHolder
  },
  {
    path: '/mobile/newfeatures',
    name: 'NewFeaturesMobile',
    component: NewFeatures
  },
  {
    path: '/newfeatures',
    name: 'NewFeatures',
    component: Home,
    meta: {
      requiresAuth: true,
      baseWrapperSize: 'lg'
    },
    props: {
      showNewFeatures: true
    }
  },
  {
    path: '/sitemap',
    name: 'SiteMap',
    component: SiteMap
  },
  {
    path: '/sleep/redirect',
    name: 'SleepMobileRedirect',
    component: SleepMobileRedirect
  },
  {
    path: '/connect/conference',
    name: 'Conference',
    component: dynamicComponent('Conference/Conference'),
    meta: { requiresAuth: true, baseWrapperSize: 'lg' }
  },
  {
    path: '/aging-up',
    name: 'AgingUp',
    component: AgingUp,
    meta: { requiresAuth: true }
  },
  {
    path: '/conference/clinical-portal',
    name: 'ClinicalPortalConference',
    component: dynamicComponent('Conference/ClinicalPortalConference'),
    meta: { requiresAuth: false }
  },
  {
    path: '/guide',
    name: 'Guide',
    component: Guide,
    meta: { requiresAuth: true }
  },
  // Route modules
  ...activityRoutes,
  // Temporarily disable all sleep routes for r0
  //...sleepRoutes,
  ...connectRoutes,
  ...exploreRoutes,
  ...onboardingRoutes,
  ...webviewRoutes,
  ...reassessmentRoutes,
  ...migrationRoutes
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (savedPosition) {
          resolve(savedPosition);
        } else {
          resolve({ left: 0, top: 0 });
        }
      }, 500);
    });
  }
});

/**
 * Determines if the given path name corresponds to a hardened CSP route.
 *
 * @param pathName - The path name to check.
 * @returns A boolean indicating whether the path name corresponds to a hardened CSP route.
 */
const isHardenedCSPRoute = (pathName: string): boolean => {
  if (!pathName) {
    return false;
  }

  if (
    pathName === 'Login' ||
    pathName === 'PasswordReset' ||
    pathName === 'ChangePassword'
  ) {
    return true;
  }

  const isMigrationRoute = migrationRoutes.some((route) => {
    if (pathName === route.name) {
      return true;
    }
  });

  return isMigrationRoute;
};

/**
 * Triggers a page refresh for Content Security Policy (CSP) based on the provided path and full path.
 * If the initial document URL path stored in sessionStorage does not match the provided path,
 * the function logs a message and redirects the window to the provided full path.
 *
 * @param path - The path to compare with the initial document URL path.
 * @param fullPath - The full path to redirect the window to if the paths do not match.
 */
const triggerPageRefreshForCSP = (
  route: RouteLocation,
  fromRoute: RouteLocation
) => {
  if (route.query.isOneApp || fromRoute.query.isOneApp) {
    return;
  }

  // Reload page
  if (sessionStorage.getItem('initial-document-url-path') !== route.path) {
    window.location.href = route.fullPath;
  }
};

router.beforeEach((to, from, next) => {
  // Route config meta.requiresAuth
  // - if not logged in redirect to login
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!authService.hasAuthToken() && !authService.hasReAuthToken()) {
      store.commit('setTokenInvalid');
      const query = {} as {
        redirect: string;
      };

      if (to.fullPath !== '/home') {
        query.redirect = to.fullPath;
      }

      // Redirect users without token to login
      next({
        name: 'Login',
        query
      });
    }
  }

  // Trigger reload to load hardened CSP policy for certain routes
  if (isHardenedCSPRoute(to.name as string)) {
    sessionStorage.setItem('hardened-csp-loaded', 'true');
    triggerPageRefreshForCSP(to, from);
  } else {
    // If moving from a hardened route to a non hardened route,
    // a reload is needed to get the correct CSP
    if (sessionStorage.getItem('hardened-csp-loaded') === 'true') {
      sessionStorage.removeItem('hardened-csp-loaded');
      triggerPageRefreshForCSP(to, from);
    }
  }

  // Special route handling for OneApp
  const isOneApp = WebViewService.isOneApp(to.query) || store.getters.isOneApp;
  const language = WebViewService.language(to.query) || store.getters.language;

  if (isOneApp) {
    // Post the route path to the OneApp iframe parent so the correct page can be loaded on a refresh
    OneAppService.sendNavigationEvent(to.path);

    // if the navigation for oneApp includes any of these paths, redirect to home
    const oneAppForbiddenUrls = [
      '/profile',
      '/profile/language',
      '/profile/notifications',
      '/profile/support'
    ];
    if (oneAppForbiddenUrls.includes(to.path)) {
      next({ path: '/home', query: to.query });
      return;
    }

    // Maintain language and isOneApp query parameter so that clicking back from a different web page,
    // leads to the OneApp version of MyStrength with the correct language
    if (!to.query.isOneApp) {
      to.query.isOneApp = 'true';
      next({ path: to.path, query: to.query });
      return;
    }
    if (!to.query.language && language) {
      to.query.language = language;
      next({ path: to.path, query: to.query });
      return;
    }
  }

  // Default route handling
  next();
});

export default router;
