
import { defineComponent } from 'vue';
import { Field, defineRule } from 'vee-validate';

import {
  required,
  email,
  min,
  max,
  confirmed,
  digits
} from '@vee-validate/rules';

// Add specific rules to keep bundle size down
defineRule('required', required);
defineRule('email', email);
defineRule('max', max);
defineRule('min', min);
defineRule('confirmed', confirmed);
defineRule('digits', digits);

defineRule(
  'noSpecialChars',
  (value: string) => value.match(/^[ñáéíóúüÁÉÍÓÚÑÜ\sa-zA-Z0-9.'-]*$/) !== null
);
defineRule(
  'noExtraSpaces',
  (value: string) =>
    value.match(/^\s+/) === null && value.match(/\s+$/) === null
);

defineRule('upperCase', (value: string) => value.match(/[A-Z]/g) !== null);
defineRule('lowerCase', (value: string) => value.match(/[a-z]/g) !== null);
defineRule('oneNumber', (value: string) => value.match(/[0-9]/g) !== null);

import BaseInput from '@/components/base/mixin/BaseInput';
import InlineError from '@common/InlineError.vue';

export default defineComponent({
  name: 'BaseInputTextarea',
  components: {
    InlineError,
    Field
  },
  mixins: [BaseInput]
});
