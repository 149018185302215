
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import Alert from '@common/Alert.vue';

export default defineComponent({
  name: 'MemberMessages',
  inject: ['$analyticsTrack'],
  components: {
    Alert
  },
  computed: {
    ...mapGetters(['memberMessages'])
  },
  mounted() {
    this.init();
  },
  methods: {
    init(): void {
      this.getMemberMessages();
    },
    trackMemberMessage(messageId: number): void {
      this.$analyticsTrack('MH.Home.MemberMessage', { messageId });
    },
    ...mapActions(['getMemberMessages'])
  }
});
