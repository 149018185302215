import { GetterTree, MutationTree } from 'vuex';
import { RootState } from '@/types/storeTypes';

interface State {
  isCorrectChapter: boolean;
  cameFromAssessmentIntro: boolean;
}

const defaultState: State = {
  isCorrectChapter: true,
  cameFromAssessmentIntro: false
};

const getters: GetterTree<State, RootState> = {
  isCorrectChapter: (state: State) => state.isCorrectChapter,
  cameFromAssessmentIntro: (state: State) => state.cameFromAssessmentIntro
};

const mutations: MutationTree<State> = {
  setIsCorrectChapter(state: State, isCorrectChapter: boolean): void {
    state.isCorrectChapter = isCorrectChapter;
  },
  setCameFromAssessmentIntro(
    state: State,
    cameFromAssessmentIntro: boolean
  ): void {
    state.cameFromAssessmentIntro = cameFromAssessmentIntro;
  }
};

export default {
  state: defaultState,
  getters,
  mutations
};
