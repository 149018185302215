import axios from 'axios';
import AuthService from '@/services/AuthService';
import DomainService from '@/services/PathLookupService';
import store from '@/store';

const INVALID_TOKEN_STATUS_TEXT = 'Invalid token';
const INVALID_SESSION_TEXT = 'Invalid session';

const HTTP = axios.create({
  baseURL: DomainService.getApiUrl(),
  headers: { Pragma: 'no-cache' }
});

const directToLogin = (): void => {
  AuthService.clearAllTokens();

  if (window.location.pathname !== '/login') {
    window.location.replace('/login');
  }
};

export const handleInvalidTokenFlow = (): Promise<unknown> => {
  const reAuthToken = AuthService.getReAuthToken();
  const isOneApp = store.getters.isOneApp;

  AuthService.clearAuthToken();
  AuthService.clearReAuthToken();

  if (reAuthToken && AuthService.isLivongoToken(reAuthToken) && !isOneApp) {
    AuthService.clearLivongoAuthToken();
    AuthService.clearLivongoReAuthToken();
  }

  return store.dispatch('reAuthenticateUser', reAuthToken).then(
    (resp) => {
      // NICE COOL COOL 👍
      return resp;
    },
    (error) => {
      if (!store.getters.hasActiveReAuthRequest) {
        directToLogin();
      }
      return Promise.reject(error);
    }
  );
};

/**
 * Request interceptor - adds JWT to all requests using this service
 */
HTTP.interceptors.request.use((config) => {
  if (config?.headers && !config.headers['x-session-token']) {
    const token = AuthService.getAuthToken();
    if (token) {
      config.headers['x-session-token'] = token;
    }
  }
  return config;
});

/**
 * Sets any session tokens returned by the server
 */
HTTP.interceptors.response.use(
  (response) => {
    const token = response.headers['x-session-token'];
    const reAuthToken = response.headers['x-reauth-token'];

    if (token) {
      AuthService.setAuthToken(token);
    }
    if (reAuthToken) {
      AuthService.setReAuthToken(reAuthToken);
    }

    return response;
  },
  (error) => {
    const isReauthRequest = error.request.responseURL.includes('reauth');

    // Catch failing reauth request
    if (isReauthRequest) {
      directToLogin();
      return Promise.reject(error);
    }

    // Detect invalid token
    const errorResponse = error.response;
    const hasInvalidAuthStatus =
      errorResponse?.status === 400 || errorResponse?.status === 401;
    const hasInvalidStatusText =
      errorResponse?.statusText === INVALID_TOKEN_STATUS_TEXT ||
      errorResponse?.data.message === INVALID_TOKEN_STATUS_TEXT ||
      errorResponse?.statusText === INVALID_SESSION_TEXT ||
      errorResponse?.data.message === INVALID_SESSION_TEXT;

    const isInvalidToken =
      errorResponse && hasInvalidAuthStatus && hasInvalidStatusText;

    if (isInvalidToken) {
      handleInvalidTokenFlow().then(
        // Replay request after re-authenticating
        () => {
          error.config.headers['x-session-token'] = AuthService.getAuthToken();
          HTTP.request(error.config);
        },
        (err) => {
          // Reauth failure
          directToLogin();
          Promise.reject(err);
        }
      );
    }

    return Promise.reject(error);
  }
);

export default HTTP;
