
import { defineComponent, PropType } from 'vue';

import { RouteLocation } from 'vue-router';

import CardContent from '@common/cards/CardContent.vue';

export enum CARD_VARIANTS {
  MEDIUM = 'MEDIUM',
  NOOFFSET = 'NOOFFSET',
  ONBOARDING = 'ONBOARDING',
  REASSESSMENT = 'REASSESSMENT'
}

export default defineComponent({
  name: 'CardCircleIcon',
  components: { CardContent },
  props: {
    title: {
      type: String,
      required: false,
      default: null
    },
    heading: {
      type: String,
      required: false,
      default: null
    },
    description: {
      type: String,
      required: false,
      default: null
    },
    cardCircleColor: {
      type: String,
      required: false,
      default: null
    },
    cardCircleColorClass: {
      type: String,
      required: false,
      default: null
    },
    routeLocation: {
      type: Object as PropType<RouteLocation>,
      required: false,
      default: null
    },
    variantType: {
      type: String,
      required: false,
      default: CARD_VARIANTS.MEDIUM,
      validator: (value: string) => Object.keys(CARD_VARIANTS).includes(value)
    },
    borderTop: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    cardClass(): string {
      return `card-circle-icon-${this.variantType}`.toLowerCase();
    },
    cardCircleStyle(): { [key: string]: string } | null {
      return this.cardCircleColor
        ? { 'background-color': this.cardCircleColor }
        : null;
    }
  }
});
