
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import { Form, Field, defineRule } from 'vee-validate';

import {
  SignUpFieldView,
  SignUpFieldResponse,
  SignUpFieldType
} from '@/types/PartnerCollateralTypes';

import BaseWrapper from '@/components/base/BaseWrapper.vue';
import InlineError from '@common/InlineError.vue';
import Birthdate from '@common/Birthdate.vue';
import Tooltip from '@common/Tooltip.vue';
import { CommonRules } from '@/types/FormValidationTypes';

export default defineComponent({
  name: 'AccountSetup',
  components: {
    BaseWrapper,
    MHForm: Form,
    Field,
    InlineError,
    Birthdate,
    Tooltip
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      alias: '',
      email: '',
      password: '',
      gender: 'NO_ANSWER',
      dateOfBirth: [],
      minimumAge: 0,
      birthDateValid: false,
      customFields: {} as Record<string, string | number>,
      isValidAge: false,
      isEmailOptedIn: false,
      isTermsAgreed: false,
      genderOptions: ['MALE', 'FEMALE', 'NON_BINARY', 'NO_ANSWER'],
      SignUpFieldType,
      CommonRules,
      waitingForApi: false
    };
  },
  computed: {
    usersAge(): number {
      const birthYear = this.dateOfBirth[0];
      const birthMonth = this.dateOfBirth[1];
      const birthDay = this.dateOfBirth[2];

      const todayDate = new Date();
      const todayYear = todayDate.getFullYear();
      const todayMonth = todayDate.getMonth();
      const todayDay = todayDate.getDate();

      let age = todayYear - birthYear;

      if (todayMonth < birthMonth - 1) {
        age = age - 1;
      }
      if (birthMonth - 1 == todayMonth && todayDay < birthDay) {
        age = age - 1;
      }

      return age ? age : 0;
    },
    ...mapGetters([
      'provider',
      'accessCode',
      'organizationShortName',
      'isSubjectToGdpr',
      'accountSetupFields'
    ])
  },
  watch: {
    usersAge(age) {
      if (age < this.minimumAge) {
        this.isValidAge = false;
      }
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (!this.accessCode) {
        this.$router.push({ name: 'SignUp' });
      }

      const input = this.$refs.firstNameInput.$refs
        .validatedInputGroupInput as HTMLElement;
      if (input) {
        input.focus();
      }
    },
    setMinimumAge(minimumAge: number) {
      this.minimumAge = minimumAge;
    },
    massageCustomFields() {
      const customFields: SignUpFieldResponse[] = [];

      this.accountSetupFields.forEach((field: SignUpFieldView) => {
        customFields.push({
          signUpFieldCollectionId: field.collectionId,
          signUpFieldName: field.name,
          response: this.customFields[field.name]
        });
      });

      return {
        responses: customFields
      };
    },
    fieldValidationRules(field: SignUpFieldView) {
      const validationRules = [];

      // Is required
      if (field.required) {
        validationRules.push('required');
      }

      // Custom validation rule regex
      if (field.validationRule) {
        const ruleName =
          field.name.replace(/[\s_]+/g, '').toLowerCase() + 'Rule';

        defineRule(ruleName, (value: string) => {
          const regex = new RegExp(field.validationRule);
          const isValid = regex.test(value);

          if (!isValid && field.validationFailureText) {
            return field.validationFailureText;
          }

          return isValid;
        });

        validationRules.push(ruleName);
      }

      return validationRules.join('|');
    },
    checkboxValidationRules(field: SignUpFieldView) {
      if (field.required) {
        return { required: { allowFalse: false } };
      }
      return '';
    },
    requiredField(field: SignUpFieldView) {
      if (field.required) {
        return 'required';
      }
      return '';
    },
    saveUser() {
      if (!this.birthDateValid || this.waitingForApi) {
        return;
      }
      const payload = {
        accessCode: this.accessCode,
        organizationShortName: this.organizationShortName,
        firstName: this.firstName,
        lastName: this.lastName,
        alias: this.alias,
        email: this.email,
        password: this.password,
        gender: this.gender,
        dateOfBirth: this.dateOfBirth,
        hasOptedInToMyStrengthEmails: this.isEmailOptedIn,
        isSubjectToGDPR: this.isSubjectToGdpr,
        customFields: this.massageCustomFields(),
        ...(this.provider?.referralToken && {
          referralVerificationTicket: this.provider?.referralToken
        })
      };
      this.waitingForApi = true;
      this.register(payload)
        .then(() => {
          this.$router.push({ name: 'OnboardingIntro' });
        })
        .catch((error: { response: { data: { errorCode: string } } }) => {
          if (error.response.data.errorCode === 'USER_ALREADY_EXISTS') {
            const field = this.$t('form.emailField.name') as string;
            this.$refs.form.setErrors({
              [field]: [this.$t('form.emailField.error')]
            });
          }
        })
        .finally(() => {
          this.waitingForApi = false;
        });
    },
    setBirthDateValid($event: boolean) {
      this.birthDateValid = $event;
    },
    ...mapActions(['register'])
  }
});
