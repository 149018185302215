
import { defineComponent } from 'vue';
import PathLookupService from '@/services/PathLookupService';

export default defineComponent({
  name: 'AccountMoved',
  inject: ['$analyticsTrack'],
  computed: {
    teladocLoginUrl() {
      return PathLookupService.getTeladocSignInUrl();
    }
  },
  mounted() {
    this.$analyticsTrack('Migration.AccountMovedView');
  }
});
