export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "coachLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guide"])},
      "coachImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image of myStrength Guide"])},
      "welcomeMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nice to meet you, ", _interpolate(_named("firstName")), "!"])},
      "guideIntroMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["I'm ", _interpolate(_named("guideName")), ", and I'm here to support you on your emotional health journey. I can recommend activities that support your goals and give you a boost if you're feeling stuck. Send me a message anytime — l usually respond within 24 hours."])},
      "inputIntroPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tell me about yourself and how you hope I can help"])},
      "showBio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Bio"])},
      "hideBio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide Bio"])},
      "noGuideProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Profile available for this guide"])},
      "sendMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Message"])},
      "messageSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message sent!"])},
      "messageSentDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your guide usually responds within 24 hours."])},
      "messageFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The message failed to send."])},
      "messageFailedDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please try again in a little bit"])},
      "retrieveMessagesError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guide messages could not be retrieved at this time."])},
      "attachmentTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Attachment Title: ", _interpolate(_named("title"))])},
      "closeGuideDrawer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close Guide"])}
    }
  })
}
