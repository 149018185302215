import { RouteLocation } from 'vue-router';
import AuthService from '@/services/AuthService';
import store from '@/store';
import { OnboardingStatus } from '@/types/UserTypes';
import {
  assessmentRoutes,
  eligibilityRoutes,
  recommendationRoutes,
  surveyRoutes,
  errorRoutes
} from '@/router/onboardingRoutes';

function isInRoutes(routes: { path: string }[], path: string): boolean {
  return !!routes.find((route) => route.path === path);
}

export function isInAnyOnboardingRoutes(to: RouteLocation): boolean {
  const { path } = to;
  return (
    isInRoutes(surveyRoutes, path) ||
    isInRoutes(assessmentRoutes, path) ||
    isInRoutes(recommendationRoutes, path) ||
    isInRoutes(eligibilityRoutes, path) ||
    isInRoutes(errorRoutes, path) ||
    (store.getters.inCrisisPlanActivity && !store.getters.inReassessment)
  );
}

export default function OnboardingRedirectService(
  to: RouteLocation,
  isOneApp = false
): string | null {
  if (!AuthService.hasAuthToken() || to.path === '/logout') {
    return null;
  }

  const { onboardingStatus } = store.getters.user;

  switch (onboardingStatus) {
    case OnboardingStatus.INITIAL:
      return to.path === '/onboarding/intro' ? null : '/onboarding/intro';
    case OnboardingStatus.CHAPTER1_COMPLETED:
      return to.path === '/onboarding/assessment/intro'
        ? null
        : '/onboarding/assessment/intro';
    case OnboardingStatus.CHAPTER2_COMPLETED:
    case OnboardingStatus.CHAPTER3_READY:
      return to.path === '/onboarding/assessment/transition'
        ? null
        : '/onboarding/assessment/transition';
    case OnboardingStatus.ONBOARDING_COMPLETED:
      if (store.getters.canMoveOn) {
        const path = isOneApp ? '/home?isOneApp=true' : '/home';
        return isInAnyOnboardingRoutes(to) ? path : null;
      } else {
        return '/onboarding/gathering-information';
      }
    default:
      return null;
  }
}
