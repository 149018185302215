/**
 * This store module is for microservice service-wellness-assessment related data
 * - using gRPC for server client communication
 *
 */
import { ActionTree, GetterTree, MutationTree } from 'vuex';

import UserStore from '@/store/userStore';

import { RootState } from '@/types/storeTypes';
import { User } from '@/types/UserTypes';

import { Language } from '@/generated-proto/livongo/protobuf/common/bh/language_pb';

import {
  CreateAssessmentsResponsesRequest,
  CreateAssessmentsResponsesResponse,
  ListAssessmentsRequest,
  ListAssessmentsResponse,
  ListRetakeResultContentRequest,
  ListRetakeResultContentResponse,
  AssessmentResponse as AssessmentResponseGrpc,
  AssessmentType
} from '@/generated-proto/livongo/protobuf/grpc/external/bh/wellness_assessments/wellness_assessments_pb';

import {
  assessmentServicePromiseClient,
  getParentUser
} from '@/services/gRPCService';

interface AssessmentResponse {
  assessmentType: number;
  answers: Array<number>;
  name: string;
}

interface AssessmentAnswer {
  assessmentType: number;
  value: number;
  question: number;
  name: string;
}

interface WellnessAssessmentState {
  assessmentsConfiguration: ListAssessmentsResponse.AsObject;
  responses: Array<AssessmentResponse>;
  retakeResultsContent: ListRetakeResultContentResponse.AsObject;
  hasSuicidalIdeation: boolean;
  inReassessment: boolean;
}

const initialState = {
  assessmentsConfiguration: null,
  responses: [],
  inReassessment: false,
  retakeResultsContent: null,
  hasSuicidalIdeation: false
};

const state = { ...initialState };

const stateHelpers = {
  resolveLanguageValue(): Language.Values {
    const DEFAULT_VALUE = Language.Values.EN_US;
    const { language } = UserStore.state.user as User;
    if (language === 'es-MX') {
      return Language.Values.ES_MX;
    }
    return DEFAULT_VALUE;
  }
};

const getters: GetterTree<WellnessAssessmentState, RootState> = {
  assessmentsConfiguration: (state: WellnessAssessmentState) =>
    state.assessmentsConfiguration,
  assessmentsAnswers: (state: WellnessAssessmentState) => state.responses,
  inReassessment: (state: WellnessAssessmentState) => state.inReassessment,
  retakeResultsContent: (state: WellnessAssessmentState) =>
    state.retakeResultsContent,
  hasSuicidalIdeation: (state: WellnessAssessmentState) =>
    state.hasSuicidalIdeation,
  dueForReassessment: (
    state: WellnessAssessmentState,
    getters,
    rootState: RootState,
    rootGetters
  ) => {
    return rootGetters.user.reassessmentDue;
  }
};

const actions: ActionTree<WellnessAssessmentState, RootState> = {
  async getAssessmentsConfiguration({ commit }) {
    const assessmentListPayload = new ListAssessmentsRequest();
    assessmentListPayload.setLanguage(stateHelpers.resolveLanguageValue());
    const parentUser = await getParentUser();

    if (parentUser) {
      assessmentListPayload.setParent(parentUser);
    }

    return assessmentServicePromiseClient
      .listAssessments(assessmentListPayload)
      .then((resp) => {
        commit('setAssessmentsConfiguration', resp.toObject());
      });
  },
  async sendAssessmentAnswers({ state, commit }) {
    const responses = state.responses.map(
      (assessmentResponse: AssessmentResponse) => {
        return new AssessmentResponseGrpc()
          .setAssessmentType(
            assessmentResponse.assessmentType as AssessmentType.Values
          )
          .setName(assessmentResponse.name)
          .setAnswersList(assessmentResponse.answers);
      }
    );

    const assessmentResponsePayload =
      new CreateAssessmentsResponsesRequest().setResponsesList(responses);
    const parentUser = await getParentUser();

    if (parentUser) {
      assessmentResponsePayload.setParent(parentUser);
    }

    return assessmentServicePromiseClient
      .createAssessmentsResponses(assessmentResponsePayload)
      .then((resp: CreateAssessmentsResponsesResponse) => {
        commit('setHasSuicidalIdeation', resp.toObject().hasSi.value);
        commit('clearAssessmentResponses');
      });
  },
  async getRetakeResultsContent({ commit }) {
    const retakeResultsContentPayload = new ListRetakeResultContentRequest();
    retakeResultsContentPayload.setLanguage(
      stateHelpers.resolveLanguageValue()
    );
    const parentUser = await getParentUser();

    if (parentUser) {
      retakeResultsContentPayload.setParent(parentUser);
    }

    return assessmentServicePromiseClient
      .listRetakeResultContent(retakeResultsContentPayload)
      .then((response) => {
        commit('setRetakeResultsContent', response.toObject());
        commit('clearAssessmentResponses');
      });
  }
};

const mutations: MutationTree<WellnessAssessmentState> = {
  setAssessmentsConfiguration(
    state,
    assessmentsConfiguration: ListAssessmentsResponse.AsObject
  ) {
    state.assessmentsConfiguration = assessmentsConfiguration;
  },
  setAssessmentAnswer(
    state,
    { assessmentType, value, question, name }: AssessmentAnswer
  ): void {
    const responsesCopy = [...state.responses];
    const assessmentAnswers = responsesCopy.find(
      (assessmentObj) =>
        assessmentObj && assessmentObj.assessmentType === assessmentType
    );
    if (assessmentAnswers) {
      const answersCopy = [...assessmentAnswers.answers];
      answersCopy[question] = value;
      assessmentAnswers.answers = answersCopy;
    } else {
      const answers: Array<number> = [];
      answers[question] = value;
      responsesCopy.push({ assessmentType, answers, name });
      state.responses = responsesCopy;
    }
  },
  setInReassessment(state, inReassessment: boolean): void {
    state.inReassessment = inReassessment;
  },
  setRetakeResultsContent(
    state,
    retakeResultsContent: ListRetakeResultContentResponse.AsObject
  ): void {
    state.retakeResultsContent = retakeResultsContent;
  },
  setHasSuicidalIdeation(state, suicidalIdeation: boolean) {
    state.hasSuicidalIdeation = suicidalIdeation;
  },
  clearAssessmentResponses(state): void {
    state.responses = [];
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
