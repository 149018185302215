export enum CardState {
  INITIAL = 'INITIAL',
  UPCOMING = 'UPCOMING',
  STARTING_SOON = 'STARTING_SOON',
  SCHEDULE_NEXT = 'SCHEDULE_NEXT',
  SCHEDULE_BOOSTER = 'SCHEDULE_BOOSTER',
  COMPLETED = 'COMPLETED',
  EXPIRED = 'EXPIRED',
  DEACTIVATED = 'DEACTIVATED'
}

export enum TrackPhase {
  INITIAL = 'INITIAL',
  ACTIVE = 'ACTIVE',
  BOOSTER = 'BOOSTER',
  COMPLETED = 'COMPLETED',
  EXPIRED = 'EXPIRED'
}

export enum SessionStatus {
  SCHEDULED = 'SCHEDULED',
  COMPLETED = 'COMPLETED',
  TO_BE_SCHEDULED = 'TO_BE_SCHEDULED',
  CANCELLED = 'CANCELLED'
}

export enum SessionType {
  INITIAL = 'INITIAL',
  FOLLOW_UP = 'FOLLOW_UP',
  WRAP_UP = 'WRAP_UP',
  BOOSTER = 'BOOSTER_SESSION'
}

export enum MemberStatus {
  NON_ACTIVATED = 'NON_ACTIVATED',
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED'
}

export enum ConferenceRecordAction {
  START = 'START',
  STOP = 'STOP'
}

export interface Session {
  sessionId: number;
  startTime: string | null;
  status: SessionStatus;
  homework: string;
  homeworkCompleted: boolean;
  appointmentTypeId: number;
  calendarId: number;
  type: SessionType;
}

export interface SessionList {
  expirationDate: string;
  trackState: TrackPhase;
  sessions: Session[];
}

export interface Coach {
  name: string;
  imageUrl: string;
  specializations: string[];
  credentials: string[];
}

export interface Status {
  memberStatus: MemberStatus;
  trackPhase: TrackPhase;
}

export interface Card {
  type: string;
  title: string;
  description: string;
  content: CardContent;
}

export interface CardContent {
  coachingCardType: CardState;
  hasJoined: boolean;
  sessionId: number;
  sessionLength: number;
  sessionTime: string;
}

export interface Track {
  id: number;
  expirationDate: string;
  phase: TrackPhase;
  primaryNeed: string;
  sessions: Session[];
}
