import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-28d10cc4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "radio-input-group" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["id", "name", "aria-invalid", "data-cy", "value", "disabled", "checked"]
const _hoisted_4 = {
  key: 0,
  class: "input-errors"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Field, {
      ref: "field",
      modelValue: _ctx.inputValue,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.inputValue) = $event)),
      name: _ctx.inputName,
      rules: _ctx.validationRules
    }, {
      default: _withCtx(({ field, errors, meta }) => [
        _createElementVNode("label", {
          for: _ctx.inputId,
          class: _normalizeClass([_ctx.labelClass, {'radio-label-selected': _ctx.isSelected }])
        }, [
          _createElementVNode("input", _mergeProps(field, {
            type: "radio",
            ref: "baseInputRadio",
            id: _ctx.inputId,
            name: _ctx.inputName,
            "aria-invalid": !meta.valid,
            class: _ctx.inputClass(meta),
            "data-cy": _ctx.dataCy,
            value: _ctx.value,
            disabled: _ctx.disabled,
            checked: _ctx.isSelected,
            onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:inputModel', $event.target.value)))
          }), null, 16, _hoisted_3),
          _createTextVNode(_toDisplayString(_ctx.labelText), 1)
        ], 10, _hoisted_2),
        (errors[0])
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(errors[0]), 1))
          : _createCommentVNode("", true),
        _createElementVNode("span", null, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ])
      ]),
      _: 3
    }, 8, ["modelValue", "name", "rules"])
  ]))
}