import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5268a36a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "hero card" }
const _hoisted_2 = { class: "img-container" }
const _hoisted_3 = ["src", "alt", "aria-hidden"]
const _hoisted_4 = { class: "content" }
const _hoisted_5 = { class: "heading heading-md" }
const _hoisted_6 = { class: "description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _ctx.imgUrl,
        alt: _ctx.imgDescription,
        "aria-hidden": _ctx.imgDescription === null || _ctx.imgDescription === ''
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.heading), 1),
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.description), 1),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ]))
}