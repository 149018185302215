import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-558f8cc2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "sign-up-error" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BrandLogo = _resolveComponent("BrandLogo")!
  const _component_Alert = _resolveComponent("Alert")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BrandLogo, {
      class: "logo",
      role: "img",
      "aria-label": _ctx.$t('logoDescription')
    }, null, 8, ["aria-label"]),
    _createVNode(_component_Alert, {
      class: "alert",
      type: _ctx.ALERT_TYPES.WARN,
      title: _ctx.$t('signUpError.alertText'),
      externalDestination: _ctx.alertDestination
    }, null, 8, ["type", "title", "externalDestination"])
  ]))
}