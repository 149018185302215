
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { Form } from 'vee-validate';

import AuthService from '@/services/AuthService';

import InlineError from '@common/InlineError.vue';
import ValidatedMessage from '@common/ValidatedMessage.vue';

export default defineComponent({
  name: 'ChangePassword',
  inject: ['$analyticsTrack'],
  components: {
    MHForm: Form,
    InlineError,
    ValidatedMessage
  },
  data() {
    return {
      isExpiredPasswordUserFlow: this.$route.query.isExpired === 'true',
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      prevPassError: false,
      currentPasswordFail: false,
      showForm: true,
      currentPasswordValid: false,
      newPasswordValid: false,
      confirmPasswordValid: false,
      newPasswordRequirementsFailed: false,
      isWaitingForCurrentPasswordAuth: false
    };
  },
  computed: {
    showCurrentPasswordError() {
      if (!this.prevPassError) {
        return this.currentPasswordFail;
      }
      return false;
    },
    newAndConfirmMatch() {
      return this.newPassword === this.confirmPassword;
    },
    ...mapGetters(['passwordIsExpired', 'hasExceededPasswordAttempts'])
  },
  watch: {
    newPassword(value) {
      if (value) {
        this.prevPassError = false;
      }
    },
    passwordIsExpired(value) {
      if (value) {
        this.isExpiredPasswordUserFlow = true;
      }
    }
  },
  mounted() {
    this.$analyticsTrack('MH.PasswordExpired.Start');
  },
  methods: {
    doResetPassword(): void {
      if (this.isExpiredPasswordUserFlow && this.currentPassword) {
        if (this.newAndConfirmMatch) {
          this.resetExpiredPassword({
            resetPasswordToken: this.$route.query.token,
            currentPassword: this.currentPassword,
            newPassword: this.newPassword
          }).then(
            () => {
              // On success , clear the token so the user can re login
              AuthService.clearAuthToken();
              this.showForm = false;
              this.$analyticsTrack('MH.PasswordExpired.Complete');
            },
            (err: { response: { data: { errorCode: string } } }) => {
              if (err.response.data.errorCode == 'PASSWORD_MATCHES_PREVIOUS') {
                this.prevPassError = true;
              }
              if (err.response.data.errorCode == 'INVALID_PASSWORD') {
                this.currentPasswordFail = true;
              }
              if (
                err.response.data.errorCode ==
                'PASSWORD_DOES_NOT_MEET_REQUIREMENTS'
              ) {
                this.newPasswordRequirementsFailed = true;
              }
              this.resetForm();
            }
          );
        } else {
          this.addErrorNotification({
            heading: this.$t('updateFailureHeading'),
            description: this.$t('updateProfileFailureDescription')
          });
        }
      } else {
        if (this.newAndConfirmMatch) {
          this.resetPassword({
            resetPasswordToken: this.$route.query.token,
            newPassword: this.newPassword
          }).then(
            () => {
              // On success , clear the token so the user can re login
              AuthService.clearAuthToken();
              this.showForm = false;
              this.$analyticsTrack('MH.PasswordExpired.Complete');
            },
            (err: { response: { data: { errorCode: string } } }) => {
              if (err.response.data.errorCode == 'PASSWORD_MATCHES_PREVIOUS') {
                this.prevPassError = true;
              }
              this.resetForm();
            }
          );
        } else {
          this.addErrorNotification({
            heading: this.$t('updateFailureHeading'),
            description: this.$t('updateProfileFailureDescription')
          });
        }
      }
    },
    setCurrentPasswordValid(valid: boolean): void {
      this.currentPasswordValid = valid;
    },
    setNewPasswordValid(valid: boolean): void {
      this.newPasswordValid = valid;
    },
    setConfirmPasswordValid(valid: boolean): void {
      this.confirmPasswordValid = valid;
    },
    resetForm(): void {
      this.showCurrentPasswordError = false;
      this.currentPassword = '';
      this.newPassword = '';
      this.confirmPassword = '';
      this.setCurrentPasswordValid(false);
      this.setNewPasswordValid(false);
      this.$refs.form.resetForm();
    },
    ...mapActions([
      'resetExpiredPassword',
      'resetPassword',
      'addErrorNotification'
    ])
  }
});
