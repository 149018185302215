import { Form } from 'vee-validate';

export interface ValidationObserverRef {
  reset(): void;
  setErrors(errors: object): void;
  validate(): void;
  flags: {
    failed: boolean;
  };
}

export type ValidationObserverInstance = InstanceType<typeof Form>;

export enum CommonRules {
  VALID_NAME = 'required|max:50|noInvalidNameChars|noExtraSpaces'
}
