
import { defineComponent } from 'vue';
import { mapActions, mapMutations } from 'vuex';

import InternalLinkService from '@/services/InternalLinkService';

import AlertIcon from '@common/AlertIcon.vue';

export enum ALERT_TYPES {
  INFO = 'INFO',
  WARN = 'WARN',
  DANGER = 'DANGER'
}

export default defineComponent({
  name: 'Alert',
  components: {
    AlertIcon
  },
  props: {
    title: {
      type: String,
      required: true
    },
    destination: {
      type: String,
      required: false,
      default: null
    },
    externalDestination: {
      type: String,
      required: false,
      default: null
    },
    type: {
      type: String,
      required: false,
      default: ALERT_TYPES.INFO,
      validator: (value: string) => Object.keys(ALERT_TYPES).includes(value)
    }
  },
  data() {
    return {
      ALERT_TYPES: ALERT_TYPES
    };
  },
  computed: {
    destinationPath(): string {
      return InternalLinkService.getWebPathForInternalLink(this.destination);
    },
    alertClass(): { [key: string]: boolean } {
      return {
        'alert-info': this.type == ALERT_TYPES.INFO,
        'alert-warn': this.type == ALERT_TYPES.WARN,
        'alert-danger': this.type == ALERT_TYPES.DANGER
      };
    }
  },
  methods: {
    showNewFeatures(): void {
      if (this.$route.name !== 'NewFeatures') {
        this.$router.push(
          InternalLinkService.getWebPathForInternalLink(this.destination)
        );
      }

      this.setDrawerComponent('NewFeatures');
      this.openDrawer();
    },
    ...mapMutations(['setDrawerComponent']),
    ...mapActions(['openDrawer'])
  }
});
