
import { defineComponent } from 'vue';
import { mapGetters, mapActions } from 'vuex';

import { defineRule, Form } from 'vee-validate';

import SecurityMessage from '@common/SecurityMessage.vue';
import pathSvc from '@/services/PathLookupService';

export default defineComponent({
  name: 'MFAVerify',
  components: {
    SecurityMessage,
    MHForm: Form
  },
  data() {
    return {
      userMFATypeSet: false,
      verificationCode: '',
      verificationHasBeenSubmitted: false
    };
  },
  computed: {
    urlBaseApp: () => pathSvc.getWebUIDomain(),
    ...mapGetters(['userMFAType', 'userIsVerified', 'userPermissions'])
  },
  watch: {
    userIsVerified(newValue) {
      if (newValue !== null) {
        if (newValue) {
          this.goToDestination();
        }
      }
    },
    verificationCode() {
      this.verificationHasBeenSubmitted = false;
    }
  },
  created() {
    this.init();
  },
  mounted() {
    this.$nextTick(() => {
      this.focusVerificationCodeInput();
    });
  },
  methods: {
    init() {
      this.registerValidator();
      if (this.userIsVerified) {
        this.goToDestination();
      }

      // Get userMFAType if not already set
      if (this.userMFAType) {
        this.userMFATypeSet = true;
      } else {
        this.getUserMFAType().then(() => {
          this.userMFATypeSet = true;
        });
      }
    },
    submitMfaCode() {
      this.verifyAuthCode(this.verificationCode).then(() => {
        this.$refs.form.validate();
      });
      this.verificationHasBeenSubmitted = true;
    },
    sendEmailCode() {
      // Getting MFA type sends email
      this.getUserMFAType();
      this.addInfoNotification({
        heading: this.$t('emailSentHeading'),
        description: this.$t('emailSentCopy')
      });
    },
    goToDestination() {
      window.location.href = this.$route.query?.destinationUrl;
    },
    focusVerificationCodeInput() {
      if (this.$refs.verificationCodeInput) {
        const input = this.$refs.verificationCodeInput?.$refs
          .validatedInputGroupInput as HTMLElement;
        input?.focus();
      }
    },
    registerValidator() {
      defineRule('validVerificationCode', () => {
        // Don't validate against userIsVerified until verification has been submitted
        if (
          !this.verificationHasBeenSubmitted ||
          this.userIsVerified === true
        ) {
          return true;
        } else {
          return this.$t('invalidVerificationCode');
        }
      });
    },
    ...mapActions(['getUserMFAType', 'verifyAuthCode', 'addInfoNotification'])
  }
});
