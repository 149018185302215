export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "profileTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
      "profileSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To update your profile, you’ll need to visit our website."])},
      "notificationsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
      "languageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
      "personalizationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalization"])},
      "personalizationSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customize your experience by selecting topics that interest you."])},
      "supportTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
      "historyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])}
    }
  })
}
