
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MHFooter',
  computed: {
    version(): string {
      return process.env.VUE_APP_VERSION || '';
    }
  }
});
