
import { defineComponent } from 'vue';

import { Form, Field, defineRule, FormMeta } from 'vee-validate';
import { digits, is_not } from '@vee-validate/rules';

import { DateUtil } from '@/helpers/DateUtil';
import InlineError from '@common/InlineError.vue';

const appMinimumAge = 13;
const gdprMinAge = 16;

defineRule('digits', digits);
defineRule('is_not', is_not);
// See registerValidationRules()

const currentYear = new Date().getFullYear();

export default defineComponent({
  name: 'Birthdate',
  emits: ['isValid', 'update:inputModel', 'minimum-age'],
  components: {
    MHForm: Form,
    Field,
    InlineError
  },
  props: {
    // array of [year, month, day]
    inputModel: {
      type: Array,
      required: true
    },
    isSmallMode: {
      type: Boolean,
      required: false,
      default: false
    },
    isSubjectToGdpr: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      month: 0,
      day: 0,
      year: '',
      daysInMonth: 0,
      currentYear: currentYear,
      minimumAge: this.isSubjectToGdpr ? gdprMinAge : appMinimumAge
    };
  },
  watch: {
    inputModel: {
      deep: true,
      handler(): void {
        if (this.inputModel && this.inputModel.length) {
          this.year = this.inputModel[0] as string;
          this.month = this.inputModel[1] as number;
          this.day = this.inputModel[2] as number;
        }
      }
    },
    month() {
      const year = Number(this.year)
        ? Number(this.year)
        : new Date().getFullYear();

      if (year > currentYear) {
        return;
      }

      this.daysInMonth = DateUtil.getDaysInMonth(year, this.month);
      this.emitDate();
    },
    day() {
      this.emitDate();
    },
    year(newYear) {
      if (newYear > currentYear) {
        return;
      }

      this.daysInMonth = DateUtil.getDaysInMonth(newYear, this.month);
      this.emitDate();
    }
  },
  mounted() {
    this.init();
    this.$emit('minimum-age', this.minimumAge);
  },
  methods: {
    init() {
      // Validation rules with $t translated strings
      this.registerValidationRules();
    },
    validateAndEmitValidity(): void {
      // Timeout needed to avoid race condition
      setTimeout(() => {
        this.$refs.dateInputForm
          .validate()
          .then((value: { valid: boolean; errors: Record<string, string> }) => {
            this.$emit('isValid', value.valid);
          });
      }, 100);
    },
    setValidity(meta: FormMeta<never>) {
      this.$emit('isValid', meta.valid);
    },
    registerValidationRules() {
      // meetsMinimumAge
      defineRule(
        'meetsMinimumAge',
        (value: number, [month, day, minimumAge]: number[]) => {
          value = Number(value);
          const birthPlusMin = new Date(value + minimumAge, month - 1, day);
          const today = new Date();

          if (today > birthPlusMin) {
            return true;
          } else {
            return this.$t('ruleMessages.meetsMinimumAge', {
              minimumAge: minimumAge
            });
          }
        }
      );
      // youngerThan150
      defineRule('youngerThan150', (value: number, [month, day]: number[]) => {
        value = Number(value);
        const birthPlus150 = new Date(value + 150, month - 1, day);
        const today = new Date();

        if (today < birthPlus150) {
          return true;
        } else {
          return this.$t('ruleMessages.youngerThan150');
        }
      });
    },
    emitDate() {
      this.$emit('update:inputModel', [
        Number(this.year) ? Number(this.year) : '',
        this.month,
        this.day
      ]);

      this.validateAndEmitValidity();
    },
    errorMessage(errors: { year: string; month: string; day: string }): string {
      if (errors.month) {
        return errors.month;
      } else if (errors.day) {
        return errors.day;
      } else if (errors.year) {
        return errors.year;
      } else {
        return this.$t('error');
      }
    }
  }
});
