/**
 * Tells webpack to code split and load the view component dynamically
 * @param componentPath
 */
export const dynamicComponent = (componentPath: string) => () =>
  import(
    /* webpackChunkName: "chunk-[request]" */
    `@/views/${componentPath}.vue`
  );

/**
 * Tells webpack to code split and load the view component dynamically and also prefetch it
 * @param componentPath
 */
export const dynamicComponentPrefetched = (componentPath: string) => () =>
  import(
    /* webpackChunkName: "component-[request]" */
    /* webpackPrefetch: true */
    `@/views/${componentPath}.vue`
  );
