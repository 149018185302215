import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d7238636"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "alerts"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Alert = _resolveComponent("Alert")!

  return (_ctx.memberMessages)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.memberMessages.notification)
          ? (_openBlock(), _createBlock(_component_Alert, {
              key: 0,
              class: "notification",
              title: _ctx.memberMessages.notification.title,
              type: _ctx.memberMessages.notification.severity === 'HIGH' ? 'WARN' : 'INFO',
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.trackMemberMessage(_ctx.memberMessages.notification.id))),
              destination: _ctx.memberMessages.notification.destination
            }, null, 8, ["title", "type", "destination"]))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.memberMessages.promotions, (memberMessage, index) => {
          return (_openBlock(), _createBlock(_component_Alert, {
            class: "promotion",
            type: "INFO",
            key: index,
            title: memberMessage.title,
            onClick: ($event: any) => (_ctx.trackMemberMessage(memberMessage.id)),
            destination: memberMessage.destination
          }, null, 8, ["title", "onClick", "destination"]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}