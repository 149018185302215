export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set up Your Account"])},
      "form": {
        "firstNameField": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What's your first name?"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["first name"])}
        },
        "lastNameField": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What's your last name?"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["last name"])}
        },
        "screenNameField": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a screen name"])},
          "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is the name that others on myStrength will see"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["screen name"])}
        },
        "emailField": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What's your email address?"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email address"])},
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This email appears to already be in use."])}
        },
        "passwordField": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a password"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["password"])},
          "subText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least 8 characters with one number, uppercase letter, and  lowercase letter"])}
        },
        "gender": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Gender"])},
          "inline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is an optional field. If completed, this information helps myStrength personalize your experience and find appropriate resources. It is also included in overall myStrength usage data reports."])},
          "options": {
            "MALE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
            "FEMALE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
            "NON_BINARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-Binary"])},
            "NO_ANSWER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prefer not to answer"])}
          }
        },
        "validAgeCheckbox": {
          "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["I am at least ", _interpolate(_named("minAge")), " years old"])},
          "error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You must be at least ", _interpolate(_named("minAge")), " years old to use myStrength"])}
        },
        "emailOptinCheckbox": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I want to receive email communications from myStrength"])}
        },
        "termsCheckbox": {
          "labelPrefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree to myStrength's"])},
          "termsOfUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Use"])},
          "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must agree to the terms of use and privacy policy"])}
        },
        "continueBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
        "requiredValidationFailure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is required"])}
      }
    }
  })
}
