export default {
  "pageTitles": {
    "AccountMoved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta movida"])},
    "AccountSetup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuracion de cuenta"])},
    "Activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividad"])},
    "ActivityResponses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respuestas a la actividad"])},
    "AssessmentQuestionnaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluación"])},
    "AssessmentRetakeQuestionnaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluación"])},
    "AssessmentIntro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introducción a la evaluación"])},
    "AssessmentRetakeIntro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introducción a la evaluación"])},
    "AssessmentTransition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluación"])},
    "AssessmentRetakeTransition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluación"])},
    "AssessmentRetakeCrisisIntervention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluación"])},
    "AssessmentRetakeResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluación"])},
    "Browse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navegar"])},
    "Category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoría"])},
    "ChangePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar la contraseña"])},
    "Channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal"])},
    "ClinicalCoachingGetStarted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching: empezar"])},
    "ClinicalCoachingSchedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programar sesiones de coaching"])},
    "ClinicalCoachingAbout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acerca del coaching"])},
    "ClinicalCoachingCoachBio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrenador Bio"])},
    "ClinicalPortalConference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferencia del Portal Clínico"])},
    "ComingSoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muy Pronto"])},
    "Connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectar"])},
    "CrisisIntervention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluación"])},
    "DeepLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redireccionando"])},
    "DestinationChooser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selector de destino"])},
    "Explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explorar"])},
    "Favorites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favoritas"])},
    "FavoritesResourceNavigation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navegador de favoritos"])},
    "GdprTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RGPD condiciones"])},
    "HelpResources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recursos de Ayuda"])},
    "History": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historia"])},
    "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio"])},
    "Intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intro"])},
    "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajustes de idioma"])},
    "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceso"])},
    "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar sesión"])},
    "MFAVerify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticación de dos factores"])},
    "NewFeaturesMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuevas características"])},
    "NewFeatures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuevas características"])},
    "Notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración de las notificaciones"])},
    "OnboardingError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "OnboardingIneligible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reuniendo información"])},
    "OnboardingIntro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introducción a la incorporación"])},
    "OneAppTransition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preparándose para la redirección a Teladoc"])},
    "OneAppMigration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirigiendo a Teladoc"])},
    "PartnerLandingPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["myStrength"])},
    "PasswordReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecimiento de contraseña"])},
    "Personalization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuraciones de personalización"])},
    "PlaceHolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcador de posición"])},
    "PlanRecommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recomendación del plan"])},
    "Program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programa"])},
    "Progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progreso"])},
    "ProgramRecommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recomendación del programa"])},
    "Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfil"])},
    "Series": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serie"])},
    "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar"])},
    "SignUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscribirse"])},
    "SiteMap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mapa del sitio"])},
    "Sleep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sleep"])},
    "SleepMobileRedirect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirección del sueño"])},
    "Styles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guía de estilo"])},
    "SurveyQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encuesta"])},
    "SurveyTransition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encuesta"])},
    "SurveyOutro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encuesta"])},
    "Support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apoyo"])},
    "Topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tema"])},
    "WebViewScheduleBase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horario de sueño"])},
    "WebViewSleepCheckIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro de sueño"])},
    "Teletherapy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teletherapy"])},
    "WebViewSleepSchedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horario de sueño"])},
    "WebViewSleepScheduleEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horario de sueño"])},
    "WebViewActivityHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historial de actividad"])},
    "HelpCrisis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayudar"])},
    "ChangePrimaryNeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar necesidad primaria"])},
    "PlanTransition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading"])}
  },
  "labels": {
    "languageSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione el idioma"])},
    "supportedLanguages": {
      "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
      "es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Español"])}
    }
  },
  "buttons": {
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siguiente"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espalda"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])},
    "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalizar"])}
  },
  "homeLinkAria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enlace a la página de inicio de myStrength"])},
  "brandName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["myStrength"])},
  "logoDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logotipo de myStrength"])},
  "iconDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icono de myStrength"])},
  "teladocLogoDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logotipo de Teladoc"])},
  "at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en"])},
  "loadingMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargando"])},
  "loadingMessageEllipsis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargando ..."])}
}