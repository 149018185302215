import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, KeepAlive as _KeepAlive, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-23d7293a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "loading"
}
const _hoisted_3 = { class: "heading-lg loading-message" }
const _hoisted_4 = { class: "rightPanel" }
const _hoisted_5 = {
  class: "main-wrapper",
  id: "main"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SignUpError = _resolveComponent("SignUpError")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Notifications = _resolveComponent("Notifications")!
  const _component_NavigationSide = _resolveComponent("NavigationSide")!
  const _component_NavigationTop = _resolveComponent("NavigationTop")!
  const _component_Guide = _resolveComponent("Guide")!
  const _component_NewFeatures = _resolveComponent("NewFeatures")!
  const _component_BaseDrawerModal = _resolveComponent("BaseDrawerModal")!
  const _component_BaseWrapper = _resolveComponent("BaseWrapper")!
  const _component_MHFooter = _resolveComponent("MHFooter")!
  const _component_JoinConferenceButton = _resolveComponent("JoinConferenceButton")!
  const _component_SessionTimer = _resolveComponent("SessionTimer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isAppBootstrapLoading || _ctx.isAuthForLivongoTokensLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t('loadingMessageEllipsis')), 1)
        ]))
      : (_ctx.isAuthenticatedCoreRoute)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            id: "app",
            class: _normalizeClass([_ctx.oneAppWebViewClass, _ctx.mobileWebViewClass])
          }, [
            (_ctx.incorrectlyInSignUp)
              ? (_openBlock(), _createBlock(_component_SignUpError, { key: 0 }))
              : (!_ctx.showNav)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createVNode(_component_router_view),
                    _createVNode(_component_Notifications)
                  ], 64))
                : (_ctx.showNav)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                      _createVNode(_component_NavigationSide),
                      _createElementVNode("div", _hoisted_4, [
                        _createVNode(_component_NavigationTop),
                        _createVNode(_component_BaseDrawerModal, {
                          class: _normalizeClass({ 'new-features': _ctx.showNewFeatures })
                        }, {
                          drawerModalContent: _withCtx(() => [
                            _withDirectives(_createVNode(_component_Guide, null, null, 512), [
                              [_vShow, _ctx.showGuide]
                            ]),
                            _withDirectives(_createVNode(_component_NewFeatures, null, null, 512), [
                              [_vShow, _ctx.showNewFeatures]
                            ])
                          ]),
                          _: 1
                        }, 8, ["class"]),
                        _createElementVNode("main", _hoisted_5, [
                          _createVNode(_component_BaseWrapper, {
                            logoDisplay: false,
                            size: _ctx.wrapperSize
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(), _createBlock(_component_router_view, {
                                class: "view",
                                key: _ctx.$route.fullPath
                              }, {
                                default: _withCtx(({ Component }) => [
                                  (_openBlock(), _createBlock(_KeepAlive, {
                                    include: _ctx.keepAliveComponents,
                                    max: 1
                                  }, [
                                    (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                                  ], 1032, ["include"]))
                                ]),
                                _: 1
                              }))
                            ]),
                            _: 1
                          }, 8, ["size"]),
                          _createVNode(_component_MHFooter)
                        ])
                      ]),
                      _createVNode(_component_Notifications),
                      _createVNode(_component_JoinConferenceButton)
                    ], 64))
                  : _createCommentVNode("", true),
            (!_ctx.isOneApp)
              ? (_openBlock(), _createBlock(_component_SessionTimer, { key: 3 }))
              : _createCommentVNode("", true)
          ], 2))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
            _createVNode(_component_router_view),
            _createVNode(_component_Notifications)
          ], 64))
  ]))
}