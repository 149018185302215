export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])},
      "form": {
        "accessCodeField": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What's your access code?"])},
          "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An access code is given to you by your mental wellness provider."])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["access code"])}
        },
        "locationCheckbox": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am currently located outside of the United States"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["location"])}
        },
        "invalidAccessCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access code is not valid"])},
        "toomanyRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Too many requests"])},
        "nextBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start your Journey"])}
      },
      "customSignUpField": {
        "selectOptionGroupDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose an option"])},
        "selectOptionAccessCodeDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose an option"])}
      },
      "existingUser": {
        "isUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already a user?"])},
        "signInLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])}
      },
      "heading2": {
        "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You're on your way"])},
        "Teladoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We’re taking you to Teladoc"])}
      },
      "text": {
        "MyStrength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In a moment you'll be redirected to myStrength to complete registration"])},
        "Livongo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In a moment you'll be redirected to Livongo to complete registration"])},
        "Teladoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use the same code there and you’ll be on your way!"])}
      }
    }
  })
}
