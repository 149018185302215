export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "es",
    "resource": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su sesión está a punto de caducar"])},
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Se cerrará la sesión de ", _interpolate(_named("brandName")), " en menos de un minuto debido a la inactividad."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])}
    }
  })
}
