export default {
  "pageTitles": {
    "AccountMoved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Moved"])},
    "AccountSetup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Setup"])},
    "Activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
    "ActivityResponses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity Responses"])},
    "AssessmentQuestionnaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assessment"])},
    "AssessmentRetakeQuestionnaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assessment"])},
    "AssessmentIntro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assessment Intro"])},
    "AssessmentRetakeIntro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assessment Intro"])},
    "AssessmentTransition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assessment"])},
    "AssessmentRetakeTransition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assessment"])},
    "AssessmentRetakeCrisisIntervention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assessment"])},
    "AssessmentRetakeResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assessment"])},
    "Browse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browse"])},
    "Category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
    "ChangePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])},
    "Channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel"])},
    "ClinicalCoachingGetStarted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching: Get Started"])},
    "ClinicalCoachingSchedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schedule Coaching Sessions"])},
    "ClinicalCoachingAbout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Coaching"])},
    "ClinicalCoachingCoachBio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coach Bio"])},
    "ClinicalPortalConference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clinical Portal Conference"])},
    "ComingSoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coming Soon"])},
    "Connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect"])},
    "CrisisIntervention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assessment"])},
    "DeepLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirecting"])},
    "DestinationChooser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination Chooser"])},
    "Explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore"])},
    "Favorites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorites"])},
    "FavoritesResourceNavigation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorites Navigator"])},
    "GdprTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GDPR terms"])},
    "HelpResources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help resources"])},
    "History": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
    "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "Intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intro"])},
    "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language Settings"])},
    "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
    "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "MFAVerify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two-Factor Authentication"])},
    "NewFeaturesMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Features"])},
    "NewFeatures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Features"])},
    "Notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification Settings"])},
    "OnboardingError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "OnboardingIneligible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gathering Information"])},
    "OnboardingIntro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onboarding Intro"])},
    "OneAppTransition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preparing for redirect to Teladoc"])},
    "OneAppMigration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirecting to Teladoc"])},
    "PartnerLandingPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["myStrength"])},
    "PasswordReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password Reset"])},
    "Personalization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalization"])},
    "PlaceHolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PlaceHolder"])},
    "PlanRecommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan Recommendation"])},
    "Program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program"])},
    "ProgramRecommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program Recommendation"])},
    "Progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progress"])},
    "Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "Series": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Series"])},
    "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "SignUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Up"])},
    "SiteMap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site Map"])},
    "Sleep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sleep"])},
    "SleepMobileRedirect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sleep Redirect"])},
    "Styles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Style guide"])},
    "Support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "SurveyQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Survey"])},
    "SurveyTransition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Survey"])},
    "SurveyOutro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Survey"])},
    "Topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topic"])},
    "WebViewScheduleBase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sleep Schedule"])},
    "WebViewSleepCheckIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sleep Check In"])},
    "Teletherapy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teletherapy"])},
    "WebViewSleepSchedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sleep Schedule"])},
    "WebViewSleepScheduleEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sleep Schedule Edit"])},
    "WebViewActivityHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity History"])},
    "HelpCrisis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help"])},
    "ChangePrimaryNeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Primary Need"])},
    "PlanTransition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading"])}
  },
  "labels": {
    "languageSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select language"])},
    "supportedLanguages": {
      "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
      "es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Español"])}
    }
  },
  "buttons": {
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish"])}
  },
  "homeLinkAria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link to homepage of myStrength"])},
  "brandName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["myStrength"])},
  "logoDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["myStrength logo"])},
  "iconDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["myStrength icon"])},
  "teladocLogoDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teladoc logo"])},
  "at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["at"])},
  "loadingMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading"])},
  "loadingMessageEllipsis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading ..."])}
}