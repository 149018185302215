export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "es",
    "resource": {
      "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar/ocultar información sobre herramientas"])},
      "tooltipLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción emergente de información"])}
    }
  })
}
