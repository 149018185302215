import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { RootState } from '@/types/storeTypes';
import HTTP from '@/services/HttpService';
import DomainService from '@/services/PathLookupService';
import apiRoutes from '@config/api-routes';
import { AxiosRequestConfig } from 'axios';

import { ConferenceRecordAction } from '@/types/ClinicalCoachingTypes';
import { ConferencePayload } from '@/types/OneAppTypes';

interface AttendeeState {
  admin: boolean;
  attendeeId: string;
  authData: string;
  externalId: string;
  phoneNumber: string;
  role: string | null;
  userId: string;
}

interface AuthState {
  data: {
    attendee_id: number;
    auth_details: {
      api_key: string;
      session_id: string;
      token: string;
      video_provider: string;
    };
    id: string;
    jwt: string;
  };
}

export interface ConferenceState {
  attendee: AttendeeState;
  conferenceId: number;
  conferenceDateTime: number;
  active: boolean;
  clinicalPortalToken: string;
  clinicalPortalRefreshToken: string;
  clinicalPortalObo: string;
  clinicalAuthenticated: boolean;
  noConference: boolean;
  meetingOn: boolean;
  isRecording: boolean;
  recordingError: null | Error;
}

const initialState = {
  attendee: null,
  conferenceId: null,
  conferenceDateTime: null,
  active: false,
  clinicalPortalToken: null,
  clinicalRefreshToken: null,
  clinicalPortalObo: null,
  clinicalAuthenticated: false,
  noConference: false,
  meetingOn: false,
  isRecording: false,
  recordingError: null
};

const state = { ...initialState };

const getters: GetterTree<ConferenceState, RootState> = {
  currentAttendeeConfig: (state: ConferenceState): object | null => {
    if (!state.attendee) {
      return null;
    }
    const authData: AuthState = JSON.parse(state.attendee.authData);

    return {
      sessionId: authData.data.auth_details.session_id,
      token: authData.data.auth_details.token,
      provider: authData.data.auth_details.video_provider,
      apiKey: authData.data.auth_details.api_key,
      loggerBaseUrl: DomainService.getWebUIDomain(),
      loggerEndpoint: '_log'
    };
  },
  mobileCurrentAttendeeConfig: (
    state: ConferenceState
  ): ConferencePayload | null => {
    if (!state.attendee) {
      return null;
    }
    const authData: AuthState = JSON.parse(state.attendee.authData);

    return {
      video_consult_type: 'mystrength_clinical_coaching',
      teladoc_jwt: authData.data.jwt,
      teladoc_id: authData.data.id,
      teladoc_auth_details: authData.data.auth_details,
      teladoc_attendee_id: authData.data.attendee_id,
      apiKey: authData.data.auth_details.api_key,
      sessionId: authData.data.auth_details.session_id,
      token: authData.data.auth_details.token
    };
  },
  clinicalPortalToken: (state: ConferenceState) => state.clinicalPortalToken,
  clinicalPortalRefreshToken: (state: ConferenceState) =>
    state.clinicalPortalRefreshToken,
  clinicalPortalObo: (state: ConferenceState) => state.clinicalPortalObo,
  clinicalAuthenticated: (state: ConferenceState) =>
    state.clinicalAuthenticated,
  noConference: (state: ConferenceState) => state.noConference,
  meetingOn: (state: ConferenceState) => state.meetingOn,
  conferenceDateTime: (state: ConferenceState): number =>
    state.conferenceDateTime,
  isRecording: (state: ConferenceState) => state.isRecording,
  recordingError: (state: ConferenceState) => state.recordingError
};

const actions: ActionTree<ConferenceState, RootState> = {
  getConferenceMetaData: async ({ commit }) => {
    const { data } = await HTTP.get(apiRoutes.conference.member);
    if (data) {
      commit('setConferenceState', data);
      commit('setNoConference', false);
    } else {
      commit('setNoConference', true);
    }
  },
  getClinicalConferenceMetaData: async ({ commit, getters }) => {
    const config = {
      headers: {
        'x-session-token': getters.clinicalPortalToken
      }
    } as AxiosRequestConfig;
    const { data } = await HTTP.get(apiRoutes.conference.coach, config);
    if (data) {
      commit('setConferenceState', data);
      commit('setNoConference', false);
    } else {
      commit('setNoConference', true);
    }
  },
  deleteConference: async ({ getters }) => {
    const config = {
      headers: {
        'x-session-token': getters.clinicalPortalToken
      }
    } as AxiosRequestConfig;
    return HTTP.delete(apiRoutes.conference.delete(state.conferenceId), config);
  },
  trackEndCall() {
    return HTTP.put(apiRoutes.conference.trackEndCall(state.conferenceId));
  },
  startOrStopRecording: ({ commit, getters }) => {
    const action = getters.isRecording
      ? ConferenceRecordAction.STOP
      : ConferenceRecordAction.START;
    const config = {
      headers: {
        'x-session-token': getters.clinicalPortalToken
      }
    } as AxiosRequestConfig;
    return HTTP.put(
      apiRoutes.conference.record(state.conferenceId),
      { action },
      config
    )
      .then(() => {
        commit('setIsRecording', !getters.isRecording);
        commit('setRecordingError', null);
      })
      .catch((error) => {
        commit('setRecordingError', error);
      });
  }
};

const mutations: MutationTree<ConferenceState> = {
  setConferenceState(state, data) {
    state.conferenceId = data.conferenceId;
    state.attendee = data.attendee;
    state.active = data.active;
    state.conferenceDateTime = data.conferenceDateTime;
  },
  setClinicalPortalToken: (state, token) => {
    state.clinicalPortalToken = token;
  },
  setClinicalPortalRefreshToken: (state, token) => {
    state.clinicalPortalRefreshToken = token;
  },
  setClinicalPortalObo: (state, obo) => {
    state.clinicalPortalObo = obo;
  },
  setClinicalAuthenticated: (state, authenticated) => {
    state.clinicalAuthenticated = authenticated;
  },
  setNoConference: (state, noConference) => {
    state.noConference = noConference;
  },
  setMeetingOn: (state, meetingOn) => {
    state.meetingOn = meetingOn;
  },
  setIsRecording: (state, isRecording) => {
    state.isRecording = isRecording;
  },
  setRecordingError: (state, recordingError) => {
    state.recordingError = recordingError;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
