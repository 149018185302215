
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { Form as MHForm } from 'vee-validate';

import { AxiosResponse } from 'axios';
import { ClientOfferingVersion } from '@/types/UserTypes';
import { Provider } from '@/types/PartnerCollateralTypes';

// @ts-ignore
import debounce from '@/helpers/debounce.js';

import PathLookupService from '@/services/PathLookupService';
import BaseWrapper from '@/components/base/BaseWrapper.vue';
import Tooltip from '@common/Tooltip.vue';

export default defineComponent({
  name: 'SignUp',
  components: {
    MHForm,
    BaseWrapper,
    Tooltip
  },
  props: {
    organization: {
      type: String,
      required: false,
      default: null
    },
    group: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      input: '',
      customSignUpGroup: '',
      customSignUpShown: false,
      isAccessCodeValid: false,
      isSubjectToGdpr: false,
      showApiError: false,
      showForm: true,
      clientOfferingVersion: '',
      regDestination: 'MyStrength' as 'MyStrength' | 'Livongo' | 'Teladoc',
      accessCode: (this.$route.query.accessCode ||
        this.$route.query.pc ||
        null) as string | null,
      referrer: (this.$route.query.referrer || null) as string | null
    };
  },
  computed: {
    showGroup(): boolean {
      return !!this.groupField;
    },
    ...mapGetters([
      'providerIsValid',
      'signUpCustomFields',
      'accessCodeField',
      'groupField',
      'providerFilteredAccessCodes',
      'organizationShortName'
    ])
  },
  watch: {
    providerIsValid(isValid) {
      if (isValid) {
        this.initProviderSignUp();
      } else {
        this.customSignUpShown = false;
      }
    },
    input: {
      deep: true,
      handler: debounce(function (this: {
        doAccessCodeValidation: () => void;
      }) {
        this.doAccessCodeValidation();
      },
      1000)
    },
    signUpCustomFields: {
      deep: true,
      handler() {
        if (this.providerFilteredAccessCodes?.length) {
          this.customSignUpShown = true;
        }
      }
    },
    customSignUpGroup: {
      deep: true,
      handler() {
        this.setProvider({
          org: this.organization,
          group: this.customSignUpGroup,
          accessCode: this.accessCode,
          referralToken: this.referrer
        } as Provider);
      }
    }
  },
  mounted() {
    if (this.providerIsValid) {
      this.initProviderSignUp();
    } else {
      this.setProvider({
        org: this.organization,
        group: this.group,
        accessCode: this.accessCode,
        referralToken: this.referrer
      } as Provider);
    }
  },
  methods: {
    initProviderSignUp() {
      if (this.accessCode) {
        this.input = this.accessCode;
        this.customSignUpShown = false;
      } else {
        this.fetchCustomSignUpFields();
      }
    },
    focusAccessCodeInput() {
      const input = this.$refs.accessCodeInput.$refs
        ? (this.$refs.accessCodeInput.$refs
            .validatedInputGroupInput as HTMLElement)
        : null;

      if (input) {
        input.focus();
      }
    },
    signUpUser() {
      if (!this.isAccessCodeValid) {
        this.focusAccessCodeInput();
        return;
      }
      this.showForm = false;
      this.updateIsSubjectToGdpr(this.isSubjectToGdpr);

      this.setProvider({
        org: this.organizationShortName,
        group: this.group,
        accessCode: this.input,
        referralToken: this.referrer
      } as Provider);
      this.fetchCustomSignUpFields();
      if (this.regDestination === 'MyStrength') {
        const routeName = this.isSubjectToGdpr ? 'GdprTerms' : 'AccountSetup';
        this.$router.push({ name: routeName });
      } else if (this.regDestination === 'Livongo') {
        PathLookupService.goToLivongoRegistrationPage();
      } else if (this.regDestination === 'Teladoc') {
        PathLookupService.goToTeladocRegistrationPage();
      }
    },
    doAccessCodeValidation() {
      const form = this.$refs.form;
      form.validate();

      if (this.input.trim() === '') {
        this.isAccessCodeValid = false;
      } else {
        this.validateAccessCode(this.input)
          .then(
            (
              response: AxiosResponse<{
                clientOfferingVersion: ClientOfferingVersion;
              }>
            ) => {
              this.isAccessCodeValid = true;
              switch (response.data.clientOfferingVersion) {
                case ClientOfferingVersion.MY1_0:
                  this.regDestination = 'MyStrength';
                  break;
                case ClientOfferingVersion.ONE_APP:
                  this.regDestination = 'Teladoc';
                  break;
                default:
                  this.regDestination = 'Livongo';
                  break;
              }
            }
          )
          .catch((error: { response: { status: number } }) => {
            if (error.response.status === 429) { //Too many requests error
              form.setErrors({
                accessCode: [this.$t('form.toomanyRequests')]
              });
            } else {
              form.setErrors({
                accessCode: [this.$t('form.invalidAccessCode')]
              });
            }
            this.isAccessCodeValid = false;
          });
      }
    },
    ...mapActions([
      'validateAccessCode',
      'setProvider',
      'fetchCustomSignUpFields',
      'updateIsSubjectToGdpr'
    ])
  }
});
