export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
      "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect"])},
      "progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progress"])},
      "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore"])},
      "helpResource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help"])},
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
      "personalization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalization"])},
      "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
      "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
      "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign out"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])}
    }
  })
}
