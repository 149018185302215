
import { defineComponent } from 'vue';

import { mapActions, mapGetters } from 'vuex';

import OneApp from '@/components/navigation/mixins/OneApp';
import AwaitInflightRequests from '@/views/Teletherapy/mixins/AwaitInflightRequests';

import NavigationItems from '@/components/navigation/mixins/NavigationItems';
import NavigationList from '@/components/navigation/NavigationList.vue';

import BrandLogo from '@svg/brand-logo.svg';

export default defineComponent({
  name: 'NavigationSide',
  components: {
    BrandLogo,
    NavigationList
  },
  mixins: [NavigationItems, OneApp, AwaitInflightRequests],
  computed: {
    ...mapGetters([
      'unreadTeletherapyMessages',
      'isTeletherapyUser',
      'userIsUnderAge',
      'isGuideMessageOnlyView'
    ])
  },
  watch: {
    async hasTeletherapyAuth() {
      await this.waitForTeletherapyRequests();
      if (!this.userIsUnderAge) {
        await this.getTeletherapyMessages();
      }
    }
  },
  async updated() {
    if (!this.userIsUnderAge && this.isTeletherapyUser) {
      await this.waitForTeletherapyAuth();
      await this.waitForTeletherapyRequests();
      await this.getTeletherapyMessages();
    }
  },
  async beforeMount() {
    if (!this.userIsUnderAge && this.isTeletherapyUser) {
      await this.waitForTeletherapyAuth();
    }
  },
  methods: {
    ...mapActions(['getTeletherapyMessages'])
  }
});
