
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'Hero',
  props: {
    imgUrl: {
      type: String,
      required: false,
      default: null
    },
    imgDescription: {
      type: String,
      required: false,
      default: null
    },
    heading: {
      type: String,
      required: false,
      default: null
    },
    description: {
      type: String,
      required: false,
      default: null
    }
  }
});
