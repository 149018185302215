import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, Transition as _Transition, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-53d9ba8f"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "nav-container",
  id: "nav-container-top",
  "aria-label": "Top navigation"
}
const _hoisted_2 = { class: "logo" }
const _hoisted_3 = {
  key: 0,
  class: "one-app-back"
}
const _hoisted_4 = ["aria-label"]
const _hoisted_5 = {
  key: 1,
  class: "one-app-label"
}
const _hoisted_6 = { class: "nav-wrap-right" }
const _hoisted_7 = ["aria-label"]
const _hoisted_8 = {
  class: "guide-image",
  "data-cy": "nav-guide-image"
}
const _hoisted_9 = ["src", "alt"]
const _hoisted_10 = { class: "nav-label guide-label" }
const _hoisted_11 = { class: "menu" }
const _hoisted_12 = ["aria-label"]
const _hoisted_13 = { class: "nav-label" }
const _hoisted_14 = ["aria-label"]
const _hoisted_15 = { class: "nav-label" }
const _hoisted_16 = {
  key: 0,
  class: "nav-dropdown"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BrandLogoIcon = _resolveComponent("BrandLogoIcon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_IconArrowLeft = _resolveComponent("IconArrowLeft")!
  const _component_RedBadgeCircle = _resolveComponent("RedBadgeCircle")!
  const _component_MenuIcon = _resolveComponent("MenuIcon")!
  const _component_ChevronDown = _resolveComponent("ChevronDown")!
  const _component_NavigationList = _resolveComponent("NavigationList")!

  return (!_ctx.isGuideMessageOnlyView)
    ? (_openBlock(), _createElementBlock("nav", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(["navigation-top", _ctx.oneAppNavOpen])
        }, [
          _createVNode(_component_router_link, {
            class: "logo-link",
            to: { name: 'Home' },
            "aria-label": _ctx.$t('homeLinkAria')
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_BrandLogoIcon, {
                  class: "logo-icon",
                  role: "img",
                  "aria-label": _ctx.$t('iconDescription')
                }, null, 8, ["aria-label"])
              ])
            ]),
            _: 1
          }, 8, ["aria-label"]),
          (_ctx.isOneApp && _ctx.showNav)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("button", {
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleNav && _ctx.toggleNav(...args))),
                  "aria-label": _ctx.$t('menuAriaClose')
                }, [
                  _createVNode(_component_IconArrowLeft, { class: "one-app-back-arrow" })
                ], 8, _hoisted_4)
              ]))
            : _createCommentVNode("", true),
          (_ctx.isOneApp)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t(_ctx.oneAppLabel)), 1))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_6, [
            (_ctx.hasGuide)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "btn-link guide",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleGuideDrawer && _ctx.toggleGuideDrawer(...args))),
                  "data-cy": "nav-guide-label",
                  "aria-label": _ctx.drawerOpen ? _ctx.$t('guideButtonAriaClose') : _ctx.$t('guideButtonAriaOpen')
                }, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("img", {
                      src: _ctx.guideImageUrl,
                      alt: _ctx.$t('guideImage')
                    }, null, 8, _hoisted_9),
                    (_ctx.unreadMessages)
                      ? (_openBlock(), _createBlock(_component_RedBadgeCircle, {
                          key: 0,
                          class: "badge"
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('guideLabel')), 1)
                ], 8, _hoisted_7))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("button", {
                class: "menu-md",
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.toggleNav && _ctx.toggleNav(...args))),
                "aria-label": _ctx.showNav ? _ctx.$t('menuAriaClose') : _ctx.$t('menuAriaOpen')
              }, [
                _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t('menu')), 1),
                _createVNode(_component_MenuIcon, {
                  class: "menu-icon",
                  "aria-hidden": "true"
                })
              ], 8, _hoisted_12),
              _createElementVNode("button", {
                class: "menu-lg",
                onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.toggleNav && _ctx.toggleNav(...args))),
                "aria-label": _ctx.showNav ? _ctx.$t('menuAriaClose') : _ctx.$t('menuAriaOpen')
              }, [
                _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.firstName), 1),
                _createVNode(_component_ChevronDown, {
                  class: "menu-icon",
                  "aria-hidden": "true"
                })
              ], 8, _hoisted_14)
            ])
          ]),
          _createVNode(_Transition, { name: "fade" }, {
            default: _withCtx(() => [
              (_ctx.showNav)
                ? (_openBlock(), _createElementBlock("nav", _hoisted_16, [
                    _createVNode(_component_NavigationList, {
                      class: "nav-items",
                      navList: _ctx.navItems,
                      underlineFirstItem: true
                    }, null, 8, ["navList"]),
                    _createVNode(_component_NavigationList, {
                      class: "profile-items",
                      navList: _ctx.profileItems
                    }, null, 8, ["navList"])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ], 2)
      ]))
    : _createCommentVNode("", true)
}