export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "es",
    "resource": {
      "links": {
        "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condiciones de uso"])},
        "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de privacidad"])},
        "siteMap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mapa del sitio"])},
        "call911": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["llama al 911"])}
      },
      "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservados todos los derechos."])},
      "text1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La aplicación ", _interpolate(_named("brandName")), " y las guías no brindan asesoramiento médico, diagnóstico ni tratamiento. Si actualmente está pensando o planea hacerse daño a sí mismo o a otra persona, por favor "])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([", vaya a la sala de emergencias del hospital más cercano o llame a la línea de ayuda para casos de crisis y suicidio al "])},
      "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  o la "])}
    }
  })
}
