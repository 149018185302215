export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "404": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whoops! Something's missing."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We couldn't find the page you were looking for."])}
      },
      "500": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something's gone wrong"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We’re having an issue and are working hard to get things back on track. Try refreshing your browser, and if the problem continues please email Member Support at: "])},
        "memberSupportEmailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["membersupport", "@", "livongo.com"])}
      },
      "API": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uh oh, something went wrong!"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error accessing this page. Please refresh your browser or contact "])},
        "memberSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member Support"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" if the problem persists."])}
      },
      "supportMailTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I%20need%20help%20with%20myStrength"])}
    }
  })
}
