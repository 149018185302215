
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import { format, addMinutes, parse } from 'date-fns';

import { HomeEvents, ConnectEvents } from '@/types/AnalyticsTypes';

import OneApp from '@/views/Teletherapy/mixins/OneApp';

import CardCircleIcon from '@common/cards/CardCircleIcon.vue';

import LoadingSpinner from '@common/LoadingSpinner.vue';
import IconCalendar from '@svg/therapy/calendar.svg';
import IconTherapy from '@svg/therapy/therapy.svg';
import IconVideo from '@svg/therapy/video.svg';
import IconPhone from '@svg/therapy/phone.svg';
import AwaitInflightRequests from '@/views/Teletherapy/mixins/AwaitInflightRequests';

export default defineComponent({
  name: 'TelehealthCard',
  inject: ['$analyticsTrack'],
  components: {
    CardCircleIcon,
    LoadingSpinner,
    IconCalendar,
    IconTherapy,
    IconVideo,
    IconPhone
  },
  props: {
    title: {
      type: String,
      required: false,
      default: null
    }
  },
  mixins: [OneApp, AwaitInflightRequests],
  data() {
    return {
      actionRoute: Location,
      paths: {},
      format: format,
      isLoaded: false,
      showModal: false,
      isHome: this.$route.name === 'Home'
    };
  },
  computed: {
    canJoinWaitingRoom() {
      if (this.teletherapyConsultation?.status !== 'Locked') {
        return;
      }
      const enableJoinDateMin = addMinutes(
        new Date(this.teletherapyConsultation.requested_date),
        -10
      );
      const enableJoinDateMax = addMinutes(
        new Date(this.teletherapyConsultation.requested_date),
        10
      );
      return new Date() >= enableJoinDateMin && new Date() <= enableJoinDateMax;
    },
    requestedDate(): Date {
      const dateStr = this.teletherapyConsultation.requested_dates
        ? this.teletherapyConsultation.requested_dates[0]
        : this.teletherapyConsultation.requested_date;
      const date = new Date(dateStr);

      return isNaN(date.getDate())
        ? parse(dateStr, 'yyyy-MM-dd HH:mm:ss xx', new Date())
        : date;
    },
    isVideoConsultation() {
      return this.teletherapyConsultation?.communication_method === 'Video';
    },
    isPhoneConsultation() {
      return this.teletherapyConsultation?.communication_method === 'Phone';
    },
    ...mapGetters([
      'teletherapyConsultation',
      'userIsUnderAge',
      'isOneApp',
      'teletherapyConfig'
    ])
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      const loadedWebSdk = await import(
        /* webpackChunkName: "td-websdk" */
        /* webpackPrefetch: true */
        '@td/websdk'
      );
      this.paths = loadedWebSdk.paths;
      if (!this.userIsUnderAge) {
        await this.waitForTeletherapyAuth();
        await this.waitForTeletherapyRequests();
        try {
          await this.getTeletherapyConsultations();
          this.isLoaded = true;
          this.startTeleMsgPolling();
        } catch (e) {
          this.isLoaded = true;
        }
      } else {
        this.isLoaded = true;
      }
    },
    cancelConsultation() {
      this.cancelTeletherapyConsultation(
        this.teletherapyConsultation.consult_id
      )
        .then(
          () => {
            this.handleCancelEvent();
            this.addSuccessNotification({
              heading: this.$t('cancelSuccessHeading'),
              description: this.$t('cancelSuccessDesc')
            });
          },
          () => {
            this.addErrorNotification({
              heading: this.$t('cancelErrorHeading'),
              description: this.$t('cancelErrorDesc')
            });
          }
        )
        .finally(() => {
          //refresh consultations
          this.getTeletherapyConsultations();
          this.showModal = false;
        });
    },
    handleRequestEvent(path: string) {
      this.handleTelehealthEvent('REQUEST');

      if (this.isOneApp) {
        this.oneAppRedirectToParent(path);
      }
    },
    handleReviewEvent(path: string) {
      this.handleTelehealthEvent('REVIEW');

      if (this.isOneApp) {
        this.oneAppRedirectToParent(path);
      }
    },
    handleCancelEvent() {
      this.handleTelehealthEvent('CANCEL');
    },
    handleRescheduleEvent(path: string) {
      this.handleTelehealthEvent('RESCHEDULE');

      if (this.isOneApp) {
        this.oneAppRedirectToParent(path);
      }
    },
    handleJoinVideoCallEvent(path: string) {
      this.handleTelehealthEvent('JOIN_VIDEO_CALL');

      if (this.isOneApp) {
        this.oneAppRedirectToParent(path);
      }
    },
    handleTelehealthEvent(eventType: string) {
      const eventName = eventType + '_TELEHEALTH';
      if (this.isHome) {
        this.$analyticsTrack(HomeEvents[eventName as keyof typeof HomeEvents]);
        return;
      }
      this.$analyticsTrack(
        ConnectEvents[eventName as keyof typeof ConnectEvents]
      );
    },
    showCancelModal() {
      this.showModal = true;
    },
    ...mapActions([
      'getTeletherapyConsultations',
      'cancelTeletherapyConsultation',
      'addSuccessNotification',
      'addErrorNotification',
      'startTeleMsgPolling'
    ])
  }
});
