export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "es",
    "resource": {
      "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta"])},
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfil"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificaciones"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idioma"])},
      "personalization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalización"])},
      "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historia"])},
      "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apoyo"])},
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Casa"])},
      "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectar"])},
      "helpResources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recursos de ayuda"])},
      "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explorar"])},
      "browse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navegar"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar"])},
      "legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal"])},
      "termsOfUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condiciones de uso"])},
      "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de privacidad"])}
    }
  })
}
