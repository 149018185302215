export default {
  upperCase: 'Al menos una mayúscula',
  lowerCase: 'Al menos una letra minúscula',
  oneNumber: 'Al menos un numero',
  noSpecialChars: '{field} no puede contener caracteres especiales',
  noInvalidNameChars:
    '{field} puede no contener los caracteres especiales "<>/&;"',
  noInvalidNameCharsCrisis:
    '{field} no puede contener caracteres especiales distintos de ",.\'-"',
  noExtraSpaces: '{field} no puede contener espacios iniciales o finales'
} as {
  [key: string]: string;
};
