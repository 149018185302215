import HTTP from '@/services/HttpService';
import apiRoutes from '@config/api-routes';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '@/types/storeTypes';

import { ProgramSlim } from '@/types/LibraryTypes';

export interface ProgramStore {
  assignedPrograms: ProgramSlim[] | null;
}

const state: ProgramStore = {
  assignedPrograms: null
};

const getters: GetterTree<ProgramStore, RootState> = {
  assignedPrograms: (state: ProgramStore) => state.assignedPrograms
};

const actions: ActionTree<ProgramStore, RootState> = {
  getAssignedPrograms({ commit }): void {
    HTTP.get(apiRoutes.activity.assignedPrograms).then((response) => {
      commit('setAssignedPrograms', response.data);
    });
  }
};

const mutations: MutationTree<ProgramStore> = {
  setAssignedPrograms(state: ProgramStore, assignedPrograms: ProgramSlim[]) {
    state.assignedPrograms = assignedPrograms;
  }
};

export const ProgramStore: Module<ProgramStore, RootState> = {
  state,
  actions,
  mutations,
  getters
};

export default ProgramStore;
