import HTTP from '@/services/HttpService';
import apiRoutes from '@config/api-routes';

import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { RootState } from '@/types/storeTypes';
import { AxiosResponse } from 'axios';
import {
  Provider,
  CoBranding,
  OrgSignUpCollateral,
  SignUpFieldView,
  SignUpFieldViewChoices
} from '@/types/PartnerCollateralTypes';

interface PartnerCollateralState {
  coBranding: CoBranding;
  provider: Provider;
  providerIsValid: boolean;
  orgSignUpCollateral: OrgSignUpCollateral;
  signUpCustomFields: SignUpFieldView[];
}

/**
 * This store module is for landing page related app data
 */
const initialState = {
  coBranding: {},
  provider: {},
  providerIsValid: false,
  orgSignUpCollateral: {},
  signUpCustomFields: []
};

const defaultAcField = {
  type: 'TEXT',
  priority: 4
} as SignUpFieldView;

const state = { ...initialState };

const stateHelpers = {
  resolveAccessCodeFields(signUpCustomFields: SignUpFieldView[]) {
    return signUpCustomFields
      .filter((field) => field.name === 'ACCESS_CODE')
      .reduce(
        (field1: SignUpFieldView, field2: SignUpFieldView) =>
          field1.priority < field2.priority ? field1 : field2,
        defaultAcField
      );
  },
  resolveAccountSetupFields(signUpCustomFields: SignUpFieldView[]) {
    return signUpCustomFields.filter(
      (field) =>
        field.name !== 'ACCESS_CODE' && field.name !== 'ACCESS_CODE_GROUP'
    );
  },
  resolveGroupFields(signUpCustomFields: SignUpFieldView[]) {
    const list = signUpCustomFields.filter(
      (field) => field.name === 'ACCESS_CODE_GROUP'
    );

    return list.length ? list[0] : null;
  }
};

const getters: GetterTree<PartnerCollateralState, RootState> = {
  coBranding: (state) => state.coBranding,
  coBrandingLoaded: (state) => typeof state.coBranding.id !== 'undefined',
  provider: (state) => state.provider,
  providerIsValid: (state) => state.providerIsValid,
  signUpCustomFields: (state) => state.signUpCustomFields,
  accessCodeField: (state): SignUpFieldView => {
    return stateHelpers.resolveAccessCodeFields(state.signUpCustomFields);
  },
  accountSetupFields: (state): SignUpFieldView[] => {
    // return array of fields in order according to positionOrdinal property on each SignUpFieldView
    return stateHelpers
      .resolveAccountSetupFields(state.signUpCustomFields)
      .sort((a, b) => a.positionOrdinal - b.positionOrdinal);
  },
  groupField: (state) => {
    return stateHelpers.resolveGroupFields(state.signUpCustomFields);
  },
  providerFilteredAccessCodes: (state, getter) => {
    const accessCodeGroup = state.provider.group;
    let list = null as SignUpFieldViewChoices[] | null;

    if (accessCodeGroup) {
      list = getter.accessCodeField.choices?.filter(
        (field: SignUpFieldViewChoices) => {
          return field.filterByValue === accessCodeGroup && field.enabled;
        }
      );
    } else {
      list = getter.accessCodeField.choices?.filter(
        (field: SignUpFieldViewChoices) => field.enabled
      );
    }

    return list?.sort(
      (a: SignUpFieldViewChoices, b: SignUpFieldViewChoices) => {
        return a.positionOrdinal - b.positionOrdinal;
      }
    );
  }
};

const actions: ActionTree<PartnerCollateralState, RootState> = {
  getCoBranding(store, routeSuffix) {
    return HTTP.get(
      `${apiRoutes.landingPage.getPartnerBrandingBase}${routeSuffix}`
    ).then(
      (response) => {
        store.commit('setCoBranding', response.data);
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  },
  setProvider(store, provider) {
    store.commit('setProvider', provider);

    if (provider.org || provider.group || provider.accessCode) {
      store.dispatch('validateProvider');
    }
  },
  validateProvider(store) {
    if (store.state.provider.accessCode) {
      return HTTP.post<string, AxiosResponse>(
        apiRoutes.partnerCollateral.validateProvider(store.state.provider),
        store.state.provider.accessCode,
        { headers: { 'Content-Type': 'text/plain' } }
      ).then((resp: AxiosResponse) => {
        store.commit('setProviderValid', resp.data);
      });
    } else {
      return HTTP.post(
        apiRoutes.partnerCollateral.validateProvider(store.state.provider)
      ).then((resp: AxiosResponse) => {
        store.commit('setProviderValid', resp.data);
      });
    }
  },
  fetchCustomSignUpFields(store) {
    if (store.state.provider.accessCode) {
      return HTTP.post<string, AxiosResponse>(
        apiRoutes.partnerCollateral.getCustomSignUpFields(store.state.provider),
        store.state.provider.accessCode,
        { headers: { 'Content-Type': 'text/plain' } }
      ).then((resp: AxiosResponse) => {
        store.commit('setProviderCustomSignUpFields', resp.data);
      });
    } else {
      return HTTP.get(
        apiRoutes.partnerCollateral.getCustomSignUpFields(store.state.provider)
      ).then((resp: AxiosResponse) => {
        store.commit('setProviderCustomSignUpFields', resp.data);
      });
    }
  }
};

const mutations: MutationTree<PartnerCollateralState> = {
  setCoBranding(state, coBranding: CoBranding) {
    state.coBranding = coBranding;
  },
  setProvider(state, provider: Provider) {
    state.provider = provider;
  },
  setProviderValid(state, isValid: boolean) {
    state.providerIsValid = isValid;
  },
  setProviderCustomSignUpFields(state, payload) {
    // break out the sign up fields
    const collections = payload.map((collection: OrgSignUpCollateral) => {
      // set signUpFieldCollectionId in each field
      collection.signUpFieldViews.forEach(function (field: SignUpFieldView) {
        field.collectionId = collection.id;
        // The priority specifies when a field should overwrite another
        // Access code is highest (1), followed by group (2), then by org (3)
        field.priority = collection.isDefault
          ? 3
          : collection.accessCodeGroup
          ? 2
          : 1;
      });
      return collection.signUpFieldViews;
    });

    // flatten array of arrays
    let allFields: SignUpFieldView[] = [];
    allFields = allFields.concat(...collections);

    // Keep a map of the field names to the fields. This is to remove duplicate fields
    const fieldMap = {} as Record<string, SignUpFieldView>;
    allFields.forEach((field: SignUpFieldView) => {
      if (
        !fieldMap[field.name] ||
        field.priority < fieldMap[field.name].priority
      ) {
        fieldMap[field.name] = field;
      }
    });

    // A de-duped list of fields
    state.signUpCustomFields = Object.values(fieldMap);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
