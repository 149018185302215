import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import WebViewService from '@/services/WebViewService';
import OneAppService from '@/services/OneAppService';
import { User } from '@/types/UserTypes';
import AuthService from '@/services/AuthService';

export default defineComponent({
  computed: {
    oneAppWebViewClass() {
      return { 'is-one-app-web-view': this.isOneApp };
    },
    ...mapGetters(['isOneApp', 'isMobileWebView'])
  },
  watch: {
    isAppBootstrapLoading: {
      immediate: true,
      handler(isLoading) {
        if (!isLoading && this.isOneApp) {
          OneAppService.sendStatusEvent('appLoaded');
        }
      }
    }
  },
  methods: {
    updateLanguagePreference() {
      if (this.isOneApp) {
        const oneAppLanguage = WebViewService.language(this.$route.query);
        if (oneAppLanguage && this.user.language != oneAppLanguage) {
          return this.updateAndGetUser({ language: oneAppLanguage } as User)
            .then(() => {
              const type = this.isMobileWebView ? 'mobile' : 'web';
              this.updateUserSessionLanguage({
                language: oneAppLanguage,
                type
              });
            })
            .then(() => {
              this.getCards();
            });
        }
      }
    },
    ...mapActions([
      'updateAndGetUser',
      'updateUserSessionLanguage',
      'getCards',
      'getUser'
    ])
  }
});
