export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "es",
    "resource": {
      "default": {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hablar con una terapeuta"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programe una cita con un terapeuta autorizado por teléfono o video"])},
        "actionLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicita una visita"])}
      },
      "requested": {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitud de cita de terapia"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su proveedor responderá a su solicitud dentro de las 48 horas."])}
      },
      "proposed": {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propuesta de nuevo horario de terapia"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su proveedor ha propuesto un nuevo horario para su visita. Revise este cambio en su centro de mensajes."])},
        "actionLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visita de revisión"])}
      },
      "locked": {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próxima cita de terapia"])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La visita de ", _interpolate(_named("member")), " con ", _interpolate(_named("provider"))])},
        "actionLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reprogramar"])}
      },
      "join": {
        "video": {
          "header": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tu video visita con ", _interpolate(_named("provider")), " comienza a las ", _interpolate(_named("time"))])},
          "actionLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Únete ahora"])}
        },
        "audio": {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su visita de terapia comienza pronto"])},
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("provider")), " te llamará a las ", _interpolate(_named("time"))])}
        }
      },
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
      "cancelSuccessHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulta cancelada"])},
      "cancelSuccessDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su consulta fue cancelada."])},
      "cancelErrorHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algo salió mal"])},
      "cancelErrorDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intente cancelar su consulta nuevamente."])},
      "cancelHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar visita"])},
      "cancelDialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Estás segura de que deseas cancelar esta visita?"])},
      "confirmCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si cancelar"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])}
    }
  })
}
