import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

export default defineComponent({
  data() {
    return {
      loginErrorDescription: '',
      showLogo: true,
      size: 'xs',
      buttonLabel: '',
      navigate: '',
      emailAddress: '',
      emailText: ''
    };
  },
  computed: mapGetters(['isOneApp', 'isMobileWebView']),
  methods: {
    checkAndSetOneAppAlert() {
      if (!this.isOneApp) {
        return false;
      }

      // App settings
      this.showLogo = false;
      this.size = 'sm';
      this.navigate = 'https://web-ui.mystrength.livongo.com/login';
      this.emailAddress = 'member-support@teladoc.com';

      // Translation strings
      const description = `oneapp.${
        this.isMobileWebView ? 'mobile' : 'web'
      }.dupAccountErrorDescr`;
      this.loginErrorDescription = this.$t(description);
      this.emailText = this.$t('oneapp.emailText');
      this.loginErrorAlert = this.$t('oneapp.dupAccountErrorHeading');

      // Mobile WebView overrides
      if (!this.isMobileWebView) {
        this.buttonLabel = this.$t('oneapp.buttonLabel');
        this.livongoPortalUrl = 'myStrengthLegacy';
      }

      return true;
    }
  }
});
