import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-65c47e9b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "birth-date-input" }
const _hoisted_2 = { class: "input-group" }
const _hoisted_3 = { class: "month-input" }
const _hoisted_4 = { class: "styled-select" }
const _hoisted_5 = ["onBlur"]
const _hoisted_6 = {
  value: "0",
  disabled: "",
  selected: ""
}
const _hoisted_7 = ["value"]
const _hoisted_8 = { class: "day-input" }
const _hoisted_9 = { class: "styled-select" }
const _hoisted_10 = ["onBlur"]
const _hoisted_11 = {
  value: "0",
  disabled: "",
  selected: ""
}
const _hoisted_12 = ["value"]
const _hoisted_13 = { class: "year-input" }
const _hoisted_14 = ["max", "placeholder", "onChange"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_InlineError = _resolveComponent("InlineError")!
  const _component_MHForm = _resolveComponent("MHForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", null, _toDisplayString(_ctx.$t('label')), 1),
      _createVNode(_component_MHForm, {
        as: "div",
        ref: "dateInputForm"
      }, {
        default: _withCtx((formProps) => [
          _createElementVNode("div", {
            class: _normalizeClass(["input-row", { 'small' : _ctx.isSmallMode }])
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_Field, {
                name: "month",
                type: "select",
                modelValue: _ctx.month,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.month) = $event)),
                rules: { required:true, is_not:0 }
              }, {
                default: _withCtx(({ field, meta }) => [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("select", _mergeProps({ class: "month input-padding" }, field, {
                      name: "month",
                      "data-cy": "month-select",
                      class: { 'is-invalid': meta.touched && !meta.valid },
                      onBlur: ($event: any) => (_ctx.setValidity(formProps.meta))
                    }), [
                      _createElementVNode("option", _hoisted_6, _toDisplayString(_ctx.$t('monthLabel')), 1),
                      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(12, (_, index) => {
                        return _createElementVNode("option", {
                          key: index,
                          value: index + 1
                        }, _toDisplayString(_ctx.$t(`months[${index}]`)), 9, _hoisted_7)
                      }), 64))
                    ], 16, _hoisted_5)
                  ])
                ]),
                _: 2
              }, 1032, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_Field, {
                name: "day",
                modelValue: _ctx.day,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.day) = $event)),
                rules: { required:true, is_not:0 }
              }, {
                default: _withCtx(({ field, meta }) => [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("select", _mergeProps({ class: "day input-padding" }, field, {
                      name: "day",
                      "data-cy": "day-select",
                      class: { 'is-invalid': meta.touched && !meta.valid },
                      onBlur: ($event: any) => (_ctx.setValidity(formProps.meta))
                    }), [
                      _createElementVNode("option", _hoisted_11, _toDisplayString(_ctx.$t('dayLabel')), 1),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.daysInMonth, (_, index) => {
                        return (_openBlock(), _createElementBlock("option", {
                          value: index+1,
                          key: index
                        }, _toDisplayString(index+1), 9, _hoisted_12))
                      }), 128))
                    ], 16, _hoisted_10)
                  ])
                ]),
                _: 2
              }, 1032, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_component_Field, {
                name: "year",
                modelValue: _ctx.year,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.year) = $event)),
                rules: { required:true, digits:4, meetsMinimumAge:[ _ctx.month, _ctx.day, _ctx.minimumAge ], youngerThan150:[ _ctx.month, _ctx.day ]}
              }, {
                default: _withCtx(({ field, meta }) => [
                  _createElementVNode("input", _mergeProps({ class: "year" }, field, {
                    type: "number",
                    name: "year",
                    "data-cy": "year-input",
                    max: _ctx.currentYear,
                    class: { 'is-invalid': meta.touched && !meta.valid },
                    placeholder: _ctx.$t('yearLabel'),
                    onChange: ($event: any) => (_ctx.setValidity(formProps.meta))
                  }), null, 16, _hoisted_14)
                ]),
                _: 2
              }, 1032, ["modelValue", "rules"])
            ])
          ], 2),
          (formProps.meta.touched && !formProps.meta.valid)
            ? (_openBlock(), _createBlock(_component_InlineError, {
                key: 0,
                "data-cy": "birthday-error",
                message: _ctx.errorMessage(formProps.errors)
              }, null, 8, ["message"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 512)
    ])
  ]))
}