import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8f843fce"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "heading-sm" }
const _hoisted_2 = { class: "instruction" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconHeart = _resolveComponent("IconHeart")!
  const _component_CardCircleIcon = _resolveComponent("CardCircleIcon")!

  return (_openBlock(), _createBlock(_component_CardCircleIcon, {
    id: "noFavorites",
    cardCircleColor: '#C2D0DF',
    onClick: _ctx.toggleNoFavorite
  }, {
    icon: _withCtx(() => [
      _createVNode(_component_IconHeart, { class: "heart" })
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.header), 1),
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.instructions), 1)
    ]),
    _: 1
  }, 8, ["onClick"]))
}