export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "es",
    "resource": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hemos actualizado tu experiencia"])},
      "program": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explora tu nuevo programa"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Según su edad y lo que hemos aprendido sobre usted, hemos actualizado su experiencia con nuevas recomendaciones y funciones. Todavía puede encontrar el programa en el que estaba trabajando en la sección \"Explorar todo\" de Explorar."])}
      },
      "therapy": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programe la terapia por su cuenta"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ahora puede programar y administrar visitas de terapia sin la ayuda de sus padres o tutores."])}
      },
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver qué hay de nuevo"])}
    }
  })
}
