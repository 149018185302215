export enum HomeEvents {
  REQUEST_TELEHEALTH = 'MH.Home.Telehealth.RequestAVisit',
  RESCHEDULE_TELEHEALTH = 'MH.Home.Telehealth.Reschedule',
  REVIEW_TELEHEALTH = 'MH.Home.Telehealth.ReviewVisit',
  CANCEL_TELEHEALTH = 'MH.Home.Telehealth.Cancel',
  JOIN_VIDEO_CALL_TELEHEALTH = 'MH.Home.Telehealth.JoinVideoCall'
}

export enum ConnectEvents {
  REQUEST_TELEHEALTH = 'MH.Connect.Telehealth.RequestAVisit',
  RESCHEDULE_TELEHEALTH = 'MH.Connect.Telehealth.Reschedule',
  REVIEW_TELEHEALTH = 'MH.Connect.Telehealth.ReviewVisit',
  CANCEL_TELEHEALTH = 'MH.Connect.Telehealth.Cancel',
  JOIN_VIDEO_CALL_TELEHEALTH = 'MH.Connect.Telehealth.JoinVideoCall',
  MESSAGES_TELEHEALTH = 'MH.Connect.Telehealth.Messages'
}
