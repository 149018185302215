import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8733a8b2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content-wrapper" }
const _hoisted_2 = { class: "action-label" }
const _hoisted_3 = { class: "content-wrapper" }
const _hoisted_4 = { class: "action-label" }
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "content-wrapper" }
const _hoisted_7 = { class: "action-label" }
const _hoisted_8 = { class: "content-wrapper" }
const _hoisted_9 = { class: "action-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AlertIcon = _resolveComponent("AlertIcon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["alert-wrapper", _ctx.alertClass]),
    "aria-live": "polite"
  }, [
    (_ctx.destination)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.destination === 'newfeatures')
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "alert-link",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showNewFeatures && _ctx.showNewFeatures(...args)))
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(["alert", _ctx.alertClass])
                }, [
                  _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_AlertIcon, { type: _ctx.type }, null, 8, ["type"]),
                    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1)
                  ])
                ], 2)
              ]))
            : (_openBlock(), _createBlock(_component_router_link, {
                key: 1,
                class: "alert-link",
                to: _ctx.destination ? _ctx.destinationPath : ''
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: _normalizeClass(["alert", _ctx.alertClass])
                  }, [
                    _createElementVNode("div", _hoisted_3, [
                      _createVNode(_component_AlertIcon, { type: _ctx.type }, null, 8, ["type"]),
                      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.title), 1)
                    ])
                  ], 2)
                ]),
                _: 1
              }, 8, ["to"]))
        ], 64))
      : (_ctx.externalDestination)
        ? (_openBlock(), _createElementBlock("a", {
            key: 1,
            class: "alert-link",
            href: _ctx.externalDestination
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["alert", _ctx.alertClass])
            }, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_AlertIcon, { type: _ctx.type }, null, 8, ["type"]),
                _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.title), 1)
              ])
            ], 2)
          ], 8, _hoisted_5))
        : (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: _normalizeClass(["alert", _ctx.alertClass])
          }, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_AlertIcon, { type: _ctx.type }, null, 8, ["type"]),
              _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.title), 1)
            ])
          ], 2))
  ], 2))
}