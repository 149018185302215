
import { defineComponent } from 'vue';
import { RouteLocation } from 'vue-router';
import { mapActions, mapGetters } from 'vuex';

import { Form } from 'vee-validate';

import {
  User,
  UserPermissions,
  ClientOfferingVersion,
  LoginWithLivongoErrors,
  UserMigrationStatus
} from '@/types/UserTypes';
import { AxiosResponse } from 'axios';

import AuthService from '@/services/AuthService';
import PathLookupService from '@/services/PathLookupService';
import OnboardingRedirectService from '@/services/OnboardingRedirectService';

import Alert, { ALERT_TYPES } from '@common/Alert.vue';
import InlineError from '@common/InlineError.vue';
import OneApp from '@/components/login/mixins/OneApp';
import OneAppError from '@common/OneAppError.vue';
import getNextMigrationRoute from '@/services/ClientMigrationRedirectService';

export default defineComponent({
  name: 'Login',
  inject: [
    '$analyticsRegisterGlobalFields',
    '$analyticsTrack',
    '$analyticsReset'
  ],
  components: {
    MHForm: Form,
    Alert,
    OneAppError,
    InlineError
  },
  mixins: [OneApp],
  data() {
    return {
      email: '',
      password: '',
      isWaitingForLoginResponse: false,
      emailOnMemberCheck: '',
      isLegacyUser: null as boolean | null,
      loginFailed: false,
      spInitiatedSSOUrl: PathLookupService.getSsoLoginUrl(),
      redirectUrl: window.location.href,
      loginErrorType: ALERT_TYPES.WARN,
      livongoPortalUrl: PathLookupService.getLivongoDomain(),
      redirecting: false
    };
  },
  computed: {
    // internal access code can access referral link from destination-chooser
    userIsInternalOrElevated(): boolean {
      // iterate through permissions,
      // if permission is in Enum of Permissions from Destination Chooser and != 'USER',
      // user has elevated permissions
      const hasElevatedPermissions = this.userPermissions?.some(
        (permissionName: string) => {
          return (
            Object.keys(UserPermissions).includes(permissionName) &&
            permissionName !== 'USERS'
          );
        }
      );
      return this.userAccessCode?.type === 'INTERNAL' || hasElevatedPermissions;
    },
    hasLivongoAuthError(): boolean {
      return this.livongoAuthStatus?.errorCode;
    },
    loginErrorAlertTitle(): string {
      if (!this.hasLivongoAuthError) {
        return '';
      }

      const errorCode = this.livongoAuthStatus.errorCode;
      if (errorCode === LoginWithLivongoErrors.UNIQUE_EMAIL_ADDRESS_REQUIRED) {
        return `${this.$t('dupAccountErrorHeading')}.\n ${this.$t(
          'dupAccountErrorDescr'
        )}`;
      } else {
        return `${this.$t('errorHeading')}.\n ${this.$t('errorDescription')}`;
      }
    },
    hasCompletedMigration(): boolean {
      return this.oneAppMigrationStatus === UserMigrationStatus.COMPLETED;
    },
    ...mapGetters([
      'user',
      'isOneApp',
      'hasAgedUp',
      'userLoaded',
      'livongoAuthStatus',
      'userAccessCode',
      'isAuthenticated',
      'userPermissions',
      'passwordIsExpired',
      'passwordIsChanged',
      'requiresLivongoReg',
      'hasExceededPasswordAttempts',
      'oneAppMigrationStatus',
      'requiresOneAppMigration'
    ])
  },
  watch: {
    email(value) {
      if (value) {
        this.loginFailed = false;
      }
      // Reset display if user changes email address after member check
      if (this.email !== this.emailOnMemberCheck) {
        this.isLegacyUser = null;
      }
    },
    password(value) {
      if (value) {
        this.loginFailed = false;
      }
    },
    livongoAuthStatus: {
      deep: true,
      handler(value) {
        if (value && value.successful) {
          this.goToNextStep();
        }
      }
    }
  },
  mounted() {
    this.$analyticsReset();
    this.$analyticsRegisterGlobalFields();
    this.$analyticsTrack('MH.Login.Start');

    this.testForLoginSkip();
    this.showUpgradeCompleteNotification();
    this.storeKeycloakRedirectUrl();

    if (!this.hasLivongoAuthError) {
      this.resetValidationState();
      this.focusEmailInput();
    }
  },
  updated() {
    this.resetValidationState();
  },
  beforeRouteEnter(to) {
    // For redirects from ccm portals , clear the user tokens since this could be a new user
    if (to.query?.ccmRedirect) {
      AuthService.clearAuthToken();
      AuthService.clearReAuthToken();
    }
  },
  methods: {
    showUpgradeCompleteNotification() {
      if (this.$route.query?.upgradeComplete) {
        this.addLongLivedSuccessNotification({
          description: this.$t('accountUpgradeCompleteDescription')
        });
      }
    },
    resetValidationState(): void {
      if (this.$refs.loginForm) {
        this.$refs.loginForm.resetForm();
      }
    },
    memberVersionCheck(): void {
      // Trigger validation
      this.$refs.loginForm.validate();

      if (this.email.length === 0) {
        this.focusEmailInput();
        return;
      }
      this.getMemberVersion({ emailAddress: this.email }).then(
        (
          resp: AxiosResponse<{ clientOfferingVersion: ClientOfferingVersion }>
        ) => {
          this.emailOnMemberCheck = this.email;

          this.isLegacyUser =
            resp.data.clientOfferingVersion === ClientOfferingVersion.MY1_0;
          if (
            resp.data.clientOfferingVersion === ClientOfferingVersion.ONE_APP
          ) {
            if (this.hasCompletedMigration) {
              this.$router.push({ name: 'AccountMoved' });
            } else {
              this.redirecting = true;
              setTimeout(() => {
                PathLookupService.goToTeladocLoginPage();
              }, 3000);
            }
          } else if (this.isLegacyUser === false) {
            // Store redirect url in local storage for livongo.com login users
            this.storeRedirectUrl();

            // Pass redirect to livongo frontend
            // Returns users back to the myS login page after successful login
            const hiddenForm = this.$refs.SpInitiatedSSOForm as HTMLFormElement;
            hiddenForm.submit();
          } else {
            this.focusPasswordInput();
          }
        }
      );
    },
    focusEmailInput() {
      if (this.$refs.emailInput) {
        const input = this.$refs.emailInput.$refs
          .validatedInputGroupInput as HTMLElement;
        input?.focus();
      }
    },
    focusPasswordInput() {
      setTimeout(() => {
        if (this.$refs.passwordInput) {
          const input = this.$refs.passwordInput.$refs
            .validatedInputGroupInput as HTMLElement;
          input?.focus();
        }
      }, 100);
    },
    submitForm() {
      if (this.isLegacyUser) {
        this.submitLogin();
      } else {
        this.memberVersionCheck();
      }
    },
    submitLogin() {
      // Prevent duplicate login attempts
      if (this.isWaitingForLoginResponse) {
        return;
      }

      this.isWaitingForLoginResponse = true;
      this.loginUser({
        email: this.email,
        password: this.password
      })
        .then(
          (loginResp: {
            data: {
              response: User;
            };
          }) => {
            this.loginFailed = false;

            if (this.passwordIsExpired) {
              this.$router.push({
                name: 'ChangePassword',
                query: { isExpired: true }
              });
            } else if (this.hasExceededPasswordAttempts) {
              this.$router.push({
                name: 'PasswordReset',
                query: { hasExceededPasswordAttempts: true }
              });
            } else {
              this.$analyticsRegisterGlobalFields({
                $MSUserId: loginResp.data.response.id
              });
              this.$analyticsTrack('MH.Login.Succeed');
              this.goToNextStep();
            }
          },
          () => {
            this.loginFailed = true;

            // Only my1_0 users can reach this path
            this.$analyticsTrack(
              'MH.Login.Failed',
              ClientOfferingVersion.MY1_0
            );
          }
        )
        .finally(() => {
          this.isWaitingForLoginResponse = false;
        });
    },
    async goToNextStep() {
      await this.loadUser();

      const currentRoute = this.$router.currentRoute.value;
      const clientMigrationRedirectRoute = getNextMigrationRoute();
      const homeRoute = { name: 'Home' } as RouteLocation;
      let redirectRoute = null;

      if (this.$route.query.redirect) {
        const path = decodeURIComponent(this.$route.query.redirect as string);
        redirectRoute =
          (clientMigrationRedirectRoute ||
            OnboardingRedirectService({ path } as RouteLocation)) ??
          path;
      } else if (this.requiresLivongoReg) {
        redirectRoute = '/upgrade-registration';
      } else if (this.userIsInternalOrElevated) {
        redirectRoute = '/destination-chooser';
      } else if (this.hasAgedUp) {
        redirectRoute = '/aging-up';
      } else {
        redirectRoute =
          clientMigrationRedirectRoute || OnboardingRedirectService(homeRoute);
      }

      // Follow redirect if not already on route
      if (currentRoute.path !== redirectRoute) {
        this.$router.push(redirectRoute || homeRoute).catch(() => {
          // Ignore error - Any issue likely caused by vue router navigation guards
        });
      }
    },
    async loadUser() {
      if (!this.userLoaded) {
        await this.getUser();
        await this.getUserPermissions();
      }
    },
    hasLivongoTokens() {
      return (
        AuthService.getLivongoAuthToken() && AuthService.getLivongoReAuthToken()
      );
    },
    async testForLoginSkip() {
      if (AuthService.getAuthToken()) {
        this.goToNextStep();
      }
    },
    storeRedirectUrl() {
      if (this.$route.query.redirect && window.localStorage) {
        window.localStorage.setItem(
          'redirectUrl',
          decodeURIComponent(this.$route.query.redirect as string)
        );
      }
    },
    storeKeycloakRedirectUrl() {
      if (this.$route.query?.teladocEOD && window.localStorage) {
        window.localStorage.setItem(
          'keycloakRedirectUrl',
          decodeURIComponent(this.$route.query.teladocEOD as string)
        );
      }
    },
    ...mapActions([
      'getUser',
      'loginUser',
      'getMemberVersion',
      'getUserPermissions',
      'addLongLivedSuccessNotification'
    ])
  }
});
