export enum GuideEvents {
  ATTACHMENT_CLICKED = 'ATTACHMENT_CLICKED'
}

export enum AttachmentSubTypes {
  CORE_INSPIRATION = 'CORE_INSPIRATION'
}

export enum AttachmentTypes {
  ACTIVITY = 'ACTIVITY',
  INTERNAL_LINK = 'INTERNAL_LINK'
}

export enum MessageType {
  FROM_COACH = 'FROM_COACH',
  TO_COACH = 'TO_COACH'
}
