import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c39ec8ae"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "alert-icon-container",
  "aria-hidden": "true"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconAlertInfo = _resolveComponent("IconAlertInfo")!
  const _component_IconAlertWarn = _resolveComponent("IconAlertWarn")!
  const _component_IconAlertDanger = _resolveComponent("IconAlertDanger")!

  return (_ctx.type)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.type === _ctx.ALERT_TYPES.INFO)
          ? (_openBlock(), _createBlock(_component_IconAlertInfo, {
              key: 0,
              class: "alert-icon alert-icon-info"
            }))
          : _createCommentVNode("", true),
        (_ctx.type === _ctx.ALERT_TYPES.WARN)
          ? (_openBlock(), _createBlock(_component_IconAlertWarn, {
              key: 1,
              class: "alert-icon alert-icon-warn"
            }))
          : _createCommentVNode("", true),
        (_ctx.type === _ctx.ALERT_TYPES.DANGER)
          ? (_openBlock(), _createBlock(_component_IconAlertDanger, {
              key: 2,
              class: "alert-icon alert-icon-danger"
            }))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}