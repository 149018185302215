import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2aba90ac"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "container",
  id: "progress-bar"
}
const _hoisted_2 = ["aria-valuenow", "aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "progress",
      role: "progressbar",
      "aria-valuenow": _ctx.progressInt,
      "aria-label": _ctx.labelText,
      style: _normalizeStyle(_ctx.percentProgressStyle)
    }, null, 12, _hoisted_2)
  ]))
}