
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import { NOTIFICATION_TYPES } from '@/types/NotificationTypes';

import Notification from '@/components/common/Notification.vue';

export default defineComponent({
  name: 'Alert',
  components: {
    Notification
  },
  data() {
    return {
      NOTIFICATION_TYPES: NOTIFICATION_TYPES
    };
  },
  computed: {
    ...mapGetters(['notifications'])
  }
});
