
import { defineComponent } from 'vue';
import { mapGetters, mapActions } from 'vuex';

import WebViewService from '@/services/WebViewService';
import InternalLinkService from '@/services/InternalLinkService';
import OnboardingRedirectService from '@/services/OnboardingRedirectService';

import { OnboardingStatus } from '@/types/UserTypes';

import CylonLoader from '@common/CylonLoader.vue';
import OneApp from '@/views/DeepLink/OneApp';
import AuthService from '@/services/AuthService';

export default defineComponent({
  name: 'DeepLink',
  components: { CylonLoader },
  mixins: [OneApp],
  computed: {
    destination(): string | null {
      const queryParam = 'destination';
      const hasQueryParam = this.$route.query && this.$route.query[queryParam];
      return hasQueryParam
        ? (this.$route.query[queryParam] as string).toLowerCase()
        : null;
    },
    trackerId(): string | null {
      const queryParam = 'trackerId';
      const hasQueryParam = this.$route.query && this.$route.query[queryParam];
      return hasQueryParam ? (this.$route.query[queryParam] as string) : null;
    },
    ...mapGetters(['user'])
  },
  watch: {
    user: {
      immediate: true,
      handler() {
        if (!this.user?.onboardingStatus && AuthService.getAuthToken()) {
          // Await user response if user is logged in
          return;
        }

        if (
          this.user?.onboardingStatus &&
          this.user?.onboardingStatus !== OnboardingStatus.ONBOARDING_COMPLETED
        ) {
          // Prevent users who are not onboarding complete from visiting deep link
          this.$router.push({
            path: OnboardingRedirectService(this.$route)
          });
        } else {
          this.init();
        }
      }
    }
  },
  methods: {
    init(): void {
      const destination = this.getOneAppDestination(this.destination) || '';
      const webUrlPath =
        InternalLinkService.getWebPathForInternalLink(destination);
      const push = this.$router.push;

      // Send tracking event if trackerId present
      if (this.trackerId) {
        this.sendCommunicationsEvent({
          trackerId: this.trackerId
        });
      }

      setTimeout(() => {
        WebViewService.sendUserToMobileOrCallback(destination, () => {
          push(webUrlPath);
        });
      }, 2000);
    },
    ...mapActions(['sendCommunicationsEvent'])
  }
});
