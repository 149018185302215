// source: livongo/protobuf/grpc/external/bh/stepped_care/stepped_care.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var livongo_protobuf_common_bh_language_pb = require('../../../../../../livongo/protobuf/common/bh/language_pb.js');
goog.object.extend(proto, livongo_protobuf_common_bh_language_pb);
var livongo_protobuf_common_bh_programRecommendation_pb = require('../../../../../../livongo/protobuf/common/bh/programRecommendation_pb.js');
goog.object.extend(proto, livongo_protobuf_common_bh_programRecommendation_pb);
goog.exportSymbol('proto.com.livongo.protobuf.grpc.external.bh.stepped_care.AllPrimaryNeedsContent', null, global);
goog.exportSymbol('proto.com.livongo.protobuf.grpc.external.bh.stepped_care.CategorizedPrimaryNeeds', null, global);
goog.exportSymbol('proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetAllPrimaryNeedsRequest', null, global);
goog.exportSymbol('proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetProgramRecommendationContentRequest', null, global);
goog.exportSymbol('proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetProgramRecommendationRequest', null, global);
goog.exportSymbol('proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent', null, global);
goog.exportSymbol('proto.com.livongo.protobuf.grpc.external.bh.stepped_care.ProgramRecommendationContent', null, global);
goog.exportSymbol('proto.com.livongo.protobuf.grpc.external.bh.stepped_care.SelectPrimaryNeedRequest', null, global);
goog.exportSymbol('proto.com.livongo.protobuf.grpc.external.bh.stepped_care.StepOfCareContent', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetProgramRecommendationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetProgramRecommendationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetProgramRecommendationRequest.displayName = 'proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetProgramRecommendationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetProgramRecommendationContentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetProgramRecommendationContentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetProgramRecommendationContentRequest.displayName = 'proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetProgramRecommendationContentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetAllPrimaryNeedsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetAllPrimaryNeedsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetAllPrimaryNeedsRequest.displayName = 'proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetAllPrimaryNeedsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.SelectPrimaryNeedRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.livongo.protobuf.grpc.external.bh.stepped_care.SelectPrimaryNeedRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.livongo.protobuf.grpc.external.bh.stepped_care.SelectPrimaryNeedRequest.displayName = 'proto.com.livongo.protobuf.grpc.external.bh.stepped_care.SelectPrimaryNeedRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.ProgramRecommendationContent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.livongo.protobuf.grpc.external.bh.stepped_care.ProgramRecommendationContent.repeatedFields_, null);
};
goog.inherits(proto.com.livongo.protobuf.grpc.external.bh.stepped_care.ProgramRecommendationContent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.livongo.protobuf.grpc.external.bh.stepped_care.ProgramRecommendationContent.displayName = 'proto.com.livongo.protobuf.grpc.external.bh.stepped_care.ProgramRecommendationContent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.StepOfCareContent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.livongo.protobuf.grpc.external.bh.stepped_care.StepOfCareContent.repeatedFields_, null);
};
goog.inherits(proto.com.livongo.protobuf.grpc.external.bh.stepped_care.StepOfCareContent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.livongo.protobuf.grpc.external.bh.stepped_care.StepOfCareContent.displayName = 'proto.com.livongo.protobuf.grpc.external.bh.stepped_care.StepOfCareContent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent.displayName = 'proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.CategorizedPrimaryNeeds = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.livongo.protobuf.grpc.external.bh.stepped_care.CategorizedPrimaryNeeds.repeatedFields_, null);
};
goog.inherits(proto.com.livongo.protobuf.grpc.external.bh.stepped_care.CategorizedPrimaryNeeds, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.livongo.protobuf.grpc.external.bh.stepped_care.CategorizedPrimaryNeeds.displayName = 'proto.com.livongo.protobuf.grpc.external.bh.stepped_care.CategorizedPrimaryNeeds';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.AllPrimaryNeedsContent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.livongo.protobuf.grpc.external.bh.stepped_care.AllPrimaryNeedsContent.repeatedFields_, null);
};
goog.inherits(proto.com.livongo.protobuf.grpc.external.bh.stepped_care.AllPrimaryNeedsContent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.livongo.protobuf.grpc.external.bh.stepped_care.AllPrimaryNeedsContent.displayName = 'proto.com.livongo.protobuf.grpc.external.bh.stepped_care.AllPrimaryNeedsContent';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetProgramRecommendationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetProgramRecommendationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetProgramRecommendationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetProgramRecommendationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    parent: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetProgramRecommendationRequest}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetProgramRecommendationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetProgramRecommendationRequest;
  return proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetProgramRecommendationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetProgramRecommendationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetProgramRecommendationRequest}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetProgramRecommendationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setParent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetProgramRecommendationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetProgramRecommendationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetProgramRecommendationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetProgramRecommendationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string parent = 1;
 * @return {string}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetProgramRecommendationRequest.prototype.getParent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetProgramRecommendationRequest} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetProgramRecommendationRequest.prototype.setParent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetProgramRecommendationContentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetProgramRecommendationContentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetProgramRecommendationContentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetProgramRecommendationContentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    parent: jspb.Message.getFieldWithDefault(msg, 1, ""),
    language: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetProgramRecommendationContentRequest}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetProgramRecommendationContentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetProgramRecommendationContentRequest;
  return proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetProgramRecommendationContentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetProgramRecommendationContentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetProgramRecommendationContentRequest}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetProgramRecommendationContentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setParent(value);
      break;
    case 2:
      var value = /** @type {!proto.com.livongo.protobuf.common.bh.Language.Values} */ (reader.readEnum());
      msg.setLanguage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetProgramRecommendationContentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetProgramRecommendationContentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetProgramRecommendationContentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetProgramRecommendationContentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string parent = 1;
 * @return {string}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetProgramRecommendationContentRequest.prototype.getParent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetProgramRecommendationContentRequest} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetProgramRecommendationContentRequest.prototype.setParent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional com.livongo.protobuf.common.bh.Language.Values language = 2;
 * @return {!proto.com.livongo.protobuf.common.bh.Language.Values}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetProgramRecommendationContentRequest.prototype.getLanguage = function() {
  return /** @type {!proto.com.livongo.protobuf.common.bh.Language.Values} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.com.livongo.protobuf.common.bh.Language.Values} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetProgramRecommendationContentRequest} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetProgramRecommendationContentRequest.prototype.setLanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetAllPrimaryNeedsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetAllPrimaryNeedsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetAllPrimaryNeedsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetAllPrimaryNeedsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    parent: jspb.Message.getFieldWithDefault(msg, 1, ""),
    language: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetAllPrimaryNeedsRequest}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetAllPrimaryNeedsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetAllPrimaryNeedsRequest;
  return proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetAllPrimaryNeedsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetAllPrimaryNeedsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetAllPrimaryNeedsRequest}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetAllPrimaryNeedsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setParent(value);
      break;
    case 2:
      var value = /** @type {!proto.com.livongo.protobuf.common.bh.Language.Values} */ (reader.readEnum());
      msg.setLanguage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetAllPrimaryNeedsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetAllPrimaryNeedsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetAllPrimaryNeedsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetAllPrimaryNeedsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string parent = 1;
 * @return {string}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetAllPrimaryNeedsRequest.prototype.getParent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetAllPrimaryNeedsRequest} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetAllPrimaryNeedsRequest.prototype.setParent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional com.livongo.protobuf.common.bh.Language.Values language = 2;
 * @return {!proto.com.livongo.protobuf.common.bh.Language.Values}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetAllPrimaryNeedsRequest.prototype.getLanguage = function() {
  return /** @type {!proto.com.livongo.protobuf.common.bh.Language.Values} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.com.livongo.protobuf.common.bh.Language.Values} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetAllPrimaryNeedsRequest} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetAllPrimaryNeedsRequest.prototype.setLanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.SelectPrimaryNeedRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.livongo.protobuf.grpc.external.bh.stepped_care.SelectPrimaryNeedRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.SelectPrimaryNeedRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.SelectPrimaryNeedRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    parent: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.SelectPrimaryNeedRequest}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.SelectPrimaryNeedRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.livongo.protobuf.grpc.external.bh.stepped_care.SelectPrimaryNeedRequest;
  return proto.com.livongo.protobuf.grpc.external.bh.stepped_care.SelectPrimaryNeedRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.SelectPrimaryNeedRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.SelectPrimaryNeedRequest}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.SelectPrimaryNeedRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setParent(value);
      break;
    case 2:
      var value = /** @type {!proto.com.livongo.protobuf.common.bh.Condition.Values} */ (reader.readEnum());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.SelectPrimaryNeedRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.livongo.protobuf.grpc.external.bh.stepped_care.SelectPrimaryNeedRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.SelectPrimaryNeedRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.SelectPrimaryNeedRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string parent = 1;
 * @return {string}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.SelectPrimaryNeedRequest.prototype.getParent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.SelectPrimaryNeedRequest} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.SelectPrimaryNeedRequest.prototype.setParent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional com.livongo.protobuf.common.bh.Condition.Values id = 2;
 * @return {!proto.com.livongo.protobuf.common.bh.Condition.Values}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.SelectPrimaryNeedRequest.prototype.getId = function() {
  return /** @type {!proto.com.livongo.protobuf.common.bh.Condition.Values} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.com.livongo.protobuf.common.bh.Condition.Values} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.SelectPrimaryNeedRequest} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.SelectPrimaryNeedRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.ProgramRecommendationContent.repeatedFields_ = [5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.ProgramRecommendationContent.prototype.toObject = function(opt_includeInstance) {
  return proto.com.livongo.protobuf.grpc.external.bh.stepped_care.ProgramRecommendationContent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.ProgramRecommendationContent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.ProgramRecommendationContent.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultsHeader: jspb.Message.getFieldWithDefault(msg, 1, ""),
    resultsFeedback: jspb.Message.getFieldWithDefault(msg, 2, ""),
    primaryNeedHeader: jspb.Message.getFieldWithDefault(msg, 3, ""),
    primaryNeedBody: jspb.Message.getFieldWithDefault(msg, 4, ""),
    topComplaintsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    planStepsList: jspb.Message.toObjectList(msg.getPlanStepsList(),
    proto.com.livongo.protobuf.grpc.external.bh.stepped_care.StepOfCareContent.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.ProgramRecommendationContent}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.ProgramRecommendationContent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.livongo.protobuf.grpc.external.bh.stepped_care.ProgramRecommendationContent;
  return proto.com.livongo.protobuf.grpc.external.bh.stepped_care.ProgramRecommendationContent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.ProgramRecommendationContent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.ProgramRecommendationContent}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.ProgramRecommendationContent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResultsHeader(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setResultsFeedback(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrimaryNeedHeader(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrimaryNeedBody(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addTopComplaints(value);
      break;
    case 6:
      var value = new proto.com.livongo.protobuf.grpc.external.bh.stepped_care.StepOfCareContent;
      reader.readMessage(value,proto.com.livongo.protobuf.grpc.external.bh.stepped_care.StepOfCareContent.deserializeBinaryFromReader);
      msg.addPlanSteps(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.ProgramRecommendationContent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.livongo.protobuf.grpc.external.bh.stepped_care.ProgramRecommendationContent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.ProgramRecommendationContent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.ProgramRecommendationContent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResultsHeader();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getResultsFeedback();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPrimaryNeedHeader();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPrimaryNeedBody();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTopComplaintsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getPlanStepsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.com.livongo.protobuf.grpc.external.bh.stepped_care.StepOfCareContent.serializeBinaryToWriter
    );
  }
};


/**
 * optional string results_header = 1;
 * @return {string}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.ProgramRecommendationContent.prototype.getResultsHeader = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.ProgramRecommendationContent} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.ProgramRecommendationContent.prototype.setResultsHeader = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string results_feedback = 2;
 * @return {string}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.ProgramRecommendationContent.prototype.getResultsFeedback = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.ProgramRecommendationContent} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.ProgramRecommendationContent.prototype.setResultsFeedback = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string primary_need_header = 3;
 * @return {string}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.ProgramRecommendationContent.prototype.getPrimaryNeedHeader = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.ProgramRecommendationContent} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.ProgramRecommendationContent.prototype.setPrimaryNeedHeader = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string primary_need_body = 4;
 * @return {string}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.ProgramRecommendationContent.prototype.getPrimaryNeedBody = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.ProgramRecommendationContent} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.ProgramRecommendationContent.prototype.setPrimaryNeedBody = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated string top_complaints = 5;
 * @return {!Array<string>}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.ProgramRecommendationContent.prototype.getTopComplaintsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.ProgramRecommendationContent} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.ProgramRecommendationContent.prototype.setTopComplaintsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.ProgramRecommendationContent} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.ProgramRecommendationContent.prototype.addTopComplaints = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.ProgramRecommendationContent} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.ProgramRecommendationContent.prototype.clearTopComplaintsList = function() {
  return this.setTopComplaintsList([]);
};


/**
 * repeated StepOfCareContent plan_steps = 6;
 * @return {!Array<!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.StepOfCareContent>}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.ProgramRecommendationContent.prototype.getPlanStepsList = function() {
  return /** @type{!Array<!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.StepOfCareContent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.livongo.protobuf.grpc.external.bh.stepped_care.StepOfCareContent, 6));
};


/**
 * @param {!Array<!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.StepOfCareContent>} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.ProgramRecommendationContent} returns this
*/
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.ProgramRecommendationContent.prototype.setPlanStepsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.StepOfCareContent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.StepOfCareContent}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.ProgramRecommendationContent.prototype.addPlanSteps = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.com.livongo.protobuf.grpc.external.bh.stepped_care.StepOfCareContent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.ProgramRecommendationContent} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.ProgramRecommendationContent.prototype.clearPlanStepsList = function() {
  return this.setPlanStepsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.StepOfCareContent.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.StepOfCareContent.prototype.toObject = function(opt_includeInstance) {
  return proto.com.livongo.protobuf.grpc.external.bh.stepped_care.StepOfCareContent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.StepOfCareContent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.StepOfCareContent.toObject = function(includeInstance, msg) {
  var f, obj = {
    stepHeader: jspb.Message.getFieldWithDefault(msg, 1, ""),
    stepBody: jspb.Message.getFieldWithDefault(msg, 2, ""),
    bulletPointsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    footer: jspb.Message.getFieldWithDefault(msg, 4, ""),
    stepOfCare: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.StepOfCareContent}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.StepOfCareContent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.livongo.protobuf.grpc.external.bh.stepped_care.StepOfCareContent;
  return proto.com.livongo.protobuf.grpc.external.bh.stepped_care.StepOfCareContent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.StepOfCareContent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.StepOfCareContent}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.StepOfCareContent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStepHeader(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStepBody(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addBulletPoints(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFooter(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStepOfCare(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.StepOfCareContent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.livongo.protobuf.grpc.external.bh.stepped_care.StepOfCareContent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.StepOfCareContent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.StepOfCareContent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStepHeader();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStepBody();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBulletPointsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getFooter();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStepOfCare();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string step_header = 1;
 * @return {string}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.StepOfCareContent.prototype.getStepHeader = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.StepOfCareContent} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.StepOfCareContent.prototype.setStepHeader = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string step_body = 2;
 * @return {string}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.StepOfCareContent.prototype.getStepBody = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.StepOfCareContent} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.StepOfCareContent.prototype.setStepBody = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string bullet_points = 3;
 * @return {!Array<string>}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.StepOfCareContent.prototype.getBulletPointsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.StepOfCareContent} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.StepOfCareContent.prototype.setBulletPointsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.StepOfCareContent} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.StepOfCareContent.prototype.addBulletPoints = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.StepOfCareContent} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.StepOfCareContent.prototype.clearBulletPointsList = function() {
  return this.setBulletPointsList([]);
};


/**
 * optional string footer = 4;
 * @return {string}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.StepOfCareContent.prototype.getFooter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.StepOfCareContent} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.StepOfCareContent.prototype.setFooter = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string step_of_care = 5;
 * @return {string}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.StepOfCareContent.prototype.getStepOfCare = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.StepOfCareContent} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.StepOfCareContent.prototype.setStepOfCare = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent.prototype.toObject = function(opt_includeInstance) {
  return proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    header: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    imgUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    imgAccessibilityDescription: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent;
  return proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.com.livongo.protobuf.common.bh.Condition.Values} */ (reader.readEnum());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHeader(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setImgUrl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setImgAccessibilityDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getHeader();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getImgUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getImgAccessibilityDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional com.livongo.protobuf.common.bh.Condition.Values id = 1;
 * @return {!proto.com.livongo.protobuf.common.bh.Condition.Values}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent.prototype.getId = function() {
  return /** @type {!proto.com.livongo.protobuf.common.bh.Condition.Values} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.com.livongo.protobuf.common.bh.Condition.Values} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent.prototype.setId = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string header = 2;
 * @return {string}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent.prototype.getHeader = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent.prototype.setHeader = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string img_url = 4;
 * @return {string}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent.prototype.getImgUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent.prototype.setImgUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string img_accessibility_description = 5;
 * @return {string}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent.prototype.getImgAccessibilityDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent.prototype.setImgAccessibilityDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.CategorizedPrimaryNeeds.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.CategorizedPrimaryNeeds.prototype.toObject = function(opt_includeInstance) {
  return proto.com.livongo.protobuf.grpc.external.bh.stepped_care.CategorizedPrimaryNeeds.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.CategorizedPrimaryNeeds} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.CategorizedPrimaryNeeds.toObject = function(includeInstance, msg) {
  var f, obj = {
    category: jspb.Message.getFieldWithDefault(msg, 1, ""),
    primaryNeedsList: jspb.Message.toObjectList(msg.getPrimaryNeedsList(),
    proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.CategorizedPrimaryNeeds}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.CategorizedPrimaryNeeds.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.livongo.protobuf.grpc.external.bh.stepped_care.CategorizedPrimaryNeeds;
  return proto.com.livongo.protobuf.grpc.external.bh.stepped_care.CategorizedPrimaryNeeds.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.CategorizedPrimaryNeeds} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.CategorizedPrimaryNeeds}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.CategorizedPrimaryNeeds.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 2:
      var value = new proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent;
      reader.readMessage(value,proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent.deserializeBinaryFromReader);
      msg.addPrimaryNeeds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.CategorizedPrimaryNeeds.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.livongo.protobuf.grpc.external.bh.stepped_care.CategorizedPrimaryNeeds.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.CategorizedPrimaryNeeds} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.CategorizedPrimaryNeeds.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPrimaryNeedsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent.serializeBinaryToWriter
    );
  }
};


/**
 * optional string category = 1;
 * @return {string}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.CategorizedPrimaryNeeds.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.CategorizedPrimaryNeeds} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.CategorizedPrimaryNeeds.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated PrimaryNeedContent primary_needs = 2;
 * @return {!Array<!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent>}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.CategorizedPrimaryNeeds.prototype.getPrimaryNeedsList = function() {
  return /** @type{!Array<!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent, 2));
};


/**
 * @param {!Array<!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent>} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.CategorizedPrimaryNeeds} returns this
*/
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.CategorizedPrimaryNeeds.prototype.setPrimaryNeedsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.CategorizedPrimaryNeeds.prototype.addPrimaryNeeds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.CategorizedPrimaryNeeds} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.CategorizedPrimaryNeeds.prototype.clearPrimaryNeedsList = function() {
  return this.setPrimaryNeedsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.AllPrimaryNeedsContent.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.AllPrimaryNeedsContent.prototype.toObject = function(opt_includeInstance) {
  return proto.com.livongo.protobuf.grpc.external.bh.stepped_care.AllPrimaryNeedsContent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.AllPrimaryNeedsContent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.AllPrimaryNeedsContent.toObject = function(includeInstance, msg) {
  var f, obj = {
    topRecommendedPrimaryNeed: (f = msg.getTopRecommendedPrimaryNeed()) && proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent.toObject(includeInstance, f),
    otherRecommendedPrimaryNeedsList: jspb.Message.toObjectList(msg.getOtherRecommendedPrimaryNeedsList(),
    proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent.toObject, includeInstance),
    otherCategorizedPrimaryNeedsList: jspb.Message.toObjectList(msg.getOtherCategorizedPrimaryNeedsList(),
    proto.com.livongo.protobuf.grpc.external.bh.stepped_care.CategorizedPrimaryNeeds.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.AllPrimaryNeedsContent}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.AllPrimaryNeedsContent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.livongo.protobuf.grpc.external.bh.stepped_care.AllPrimaryNeedsContent;
  return proto.com.livongo.protobuf.grpc.external.bh.stepped_care.AllPrimaryNeedsContent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.AllPrimaryNeedsContent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.AllPrimaryNeedsContent}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.AllPrimaryNeedsContent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent;
      reader.readMessage(value,proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent.deserializeBinaryFromReader);
      msg.setTopRecommendedPrimaryNeed(value);
      break;
    case 2:
      var value = new proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent;
      reader.readMessage(value,proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent.deserializeBinaryFromReader);
      msg.addOtherRecommendedPrimaryNeeds(value);
      break;
    case 3:
      var value = new proto.com.livongo.protobuf.grpc.external.bh.stepped_care.CategorizedPrimaryNeeds;
      reader.readMessage(value,proto.com.livongo.protobuf.grpc.external.bh.stepped_care.CategorizedPrimaryNeeds.deserializeBinaryFromReader);
      msg.addOtherCategorizedPrimaryNeeds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.AllPrimaryNeedsContent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.livongo.protobuf.grpc.external.bh.stepped_care.AllPrimaryNeedsContent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.AllPrimaryNeedsContent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.AllPrimaryNeedsContent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTopRecommendedPrimaryNeed();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent.serializeBinaryToWriter
    );
  }
  f = message.getOtherRecommendedPrimaryNeedsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent.serializeBinaryToWriter
    );
  }
  f = message.getOtherCategorizedPrimaryNeedsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.com.livongo.protobuf.grpc.external.bh.stepped_care.CategorizedPrimaryNeeds.serializeBinaryToWriter
    );
  }
};


/**
 * optional PrimaryNeedContent top_recommended_primary_need = 1;
 * @return {?proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.AllPrimaryNeedsContent.prototype.getTopRecommendedPrimaryNeed = function() {
  return /** @type{?proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent} */ (
    jspb.Message.getWrapperField(this, proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent, 1));
};


/**
 * @param {?proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent|undefined} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.AllPrimaryNeedsContent} returns this
*/
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.AllPrimaryNeedsContent.prototype.setTopRecommendedPrimaryNeed = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.AllPrimaryNeedsContent} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.AllPrimaryNeedsContent.prototype.clearTopRecommendedPrimaryNeed = function() {
  return this.setTopRecommendedPrimaryNeed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.AllPrimaryNeedsContent.prototype.hasTopRecommendedPrimaryNeed = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated PrimaryNeedContent other_recommended_primary_needs = 2;
 * @return {!Array<!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent>}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.AllPrimaryNeedsContent.prototype.getOtherRecommendedPrimaryNeedsList = function() {
  return /** @type{!Array<!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent, 2));
};


/**
 * @param {!Array<!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent>} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.AllPrimaryNeedsContent} returns this
*/
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.AllPrimaryNeedsContent.prototype.setOtherRecommendedPrimaryNeedsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.AllPrimaryNeedsContent.prototype.addOtherRecommendedPrimaryNeeds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.com.livongo.protobuf.grpc.external.bh.stepped_care.PrimaryNeedContent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.AllPrimaryNeedsContent} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.AllPrimaryNeedsContent.prototype.clearOtherRecommendedPrimaryNeedsList = function() {
  return this.setOtherRecommendedPrimaryNeedsList([]);
};


/**
 * repeated CategorizedPrimaryNeeds other_categorized_primary_needs = 3;
 * @return {!Array<!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.CategorizedPrimaryNeeds>}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.AllPrimaryNeedsContent.prototype.getOtherCategorizedPrimaryNeedsList = function() {
  return /** @type{!Array<!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.CategorizedPrimaryNeeds>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.livongo.protobuf.grpc.external.bh.stepped_care.CategorizedPrimaryNeeds, 3));
};


/**
 * @param {!Array<!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.CategorizedPrimaryNeeds>} value
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.AllPrimaryNeedsContent} returns this
*/
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.AllPrimaryNeedsContent.prototype.setOtherCategorizedPrimaryNeedsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.CategorizedPrimaryNeeds=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.CategorizedPrimaryNeeds}
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.AllPrimaryNeedsContent.prototype.addOtherCategorizedPrimaryNeeds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.com.livongo.protobuf.grpc.external.bh.stepped_care.CategorizedPrimaryNeeds, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.AllPrimaryNeedsContent} returns this
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.AllPrimaryNeedsContent.prototype.clearOtherCategorizedPrimaryNeedsList = function() {
  return this.setOtherCategorizedPrimaryNeedsList([]);
};


goog.object.extend(exports, proto.com.livongo.protobuf.grpc.external.bh.stepped_care);
