import { ActionTree, GetterTree, MutationTree } from 'vuex';

import apiRoutes from '@config/api-routes';
import HTTP from '@/services/HttpService';

import { AxiosResponse } from 'axios';
import { RootState } from '@/types/storeTypes';

interface State {
  crisisProtocol: string;
  contactInfo: {
    firstName: string;
    lastName: string;
    phoneNumber: string;
  };
  inCrisisPlanActivity: boolean;
  finishedCrisisPlanActivity: boolean;
}

const defaultState = {
  crisisProtocol: '',
  contactInfo: {
    firstName: '',
    lastName: '',
    phoneNumber: ''
  },
  inCrisisPlanActivity: false,
  finishedCrisisPlanActivity: false
};

const formatPhoneNumber = (phoneNumber: string): string => {
  if (phoneNumber && !isNaN(Number(phoneNumber))) {
    if (phoneNumber.length === 12 && phoneNumber.startsWith('+')) {
      return phoneNumber.substring(2);
    }

    if (phoneNumber.length === 9) {
      return phoneNumber;
    }
    return '';
  }
  return '';
};

const getters: GetterTree<State, RootState> = {
  crisisProtocol: (state: State) => state.crisisProtocol,
  contactInfo: (state: State) => state.contactInfo
};

const actions: ActionTree<State, RootState> = {
  getCrisisProtocol({ commit }): Promise<AxiosResponse | void> {
    return HTTP.get(apiRoutes.crisis.protocol).then(
      (response) => {
        commit('setCrisisProtocol', response.data);
      },
      () => {
        // No-op : ignore this error and alow the user to continue
      }
    );
  },
  makeCrisisHelpRequest(store, request): Promise<AxiosResponse> {
    return HTTP.post(apiRoutes.crisis.request, request);
  },
  getContactInfo({ commit }): Promise<AxiosResponse | void> {
    return HTTP.get(apiRoutes.crisis.contactInfo).then((response) =>
      commit('setContactInfo', response.data)
    );
  }
};

const mutations: MutationTree<State> = {
  setCrisisProtocol(state: State, crisisProtocol: string) {
    state.crisisProtocol = crisisProtocol;
  },
  setContactInfo(
    state: State,
    contactInfo: { firstName: string; lastName: string; phoneNumber: string }
  ) {
    if (contactInfo.phoneNumber !== undefined) {
      contactInfo.phoneNumber = formatPhoneNumber(contactInfo.phoneNumber);
    }
    state.contactInfo = contactInfo;
  },
  setContactPhoneNumber(state: State, phoneNumber: string) {
    state.contactInfo = { ...state.contactInfo, ...{ phoneNumber } };
  },
  setContactFirstName(state: State, firstName: string) {
    state.contactInfo = { ...state.contactInfo, ...{ firstName } };
  },
  setContactLastName(state: State, lastName: string) {
    state.contactInfo = { ...state.contactInfo, ...{ lastName } };
  }
};

export default {
  state: defaultState,
  actions,
  getters,
  mutations
};
