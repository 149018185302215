import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-40d20931"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "navigation-top"
}
const _hoisted_3 = { class: "logo" }
const _hoisted_4 = { class: "nav-wrap-right" }
const _hoisted_5 = { class: "feature" }
const _hoisted_6 = { class: "feature-content" }
const _hoisted_7 = { class: "feature-name small-eyebrow" }
const _hoisted_8 = { class: "heading-lg feature-title" }
const _hoisted_9 = { class: "mobile-badges" }
const _hoisted_10 = ["alt"]
const _hoisted_11 = ["alt"]
const _hoisted_12 = { class: "feature-img" }
const _hoisted_13 = ["alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BrandLogoIcon = _resolveComponent("BrandLogoIcon")!
  const _component_BrandLogo = _resolveComponent("BrandLogo")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_BaseWrapper = _resolveComponent("BaseWrapper")!
  const _component_MHFooter = _resolveComponent("MHFooter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BaseWrapper, {
      class: "base-wrapper",
      logoDisplay: false
    }, {
      default: _withCtx(() => [
        (!_ctx.isAuthenticated)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_router_link, { to: { name: 'Home' } }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_BrandLogoIcon, {
                      class: "logo-icon",
                      role: "img",
                      "aria-label": _ctx.$t('iconDescription')
                    }, null, 8, ["aria-label"]),
                    _createVNode(_component_BrandLogo, {
                      class: "logo-full",
                      role: "img",
                      "aria-label": _ctx.$t('logoDescription')
                    }, null, 8, ["aria-label"])
                  ])
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_router_link, { to: {name:'Login'} }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('login')), 1)
                  ]),
                  _: 1
                })
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('title')), 1),
            _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('heading')), 1),
            _createElementVNode("p", null, _toDisplayString(_ctx.isOneApp? _ctx.$t('oneAppLabel.description') : _ctx.$t('description')), 1),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("a", {
                target: "_blank",
                href: "https://apps.apple.com/us/app/mystrength/id1446491603",
                onClick: _cache[0] || (_cache[0] = (e) => _ctx.onMobileClick(e, 'iOS'))
              }, [
                _createElementVNode("img", {
                  alt: _ctx.$t('appStoreAlt'),
                  src: "/img/sleep/mobile_redirect/app_store_badge.png"
                }, null, 8, _hoisted_10)
              ]),
              _createElementVNode("a", {
                target: "_blank",
                href: "https://play.google.com/store/apps/details?id=com.mystrength.cross.MyStrength",
                onClick: _cache[1] || (_cache[1] = (e) => _ctx.onMobileClick(e, 'android'))
              }, [
                _createElementVNode("img", {
                  alt: _ctx.$t('playStoreAlt'),
                  src: "/img/sleep/mobile_redirect/play_store_badge.png"
                }, null, 8, _hoisted_11)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("img", {
              class: "img-full",
              src: "/img/sleep/mobile_redirect/mobile_screenshot.png",
              alt: _ctx.$t('phoneImageDescription')
            }, null, 8, _hoisted_13)
          ])
        ])
      ]),
      _: 1
    }),
    (!_ctx.isAuthenticated)
      ? (_openBlock(), _createBlock(_component_MHFooter, { key: 0 }))
      : _createCommentVNode("", true)
  ]))
}