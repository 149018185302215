
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import InternalLinkService from '@/services/InternalLinkService';

import { Activity } from '@/types/ActivityTypes';
import { CardType, HomeCard } from '@/types/CardTypes';
import { Card, CardState } from '@/types/ClinicalCoachingTypes';
import { FeatureFlags } from '@/types/FeatureFlagTypes';
import { RecommendationType } from '@/types/RecommendationTypes';

import Hero from '@common/Hero.vue';
import Tracker from '@/components/Tracker.vue';
import CardCircleImg from '@common/cards/CardCircleImg.vue';
import MemberMessages from '@/components/MemberMessages.vue';
import CardCircleIcon from '@common/cards/CardCircleIcon.vue';
import NoFavorites from '@/components/favorite/NoFavorites.vue';
import TelehealthCard from '@/components/home/TelehealthCard.vue';
import ClinicalCoachingCard from '@/components/clinicalCoaching/ClinicalCoachingCard.vue';
import CrisisPlanCard from '@/components/home/CrisisPlanCard.vue';
import CrisisFooter from '@/components/common/CrisisFooter.vue';

import IconClipboardCheckmark from '@svg/icon-clipboard-checkmark.svg';
import SleepDiaryIcon from '@svg/icon-sleep-quality.svg';
import IconTherapy from '@svg/therapy/therapy.svg';
import WebViewService from '@/services/WebViewService';

const CARD_REFRESH_INTERVAL_MILLIS = 1000 * 60;

export default defineComponent({
  name: 'Home',
  inject: ['$analyticsTrack'],
  components: {
    MemberMessages,
    Hero,
    Tracker,
    ClinicalCoachingCard,
    CrisisPlanCard,
    TelehealthCard,
    CardCircleIcon,
    CardCircleImg,
    NoFavorites,
    CrisisFooter,
    IconClipboardCheckmark,
    SleepDiaryIcon,
    IconTherapy
  },
  props: {
    showNewFeatures: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      CardType,
      CardState,
      RecommendationType,
      cardUpdateInterval: 0
    };
  },
  computed: {
    favorites(): HomeCard<unknown>[] {
      return this.cards.filter(
        (card: HomeCard<unknown>) => card.type === CardType.FAVORITES
      );
    },
    noFavorites(): boolean {
      return this.cards && this.favorites.length === 0;
    },
    showViewAllFavoritesLink(): boolean {
      return this.favorites.length >= 2;
    },
    clinicalCoachingEnabled(): boolean {
      return this.hasFeatureEnabled(FeatureFlags.CLINICAL_COACHING);
    },
    crisisOutreachEnabled(): boolean {
      return this.hasFeatureEnabled(FeatureFlags.CRISIS_OUTREACH);
    },
    ...mapGetters(['cards', 'hasFeatureEnabled', 'isOneApp', 'isMobileWebView'])
  },
  unmounted() {
    if (this.cardUpdateInterval) {
      clearInterval(this.cardUpdateInterval);
    }
  },
  mounted() {
    this.init();
    this.$analyticsTrack('MH.Home.Start');
  },
  methods: {
    init(): void {
      this.getCards().then(() => {
        this.focusHomeTitle();
      });
      if (this.showNewFeatures) {
        this.setDrawerComponent('NewFeatures');
        this.openDrawer();
      }
      this.cardUpdateInterval = setInterval(
        this.getCards,
        CARD_REFRESH_INTERVAL_MILLIS
      );
    },
    getDestinationPath(destination: string): string {
      return InternalLinkService.getWebPathForInternalLink(destination);
    },
    focusHomeTitle() {
      if (Array.isArray(this.$refs.homeTitle)) {
        const homeTitle = this.$refs.homeTitle as HTMLElement[];
        homeTitle[0]?.focus();
      } else {
        const homeTitle = this.$refs.homeTitle as HTMLElement;
        homeTitle?.focus();
      }
    },
    displayClinicalCoachingCard(card: Card): boolean {
      return (
        this.clinicalCoachingEnabled &&
        card.content?.coachingCardType !== CardState.COMPLETED
      );
    },
    onNavigatingToFavoriteActivity() {
      this.$analyticsTrack('MH.Home.Favorites');
    },
    onNavigatingToITMActivity(destination: string) {
      const activitySlug = destination.split(':')[1];

      this.getActivityForSlug(activitySlug).then(
        (response: { data: Activity }) => {
          const activityData = response.data;
          this.$analyticsTrack('MH.Home.ITM', {
            ['activity_id']: activityData.id
          });
        }
      );
    },
    sendUserToOneAppMobile(destination: string) {
      WebViewService.sendUserToMobileOrCallback('action:' + destination);
    },
    onNavigatingToRecommended(destination: string) {
      const activitySlug = destination.split(':')[1];

      this.getActivityForSlug(activitySlug).then(
        (response: { data: Activity }) => {
          const activityData = response.data;
          this.$analyticsTrack('MH.Home.Recommended', {
            ['activity_id']: activityData.id
          });
        }
      );
    },
    onNavigatingToProgram(destination: string) {
      const programId = destination.split(':')[1];

      this.$analyticsTrack('MH.Home.Program', {
        ['$program_id']: programId
      });
    },
    onClinicalCoachingAppointmentCancelled() {
      // Refresh data after 2, 4, 6, and 10 seconds to accommodate for webhook latency
      setTimeout(() => {
        this.init();
      }, 2000);

      setTimeout(() => {
        this.init();
      }, 4000);

      setTimeout(() => {
        this.init();
      }, 6000);

      setTimeout(() => {
        this.init();
      }, 10000);
    },
    onClinicalCoachingAppointmentNoShow() {
      // Occurs at 10 min past the appointment time, when user has not joined
      this.init();
    },
    ...mapActions([
      'getCards',
      'openDrawer',
      'setDrawerComponent',
      'getActivityForSlug'
    ])
  }
});
