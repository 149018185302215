export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "es",
    "resource": {
      "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
      "instructions": {
        "sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cree una nueva contraseña que incluya:"])},
        "lowerCaseRule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al menos una letra minúscula"])},
        "capitalRule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al menos una mayúscula"])},
        "numberRule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al menos un número"])},
        "sizeRule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al menos 8 carácteres"])}
      },
      "passwordResetForm": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer su contraseña"])},
        "currentPassword": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["currentPassword"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña Actual"])}
        },
        "newPassword": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["newPassword"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva Contraseña"])}
        },
        "confirmPassword": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["confirmPassword"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar Contraseña"])}
        },
        "resetBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer la contraseña"])},
        "passwordMatchError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta contraseña no coincide"])},
        "matchesPrevPasswordError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su contraseña no puede coincidir con una contraseña utilizada anteriormente"])}
      },
      "passwordExpiredForm": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu contraseña ha caducado"])},
        "updateBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualiza contraseña"])}
      },
      "confirmationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualización exitosa"])},
      "confirmationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu contraseña ha sido cambiada. Ahora puede usar su nueva contraseña para iniciar sesión en myStrength."])},
      "backToLoginBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver a Iniciar sesión"])},
      "incorrectCurrentPasswordDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su contraseña actual debe ser correcta antes de que pueda crear una nueva contraseña."])}
    }
  })
}
