import { RootState } from '@/types/storeTypes';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';

import HTTP from '@/services/HttpService';

import apiRoutes from '@config/api-routes';

import { AxiosResponse } from 'axios';
import { HomeCard } from '@/types/CardTypes';

export interface HomeState {
  cards: HomeCard<unknown>[];
  memberMessages: Record<string, unknown>[];
}

const state: HomeState = {
  cards: [],
  memberMessages: []
};

const getters: GetterTree<HomeState, RootState> = {
  cards: (state: HomeState): HomeCard<unknown>[] => {
    return state.cards;
  },
  memberMessages: (state: HomeState): Record<string, unknown>[] => {
    return state.memberMessages;
  }
};

const actions: ActionTree<HomeState, RootState> = {
  getCards({ commit }): Promise<unknown> {
    return HTTP.get(apiRoutes.home.cards, {
      headers: { 'x-version': '2' }
    }).then((response) => {
      commit('setCards', {
        cards: response.data
      });
      return response.data;
    });
  },
  dismissCard({ commit }, type: string): Promise<unknown> {
    return HTTP.delete(`${apiRoutes.home.cards}/${type}`).finally(() => {
      commit('setCards', {
        cards: state.cards.filter((card) => card.type !== type)
      });
    });
  },
  getMemberMessages({ commit }): Promise<unknown> {
    return HTTP.get(apiRoutes.home.memberMessages).then((response) => {
      commit('setMemberMessages', response.data);
    });
  },
  addEmotionalMood(
    store,
    { moodSliderId, value, createdOn }
  ): Promise<AxiosResponse> {
    return HTTP.post(apiRoutes.tracker, {
      moodSliderEntries: [
        {
          moodSliderId,
          value,
          createdOn
        }
      ]
    });
  }
};

const mutations: MutationTree<HomeState> = {
  setCards(state: HomeState, { cards }): void {
    state.cards = cards;
  },
  setMemberMessages(
    state: HomeState,
    memberMessages: HomeState['memberMessages']
  ): void {
    state.memberMessages = memberMessages;
  }
};

export const homeStore: Module<HomeState, RootState> = {
  state,
  getters,
  actions,
  mutations
};

export default homeStore;
