import HTTP from '@/services/HttpService';
import apiRoutes from '@config/api-routes';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';

import { AxiosPromise } from 'axios';
import { RootState } from '@/types/storeTypes';
import { ActivitySlim } from '@/types/ActivityTypes';

export interface FavoriteState {
  favoriteActivities: ActivitySlim[] | null;
}

const state: FavoriteState = {
  favoriteActivities: null
};

const getters: GetterTree<FavoriteState, RootState> = {
  favoriteActivities: (state: FavoriteState) => state.favoriteActivities,
  isActivityFavorite: (state: FavoriteState) => (activitySlug: string) => {
    if (!state.favoriteActivities) {
      return false;
    }
    const favorite = state.favoriteActivities?.filter(
      (activity) => activity.slug == activitySlug
    );
    return favorite?.length === 1;
  }
};

const actions: ActionTree<FavoriteState, RootState> = {
  getFavoriteActivities({ commit }): void {
    HTTP.get(apiRoutes.activity.favorites).then((response) => {
      commit('setFavoriteActivities', response.data);
    });
  },
  addFavoriteActivity(
    { dispatch },
    activitySlug: string
  ): Promise<AxiosPromise | void> {
    return HTTP.put(apiRoutes.activity.alterFavorites(activitySlug)).then(
      () => {
        //get favorited activities again
        dispatch('getFavoriteActivities');
      }
    );
  },
  deleteFavoriteActivity(
    { dispatch },
    activitySlug: string
  ): Promise<AxiosPromise | void> {
    return HTTP.delete(apiRoutes.activity.alterFavorites(activitySlug)).then(
      () => {
        //get favorited activities again
        dispatch('getFavoriteActivities');
      }
    );
  }
};

const mutations: MutationTree<FavoriteState> = {
  setFavoriteActivities(
    state: FavoriteState,
    favoriteActivities: ActivitySlim[]
  ) {
    state.favoriteActivities = favoriteActivities;
  }
};

export const FavoritesStore: Module<FavoriteState, RootState> = {
  state,
  actions,
  mutations,
  getters
};

export default FavoritesStore;
