/**
 * UserTypes : interfaces and enums related to User
 */

export interface UserPreferences {
  optedIntoConsumerEmails: boolean;
  optedIntoCoachingEmails: boolean;
  optedIntoAssessmentEmails: boolean;
  'sleep_diary_enabled:true'?: boolean;
  'sleep_diary_reminder:email'?: boolean;
  //the api provides this field as a string
  optedIntoSleepDiary?: string;
}

export interface AccessCode {
  accessCode: string;
  type: string;
}

export interface UserEligibility {
  clientOfferingVersion: ClientOfferingVersion;
  memberProgram: MemberProgram;
  programFeatures: Features[];
  canMoveOn: boolean;
}

export interface Organization {
  id: number;
  shortName: string;
}

export interface User {
  emailAddress: string;
  firstName: string;
  lastName: string;
  language: string;
  onboardingStatus: OnboardingStatus;
  // dateOfBirth represented as an array of numbers:
  // - [year, month, day]
  // - e.g. : [2002, 1, 5] => January 5, 2002
  dateOfBirth: number[];
  gender: Gender;
  id: number;
  clientOfferingVersion: ClientOfferingVersion;
  userPreferences: UserPreferences;
  userEligibility: UserEligibility;
  accessCode: AccessCode;
  headerImage: string;
  organization: Organization;
  optedIntoAssessmentEmails: boolean;
  optedIntoConsumerEmails: boolean;
  reassessmentDue: boolean;
  hasAgedUp: boolean;
  teladocMemberId: string;
  requiresOneAppMigration: boolean;
}

export enum ClientOfferingVersion {
  MY1_0 = 'MY1_0',
  BH2_0 = 'BH2_0',
  BH3_0 = 'BH3_0',
  ONE_APP = 'ONE_APP'
}

export enum MemberProgram {
  DIGITAL = 'DIGITAL',
  COMPLETE = 'COMPLETE'
}

export enum Features {
  COACHING = 'COACHING',
  TELETHERAPY = 'TELETHERAPY',
  CLINICAL_COACHING = 'CLINICAL_COACHING'
}

export enum Gender {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  NON_BINARY = 'NON_BINARY',
  NO_ANSWER = 'NO_ANSWER'
}

export enum UserDeletionStatus {
  REQUESTED = 'REQUESTED',
  CANCELLED = 'CANCELLED',
  DELETED = 'DELETED'
}

export enum OnboardingStatus {
  INITIAL = 'INITIAL',
  CHAPTER1_COMPLETED = 'CHAPTER1_COMPLETED',
  CHAPTER2_COMPLETED = 'CHAPTER2_COMPLETED',
  CHAPTER3_READY = 'CHAPTER3_READY',
  ONBOARDING_COMPLETED = 'ONBOARDING_COMPLETED'
}

export enum UserPermissions {
  USERS = 'USERS',
  CONSOLE = 'CONSOLE',
  COACH = 'COACH',
  PARTNER = 'PARTNER',
  ADMIN = 'ADMIN',
  CONTENT_READ_ONLY = 'CONTENT_READ_ONLY',
  SYSTEM_MANAGEMENT = 'SYSTEM_MANAGEMENT'
}

export enum LivongoRegStatus {
  NOT_REQUIRED = 'NOT_REQUIRED',
  REQUIRED = 'REQUIRED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED'
}

export enum LoginWithLivongoErrors {
  UNIQUE_EMAIL_ADDRESS_REQUIRED = 'UNIQUE_EMAIL_ADDRESS_REQUIRED',
  MISSING_BH_SCOPE = 'MISSING_BH_SCOPE'
}

export enum UserMigrationStatus {
  NOT_REQUIRED = 'NOT_REQUIRED',
  UNKNOWN = 'UNKNOWN',
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  ACCOUNT_CREATED = 'ACCOUNT_CREATED',
  ACCOUNT_MATCHED = 'ACCOUNT_MATCHED',
  COMPLETED = 'COMPLETED'
}
