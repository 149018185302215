import { LANGUAGE_TYPES } from '@/types/WebviewTypes';
import { ENV_VARIABLES } from '@/types/EnvVariables';

class PathLookupService {
  myWindow: Window;

  constructor() {
    this.myWindow = window;
  }

  private isLocalhost = (
    hostname: string = this.myWindow.location.hostname
  ): boolean => {
    return hostname === 'localhost';
  };

  private localhostFrontendPaths = {
    app: 'http://localhost:9099',
    api: 'http://localhost:9080',
    webUI: 'http://localhost:5050',
    admin: 'http://localhost:9005',
    adminV2: 'http://localhost:4040',
    dashboard: 'http://localhost:8080'
  };

  private buildUrlForSubDomain = (subDomain: string): string => {
    const host = this.myWindow.location.host.replace('web-ui', subDomain);
    return `${this.myWindow.location.protocol}//${host}`;
  };

  private windowNavigate = (destinationUrl: string): void => {
    this.myWindow.location.href = destinationUrl;
  };

  /**
   *
   * IN: app-env.mystrength.livongo.com
   * OUT: mystrength.livongo.com
   *
   * IN: app.mystrength.livongo.com
   * OUT: mystrength.livongo.com
   *
   * IN: localhost
   * OUT: localhost
   *
   * @return {string} Represents the last 2 segments of the host domain.
   *
   */
  public getRootDomain = (): string => {
    const host = this.myWindow.location.host;
    if (!host) {
      return '';
    }

    if (this.isLocalhost()) {
      return host;
    }

    const segments = host.split('.');

    //possible values
    //localhost
    //app-mystrength.livongo
    //app-mystrength.livongo.*
    if (segments.length < 2) {
      return host;
    }

    //Skip the first segment as it's considered the root prefix
    return segments.slice(1).join('.');
  };

  /**
   *
   * IN: web-ui-env.mystrength.livongo.com
   * OUT: livongo.com
   *
   * IN: web-ui.mystrength.livongo.com
   * OUT: livongo.com
   *
   * @return {string} Represents the last 2 segments of the host domain.
   *
   */
  public getParentDomain = (): string => {
    const host = window.location.host;
    if (!host) {
      return '';
    }

    if (this.isLocalhost()) {
      return window.location.origin;
    }

    const segments = host.split('.');

    if (segments.length < 2) {
      return host;
    }

    //Return the last 2 segments
    return segments.slice(-2).join('.');
  };

  private lookupEnv = (env: ENV_VARIABLES): string => {
    if (process.env[env]) {
      return process.env[env];
    }

    return window.MYSTRENGTH_GLOBAL_ENV_VARIABLES[env];
  };

  public getApiUrl = (): string => {
    if (process.env.VUE_APP_API_URL) {
      return process.env.VUE_APP_API_URL;
    }
    if (this.isLocalhost()) {
      return this.localhostFrontendPaths.api;
    }
    return this.lookupEnv(ENV_VARIABLES.VUE_APP_API_URL);
  };

  /**
   * Get Teladoc web sdk baseUrl
   */
  public getTeladocWebSdkBaseUrl = (isOneApp = false): string => {
    if (isOneApp) {
      return this.lookupEnv(ENV_VARIABLES.VUE_APP_WEB_SDK_URL_ONEAPP);
    } else {
      return this.lookupEnv(ENV_VARIABLES.VUE_APP_WEB_SDK_URL);
    }
  };

  /**
   * Get Teladoc web sdk teladoc api key
   */
  public getTeladocWebSdkApiKey = (isOneApp = false): string => {
    if (isOneApp) {
      return this.lookupEnv(ENV_VARIABLES.VUE_APP_WEB_SDK_TOKEN_ONEAPP);
    } else {
      return this.lookupEnv(ENV_VARIABLES.VUE_APP_WEB_SDK_TOKEN);
    }
  };

  /**
   * Get Teladoc web sdk google api key
   */
  public getTeladocWebSdkGoogleApiKey = (): string => {
    return this.lookupEnv(ENV_VARIABLES.VUE_APP_WEB_SDK_GOOGLE_TOKEN);
  };

  /**
   * Get Teladoc web sdk baseUrl
   */
  public getTeladocWebSdkBundleId = (isOneApp = false): string => {
    if (isOneApp) {
      return this.lookupEnv(ENV_VARIABLES.VUE_APP_WEB_SDK_BUNDLE_ID_ONEAPP);
    } else {
      return this.lookupEnv(ENV_VARIABLES.VUE_APP_WEB_SDK_BUNDLE_ID);
    }
  };

  /**
   * Get a microservice baseUrl to be used with GRPC calls
   */
  public getMicroserviceBaseUrl = (): string => {
    return this.lookupEnv(ENV_VARIABLES.VUE_APP_GRPC_URL);
  };

  /**
   * Return livongo
   *      Integration server for localhost / PR BH env
   *      PREPROD for staging
   *      PROD for PROD
   */
  public getLivongoDomain = (): string => {
    return this.lookupEnv(ENV_VARIABLES.VUE_APP_CCM_LOGIN_URL);
  };

  public getLivongoRegistrationUrl = (): string => {
    return this.lookupEnv(ENV_VARIABLES.VUE_APP_CCM_REGISTRATION_URL);
  };

  public getKeycloakUrl = (): string => {
    return this.lookupEnv(ENV_VARIABLES.VUE_APP_KEYCLOAK_URL);
  };

  public getTeladocSignInUrl = (): string => {
    return this.lookupEnv(ENV_VARIABLES.VUE_APP_TDOC_SIGNIN_URL);
  };

  public getTeladocRegistrationUrl = (): string => {
    return this.lookupEnv(ENV_VARIABLES.VUE_APP_TDOC_REG_URL);
  };

  public getCoachingScheduleQueryParams = (
    appointmentTypeId: number,
    calendarId: number | null,
    expirationDate: string | null,
    isOneApp: boolean,
    language = 'en-US',
    isTimeTap: boolean
  ): string => {
    if (!appointmentTypeId) {
      console.warn('appointmentTypeId must be defined: ', appointmentTypeId);
    }
    const referrer = isTimeTap ? 'mentalhealth-web' : 'mystrength-web';
    const oneAppParamKey = isTimeTap ? 'oneAppSdk' : 'oneapp';
    const idParamKey = isTimeTap ? 'serviceId' : 'appointmentTypeId';
    const staffIdParamKey = isTimeTap ? 'staffId' : 'calendarId';

    const params = {
      referrer,
      returnPath:
        '/connect/clinical-coaching/post-schedule' +
        (isOneApp ? '?isoneapp=true' : '')
    } as { [key: string]: string };

    if (isOneApp) {
      params[oneAppParamKey] = 'true';
      params['locale'] =
        language === LANGUAGE_TYPES.ES_MX ? LANGUAGE_TYPES.ES_US : language;
    }

    if (appointmentTypeId) {
      params[idParamKey] = appointmentTypeId.toString();
    }
    if (calendarId) {
      params[staffIdParamKey] = calendarId.toString();
    }
    if (expirationDate) {
      params['expirationDate'] = expirationDate.toString();
    }

    const searchParams = new URLSearchParams(params);
    return `?${searchParams.toString()}`;
  };

  public getClinicalCoachingScheduleSessionUrl = (
    appointmentTypeId: number,
    calendarId: number | null,
    expirationDate: string | null,
    isOneApp: boolean,
    language: string,
    isTimeTap: boolean
  ): string => {
    let url;
    if (isOneApp) {
      url = this.lookupEnv(ENV_VARIABLES.VUE_APP_CCM_COACHING_URL_ONEAPP);
    } else {
      url = this.lookupEnv(ENV_VARIABLES.VUE_APP_CCM_COACHING_URL);
    }

    return `${url}/${this.getCoachingScheduleQueryParams(
      appointmentTypeId,
      calendarId,
      expirationDate,
      isOneApp,
      language,
      isTimeTap
    )}`;
  };

  public goToLivongoLoginPage = (redirectUrl: string): void => {
    let livongoLoginPageUrl = '/login';
    if (redirectUrl) {
      livongoLoginPageUrl += '?redirect=' + redirectUrl;
    }
    this.windowNavigate(this.getLivongoDomain() + livongoLoginPageUrl);
  };

  public getSsoLoginUrl = (): string => {
    return this.getApiUrl() + '/public/sso/livongo/sprequest';
  };

  public goToLivongoRegistrationPage = (): void => {
    this.windowNavigate(
      this.getLivongoRegistrationUrl() + '/registration/sign-up/6/1/'
    );
  };

  public goToTeladocLoginPage = (): void => {
    this.windowNavigate(this.getTeladocSignInUrl());
  };

  public goToTeladocRegistrationPage = (): void => {
    this.windowNavigate(this.getTeladocRegistrationUrl());
  };

  public goToURL = (url: string): void => {
    this.windowNavigate(url);
  };

  public goToLivongoProfilePage = (): void => {
    this.windowNavigate(
      this.getLivongoDomain() + '/profile-preferences/account-settings'
    );
  };

  public getAppDomain = (): string | null => {
    if (process.env.VUE_APP_APP_URL) {
      return process.env.VUE_APP_APP_URL;
    }
    if (this.isLocalhost()) {
      return this.localhostFrontendPaths.app;
    }
    return this.buildUrlForSubDomain('app');
  };

  public getWebUIDomain = (): string | null => {
    if (this.isLocalhost()) {
      return this.localhostFrontendPaths.webUI;
    }
    return this.buildUrlForSubDomain('web-ui');
  };

  public getAdminDomain = (): string | null => {
    if (process.env.VUE_APP_ADMIN_URL) {
      return process.env.VUE_APP_ADMIN_URL;
    }
    if (this.isLocalhost()) {
      return this.localhostFrontendPaths.admin;
    }
    return this.buildUrlForSubDomain('admin');
  };

  public getAdminV2Domain = (): string | null => {
    if (process.env.VUE_APP_ADMINV2_URL) {
      return process.env.VUE_APP_ADMINV2_URL;
    }
    if (this.isLocalhost()) {
      return this.localhostFrontendPaths.adminV2;
    }
    return this.buildUrlForSubDomain('adminv2');
  };

  public getDashboardDomain = (): string | null => {
    if (process.env.VUE_APP_DASHBOARD_URL) {
      return process.env.VUE_APP_DASHBOARD_URL;
    }
    if (this.isLocalhost()) {
      return this.localhostFrontendPaths.dashboard;
    }
    return this.buildUrlForSubDomain('dashboard');
  };
}

export default new PathLookupService();
