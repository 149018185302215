import { Message } from '@/types/OneAppTypes';

class OneAppService {
  private sendEvent(message: Message): void {
    window.parent.postMessage(message, '*');
  }

  public sendNavigationEvent(payload: string): void {
    this.sendEvent({ type: 'navigation', payload });
  }

  public sendStatusEvent(payload: string): void {
    this.sendEvent({ type: 'status', payload });
  }

  public sendRedirectEvent(payload: string): void {
    this.sendEvent({ type: 'redirect', payload });
  }

  public sendWebSDKPathEvent(
    payload: string,
    params: Array<Record<string, number | string | object>> = []
  ): void {
    this.sendEvent({ type: 'webSDKpath', payload, params });
  }
}

export default new OneAppService();
