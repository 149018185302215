import { ActionTree, GetterTree, MutationTree } from 'vuex';

import HTTP from '@/services/HttpService';
import authSvc from '@/services/AuthService';
import { analyticsRegisterGlobalFields } from '@/vueMixpanel';

import store from '@/store/index';
import apiRoutes from '@config/api-routes';

import { AxiosResponse } from 'axios';
import { RootState } from '@/types/storeTypes';
import {
  Features,
  OnboardingStatus,
  User,
  UserDeletionStatus
} from '@/types/UserTypes';
import AuthService from '@/services/AuthService';

const getTimeZone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
    ? Intl.DateTimeFormat().resolvedOptions().timeZone
    : '';
};

const getAgeFromDateOfBirth = (dateOfBirth: number[]): number => {
  if (!Array.isArray(dateOfBirth) || dateOfBirth.length !== 3) {
    return 0;
  }
  const birthYear = dateOfBirth[0];
  const birthMonth = dateOfBirth[1];
  const birthDay = dateOfBirth[2];

  const todayDate = new Date();
  const todayYear = todayDate.getFullYear();
  const todayMonth = todayDate.getMonth();
  const todayDay = todayDate.getDate();

  let age = todayYear - birthYear;

  if (todayMonth < birthMonth - 1) {
    age = age - 1;
  }
  if (birthMonth - 1 == todayMonth && todayDay < birthDay) {
    age = age - 1;
  }

  return age ? age : 0;
};

export interface UserState {
  accessCode: string;
  authenticated: boolean;
  hasExceededPasswordAttempts: boolean;
  headerHTML: string;
  isLegacyUser: boolean;
  isSubjectToGdpr: boolean;
  organizationShortName: string;
  passwordIsExpired: boolean;
  passwordIsChanged: boolean;
  user: User;
  userDeletionStatus: UserDeletionStatus;
  userIsVerified: boolean;
  userMFAType: string;
  userPermissions: string[];
  requiresLivongoReg: boolean;
  migrationRegistrationUrl: string;
  migrationRegistrationStatus: string;
  isClinicalPortalUser: boolean;
  livongoAuthStatus: null | { errorCode?: string; successful?: boolean };
  oneAppMigrationStatus: string;
  requiresOneAppMigration: boolean;
  oneAppMigrationUrl: string;
}

/**
 * This store module is for user related data
 */

const token = AuthService.getAuthToken();
const accessToken = AuthService.getLivongoAuthToken();
const sameToken = token === accessToken;

const initialState = {
  // The logged in user
  accessCode: '',
  authenticated: (token && !accessToken) || (token && accessToken && sameToken),
  hasExceededPasswordAttempts: false,
  headerHTML: '',
  isLegacyUser: false,
  isSubjectToGdpr: false,
  organizationShortName: null,
  passwordIsExpired: false,
  passwordIsChanged: false,
  user: {},
  userDeletionStatus: '',
  userIsVerified: false,
  userMFAType: null,
  userPermissions: authSvc.getAuthTokenPermissions(),
  requiresLivongoReg: false,
  migrationRegistrationURL: '',
  migrationRegistrationStatus: '',
  isClinicalPortalUser: authSvc.getClinicalPortalUser(),
  livongoAuthStatus: null,
  oneAppMigrationStatus: '',
  requiresOneAppMigration: false,
  oneAppMigrationUrl: ''
};

const state = { ...initialState };

const getters: GetterTree<UserState, RootState> = {
  accessCode: (state: UserState) => state.accessCode,
  canMoveOn: (state: UserState) => state.user?.userEligibility?.canMoveOn,
  clientOfferingVersion: (state: UserState) =>
    state.user?.clientOfferingVersion,
  deletionStatus: (state: UserState) => state.userDeletionStatus,
  hasExceededPasswordAttempts: (state: UserState) =>
    state.hasExceededPasswordAttempts,
  headerHTML: (state: UserState) => state.headerHTML,
  headerImage: (state: UserState) => state.user?.headerImage,
  isAuthenticated: (state: UserState) => state.authenticated,
  isLegacyUser: (state: UserState) => state.isLegacyUser,
  isSubjectToGdpr: (state: UserState) => state.isSubjectToGdpr,
  optedIntoSleepDiary: (state: UserState) =>
    state.user?.userPreferences?.optedIntoSleepDiary === 'true',
  // set during register from access code
  organizationShortName: (state: UserState) => state.organizationShortName,
  organizationId: (state: UserState) => state.user?.organization?.id,
  passwordIsExpired: (state: UserState) => state.passwordIsExpired,
  passwordIsChanged: (state: UserState) => state.passwordIsChanged,
  user: (state: UserState) => state.user,
  userAccessCode: (state: UserState) => state.user?.accessCode,
  userId: (state: UserState) => state.user?.id || null,
  userIsVerified: (state: UserState) => state.userIsVerified,
  userLoaded: (state: UserState) => typeof state.user?.id !== 'undefined',
  userMFAType: (state: UserState) => state.userMFAType,
  // reflects user object organization shortName
  userOrgShortName: (state: UserState) => state.user?.organization?.shortName,
  userPermissions: (state: UserState) => state.userPermissions,
  userPreferences: (state: UserState) => state.user?.userPreferences,
  isTeletherapyUser: (state: UserState) =>
    state.user?.userEligibility?.programFeatures?.includes(
      Features.TELETHERAPY
    ),
  isClinicalCoachingUser: (state: UserState) =>
    state.user?.userEligibility?.programFeatures?.includes(
      Features.CLINICAL_COACHING
    ),
  onboardingStatus: (state: UserState) => state.user.onboardingStatus,
  userIsUnderAge: (state: UserState): boolean => {
    const underAgeYearsOld = 18;
    const userAge = getAgeFromDateOfBirth(state.user.dateOfBirth);
    return userAge < underAgeYearsOld;
  },
  requiresLivongoReg: (state: UserState) => state.requiresLivongoReg,
  migrationRegistrationURL: (state: UserState) =>
    state.migrationRegistrationUrl,
  migrationRegistrationStatus: (state: UserState) =>
    state.migrationRegistrationStatus,
  isClinicalPortalUser: (state: UserState) => state.isClinicalPortalUser,
  hasAgedUp: (state: UserState) => state.user.hasAgedUp,
  livongoAuthStatus: (state: UserState) => state.livongoAuthStatus,
  teladocMemberId: (state: UserState) => state.user.teladocMemberId,
  userLanguage: (state: UserState) => state.user.language,
  oneAppMigrationStatus: (state: UserState) => state.oneAppMigrationStatus,
  requiresOneAppMigration: (state: UserState) => state.requiresOneAppMigration,
  oneAppMigrationUrl: (state: UserState) => state.oneAppMigrationUrl
};

const actions: ActionTree<UserState, RootState> = {
  cancelDeleteRequest(store) {
    return HTTP.delete(apiRoutes.user.deleteAccount).then(() => {
      store.dispatch('getUserDeleteStatus');
    });
  },
  userChangePassword(store, { newPassword, currentPassword }) {
    return HTTP.post(apiRoutes.user.userChangePassword, {
      currentPassword,
      newPassword
    });
  },
  userChangeCredentials(store, { newPassword, currentPassword, newEmail }) {
    return HTTP.post(apiRoutes.user.userChangeEmail, {
      currentPassword,
      newPassword,
      newEmail
    });
  },
  deleteUser(store, reason: string) {
    const payload = { reason: reason };
    return HTTP.post(apiRoutes.user.deleteAccount, payload).then(() => {
      store.dispatch('getUserDeleteStatus');
    });
  },
  getHeaderHTML(store) {
    return HTTP.get<{ html: string }>(apiRoutes.headers).then((response) => {
      store.commit('setHeaderHTML', response.data.html);
    });
  },
  getMemberVersion(store, email) {
    // Keep member version out of store until login
    return HTTP.post<{ oneAppMigrationStatus: string }>(
      apiRoutes.user.memberVersion,
      email
    ).then((response) => {
      store.commit(
        'setOneAppMigrationStatus',
        response.data?.oneAppMigrationStatus
      );
      return response;
    });
  },
  getUser({ commit }) {
    return HTTP.get<{
      clientOfferingVersion: string;
      requiresLivongoReg: boolean;
    }>(apiRoutes.user.getUser).then((response) => {
      if (response && response.data) {
        commit('setIsLegacyUser', response.data.clientOfferingVersion);
        commit('setRequiresLivongoReg', response.data.requiresLivongoReg);
        commit('setUser', response.data);

        return response.data;
      }
    });
  },
  getUserDeleteStatus(store) {
    return HTTP.get<{ status: UserDeletionStatus }>(
      apiRoutes.user.deleteAccount
    ).then((response) => {
      store.commit('setUserDeletionStatus', response.data.status);
    });
  },
  getUserMFAType(store) {
    return HTTP.post(apiRoutes.user.getMFAType).then((response) => {
      store.commit('setUserMFAType', response.data);
    });
  },
  getUserPermissions(store) {
    const tokenPermissions = authSvc.getAuthTokenPermissions();
    return store.commit('setUserPermissions', tokenPermissions);
  },
  loginUser(store, user) {
    const userTimeZone = getTimeZone();
    return HTTP.post(`${apiRoutes.user.login}?tz=${userTimeZone}`, user)
      .then((response) => {
        if (response.data?.pwExpired) {
          store.commit('setPasswordIsExpired', true);
        } else {
          store.commit('initializeUserState');
          store.commit('setAuthenticated', true);
          store.commit(
            'setRequiresOneAppMigration',
            response.data.response?.requiresOneAppMigration
          );
        }

        return response;
      })
      .catch((errorResponse) => {
        if (
          errorResponse.response.data?.errorCode ===
          'EXCEEDED_MAX_PASSWORD_ATTEMPTS'
        ) {
          store.commit('setExceededPasswordAttempts', true);
        } else {
          return Promise.reject();
        }
      });
  },
  async loginUserWithLivongoToken({ commit, state }, liveonAuthView) {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
      ? Intl.DateTimeFormat().resolvedOptions().timeZone
      : '';
    return await HTTP.post(
      `${apiRoutes.user.loginWithLivongoToken}?tz=${userTimeZone}`,
      liveonAuthView
    ).then(
      (response) => {
        if (!state.user || Object.keys(state.user).length < 1) {
          commit('initializeUserState');
          commit('setUser', response.data.response);
        }
        commit('setAuthenticated', true);
        commit('setLivongoAuthStatus', { successful: true });
      },
      (err: { response: AxiosResponse<{ errorCode: string }> }) => {
        commit('setLivongoAuthStatus', {
          errorCode: err.response.data.errorCode
        });
      }
    );
  },
  loginUserWithClinicalPortalToken(store, liveonAuthView) {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
      ? Intl.DateTimeFormat().resolvedOptions().timeZone
      : '';
    return HTTP.post(
      `${apiRoutes.user.loginWithClinicalPortalToken}?tz=${userTimeZone}`,
      liveonAuthView
    ).then((response) => {
      store.commit('initializeUserState');
      store.commit('setAuthenticated', true);
      store.commit('setClinicalAuthenticated', true);

      if (response.data.response) {
        store.commit('setUser', response.data.response);
      }

      return response;
    });
  },
  logoutUser(store) {
    return HTTP.post(apiRoutes.user.logout).finally(() => {
      store.commit('resetUserState');
      store.commit('clearSurveyResponses');
      store.commit('clearAssessmentResponses');
    });
  },
  postOnboardingCompleted() {
    return HTTP.post(apiRoutes.onboarding.status);
  },
  register(store, data) {
    const userTimeZone = getTimeZone();
    return HTTP.post(apiRoutes.registration.submit(userTimeZone), data, {
      headers: { 'Content-Type': 'application/json;charset=UTF-8' }
    }).then(() => {
      store.commit('setAuthenticated', true);
    });
  },
  requestPasswordReset(store, email) {
    return HTTP.post(`${apiRoutes.user.passwordReset}`, email, {
      headers: { 'Content-Type': 'application/json;charset=UTF-8' }
    });
  },
  resetPassword(store, { newPassword, resetPasswordToken }) {
    return HTTP.post(apiRoutes.user.resetPassword, newPassword, {
      withCredentials: true,
      headers: {
        'x-session-token': resetPasswordToken,
        'Content-Type': 'application/json;charset=UTF-8'
      }
    }).then(() => {
      store.commit('resetUserState');
    });
  },
  resetExpiredPassword(
    store,
    { newPassword, currentPassword, resetPasswordToken }
  ) {
    return HTTP.post(
      apiRoutes.user.resetExpiredPassword,
      { currentPassword, newPassword },
      {
        withCredentials: true,
        headers: {
          'x-session-token': resetPasswordToken,
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }
    ).then(() => {
      store.commit('resetUserState');
    });
  },
  updateIsSubjectToGdpr(store, isSubjectToGdpr) {
    store.commit('setIsSubjectToGdpr', isSubjectToGdpr);
  },
  updateSleepDiaryEmails(store, enabled) {
    return HTTP.post(
      apiRoutes.user.tags('sleep_diary_reminder:email', enabled)
    );
  },
  updateSleepDiaryEmailsAndGetUser(store, enabled) {
    return HTTP.post(
      apiRoutes.user.tags('sleep_diary_reminder:email', enabled)
    ).then(() => {
      store.dispatch('getUser');
    });
  },
  updateUser(store, user) {
    return HTTP.post(apiRoutes.user.update, user);
  },
  updateUserPermissions(store) {
    store.commit('setUserPermissions', authSvc.getAuthTokenPermissions());
  },

  initializeLivongoRegistration(store) {
    return HTTP.get<{ status: string; url: string }>(
      apiRoutes.user.livongoIdpRequest
    ).then((response) => {
      store.commit('setMigrationRegistrationStatus', response.data.status);
      store.commit('setMigrationRegistrationUrl', response.data.url);
    });
  },
  updateAndGetUser(store, user) {
    return HTTP.post(apiRoutes.user.update, user).then(() => {
      store.dispatch('getUser');
    });
  },
  validateAccessCode(store, accessCode) {
    return HTTP.post<{ organizationShortName: string }>(
      apiRoutes.registration.validateAccessCode(),
      accessCode,
      { headers: { 'Content-Type': 'text/plain' } }
    ).then((response) => {
      store.commit('setAccessCode', accessCode.trim());
      store.commit(
        'setOrganizationShortName',
        response.data.organizationShortName
      );
      return response;
    });
  },
  verifyAuthCode(store, authCode) {
    store.commit('setUserIsVerified', null);
    return (
      HTTP.post(apiRoutes.user.mfaVerify, authCode, {
        headers: { 'Content-Type': 'application/json;charset=UTF-8' }
      })
        // must getUserPermissions after verifying MFA auth code to exit MFAVerify component
        .then(() => store.dispatch('getUserPermissions'))
        .catch(() => store.commit('setUserIsVerified', false))
    );
  },
  deleteAdolescence() {
    return HTTP.delete(apiRoutes.user.adolescence);
  },
  updateUserSessionLanguage(
    store,
    { type, language }: { type: string; language: string }
  ) {
    return HTTP.post(
      apiRoutes.user.language(type),
      { language },
      {
        headers: { 'Content-Type': 'application/json;charset=UTF-8' }
      }
    );
  },
  getSaml(store, destURL) {
    return HTTP.get<{ samlAssertion: string }>(apiRoutes.user.migrationSaml, {
      params: { destinationUrl: destURL }
    });
  }
};

const mutations: MutationTree<UserState> = {
  initializeUserState(state) {
    Object.assign(state, initialState);
  },
  resetUserState(state) {
    authSvc.clearAllTokens();
    Object.assign(state, initialState);
  },
  setAccessCode(state, accessCode) {
    state.accessCode = accessCode;
  },
  setAuthenticated(state, authenticated: boolean) {
    state.authenticated = authenticated;
  },
  setExceededPasswordAttempts(state, exceeded) {
    state.hasExceededPasswordAttempts = exceeded;
  },
  setHeaderHTML(state, html: string): void {
    state.headerHTML = html;
  },
  setIsLegacyUser(state, clientOfferingVersion) {
    state.isLegacyUser = clientOfferingVersion == 'MY1_0';
  },
  setIsSubjectToGdpr(state, isSubjectToGdpr) {
    state.isSubjectToGdpr = isSubjectToGdpr;
  },
  setOnboardingStatus(state, onboardingStatus: OnboardingStatus) {
    state.user.onboardingStatus = onboardingStatus;
  },
  setOrganizationShortName(state, organizationShortName) {
    state.organizationShortName = organizationShortName;
  },
  setPasswordIsChanged(state, changed) {
    state.passwordIsChanged = changed;
  },
  setPasswordIsExpired(state, expired) {
    state.passwordIsExpired = expired;
  },
  setUser(state, user) {
    state.user = user;
    analyticsRegisterGlobalFields({ $MSUserId: user?.id });
  },
  setUserDeletionStatus(state, status: UserDeletionStatus) {
    state.userDeletionStatus = status;
  },
  setUserIsVerified(state, verified) {
    state.userIsVerified = verified;
  },
  setUserMFAType(state, userMFAType) {
    state.userMFAType = userMFAType;
  },
  setUserPermissions(state, permissions) {
    if (permissions.includes('MFA_VERIFIED')) {
      store.commit('setUserIsVerified', true);
    }
    state.userPermissions = permissions;
  },
  setRequiresLivongoReg(state, requiresLivongoReg) {
    state.requiresLivongoReg = requiresLivongoReg;
  },
  setMigrationRegistrationUrl(state, migrationRegistrationUrl) {
    state.migrationRegistrationUrl = migrationRegistrationUrl;
  },
  setMigrationRegistrationStatus(state, migrationRegistrationStatus) {
    state.migrationRegistrationStatus = migrationRegistrationStatus;
  },
  setLivongoAuthStatus(state, status: UserState['livongoAuthStatus']) {
    if (!status) {
      state.livongoAuthStatus = null;
    } else if (status.errorCode) {
      state.livongoAuthStatus = {
        errorCode: status.errorCode,
        successful: false
      };
    } else if (status.successful) {
      state.livongoAuthStatus = {
        successful: true
      };
    }
  },
  setOneAppMigrationStatus(state, oneAppMigrationStatus) {
    state.oneAppMigrationStatus = oneAppMigrationStatus;
  },
  setRequiresOneAppMigration(state, requiresOneAppMigration) {
    state.requiresOneAppMigration = requiresOneAppMigration;
  },
  setOneAppMigrationUrl(state, oneAppMigrationUrl) {
    state.oneAppMigrationUrl = oneAppMigrationUrl;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
