import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-42ebc524"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "help-crisis-wrapper" }
const _hoisted_2 = { class: "card crisis-card" }
const _hoisted_3 = { class: "bold" }
const _hoisted_4 = {
  class: "btn-primary",
  href: "tel:800-273-8255"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ExploreTitle = _resolveComponent("ExploreTitle")!
  const _component_CrisisPlanCard = _resolveComponent("CrisisPlanCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ExploreTitle, {
      title: _ctx.$t('heading'),
      description: _ctx.$t('body')
    }, null, 8, ["title", "description"]),
    _createVNode(_component_CrisisPlanCard, {
      class: "card-crisis-plan",
      currentLocation: "HelpCrisis",
      showDismiss: false,
      card: _ctx.crisisCard
    }, null, 8, ["card"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('crisisCard.title')), 1),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('crisisCard.phoneNumber')), 1),
      _createElementVNode("a", _hoisted_4, _toDisplayString(_ctx.$t('crisisCard.buttonLabel')), 1)
    ])
  ]))
}