import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0afb68f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "center-heading" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CylonLoader = _resolveComponent("CylonLoader")!
  const _component_BaseWrapper = _resolveComponent("BaseWrapper")!

  return (_openBlock(), _createBlock(_component_BaseWrapper, {
    class: "deep-link-wrapper",
    logoDisplay: _ctx.displayLogo
  }, {
    default: _withCtx(() => [
      _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t('redirectText')), 1),
      _createVNode(_component_CylonLoader)
    ]),
    _: 1
  }, 8, ["logoDisplay"]))
}