
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PulseButton',
  emits: ['click', 'focus'],
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    ariaLabel: {
      type: String,
      required: false,
      default: null
    }
  },
  methods: {
    handleClick($event: Event) {
      if (!this.disabled) {
        // buttons are not automatically focused on click in safari
        const button = this.$refs.button as HTMLElement;
        button?.focus();

        this.$emit('click', $event);
      }
    }
  }
});
