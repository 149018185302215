
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

export default defineComponent({
  name: 'HelpResources',
  inject: ['$analyticsTrack'],
  data() {
    return {
      loading: true
    };
  },
  computed: {
    ...mapGetters(['headerHTML', 'isMobileWebView'])
  },
  mounted() {
    this.init();
    this.getHeaderHTML().finally(() => {
      this.loading = false;
    });
  },
  methods: {
    init() {
      this.$analyticsTrack('MH.Settings.Resources.Start');
    },
    ...mapActions(['getHeaderHTML'])
  }
});
