
import { Field, defineRule } from 'vee-validate';

import { required } from '@vee-validate/rules';

// i18n TODO
// import veeEn from 'vee-validate/dist/locale/en.json';
// VeeValidateLocalize('en', veeEn);

// Add specific rules to keep bundle size down
defineRule('required', required);

import { defineComponent } from 'vue';
import BaseInput from '@/components/base/mixin/BaseInput';

export default defineComponent({
  name: 'BaseInputRadio',
  emits: ['update:inputModel'],
  mixins: [BaseInput],
  components: {
    Field
  },
  props: {
    inputId: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    }
  },
  computed: {
    isSelected() {
      return this.inputValue === this.value;
    }
  }
});
