import { dynamicComponent } from '@/helpers/Webpack';

export default [
  {
    path: '/profile',
    name: 'Profile',
    component: dynamicComponent('Profile/Profile')
  },
  {
    path: 'notifications',
    name: 'Notifications',
    component: dynamicComponent('Profile/NotificationSettings')
  },
  {
    path: 'language',
    name: 'Language',
    component: dynamicComponent('Profile/Language')
  },
  {
    path: 'personalization',
    name: 'Personalization',
    component: dynamicComponent('Profile/Personalization')
  },
  {
    path: 'support',
    name: 'Support',
    component: dynamicComponent('Profile/Support')
  },
  {
    path: 'history',
    name: 'History',
    component: dynamicComponent('Profile/History')
  },
  {
    path: '*',
    redirect: '/'
  }
];
