// source: livongo/protobuf/grpc/external/bh/wellness_assessments/wellness_assessments_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var livongo_protobuf_grpc_external_bh_wellness_assessments_wellness_assessments_pb = require('../../../../../../livongo/protobuf/grpc/external/bh/wellness_assessments/wellness_assessments_pb.js');
goog.object.extend(proto, livongo_protobuf_grpc_external_bh_wellness_assessments_wellness_assessments_pb);
