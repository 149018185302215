export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "es",
    "resource": {
      "validBirthDateMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor ingresa una fecha de nacimiento valida"])},
      "validDateMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se requiere una fecha válida"])},
      "minimumAgeMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debes tener al menos 13 años para utilizar este servicio."])}
    }
  })
}
