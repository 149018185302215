export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "es",
    "resource": {
      "404": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Ups! Falta algo."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No pudimos encontrar la página que buscaba."])}
      },
      "500": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algo salió mal"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estamos teniendo un problema y estamos trabajando duro para volver a encarrilar las cosas. Intente actualizar su navegador y, si el problema persiste, envíe un correo electrónico a Asistencia para miembros a: "])},
        "memberSupportEmailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["membersupport", "@", "livongo.com"])}
      },
      "API": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Oh, oh, algo salió mal!"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubo un error al acceder a esta página. Actualiza tu navegador o contacta "])},
        "memberSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soporte para miembros"])},
        "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" si el problema persiste."])}
      },
      "supportMailTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Necesito%20ayuda%20con%20myStrength"])}
    }
  })
}
