
import { defineComponent } from 'vue';
import { mapGetters, mapActions } from 'vuex';

import { UserPermissions } from '@/types/UserTypes';

import IconArrowRight from '@svg/icon-arrow-right.svg';

import pathSvc from '@/services/PathLookupService';
import OnboardingRedirectService from '@/services/OnboardingRedirectService';
import { RouteLocation } from 'vue-router';

export interface Destination {
  url: string | null;
  label: string;
  requiresMfa?: boolean;
  permission?: UserPermissions;
  translationIndex?: number;
}

export default defineComponent({
  name: 'DestinationChooser',
  components: {
    IconArrowRight
  },
  data() {
    return {
      destinationList: [] as Destination[],
      permissionsToDestinationMap: {
        USERS: [
          {
            label: 'myStrength App',
            url:
              OnboardingRedirectService({ path: '/home' } as RouteLocation) ||
              '/home'
          }
        ],
        CONSOLE: [
          {
            label: 'User Dashboard',
            url: `${pathSvc.getDashboardDomain()}/dashboard`,
            requiresMfa: true
          }
        ],
        COACH: [
          {
            label: 'Coach Dashboard',
            url: `${pathSvc.getDashboardDomain()}/coaching`,
            requiresMfa: true
          }
        ],
        PARTNER: [
          {
            label: 'Partner API',
            url: `${pathSvc.getAppDomain()}/partner`,
            requiresMfa: true
          }
        ],
        ADMIN: [
          {
            label: 'Admin',
            url: `${pathSvc.getAdminDomain()}/#/`,
            requiresMfa: true
          },
          {
            label: 'AdminV2',
            url: pathSvc.getAdminV2Domain(),
            requiresMfa: true
          }
        ],
        CONTENT_READ_ONLY: [
          {
            label: 'AdminV2',
            url: pathSvc.getAdminV2Domain(),
            requiresMfa: true
          }
        ],
        SYSTEM_MANAGEMENT: []
      }
    };
  },
  computed: {
    ...mapGetters([
      'userAccessCode',
      'userOrgShortName',
      'userPermissions',
      'userMFAType'
    ])
  },
  watch: {
    userAccessCode() {
      this.init();
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      await this.updateUserPermissions();
      this.destinationList = this.buildUsersDestinationList();
      // Get userMFAType if not already set
      if (!this.userMFAType) {
        this.getUserMFAType();
      }
    },
    buildUsersDestinationList(): Destination[] {
      // Build destination list matching user.permissions
      const permissionList = Object.keys(
        this.permissionsToDestinationMap
      ).reduce((acc: Destination[], permission) => {
        // Only add destination(s) if user has permission
        // NOTE: consider using decoded jwt permissions
        if (!this.userPermissions.includes(permission)) {
          return acc;
        }

        const destinations =
          this.permissionsToDestinationMap[permission as UserPermissions];
        (destinations as Destination[]).map(
          (destination: Destination, index: number): void => {
            acc.push({
              translationIndex: index,
              permission: permission as UserPermissions,
              ...destination
            });
          }
        );
        if (this.userAccessCode?.type === 'INTERNAL') {
          acc.push({
            label: 'Referral Forms',
            permission: 'REFERRAL_FORMS' as UserPermissions,
            translationIndex: 0,
            url: `${pathSvc.getAppDomain()}/referral/${this.userOrgShortName}`
          });
        }
        return acc;
      }, []);

      //filter out duplicate destinations
      return permissionList.filter((v, i, a) => {
        return a.findIndex((t) => t.label === v.label) === i;
      });
    },
    dataCy(label: string): string {
      return label.toLowerCase().split(' ').join('-') + '-link';
    },
    ...mapActions(['getUserMFAType', 'updateUserPermissions'])
  }
});
