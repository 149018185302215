export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "heading": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account has moved"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use the Teladoc Health app or website to access your programs."])}
      },
      "nextBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Teladoc Health"])},
      "logosDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teladoc logo and myStrength logo"])}
    }
  })
}
