import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-77879c1e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "alert" }
const _hoisted_2 = {
  key: 0,
  class: "img",
  "data-cy": "checkMark"
}
const _hoisted_3 = {
  key: 1,
  class: "img",
  "data-cy": "defaultOval"
}
const _hoisted_4 = { class: "content small" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_checkMark = _resolveComponent("checkMark")!
  const _component_oval = _resolveComponent("oval")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.validated)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_checkMark)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_oval)
        ])),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("span", null, _toDisplayString(_ctx.message), 1)
    ])
  ]))
}