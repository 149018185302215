import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-56c4bd14"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "heading-sm" }
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_BaseWrapper = _resolveComponent("BaseWrapper")!

  return (_openBlock(), _createBlock(_component_BaseWrapper, { verticalCenter: true }, {
    default: _withCtx(() => [
      _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.$t('heading')), 1),
      _createVNode(_component_i18n_t, {
        keypath: "terms",
        tag: "p"
      }, {
        brandName: _withCtx(() => [
          _createElementVNode("span", null, _toDisplayString(_ctx.brandName), 1)
        ]),
        privacyLink: _withCtx(() => [
          _createElementVNode("a", {
            href: _ctx.privacyPolicyLink,
            target: "_blank"
          }, _toDisplayString(_ctx.$t('privacyPolicy')), 9, _hoisted_2)
        ]),
        _: 1
      }),
      _createVNode(_component_BaseButton, {
        class: "btn-ghost",
        "data-cy": "accept-button",
        type: "button",
        onClick: _ctx.acceptTerms
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('acceptButton')), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _: 1
  }))
}