import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-55f3a4ef"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "link-img" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Home = _resolveComponent("Home")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    class: "internal-link",
    to: _ctx.routerLinkLocation,
    onClick: _ctx.linkClick
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Home, { class: "link-svg" })
      ]),
      _createTextVNode(_toDisplayString(_ctx.internalLinkTitle), 1)
    ]),
    _: 1
  }, 8, ["to", "onClick"]))
}