
import { defineComponent } from 'vue';
import NavigationItems from './mixins/NavigationItems';

export default defineComponent({
  name: 'NavigationProfile',
  mixins: [NavigationItems],
  watch: {
    $route() {
      const routeName = this.$route.name;

      if (routeName) {
        const tab = this.$refs[this.$route.name] as HTMLElement[];
        if (tab && tab[0] && typeof tab[0].focus === 'function') {
          try {
            tab[0].focus();
          } catch (e) {
            //
          }
        }
      }
    }
  },
  methods: {
    tabClass(routeName: string) {
      return {
        'action-label': this.$route.name === routeName,
        'aria-selected': this.$route.name === routeName
      };
    },
    onNavigate(name: string): void {
      this.$router.push({ name });
    }
  }
});
