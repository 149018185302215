
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';

import { ActivitySlugResponse } from '@/types/ActivityTypes';
import { CrisisPlanState } from '@/types/CrisisTypes';

import ExploreTitle from '@/components/explore/ExploreTitle.vue';
import CrisisPlanCard from '@/components/home/CrisisPlanCard.vue';

export default defineComponent({
  name: 'HelpCrisis',
  components: {
    ExploreTitle,
    CrisisPlanCard
  },
  data() {
    return {
      crisisActivitySlug: 'recognize_and_reach_out',
      crisisCard: {
        type: 'CRISIS_PLAN',
        title: this.$t('crisisPlanCard.title'),
        description: this.$t('crisisPlanCard.description'),
        content: {
          crisisPlanState: null as null | CrisisPlanState,
          lastCompleted: null as null | Date
        }
      }
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getResponseHistory(this.crisisActivitySlug).then(
        (resp: { data: ActivitySlugResponse[] }) => {
          const responseData = resp.data;
          if (responseData && responseData.length > 0) {
            this.crisisCard.content.crisisPlanState =
              responseData[0].type === 'COMPLETED'
                ? CrisisPlanState.COMPLETE
                : CrisisPlanState.INCOMPLETE;

            this.crisisCard.content.lastCompleted = responseData[0].createdOn;
          }
        }
      );
    },
    ...mapActions(['getResponseHistory'])
  }
});
