
import { defineComponent } from 'vue';
import { mapGetters, mapMutations } from 'vuex';

import { OnboardingChapter } from '@/types/OnboardingTypes';
import { OnboardingStatus } from '@/types/UserTypes';

import BrandLogo from '@svg/brand-logo.svg';
import ProgressBar from '@/components/onboarding/ProgressBar.vue';
import Alert, { ALERT_TYPES } from '@common/Alert.vue';
import OnboardingRedirectService from '@/services/OnboardingRedirectService';

const progressBarCalculators = {
  [OnboardingChapter.Chapter1]: (page: number): number =>
    Number((Math.min(page / (10 * 3), 0.33) * 100).toFixed(2)),
  [OnboardingChapter.Chapter2]: (page: number): number =>
    Number((Math.min((page + 24) / (24 * 3), 0.66) * 100).toFixed(2)),
  [OnboardingChapter.Chapter3]: (page: number): number => {
    return Number((Math.min((4 * 2 + page) / (4 * 3), 1) * 100).toFixed(2));
  }
};

export default defineComponent({
  name: 'OnboardWrapper',
  components: {
    Alert,
    BrandLogo,
    ProgressBar
  },
  props: {
    chapter: {
      validator: (value) => {
        return Object.values(OnboardingChapter).includes(
          value as OnboardingChapter
        );
      },
      default: OnboardingChapter.Chapter1
    },
    page: {
      type: Number,
      default: null
    },
    showProgressBar: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      ALERT_TYPES
    };
  },
  computed: {
    progress(): number {
      const chapter = this.chapter as OnboardingChapter;
      return progressBarCalculators[chapter](this.page);
    },
    alertDestination(): string {
      return OnboardingRedirectService(this.$route, this.isOneApp) || '';
    },
    showAlert(): boolean {
      return !this.isCorrectChapter && !this.inReassessment && !!this.page;
    },
    ...mapGetters([
      'user',
      'isCorrectChapter',
      'cameFromAssessmentIntro',
      'inReassessment',
      'isOneApp'
    ])
  },
  watch: {
    user(user) {
      this.setIsCorrectChapter(this.getIsCorrectChapter(user.onboardingStatus));
    }
  },
  mounted() {
    this.setIsCorrectChapter(
      this.getIsCorrectChapter(this.user.onboardingStatus)
    );
  },
  methods: {
    getIsCorrectChapter(onboardingStatus: OnboardingStatus): boolean {
      switch (onboardingStatus) {
        case OnboardingStatus.INITIAL:
          return this.chapter === OnboardingChapter.Chapter1;
        case OnboardingStatus.CHAPTER1_COMPLETED:
          if (this.$route.name === 'SurveyOutro') {
            return !this.cameFromAssessmentIntro;
          }
          return this.chapter === OnboardingChapter.Chapter2;
        case OnboardingStatus.CHAPTER2_COMPLETED:
        case OnboardingStatus.CHAPTER3_READY:
          return this.chapter === OnboardingChapter.Chapter3;
        case OnboardingStatus.ONBOARDING_COMPLETED:
          return this.$route.name === 'OnboardingIneligible';
        default:
          return true;
      }
    },
    ...mapMutations(['setIsCorrectChapter'])
  }
});
