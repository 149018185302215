import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-29749da6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "error" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "heading-sm" }
const _hoisted_4 = { class: "text" }
const _hoisted_5 = {
  key: 0,
  class: "api-error-text"
}
const _hoisted_6 = ["href"]
const _hoisted_7 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      class: "icon",
      src: _ctx.iconPath,
      "aria-hidden": "true",
      alt: _ctx.$t(`${_ctx.translationKey}.title`)
    }, null, 8, _hoisted_2),
    _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t(`${_ctx.translationKey}.title`)), 1),
    _createElementVNode("p", _hoisted_4, [
      _createTextVNode(_toDisplayString(_ctx.$t(`${_ctx.translationKey}.text`)), 1),
      (_ctx.isAPIError)
        ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
            _createElementVNode("a", {
              class: "support-link",
              href: `mailto:memberSupport%40livongo.com?subject=${_ctx.$t('supportMailTo')}`
            }, _toDisplayString(_ctx.$t('API.memberSupport')), 9, _hoisted_6),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('API.text2')), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.status === '500')
      ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          class: "support-link-500",
          href: `mailto:memberSupport%40livongo.com?subject=${_ctx.$t('supportMailTo')}`
        }, _toDisplayString(_ctx.$t('500.memberSupportEmailAddress')), 9, _hoisted_7))
      : _createCommentVNode("", true)
  ]))
}