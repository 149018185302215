import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, withModifiers as _withModifiers, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, vShow as _vShow, withKeys as _withKeys, withDirectives as _withDirectives, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-14f26f6a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "button-row" }
const _hoisted_2 = ["aria-label"]
const _hoisted_3 = { class: "drawer-content" }
const _hoisted_4 = { class: "drawer-content-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconCloseCircle = _resolveComponent("IconCloseCircle")!

  return (_openBlock(), _createBlock(_Transition, {
    name: "fade",
    onAfterEnter: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setOverlayRendered()))
  }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", {
        class: _normalizeClass(["drawer-wrapper", `drawer-wrapper-${_ctx.drawerComponent}`]),
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.slideDrawerClose && _ctx.slideDrawerClose(...args))),
        onKeyup: _cache[3] || (_cache[3] = _withKeys(
//@ts-ignore
(...args) => (_ctx.slideDrawerClose && _ctx.slideDrawerClose(...args)), ["enter"])),
        role: "button",
        tabindex: "0"
      }, [
        _createVNode(_Transition, {
          name: "slide",
          onAfterLeave: _ctx.closeDrawer
        }, {
          default: _withCtx(() => [
            (_ctx.overlayRendered)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(["drawer-container", {'featuresMaxWidth': _ctx.showNewFeaturesLayout}]),
                  ref: "drawer",
                  tabindex: "-1",
                  onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
                }, [
                  _createElementVNode("div", _hoisted_1, [
                    (_ctx.showNewFeaturesLayout)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 0,
                          class: "drawer-close",
                          "aria-label": _ctx.$t('close'),
                          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.slideDrawerClose && _ctx.slideDrawerClose(...args)))
                        }, [
                          _createVNode(_component_IconCloseCircle)
                        ], 8, _hoisted_2))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _renderSlot(_ctx.$slots, "drawerModalContent", {}, undefined, true)
                    ])
                  ])
                ], 2))
              : _createCommentVNode("", true)
          ]),
          _: 3
        }, 8, ["onAfterLeave"])
      ], 34), [
        [_vShow, _ctx.drawerOpen]
      ])
    ]),
    _: 3
  }))
}