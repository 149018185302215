
import { defineComponent } from 'vue';
import { mapGetters, mapActions } from 'vuex';
import authSvc from '@/services/AuthService';

export default defineComponent({
  name: 'Logout',
  inject: ['$analyticsReset'],
  computed: {
    ...mapGetters(['isAuthenticated'])
  },
  created() {
    this.doLogout();
  },
  methods: {
    doLogout(): void {
      this.$analyticsReset();

      if (this.isAuthenticated) {
        this.logoutUser().finally(() => this.goToLogin());
      } else {
        this.goToLogin();
      }
    },
    goToLogin(): void {
      // Redirect to login once we verify cookie has been cleared
      const tokenInterval = setInterval(() => {
        if (!authSvc.hasAuthToken()) {
          clearInterval(tokenInterval);
          if (this.$router.currentRoute.name !== 'Login') {
            this.$router.push({ name: 'Login' });
          }
        }
      }, 25);
    },
    ...mapActions(['logoutUser'])
  }
});
