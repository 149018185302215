
import { Field, defineRule, FieldMeta } from 'vee-validate';

// the regex validates that a date is entered correctly, this is the same regex livongo uses
defineRule('validDate', (value: string) => {
  const regex =
    /((0[13578]|1[02])[/.]31[/.](18|19|20)[0-9]{2})|((01|0[3-9]|1[1-2])[/.](29|30)[/.](18|19|20)[0-9]{2})|((0[1-9]|1[0-2])[/.](0[1-9]|1[0-9]|2[0-8])[/.](18|19|20)[0-9]{2})|((02)[/.]29[/.](((18|19|20)(04|08|[2468][048]|[13579][26]))|2000))/g;
  const isValid = value.match(regex) !== null;
  if (!isValid) {
    return 'validDateMessage';
  }
  return isValid;
});

defineRule('validBirthDate', (value: string) => {
  const MAX_VALID_AGE = 150;
  const isValid =
    DateUtil.isValidDobString(value) &&
    DateUtil.getAgeFromDob(value) < MAX_VALID_AGE;
  if (!isValid) {
    return 'validBirthDateMessage';
  }
  return isValid;
});

defineRule('minimumAge', (value: string) => {
  const MIN_VALID_AGE = 13;
  if (DateUtil.getAgeFromDob(value) < MIN_VALID_AGE) {
    return 'minimumAgeMessage';
  }
  return true;
});

import { defineComponent } from 'vue';
import BaseInput from '@/components/base/mixin/BaseInput';
import InlineError from '@common/InlineError.vue';
import { DateUtil } from '@/helpers/DateUtil';

export default defineComponent({
  name: 'BaseInputText',
  components: {
    Field,
    InlineError
  },
  mixins: [BaseInput],
  data() {
    return {
      inputValue: '' as string
    };
  },
  watch: {
    inputModel(newValue) {
      this.inputValue = newValue;
    }
  },
  methods: {
    labelErrorClass({ valid }: FieldMeta<string>): string {
      return valid ? '' : 'is-invalid';
    },
    onKeyDown(e: KeyboardEvent) {
      if (
        [
          '0',
          '1',
          '2',
          '3',
          '4',
          '5',
          '6',
          '7',
          '8',
          '9',
          'Backspace',
          'Delete',
          'ArrowRight',
          'ArrowLeft',
          'Tab',
          '/',
          'End',
          'Home'
        ].indexOf(e.key) === -1
      ) {
        e.preventDefault();
      }
    }
  }
});
