export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your session is about to expire"])},
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You'll be logged out of ", _interpolate(_named("brandName")), " in less than one minute due to inactivity."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])}
    }
  })
}
