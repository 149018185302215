
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'NewFeatures',
  data() {
    return {
      features: [
        'home',
        'explore',
        'sleep',
        'assessments',
        'trackers',
        'favorites',
        'history',
        'reminders'
      ]
    };
  },
  computed: {
    featureCount(): number {
      return this.features.length;
    }
  }
});
