
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BaseButton',
  emits: ['click', 'focus'],
  props: {
    disabled: {
      type: Boolean,
      default: null
    },
    size: {
      type: String,
      required: false,
      default: null
    },
    // To be deprecated
    // - Instead use classes directly on components
    type: {
      type: String,
      default: 'primary',
      required: false
    },
    ariaLabel: {
      type: String,
      required: false,
      default: null
    }
  },
  computed: {
    buttonClass(): Record<string, boolean> {
      return {
        // TO BE deprecated
        // - Instead use classes directly on components
        'base-btn-primary': this.type == 'primary',
        'base-btn-solid-primary': this.type == 'solid-primary',
        'base-btn-secondary': this.type == 'secondary',
        'base-btn-lg': this.size == 'lg',
        'base-btn-sm': this.size == 'sm',
        'base-btn-icon': this.type == 'icon',
        'base-btn-plain': this.type == 'plain'
      };
    }
  },
  methods: {
    handleClick($event: Event) {
      if (!this.disabled) {
        // buttons are not automatically focused on click in safari
        const button = this.$refs.button as HTMLElement;
        button?.focus();
        this.$emit('click', $event);
      }
    }
  }
});
