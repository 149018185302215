
import { defineComponent } from 'vue';
import { mapGetters, mapActions } from 'vuex';

import NavigationItems from '@/components/navigation/mixins/NavigationItems';
import NavigationList from '@/components/navigation/NavigationList.vue';

import MenuIcon from '@svg/navigation/menu.svg';
import ChevronDown from '@svg/navigation/chevron-down.svg';
import BrandLogoIcon from '@svg/brand-logo-icon.svg';
import RedBadgeCircle from '@svg/red-badge-circle.svg';
import OneApp from '@/components/navigation/mixins/OneApp';
import IconArrowLeft from '@svg/icon-arrow-left.svg';

export default defineComponent({
  name: 'NavigationTop',
  components: {
    MenuIcon,
    ChevronDown,
    BrandLogoIcon,
    RedBadgeCircle,
    IconArrowLeft,
    NavigationList
  },
  mixins: [NavigationItems, OneApp],
  data() {
    return {
      showNav: false,
      resizeTimeout: undefined as number | undefined
    };
  },
  computed: {
    firstName(): string {
      return this.user?.firstName ? this.user.firstName : '';
    },
    coachName(): string {
      return 'Amy';
    },
    hasGuide(): boolean {
      return this.user?.coachingAssignment != null;
    },
    guideImageUrl(): string {
      return this.user?.coachingAssignment?.profileImageUrl || '';
    },
    ...mapGetters([
      'user',
      'unreadMessages',
      'drawerOpen',
      'isGuideMessageOnlyView'
    ])
  },
  watch: {
    $route(value) {
      this.showNav = false;

      if (this.hasGuide && value.query?.guideOpen) {
        this.showGuideDrawer();
      }
    }
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
    clearTimeout(this.resizeTimeout);
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);

    if (this.hasGuide && this.$route.query?.guideOpen) {
      this.showGuideDrawer();
    }
  },
  methods: {
    toggleNav(): void {
      this.showNav = !this.showNav;

      if (this.showNav) {
        this.blockMobileBodyScroll();
      } else {
        this.unblockMobileBodyScroll();
      }
    },
    showGuideDrawer(): void {
      this.setDrawerComponent('Guide');
      this.openDrawer();
    },
    toggleGuideDrawer() {
      this.setDrawerComponent('Guide');
      this.toggleDrawer();
    },
    handleResize(): void {
      if (this.resizeTimeout) {
        clearTimeout(this.resizeTimeout);
      }

      this.resizeTimeout = setTimeout(() => {
        this.unblockMobileBodyScroll();
        this.showNav = false;
      }, 100);
    },
    ...mapActions([
      'blockBodyScroll',
      'unblockBodyScroll',
      'openDrawer',
      'toggleDrawer',
      'setDrawerComponent',
      'blockMobileBodyScroll',
      'unblockMobileBodyScroll'
    ])
  }
});
