
import { defineComponent } from 'vue';
import BaseButton from '@/components/base/BaseButton.vue';

export default defineComponent({
  name: 'OneAppError',
  components: {
    BaseButton
  },
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: false,
      default: null
    },
    buttonLabel: {
      type: String,
      required: false,
      default: null
    },
    navigate: {
      type: String,
      required: false,
      default: null
    },
    emailAddress: {
      type: String,
      require: false,
      default: null
    },
    emailText: {
      type: String,
      require: false,
      default: null
    }
  },
  computed: {
    email(): string {
      return `mailto:${this.emailAddress}`;
    }
  }
});
