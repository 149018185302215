export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "es",
    "resource": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selector de destino"])},
      "subHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elija su destino de la lista a continuación:"])},
      "destinationListLabels": {
        "USERS": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["myStrength aplicación"])}
        ],
        "CONSOLE": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Dashboard"])}
        ],
        "COACH": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coach Dashboard"])}
        ],
        "PARTNER": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partner API"])}
        ],
        "ADMIN": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AdminV2"])}
        ],
        "CONTENT_READ_ONLY": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AdminV2"])}
        ],
        "SYSTEM_MANAGEMENT": [
          
        ],
        "REFERRAL_FORMS": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formularios de referencia"])}
        ]
      },
      "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar sesión"])}
    }
  })
}
