export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "es",
    "resource": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recursos de ayuda"])},
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si actualmente está pensando o planea hacerse daño a sí mismo o a otra persona, por favor "])},
      "call911": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["llama al 911"])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([", vaya a la sala de emergencias del hospital más cercano o llame a la línea de ayuda para casos de crisis y suicidio al "])},
      "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  o al "])}
    }
  })
}
