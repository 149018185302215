
import { defineComponent } from 'vue';

import BrandLogo from '@svg/brand-logo.svg';

export default defineComponent({
  name: 'BaseWrapper',
  components: {
    BrandLogo
  },
  props: {
    logoDisplay: {
      type: Boolean,
      default: true,
      required: false
    },
    verticalCenter: {
      type: Boolean,
      required: false,
      default: false
    },
    size: {
      type: String,
      required: false,
      default: 'md'
    }
  },
  computed: {
    wrapperClass(): { [key: string]: boolean } {
      return {
        'vertical-center': this.verticalCenter,
        'base-wrapper-xs': this.size === 'xs',
        'base-wrapper-sm': this.size === 'sm',
        'base-wrapper-md': this.size === 'md',
        'base-wrapper-lg': this.size === 'lg'
      };
    }
  }
});
