
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import PathLookupService from '@/services/PathLookupService';
import authSvc from '@/services/AuthService';

export default defineComponent({
  name: 'UpgradeRegistration',
  data() {
    return {
      isWaitingForMigrationResponse: false
    };
  },
  computed: {
    ...mapGetters(['migrationRegistrationURL', 'migrationRegistrationStatus'])
  },
  methods: {
    livongoRegistration() {
      if (this.isWaitingForMigrationResponse) {
        return;
      }
      this.isWaitingForMigrationResponse = true;
      this.initializeLivongoRegistration()
        .then(
          () => {
            if (this.migrationRegistrationStatus == 'USER_ALREADY_EXISTS') {
              this.logoutUser().finally(() => this.goToLogin());
            } else if (this.migrationRegistrationURL) {
              this.logoutUser().finally(() => {
                PathLookupService.goToURL(this.migrationRegistrationURL);
              });
            }
          },
          (err: {
            response: { data: { errorCode: string; errorCoode: string } };
          }) => {
            if (
              err.response.data.errorCode == 'MIGRATION_REGISTRATION_FAILED'
            ) {
              this.addErrorNotification({
                heading: this.$t('errorHeading'),
                description: this.$t('migrationRegistrationErrorMessage')
              });
            } else if (
              err.response.data.errorCoode == 'MIGRATION_ACCESSCODE_MISMATCH'
            ) {
              this.addErrorNotification({
                heading: this.$t('errorHeading'),
                description: this.$t('accessCodeMismatchErrorMessage')
              });
            }
          }
        )
        .finally(() => (this.isWaitingForMigrationResponse = false));
    },
    goToLogin(): void {
      // Redirect to login once we verify cookie has been cleared
      const tokenInterval = setInterval(() => {
        if (!authSvc.hasAuthToken()) {
          clearInterval(tokenInterval);
          this.$router.push({
            name: 'Login',
            query: { upgradeComplete: 'true' }
          });
        }
      }, 25);
    },
    ...mapActions([
      'initializeLivongoRegistration',
      'logoutUser',
      'addErrorNotification'
    ])
  }
});
