import { mapGetters } from 'vuex';
import { defineComponent } from 'vue';
import OneAppService from '@/services/OneAppService';

type NavItem = {
  icon: string;
  routeName: string;
  i18nLabel: string;
  displayTab?: boolean;
};
type NavData = {
  navItems: Array<NavItem>;
  profileItems: Array<NavItem>;
  showNav: boolean;
};

export default defineComponent({
  data() {
    return {} as NavData;
  },
  computed: {
    oneAppLabel(): string {
      return `oneAppLabel.${this.$route.name}`;
    },
    oneAppNavOpen(): Record<string, boolean> {
      return {
        'nav-open': this.showNav
      };
    },
    ...mapGetters(['isOneApp', 'user'])
  },
  mounted() {
    if (this.isOneApp) {
      this.navItems = [
        {
          icon: 'HomeIcon',
          routeName: 'Home',
          i18nLabel: 'oneApp.home'
        },
        {
          icon: 'ConnectIcon',
          routeName: 'Connect',
          i18nLabel: 'connect'
        },
        {
          icon: 'ExploreIcon',
          routeName: 'Explore',
          i18nLabel: 'explore'
        },
        {
          icon: 'PreferencesIcon',
          routeName: 'Personalization',
          i18nLabel: 'oneApp.personalization',
          displayTab: true
        },
        {
          icon: 'ResourcesIcon',
          routeName: 'HelpResources',
          i18nLabel: 'oneApp.helpResource'
        },
        {
          icon: 'HistoryIcon',
          routeName: 'History',
          i18nLabel: 'history',
          displayTab: true
        }
      ];
      if (this.user?.coachingAssignment != null) {
        this.navItems.splice(2, 0, {
          icon: 'GuideIcon',
          routeName: 'Guide',
          i18nLabel: 'oneApp.guide'
        });
      }

      this.profileItems = [];
    }
  },
  methods: {
    onMobileClick(event: Event, platform: 'android' | 'iOS'): boolean {
      if (!this.isOneApp) {
        return true;
      }
      event.stopPropagation();
      event.preventDefault();
      const payload =
        platform === 'android'
          ? 'https://play.google.com/store/apps/details?id=com.teladoc.members&hl=en_US&gl=US'
          : 'https://apps.apple.com/us/app/teladoc-telehealth-therapy/id656872607';
      OneAppService.sendRedirectEvent(payload);
      return false;
    }
  }
});
