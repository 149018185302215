export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "es",
    "resource": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu plan de crisis"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reconozca su propia angustia y aprenda estrategias para obtener la ayuda que necesita y la atención que merece."])},
      "history": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Respuestas guardadas ", _interpolate(_named("date"))])},
      "view_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver plan de crisis"])},
      "create_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear plan de crisis"])},
      "dismiss_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Despedir"])}
    }
  })
}
