import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-00a7e240"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "tracker-content" }
const _hoisted_3 = { class: "heading heading-sm" }
const _hoisted_4 = { class: "small" }
const _hoisted_5 = { class: "options" }
const _hoisted_6 = { class: "face-label face-label-1" }
const _hoisted_7 = { class: "face-label" }
const _hoisted_8 = { class: "face-label" }
const _hoisted_9 = { class: "face-label" }
const _hoisted_10 = { class: "face-label face-label-4" }
const _hoisted_11 = {
  key: 0,
  class: "done-btn"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconFace0 = _resolveComponent("IconFace0")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_IconFace1 = _resolveComponent("IconFace1")!
  const _component_IconFace2 = _resolveComponent("IconFace2")!
  const _component_IconFace3 = _resolveComponent("IconFace3")!
  const _component_IconFace4 = _resolveComponent("IconFace4")!
  const _component_ProgressIcon = _resolveComponent("ProgressIcon")!
  const _component_CheckmarkCircleIcon = _resolveComponent("CheckmarkCircleIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.description), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", {
        class: _normalizeClass(["faces", {'unselectedMood': _ctx.selectedMood === null}])
      }, [
        _createVNode(_component_BaseButton, {
          class: _normalizeClass(["base-btn-plain", {'selectedMood': _ctx.selectedMood === 0}]),
          role: "switch",
          "aria-checked": _ctx.selectedMood === 0 ? 'true' : 'false',
          "aria-label": _ctx.$t('faceLabels[0]'),
          disabled: _ctx.submitted,
          "data-cy": "Mood-0",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectMood(0)))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_IconFace0),
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('faceLabels[0]')), 1)
          ]),
          _: 1
        }, 8, ["aria-checked", "class", "aria-label", "disabled"]),
        _createVNode(_component_BaseButton, {
          class: _normalizeClass(["base-btn-plain", {'selectedMood': _ctx.selectedMood === 1}]),
          role: "switch",
          "aria-checked": _ctx.selectedMood === 1 ? 'true' : 'false',
          "aria-label": _ctx.$t('faceLabels[1]'),
          disabled: _ctx.submitted,
          "data-cy": "Mood-1",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectMood(1)))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_IconFace1),
            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('faceLabels[1]')), 1)
          ]),
          _: 1
        }, 8, ["aria-checked", "class", "aria-label", "disabled"]),
        _createVNode(_component_BaseButton, {
          class: _normalizeClass(["base-btn-plain", {'selectedMood': _ctx.selectedMood === 2}]),
          role: "switch",
          "aria-checked": _ctx.selectedMood === 2 ? 'true' : 'false',
          "aria-label": _ctx.$t('faceLabels[2]'),
          disabled: _ctx.submitted,
          "data-cy": "Mood-2",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.selectMood(2)))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_IconFace2),
            _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('faceLabels[2]')), 1)
          ]),
          _: 1
        }, 8, ["aria-checked", "class", "aria-label", "disabled"]),
        _createVNode(_component_BaseButton, {
          class: _normalizeClass(["base-btn-plain", {'selectedMood': _ctx.selectedMood === 3}]),
          role: "switch",
          "aria-checked": _ctx.selectedMood === 3 ? 'true' : 'false',
          "aria-label": _ctx.$t('faceLabels[3]'),
          disabled: _ctx.submitted,
          "data-cy": "Mood-3",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.selectMood(3)))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_IconFace3),
            _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('faceLabels[3]')), 1)
          ]),
          _: 1
        }, 8, ["aria-checked", "class", "aria-label", "disabled"]),
        _createVNode(_component_BaseButton, {
          class: _normalizeClass(["base-btn-plain", {'selectedMood': _ctx.selectedMood === 4}]),
          role: "switch",
          "aria-checked": _ctx.selectedMood === 4 ? 'true' : 'false',
          "aria-label": _ctx.$t('faceLabels[4]'),
          disabled: _ctx.submitted,
          "data-cy": "Mood-4",
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.selectMood(4)))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_IconFace4),
            _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('faceLabels[4]')), 1)
          ]),
          _: 1
        }, 8, ["aria-checked", "class", "aria-label", "disabled"])
      ], 2)
    ]),
    (_ctx.selectedMood !== null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          (!_ctx.loading && !_ctx.submitted)
            ? (_openBlock(), _createBlock(_component_BaseButton, {
                key: 0,
                type: "solid-primary",
                onClick: _ctx.submitMood,
                "data-cy": "SubmitMoodBtn"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('done')), 1)
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true),
          (_ctx.loading)
            ? (_openBlock(), _createBlock(_component_ProgressIcon, {
                key: 1,
                class: "loading-icon"
              }))
            : _createCommentVNode("", true),
          (_ctx.submitted)
            ? (_openBlock(), _createBlock(_component_CheckmarkCircleIcon, {
                key: 2,
                class: "success-icon"
              }))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}