import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-40b3d48b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input-group checkbox" }
const _hoisted_2 = ["for", "data-cy"]
const _hoisted_3 = ["id", "name", "aria-invalid", "data-cy", "disabled"]
const _hoisted_4 = { class: "check-mark input" }
const _hoisted_5 = { class: "label" }
const _hoisted_6 = {
  key: 0,
  class: "checkbox-tooltip-wrapper"
}
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CheckMarkFilledSquare = _resolveComponent("CheckMarkFilledSquare")!
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_InlineError = _resolveComponent("InlineError")!
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Field, {
      ref: "field",
      modelValue: _ctx.inputValue,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.inputValue) = $event)),
      name: _ctx.inputName,
      rules: _ctx.validationRules,
      validateOnMount: false
    }, {
      default: _withCtx(({ field, meta, errors }) => [
        _createElementVNode("label", {
          for: _ctx.computedInputId,
          "data-cy": `${_ctx.dataCy}-label`
        }, [
          _createElementVNode("input", _mergeProps(field, {
            type: "checkbox",
            ref: "baseInputCheckbox",
            id: _ctx.computedInputId,
            name: _ctx.inputName,
            "aria-invalid": !meta.valid,
            class: _ctx.inputClass(meta),
            "data-cy": _ctx.dataCy,
            disabled: _ctx.disabled,
            onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:inputModel', $event.target.checked)))
          }), null, 16, _hoisted_3),
          _createElementVNode("div", _hoisted_4, [
            (_ctx.isChecked)
              ? (_openBlock(), _createBlock(_component_CheckMarkFilledSquare, {
                  key: 0,
                  class: "icon-check"
                }))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _renderSlot(_ctx.$slots, "custom-label", {}, () => [
              _createTextVNode(_toDisplayString(_ctx.labelText), 1)
            ], true)
          ]),
          (_ctx.tooltipHTML)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_component_Tooltip, { class: "checkbox-tooltip" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      class: "content",
                      innerHTML: _ctx.tooltipHTML
                    }, null, 8, _hoisted_7)
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true)
        ], 8, _hoisted_2),
        (errors[0] && meta.touched)
          ? _renderSlot(_ctx.$slots, "error-message", { key: 0 }, () => [
              (errors[0] && _ctx.displayValidationError)
                ? (_openBlock(), _createBlock(_component_InlineError, {
                    key: 0,
                    "data-cy": `${_ctx.dataCy}-errors`,
                    message: errors[0]
                  }, null, 8, ["data-cy", "message"]))
                : _createCommentVNode("", true)
            ], true)
          : _createCommentVNode("", true)
      ]),
      _: 3
    }, 8, ["modelValue", "name", "rules"])
  ]))
}