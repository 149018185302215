import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f57f47b4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "phone-input-country" }
const _hoisted_2 = ["name", "inputModel"]
const _hoisted_3 = {
  value: "",
  disabled: ""
}
const _hoisted_4 = ["value"]
const _hoisted_5 = { class: "phone-country-input-icon" }
const _hoisted_6 = ["alt", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChevronDown = _resolveComponent("ChevronDown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("select", {
      class: "phone-input-country-select",
      "data-test": "phone-input-country",
      "aria-label": "$t('Select')",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
      name: _ctx.inputName,
      inputModel: _ctx.countryCode,
      "on:update:inputModel": _cache[1] || (_cache[1] = ($event: any) => (_ctx.countryCode = $event)),
      onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.updateModel && _ctx.updateModel(...args)))
    }, [
      _createElementVNode("option", _hoisted_3, _toDisplayString(_ctx.$t('Select')), 1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countries, (country) => {
        return (_openBlock(), _createElementBlock("option", {
          key: country.value,
          value: country.code
        }, _toDisplayString(country.value), 9, _hoisted_4))
      }), 128))
    ], 40, _hoisted_2), [
      [_vModelSelect, _ctx.inputValue]
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("img", {
        class: "phone-country-input-icon-img",
        alt: _ctx.countryName,
        src: _ctx.flag
      }, null, 8, _hoisted_6)
    ]),
    _createVNode(_component_ChevronDown, { class: "down-icon" })
  ]))
}