// Vee-validate config
import { configure as veeConfigure } from 'vee-validate';
import { localize, setLocale as veeSetLocale } from '@vee-validate/i18n';

import veeEn from '@vee-validate/i18n/dist/locale/en.json';
import veeEs from '@vee-validate/i18n/dist/locale/es.json';

import customEn from '@/locale/en/veeValidate';
import customEs from '@/locale/es/veeValidate';

export const runVeeConfiguration = () => {
  // Add default localized messages
  veeConfigure({
    generateMessage: localize({
      en: veeEn,
      es: veeEs
    })
  });

  // Add custom rule localized messages
  // Consider something more robust if more locales are added
  veeConfigure({
    generateMessage: localize('en', {
      messages: customEn
    })
  });

  veeConfigure({
    generateMessage: localize('es', {
      messages: customEs
    })
  });

  // Set default locale
  veeSetLocale('en');
};
