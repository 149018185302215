import {
  AsYouType,
  CountryCode,
  parsePhoneNumberFromString,
  PhoneNumber
} from 'libphonenumber-js';

export function intl(phone: string, country: CountryCode = 'US') {
  return new AsYouType(country).input(phone);
}

export function parse(
  phone: string,
  country: CountryCode = 'US'
): PhoneNumber | null {
  return parsePhoneNumberFromString(phone, country) || null;
}

export function getCountryCallingCode(
  country: CountryCode = 'US'
): PhoneNumber | null {
  return parsePhoneNumberFromString('111', country) || null;
}
