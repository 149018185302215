import { RootState } from '@/types/storeTypes';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import apiRoutes from '@config/api-routes';
import HTTP from '@/services/HttpService';

export interface SurveyState {
  survey: {
    intro: {
      title: string | null;
      text: string | null;
    };
    firstQuestionId: number;
    textForNoneAnswers: string;
    questionFlow: {
      [index: number]: QuestionNode;
    };
    questionDefinitions: {
      [index: string]: QuestionDefinition;
    };
    answerDefinitions: {
      [index: string]: AnswerDefinition;
    };
  };
  surveyResponse: SurveyResponse;
  highAcuity: boolean;
}

export interface SurveyQuestionResponse {
  currentQuestionId: number;
  currentSelectedAnswers: Array<string>;
}

export interface SurveyResponse {
  [questionId: number]: Array<string>;
}

export interface QuestionNode {
  questionData: string;
  questionType: QuestionType;
  nextQuestion?: number | null;
  branches?: Branch;
  condition?: Condition;
  shouldShowNone?: boolean;
  layout: QuestionLayout;
}

// alphabetical listing
export enum QuestionType {
  BRANCHING = 'BRANCHING',
  CONDITIONAL = 'CONDITIONAL',
  STANDARD = 'STANDARD'
}

export enum QuestionLayout {
  GRID_VIEW = 'GRID_VIEW',
  TABLE_VIEW = 'TABLE_VIEW'
}

export enum AnswerIcon {
  NONE = 'NONE',
  LEAF = 'LEAF',
  SUN = 'SUN',
  MOON = 'MOON',
  HEART = 'HEART',
  UMBRELLA = 'UMBRELLA',
  CIRCLE = 'CIRCLE'
}

export enum ConditionType {
  COMPLAINT,
  PREFERENCE
}

export enum AssociationType {
  COMPLAINT = 'COMPLAINT',
  PREFERENCE = 'PREFERENCE'
}

export interface Branch {
  readonly [index: string]: number;
}

export interface Association {
  type: AssociationType | null;
  value: string | null;
}

export interface Condition {
  type: ConditionType;
  value: string;
}

export interface QuestionDefinition {
  prompt: string;
  answers: Array<string>;
}

export interface AnswerDefinition {
  answerText: string;
  association: Association;
  icon: AnswerIcon;
}

function removeUnansweredSurveyQuestions(state: SurveyState) {
  const sanitizedResponse = { ...state.surveyResponse };

  Object.keys(sanitizedResponse).forEach((surveyNumberStr: string) => {
    const surveyNumber = Number(surveyNumberStr);
    const answerArray = sanitizedResponse[surveyNumber];
    const { questionType } = state.survey.questionFlow[surveyNumber];
    if (
      (!answerArray || !answerArray.length) &&
      questionType === QuestionType.CONDITIONAL
    ) {
      delete sanitizedResponse[Number(surveyNumber)];
    }
  });

  return sanitizedResponse;
}

const state: SurveyState = {
  survey: {
    intro: {
      title: null,
      text: null
    },
    firstQuestionId: 1,
    textForNoneAnswers: 'None of these',
    questionFlow: {
      1: {
        questionData: 'PNQ2',
        questionType: QuestionType.STANDARD,
        nextQuestion: 2,
        layout: QuestionLayout.TABLE_VIEW
      },
      2: {
        questionData: 'PNQ4',
        questionType: QuestionType.STANDARD,
        nextQuestion: 3,
        layout: QuestionLayout.TABLE_VIEW
      },
      3: {
        questionData: 'PNQ3',
        questionType: QuestionType.STANDARD,
        nextQuestion: null,
        layout: QuestionLayout.TABLE_VIEW
      }
    },
    questionDefinitions: {
      PNQ2: {
        prompt: "What's on your mind?",
        answers: ['PNQ2:1', 'PNQ2:2', 'PNQ2:3']
      }
    },
    answerDefinitions: {
      'PNQ2:1': {
        answerText: 'Reduce stress',
        association: {
          type: null,
          value: null
        },
        icon: AnswerIcon.NONE
      },
      'PNQ2:2': {
        answerText: 'Improve mood',
        association: {
          type: null,
          value: null
        },
        icon: AnswerIcon.NONE
      },
      'PNQ2:3': {
        answerText: 'Something else',
        association: {
          type: null,
          value: null
        },
        icon: AnswerIcon.NONE
      }
    }
  },
  surveyResponse: {},
  highAcuity: false
};

const getters: GetterTree<SurveyState, RootState> = {
  surveyIntro(state: SurveyState): SurveyState['survey']['intro'] {
    return state.survey.intro;
  },
  survey(state: SurveyState): SurveyState['survey'] {
    return state.survey;
  },
  surveyFirstQuestionId(
    state: SurveyState
  ): SurveyState['survey']['firstQuestionId'] {
    return state.survey.firstQuestionId;
  },
  surveyTextForNoneAnswers(
    state: SurveyState
  ): SurveyState['survey']['textForNoneAnswers'] {
    return state.survey.textForNoneAnswers;
  },
  selectedAnswers(state: SurveyState): SurveyState['surveyResponse'] {
    return state.surveyResponse;
  },
  getQuestion:
    (state: SurveyState) =>
    (questionId: number): QuestionNode => {
      return state.survey.questionFlow[questionId];
    },
  getCurrentQuestionDefinition:
    (state: SurveyState) =>
    (questionName: QuestionNode['questionData']): QuestionDefinition => {
      return state.survey.questionDefinitions[questionName];
    },
  getAnswerDefinition:
    (state: SurveyState) =>
    (answerName: string): AnswerDefinition => {
      return state.survey.answerDefinitions[answerName];
    },
  getSelectedAnswers:
    (state: SurveyState) =>
    (questionId: number): Array<string> => {
      return state.surveyResponse[questionId] || [];
    },
  highAcuity: (state: SurveyState): boolean => {
    return state.highAcuity;
  }
};

const actions: ActionTree<SurveyState, RootState> = {
  fetchSurvey({ commit }): Promise<unknown> {
    return HTTP.get<{ data: SurveyState }>(apiRoutes.onboarding.survey).then(
      (response) => {
        commit('setSurvey', response.data.data);
      }
    );
  },
  postSurveyResponse({ commit, state }): Promise<unknown> {
    return HTTP.post<
      { answers: { [x: number]: string[] } },
      { data: { highAcuity: boolean } }
    >(apiRoutes.onboarding.surveyResponse, {
      answers: removeUnansweredSurveyQuestions(state)
    }).then((response) => {
      commit('setAcuity', response.data.highAcuity);
      commit('clearSurveyResponses');
    });
  }
};

const mutations: MutationTree<SurveyState> = {
  setSurvey(state: SurveyState, survey: SurveyState['survey']): void {
    state.survey = survey;
  },
  setSurveyResponse(
    state: SurveyState,
    response: SurveyQuestionResponse
  ): void {
    state.surveyResponse[response.currentQuestionId] =
      response.currentSelectedAnswers;
  },
  setAcuity(state: SurveyState, highAcuity: boolean): void {
    state.highAcuity = highAcuity;
  },
  clearSurveyResponses(state: SurveyState): void {
    state.surveyResponse = {};
  }
};

export const surveyStore: Module<SurveyState, RootState> = {
  state,
  getters,
  actions,
  mutations
};

export default surveyStore;
