import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BackArrow = _resolveComponent("BackArrow")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    class: _normalizeClass(["btn-back btn-icon", { disabled: _ctx.disabled, 'btn-icon-highlight': _ctx.highlighted}]),
    "data-cy": "backButton",
    "aria-label": _ctx.$t('buttons.back'),
    "aria-disabled": _ctx.disabled,
    to: _ctx.backPath,
    onClick: _ctx.emitEvents
  }, {
    default: _withCtx(() => [
      _createVNode(_component_BackArrow, {
        class: "dark-arrow",
        "aria-hidden": "true"
      })
    ]),
    _: 1
  }, 8, ["class", "aria-label", "aria-disabled", "to", "onClick"]))
}