
/*************************** Session Timer *******************************\
 * This components job is to track user inactivity and log the user out
 * after 15 minutes of inactivity. It does this by keeping track of
 * the keydown and pointermove browser events using a debounced RxJS
 * observer. Whenever either of those events is detected, it will reset
 * the timer. At the end of the 14 minute timer, it pops up a modal
 * which will countdown from 60. Then it will send the user to the
 * logout page.
 \************************************************************************/

import { defineComponent } from 'vue';
import { debounceTime } from 'rxjs/operators';
import { fromEvent } from 'rxjs/observable/fromEvent';
import { Subscription } from 'rxjs/Subscription';

const DEFAULT_TIMEOUT = 60000 * 14; // 14 minutes
const EXTENDED_TIMEOUT = 60000 * 60; // 60 minutes
const FINAL_TIMEOUT = 60; // 60 seconds

const EXTENDED_TIMEOUT_ROUTE_NAMES = [
  'Conference',
  'ClinicalPortalConference',
  'Teletherapy'
];

export default defineComponent({
  name: 'SessionTimer',
  data() {
    return {
      showModal: false,
      finalTimerCount: null as number | null,
      modalTimerId: null as number | null,
      sessionTimerId: null as number | null,
      debounceSubscriptions: [] as Array<Subscription>
    };
  },
  computed: {
    isInExtendedRoute(): boolean {
      const routeName = this.$route.name || null;

      if (!routeName) {
        return false;
      }

      return EXTENDED_TIMEOUT_ROUTE_NAMES.includes(routeName);
    },
    inactivityTimeout(): number {
      if (this.$route.query?.inactivityTimeout) {
        return Number(this.$route.query.inactivityTimeout);
      }

      return this.isInExtendedRoute ? EXTENDED_TIMEOUT : DEFAULT_TIMEOUT;
    }
  },
  watch: {
    finalTimerCount: {
      handler(value) {
        if (value === null) {
          return;
        }

        if (value === 0) {
          this.showModal = false;
          this.$router.push({ name: 'Logout' });
        }

        if (value > 0) {
          if (this.modalTimerId) {
            clearTimeout(this.modalTimerId);
          }

          this.modalTimerId = setTimeout(() => {
            if (this.finalTimerCount) {
              this.finalTimerCount = this.finalTimerCount - 1;
            }
          }, 1000);
        }
      }
    },
    $route() {
      this.reset();
    }
  },
  mounted() {
    this.init();
  },
  beforeUnmount() {
    this.debounceSubscriptions.forEach(
      (subscription: { unsubscribe: () => void }) => subscription.unsubscribe()
    );
  },
  methods: {
    init() {
      this.debounceSubscriptions.push(
        this.addDebouncedEventListener('pointermove')
      );
      this.debounceSubscriptions.push(
        this.addDebouncedEventListener('keydown')
      );

      this.sessionTimerId = setTimeout(this.openModal, this.inactivityTimeout);
    },
    openModal() {
      this.showModal = true;
      this.finalTimerCount = FINAL_TIMEOUT;
    },
    reset() {
      if (this.sessionTimerId) {
        clearTimeout(this.sessionTimerId);
      }
      this.sessionTimerId = setTimeout(this.openModal, this.inactivityTimeout);
    },
    addDebouncedEventListener(event: string): Subscription {
      const clicks = fromEvent(document, event);
      const result = clicks.pipe(debounceTime(200));
      return result.subscribe(() => {
        if (!this.showModal) {
          this.reset();
        }
      });
    },
    closeModal() {
      if (this.modalTimerId) {
        clearTimeout(this.modalTimerId);
      }
      this.showModal = false;
      this.finalTimerCount = null;
      this.reset();
    }
  }
});
