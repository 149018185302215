export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "modal": {
        "buttonAriaLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity navigation choices"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What would you like to do?"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can retake this activity from scratch or view it with your previously saved answers."])},
        "retakeActivityButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retake activity"])},
        "viewSavedAnswersButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View saved answers"])}
      }
    }
  })
}
