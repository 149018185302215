import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f422a946"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "modal-content",
  "data-cy": "guide-modal"
}
const _hoisted_2 = { class: "heading-sm" }
const _hoisted_3 = { class: "message-header" }
const _hoisted_4 = ["aria-label"]
const _hoisted_5 = { class: "guide-header" }
const _hoisted_6 = { class: "guide-image" }
const _hoisted_7 = ["src", "alt"]
const _hoisted_8 = { class: "header-message" }
const _hoisted_9 = {
  key: 1,
  class: "profile",
  "data-cy": "guide-profile"
}
const _hoisted_10 = {
  key: 2,
  class: "intro-message",
  "data-cy": "guide-intro"
}
const _hoisted_11 = { class: "button-container" }
const _hoisted_12 = {
  class: "message-container",
  "data-cy": "guide-message-container"
}
const _hoisted_13 = { class: "message-inner-container" }
const _hoisted_14 = {
  key: 0,
  class: "error"
}
const _hoisted_15 = ["innerHTML"]
const _hoisted_16 = {
  key: 0,
  class: "message-attachments"
}
const _hoisted_17 = {
  key: 0,
  class: "coaching-message-activity-link"
}
const _hoisted_18 = ["onClick", "onKeyup", "data-cy"]
const _hoisted_19 = ["src", "alt"]
const _hoisted_20 = ["href", "onClick", "onKeyup"]
const _hoisted_21 = { class: "link-img" }
const _hoisted_22 = {
  key: 1,
  class: "guide-signature"
}
const _hoisted_23 = { class: "guide-image" }
const _hoisted_24 = ["src", "alt"]
const _hoisted_25 = { class: "date" }
const _hoisted_26 = {
  key: 2,
  class: "user-signature"
}
const _hoisted_27 = { class: "user-image" }
const _hoisted_28 = { class: "date" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconCloseCircle = _resolveComponent("IconCloseCircle")!
  const _component_BaseInputTextarea = _resolveComponent("BaseInputTextarea")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_AttachmentInternalLink = _resolveComponent("AttachmentInternalLink")!
  const _component_HomeIcon = _resolveComponent("HomeIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["guide-container", { 'is-guide-message-only-view': _ctx.isGuideMessageOnlyView || _ctx.isMobileWebView }]),
    "data-cy": "guide-container"
  }, [
    _createVNode(_Transition, {
      name: "fade",
      duration: "200"
    }, {
      default: _withCtx(() => [
        (_ctx.showMessageSentModal)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "coaching-message-sent-modal",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.hideMessageSentModal && _ctx.hideMessageSentModal(...args))),
              onKeyup: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.hideMessageSentModal && _ctx.hideMessageSentModal(...args)), ["enter"])),
              role: "button",
              tabindex: "0"
            }, [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('messageSent')), 1),
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('messageSentDescription')), 1)
              ])
            ], 32))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_3, [
      (!(_ctx.isGuideMessageOnlyView || _ctx.isMobileWebView))
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "close-button",
            "data-cy": "guide-drawer-close",
            "aria-label": _ctx.$t('closeGuideDrawer'),
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.closeDrawer && _ctx.closeDrawer(...args)))
          }, [
            _createVNode(_component_IconCloseCircle, { class: "close" })
          ], 8, _hoisted_4))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("img", {
            src: _ctx.user.coachingAssignment.profileImageUrl,
            alt: _ctx.$t('coachImage')
          }, null, 8, _hoisted_7)
        ]),
        _createElementVNode("h4", _hoisted_8, _toDisplayString(_ctx.$t('welcomeMessage', { firstName: _ctx.user.firstName })), 1),
        _createElementVNode("button", {
          class: "btn-link bio",
          "data-cy": "guide-profile-toggle",
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.toggleBio && _ctx.toggleBio(...args)))
        }, _toDisplayString(_ctx.showBio ? _ctx.$t('hideBio') : _ctx.$t('showBio')), 1)
      ]),
      (_ctx.showBio)
        ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(_ctx.guideProfile), 1))
        : _createCommentVNode("", true),
      (_ctx.showIntro)
        ? (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(_ctx.$t('guideIntroMessage', { guideName: _ctx.guideName })), 1))
        : _createCommentVNode("", true),
      _createVNode(_component_BaseInputTextarea, {
        ref: "userGuideInput",
        "data-cy": "user-guide-input",
        maxlength: "255",
        inputName: "guide message",
        placeholder: _ctx.$t('inputIntroPlaceholder'),
        inputModel: _ctx.userInput,
        "onUpdate:inputModel": _cache[4] || (_cache[4] = ($event: any) => (_ctx.userInput = $event))
      }, null, 8, ["placeholder", "inputModel"]),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_BaseButton, {
          class: _normalizeClass(["btn-primary btn-wide send-btn", {'disabled': !_ctx.userInput}]),
          "data-cy": "guide-drawer-send",
          disabled: !_ctx.userInput || _ctx.disableSend,
          onClick: _ctx.sendMessage
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('sendMessage')), 1)
          ]),
          _: 1
        }, 8, ["disabled", "class", "onClick"])
      ])
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("div", _hoisted_13, [
        (_ctx.retrieveMessagesError)
          ? (_openBlock(), _createElementBlock("div", _hoisted_14, _toDisplayString(_ctx.$t('retrieveMessagesError')), 1))
          : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.messages, (response) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "message",
                key: response.message.id
              }, [
                _createElementVNode("p", {
                  class: "body",
                  innerHTML: _ctx.newLineToHtml(response.message)
                }, null, 8, _hoisted_15),
                (response.attachments && response.attachments.length > 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(response.attachments, (attachment) => {
                        return (_openBlock(), _createElementBlock(_Fragment, {
                          key: attachment.id
                        }, [
                          (_ctx.isNotDeprecated(attachment.content))
                            ? (_openBlock(), _createElementBlock("p", {
                                key: 0,
                                class: _normalizeClass(["message-attachment", 'attachment-type-' + attachment.type ? attachment.type.toLowerCase() : '']),
                                "data-cy": "nav-guide-message-attachments"
                              }, [
                                (attachment.type === _ctx.AttachmentTypes.ACTIVITY && attachment.attachmentMeta)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                                      _createElementVNode("a", {
                                        onClick: _withModifiers(($event: any) => (_ctx.attachmentLinkClick($event, attachment)), ["prevent"]),
                                        onKeyup: _withKeys(($event: any) => (_ctx.attachmentLinkClick($event, attachment)), ["enter"]),
                                        role: "link",
                                        tabindex: "0",
                                        "data-cy": `nav-guide-attachment-link-${attachment.content}`
                                      }, [
                                        _createElementVNode("img", {
                                          src: attachment.attachmentMeta.imageURL,
                                          alt: attachment.attachmentMeta.title
                                        }, null, 8, _hoisted_19),
                                        _createElementVNode("p", null, _toDisplayString(attachment.attachmentMeta.title), 1)
                                      ], 40, _hoisted_18)
                                    ]))
                                  : (attachment.type === 'INTERNAL_LINK')
                                    ? (_openBlock(), _createBlock(_component_AttachmentInternalLink, {
                                        key: 1,
                                        attachment: attachment,
                                        onLinkClicked: ($event: any) => (_ctx.attachmentLinkClick($event, attachment))
                                      }, null, 8, ["attachment", "onLinkClicked"]))
                                    : (_openBlock(), _createElementBlock("a", {
                                        key: 2,
                                        class: "coaching-message-other-link",
                                        target: "_blank",
                                        rel: "noopener",
                                        href: encodeURI(attachment.content),
                                        onClick: ($event: any) => (_ctx.attachmentLinkClick($event, attachment)),
                                        onKeyup: _withKeys(($event: any) => (_ctx.attachmentLinkClick($event, attachment)), ["enter"])
                                      }, [
                                        _createElementVNode("div", _hoisted_21, [
                                          _createVNode(_component_HomeIcon, { class: "link-svg" })
                                        ]),
                                        _createElementVNode("p", null, _toDisplayString(attachment.attachmentMeta && attachment.attachmentMeta.title ? attachment.attachmentMeta.title : attachment.content), 1)
                                      ], 40, _hoisted_20))
                              ], 2))
                            : _createCommentVNode("", true)
                        ], 64))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.isFromCoach(response.type))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                      _createElementVNode("div", _hoisted_23, [
                        _createElementVNode("img", {
                          src: _ctx.user.coachingAssignment.profileImageUrl,
                          alt: _ctx.$t('coachImage')
                        }, null, 8, _hoisted_24)
                      ]),
                      _createElementVNode("div", _hoisted_25, _toDisplayString(_ctx.getDate(response.sentOn)), 1)
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_26, [
                      _createElementVNode("div", _hoisted_27, _toDisplayString(_ctx.userFirstLetter), 1),
                      _createElementVNode("div", _hoisted_28, _toDisplayString(_ctx.getDate(response.sentOn)), 1)
                    ]))
              ]))
            }), 128))
      ])
    ])
  ], 2))
}