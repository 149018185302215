export enum MOBILE_PLATFORM_TYPES {
  ANDROID = 'android',
  IOS = 'ios'
}

export enum LANGUAGE_TYPES {
  EN_US = 'en-US',
  ES_MX = 'es-MX',
  ES_US = 'es-US'
}
