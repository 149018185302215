import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9a5bf70a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "profile" }
const _hoisted_2 = {
  key: 0,
  class: "title heading-xl"
}
const _hoisted_3 = {
  key: 1,
  class: "subtitle"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavigationProfile = _resolveComponent("NavigationProfile")!
  const _component_routerView = _resolveComponent("routerView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.isMobileWebView)
      ? (_openBlock(), _createElementBlock("h1", _hoisted_2, _toDisplayString(_ctx.title), 1))
      : _createCommentVNode("", true),
    (!_ctx.profileBh10)
      ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.subtitle), 1))
      : _createCommentVNode("", true),
    _createVNode(_component_NavigationProfile),
    _createVNode(_component_routerView, {
      id: "profile-tab-panel",
      role: "tabpanel"
    })
  ]))
}