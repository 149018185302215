export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "default": {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talk with a therapist"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schedule an appointment with a licensed therapist by phone or video"])},
        "actionLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request a visit"])}
      },
      "requested": {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Therapy appointment requested"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your provider will respond to your request within 48 hours"])}
      },
      "proposed": {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New therapy time proposed"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your provider has proposed a new time for your visit. Please review this change in your message center."])},
        "actionLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review visit"])}
      },
      "locked": {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upcoming therapy appointment"])},
        "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("member")), "'s visit with ", _interpolate(_named("provider"))])},
        "actionLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reschedule"])}
      },
      "join": {
        "video": {
          "header": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your video visit with ", _interpolate(_named("provider")), " starts at ", _interpolate(_named("time"))])},
          "actionLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join now"])}
        },
        "audio": {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your therapy visit starts soon"])},
          "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("provider")), " will call you at ", _interpolate(_named("time"))])}
        }
      },
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "cancelSuccessHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultation canceled"])},
      "cancelSuccessDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your consultation was cancelled."])},
      "cancelErrorHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong"])},
      "cancelErrorDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please try canceling your consultation again."])},
      "cancelHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel visit"])},
      "cancelDialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to cancel this visit?"])},
      "confirmCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes cancel"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])}
    }
  })
}
