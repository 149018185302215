import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["aria-disabled", "aria-label", "type"]
const _hoisted_2 = { class: "action-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(_ctx.buttonClass),
    "aria-disabled": _ctx.disabled,
    "aria-label": _ctx.ariaLabel,
    ref: "button",
    type: _ctx.type,
    onFocus: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('focus', $event))),
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
  }, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ], 42, _hoisted_1))
}