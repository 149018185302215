import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Transition as _Transition, withCtx as _withCtx, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1241bcfe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "navigation-list" }
const _hoisted_2 = {
  key: 0,
  class: "badge-container"
}
const _hoisted_3 = { class: "count" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconArrowRight = _resolveComponent("IconArrowRight")!
  const _component_RedBadgeCircle = _resolveComponent("RedBadgeCircle")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navList, (navItem) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["navigation-item action-label", _ctx.navClass(navItem.routeName)]),
        key: navItem.routeName
      }, [
        _createVNode(_component_router_link, {
          class: "router-link",
          onClick: ($event: any) => (_ctx.handleSettingsNavigationEvent(navItem.routeName)),
          to: {name: _ctx.getNavName(navItem.routeName)}
        }, {
          default: _withCtx(() => [
            (_ctx.isOneApp && navItem.icon)
              ? (_openBlock(), _createBlock(_resolveDynamicComponent(navItem.icon), {
                  key: 0,
                  class: "icon"
                }))
              : _createCommentVNode("", true),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t(navItem.i18nLabel)), 1),
            (_ctx.isOneApp)
              ? (_openBlock(), _createBlock(_component_IconArrowRight, {
                  key: 1,
                  class: "one-app-arrow"
                }))
              : _createCommentVNode("", true),
            _createVNode(_Transition, { name: "badge-transition" }, {
              default: _withCtx(() => [
                (navItem.routeName === 'Connect' && _ctx.hasUnreadConnectMessages)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.unreadTeletherapyMessages), 1),
                      _createVNode(_component_RedBadgeCircle, { class: "badge" })
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1032, ["onClick", "to"])
      ], 2))
    }), 128))
  ]))
}