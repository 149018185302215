
import { defineComponent } from 'vue';
import checkMark from '@svg/checkMark.svg';
import oval from '@svg/oval.svg';
/**
 * Component to display the provided message with a green tick mark
 * if validated else with a oval bullet.
 */
export default defineComponent({
  name: 'ValidatedMessage',
  components: {
    checkMark,
    oval
  },
  props: {
    message: {
      type: String,
      default: '',
      required: true
    },
    validated: {
      type: Boolean,
      default: false,
      required: true
    }
  }
});
