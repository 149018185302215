import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0efb4042"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["alt"]
const _hoisted_2 = { class: "heading-lg" }
const _hoisted_3 = { class: "program" }
const _hoisted_4 = { class: "heading-sm" }
const _hoisted_5 = { class: "description" }
const _hoisted_6 = {
  key: 0,
  class: "therapy"
}
const _hoisted_7 = { class: "heading-sm" }
const _hoisted_8 = { class: "description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_OnboardWrapper = _resolveComponent("OnboardWrapper")!

  return (_openBlock(), _createBlock(_component_OnboardWrapper, {
    chapter: _ctx.OnboardingChapter.Chapter3,
    "show-progress-bar": false
  }, {
    default: _withCtx(() => [
      _createElementVNode("img", {
        src: "/img/upgrade-reg.png",
        alt: _ctx.$t('title')
      }, null, 8, _hoisted_1),
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('title')), 1),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('program.title')), 1),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('program.description')), 1)
      ]),
      (_ctx.isTeletherapyUser)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('therapy.title')), 1),
            _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('therapy.description')), 1)
          ]))
        : _createCommentVNode("", true),
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_LoadingSpinner, { key: 1 }))
        : (_openBlock(), _createBlock(_component_BaseButton, {
            key: 2,
            class: "btn",
            onClick: _ctx.onButtonClick
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('button')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]))
    ]),
    _: 1
  }, 8, ["chapter"]))
}