import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9cb6506a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card-circle-img" }
const _hoisted_2 = ["src", "alt", "aria-hidden"]
const _hoisted_3 = ["src", "alt", "aria-hidden"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CardContent = _resolveComponent("CardContent")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.routeLocation)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 0,
          class: "card-wrapper",
          to: _ctx.routeLocation,
          onClick: _ctx.onNavigate
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(["card", _ctx.cardClass])
            }, [
              _createElementVNode("div", {
                class: "card-circle",
                style: _normalizeStyle(_ctx.cardCircleStyle)
              }, [
                _createElementVNode("img", {
                  src: _ctx.thumbnailURL,
                  alt: _ctx.imgDescription,
                  "aria-hidden": _ctx.imgDescription === null || _ctx.imgDescription === ''
                }, null, 8, _hoisted_2)
              ], 4),
              _createVNode(_component_CardContent, {
                title: _ctx.title,
                heading: _ctx.heading,
                description: _ctx.description
              }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "default", {}, undefined, true)
                ]),
                _: 3
              }, 8, ["title", "heading", "description"])
            ], 2)
          ]),
          _: 3
        }, 8, ["to", "onClick"]))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["card", _ctx.cardClass])
        }, [
          _createElementVNode("div", {
            class: "card-circle",
            style: _normalizeStyle(_ctx.cardCircleStyle)
          }, [
            _createElementVNode("img", {
              src: _ctx.thumbnailURL,
              alt: _ctx.imgDescription,
              "aria-hidden": _ctx.imgDescription === null || _ctx.imgDescription === ''
            }, null, 8, _hoisted_3)
          ], 4),
          _createVNode(_component_CardContent, {
            title: _ctx.title,
            heading: _ctx.heading,
            description: _ctx.description
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ]),
            _: 3
          }, 8, ["title", "heading", "description"])
        ], 2))
  ]))
}