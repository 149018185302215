
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HistoryChoiceModal',
  emits: ['update:showModal', 'historyCardClick'],
  props: {
    isWebView: {
      type: Boolean,
      default: false
    },
    showNavigationModal: {
      type: Boolean,
      required: true,
      default: false
    },
    activitySlug: {
      type: String,
      required: true
    },
    activityResponseLocation: {
      type: Object,
      default: null
    },
    currentLocation: {
      type: String,
      default: 'Home'
    },
    activityRouteName: {
      type: String,
      default: 'Activity'
    }
  },
  data() {
    return {
      modalShown: false
    };
  },
  computed: {
    historyCardEvent(): string {
      return this.isWebView ? 'click' : '';
    },
    defaultActivityResponseLocation() {
      return {
        name: 'ActivityResponses',
        params: {
          activitySlug: this.activitySlug
        },
        query: {
          returnLocation: this.currentLocation
        }
      };
    }
  },
  watch: {
    showNavigationModal: {
      immediate: true,
      handler(value) {
        this.modalShown = value;
      }
    }
  },
  methods: {
    updateShowModal(value: boolean) {
      this.modalShown = value;
      this.$emit('update:showModal', value);
    },
    historyCardClick(activitySlug: string) {
      if (!this.isWebView) {
        return;
      }
      this.$emit('historyCardClick', activitySlug);
    }
  }
});
