
import { defineComponent } from 'vue';

import CardCircleIcon from '@common/cards/CardCircleIcon.vue';

import IconHeart from '@svg/icon-heart.svg';

export default defineComponent({
  name: 'NoFavorites',
  components: {
    CardCircleIcon,
    IconHeart
  },
  data() {
    return {
      noFavoritesToggle: false
    };
  },
  computed: {
    header(): string {
      return this.$t(
        this.noFavoritesToggle ? 'headerToggled' : 'header'
      ).toString();
    },
    instructions(): string {
      return this.$t(
        this.noFavoritesToggle ? 'instructionsToggled' : 'instructions'
      ).toString();
    }
  },
  methods: {
    toggleNoFavorite(): void {
      this.noFavoritesToggle = !this.noFavoritesToggle;
    }
  }
});
