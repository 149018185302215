export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "es",
    "resource": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscribirse"])},
      "form": {
        "accessCodeField": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Cuál es tu código de acceso?"])},
          "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su proveedor de bienestar mental le proporciona un código de acceso."])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["access code"])}
        },
        "locationCheckbox": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualmente me encuentro fuera de los Estados Unidos."])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["location"])}
        },
        "invalidAccessCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El código de acceso no es válido"])},
        "nextBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comience su viaje"])}
      },
      "customSignUpField": {
        "selectOptionGroupDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elige una opcion"])},
        "selectOptionAccessCodeDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elige una opcion"])}
      },
      "existingUser": {
        "isUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Ya eres usuario?"])},
        "signInLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrarse"])}
      },
      "heading2": {
        "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estás en camino"])},
        "Teladoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Te llevamos a Teladoc"])}
      },
      "text": {
        "MyStrength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En un momento será redirigido a myStrength para completar el registro"])},
        "Livongo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En un momento será redirigido a Livongo para completar el registro."])},
        "Teladoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Usa el mismo código allí y estarás en camino!"])}
      }
    }
  })
}
