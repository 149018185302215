import { ActionTree, GetterTree, MutationTree } from 'vuex';

import WebViewService, {
  OneAppQueryParams,
  stringToBool
} from '@/services/WebViewService';

import { RootState } from '@/types/storeTypes';
import { LANGUAGE_TYPES, MOBILE_PLATFORM_TYPES } from '@/types/WebviewTypes';
import { Dictionary } from '@/types/CommonTypes';

export interface WebviewState {
  prevLocation: string;
  nextLocation: string | null;
  beforeFinishFn: Promise<() => unknown>;
  beforeNextFn: () => void;
  showFinishBtn: boolean;
  showNavigation: boolean;
  disableNextBtn: boolean;
  finishBtnText: string | null;
  nextBtnText: string | null;
  isOneApp: boolean;
  isMobileWebView: boolean;
  mobilePlatform: MOBILE_PLATFORM_TYPES | null;
  language: LANGUAGE_TYPES | null;
  isGuideMessageOnlyView: boolean;
}

// Collect current session storage values for oneAppParam keys
export const oneAppSessionStorageValues =
  WebViewService.allOneAppQueryParams().reduce((acc, oneAppKey) => {
    const sessionStorageValue: string | boolean | null = stringToBool(
      sessionStorage.getItem(oneAppKey)
    );

    if (
      typeof sessionStorageValue !== 'undefined' &&
      typeof oneAppKey !== 'undefined'
    ) {
      acc[oneAppKey] = sessionStorageValue;
    }

    return acc;
  }, {} as Dictionary<string | boolean | null>);

// Sets specific oneApp related values to session storage
const setOneAppSessionStorage = (initialState: WebviewState) => {
  // Only set sessionStorage values if isOneApp
  if (initialState.isOneApp) {
    sessionStorage.setItem(
      OneAppQueryParams.IS_ONE_APP_QUERY_PARAM,
      initialState.isOneApp.toString()
    );

    if (initialState.isMobileWebView) {
      sessionStorage.setItem(
        OneAppQueryParams.IS_MOBILE_WEBVIEW_QUERY_PARAM,
        initialState.isMobileWebView.toString()
      );
    }

    if (initialState.mobilePlatform) {
      sessionStorage.setItem(
        OneAppQueryParams.MOBILE_PLATFORM,
        initialState.mobilePlatform.toString()
      );
    }

    if (initialState.language) {
      sessionStorage.setItem(
        OneAppQueryParams.LANGUAGE,
        initialState.language.toString()
      );
    }

    // Intentionally skipping isGuideMessageOnlyView unless needed
  }
};

/**
 * This store module is for Webview related data
 */
const initialState = {
  prevLocation: null,
  nextLocation: null,
  showNavigation: false,
  showFinishBtn: false,
  beforeFinishFn: false,
  beforeNextFn: false,
  disableNextBtn: false,
  finishBtnText: null,
  nextBtnText: null,
  // OneApp WebView Query Parameters
  // Use query params first & secondary sessionStorage
  // Note: these are one directional store values without associated actions & mutations
  isOneApp:
    WebViewService.isOneApp(WebViewService.getQueryParams()) ||
    oneAppSessionStorageValues[OneAppQueryParams.IS_ONE_APP_QUERY_PARAM],
  isMobileWebView:
    WebViewService.routeHasWebviewQueryParam(WebViewService.getQueryParams()) ||
    oneAppSessionStorageValues[OneAppQueryParams.IS_MOBILE_WEBVIEW_QUERY_PARAM],
  mobilePlatform:
    WebViewService.mobilePlatform(WebViewService.getQueryParams()) ||
    oneAppSessionStorageValues[OneAppQueryParams.MOBILE_PLATFORM],
  language:
    WebViewService.language(WebViewService.getQueryParams()) ||
    oneAppSessionStorageValues[OneAppQueryParams.LANGUAGE],
  isGuideMessageOnlyView:
    WebViewService.guideMessagesParam(WebViewService.getQueryParams()) ||
    oneAppSessionStorageValues[OneAppQueryParams.IS_GUIDE_MESSAGES_PARAM]
};

// Set certain OneApp values to sessionStorage
// Persists important initial state to session storage
setOneAppSessionStorage({ ...initialState } as unknown as WebviewState);

const state = { ...initialState };

const getters: GetterTree<WebviewState, RootState> = {
  webViewPrevLocation: (state) => state.prevLocation,
  webViewNextLocation: (state) => state.nextLocation,
  webViewShowFinishBtn: (state) => state.showFinishBtn,
  webViewNavigationShown: (state) => state.showNavigation,
  webViewBeforeFinishFn: (state) => state.beforeFinishFn,
  webViewBeforeNextFn: (state) => state.beforeNextFn,
  webViewDisableNextBtn: (state) => state.disableNextBtn,
  webViewFinishBtnText: (state) => state.finishBtnText,
  webViewNextBtnText: (state) => state.nextBtnText,
  // OneApp WebView Query Parameters
  isOneApp: (state) => state.isOneApp,
  isMobileWebView: (state) => state.isMobileWebView,
  mobilePlatform: (state) => state.mobilePlatform,
  language: (state) => state.language,
  isGuideMessageOnlyView: (state) => state.isGuideMessageOnlyView
};

const actions: ActionTree<WebviewState, RootState> = {
  setWebViewPrevLocation({ commit }, Location: string) {
    commit('setPrevLocation', Location);
  },
  setWebViewNextLocation({ commit }, Location: string) {
    commit('setNextLocation', Location);
  },
  setWebViewBeforeFinishFn({ commit }, beforeFinishFn: Promise<unknown>) {
    commit('setBeforeFinishFn', beforeFinishFn);
  },
  setWebViewBeforeNextFn({ commit }, beforeNextFn: () => void) {
    commit('setBeforeNextFn', beforeNextFn);
  },
  showWebViewFinishButton({ commit }) {
    commit('setShowFinishButton', true);
  },
  hideWebViewFinishButton({ commit }) {
    commit('setShowFinishButton', false);
  },
  showWebViewNavigation({ commit }) {
    commit('setShowNavigation', true);
  },
  hideWebViewNavigation({ commit }) {
    commit('setShowNavigation', false);
  },
  enableWebViewNextBtn({ commit }) {
    console.log('enableWebViewNextBtn');
    commit('setDisableNextBtn', false);
  },
  disableWebViewNextBtn({ commit }) {
    console.log('disableWebviewNextBtn');
    commit('setDisableNextBtn', true);
  },
  setFinishBtnText({ commit }, text: string) {
    commit('setFinishBtnText', text);
  },
  setNextBtnText({ commit }, text: string) {
    commit('setNextBtnText', text);
  },
  clearNextLocation({ commit }) {
    commit('clearNextLocation');
  }
};

const mutations: MutationTree<WebviewState> = {
  setPrevLocation(state, Location: string): void {
    state.prevLocation = Location;
  },
  setNextLocation(state, Location: string): void {
    state.nextLocation = Location;
  },
  setBeforeFinishFn(state, beforeFinishFn: Promise<() => unknown>): void {
    state.beforeFinishFn = beforeFinishFn;
  },
  setBeforeNextFn(state, beforeNextFn: () => unknown): void {
    state.beforeNextFn = beforeNextFn;
  },
  setShowFinishButton(state, showFinish: boolean): void {
    state.showFinishBtn = showFinish;
  },
  setShowNavigation(state, showNavigation: boolean): void {
    state.showNavigation = showNavigation;
  },
  setDisableNextBtn(state, disableNextBtn: boolean): void {
    state.disableNextBtn = disableNextBtn;
  },
  setFinishBtnText(state, text: string): void {
    state.finishBtnText = text;
  },
  setNextBtnText(state, text: string): void {
    state.nextBtnText = text;
  },
  clearNextLocation(state) {
    state.nextLocation = null;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
