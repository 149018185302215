export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "es",
    "resource": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible en la aplicación"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Quieres hacer un seguimiento de tu sueño?"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargue la aplicación myStrength para registrar entradas en su diario de sueño y obtener un horario de sueño personalizado."])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceso"])},
      "appStoreAlt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar en la App Store"])},
      "playStoreAlt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consiguelo en Google Play"])},
      "phoneImageDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La aplicación myStrength en un dispositivo móvil"])},
      "oneAppLabel": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargue la aplicación Teladoc Health para registrar entradas en su diario de sueño y obtener un horario de sueño personalizado."])}
      }
    }
  })
}
