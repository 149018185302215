export default {
  upperCase: 'At least one capital letter',
  lowerCase: 'At least one lowercase letter',
  oneNumber: 'At least one number',
  noSpecialChars: '{field} may not contain special characters',
  noInvalidNameChars: '{field} may not contain the special characters "<>/&;"',
  noInvalidNameCharsCrisis:
    '{field} may not contain special characters other than ",.\'-"',
  noExtraSpaces: '{field} may not contain leading or trailing spaces'
} as { [key: string]: string };
