export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We Value your Privacy"])},
      "terms": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("brandName")), " is committed to protecting and respecting your privacy. In order for us to provide you access to the ", _interpolate(_named("brandName")), " service, you will be requested to provide certain personal data. You may withdraw your consent at any time. For more information on how to withdraw your consent, our privacy practices, and how we are committed to protecting and respecting your privacy, please review our ", _interpolate(_named("privacyLink")), "."])},
      "terms2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["testing terms ", _interpolate(_named("brandName"))])},
      "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
      "acceptButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I accept"])}
    }
  })
}
