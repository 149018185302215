
import { defineComponent } from 'vue';
import { Field, defineRule } from 'vee-validate';

import { required } from '@vee-validate/rules';

import BaseInput from '@/components/base/mixin/BaseInput';
import InlineError from '@common/InlineError.vue';
import CheckMarkFilledSquare from '@svg/checkmark-filled-square.svg';
import Tooltip from '@common/Tooltip.vue';

// Add specific rules to keep bundle size down
defineRule('required', required);

export default defineComponent({
  name: 'BaseInputCheckbox',
  emits: ['update:inputModel'],
  components: {
    Field,
    Tooltip,
    InlineError,
    CheckMarkFilledSquare
  },
  mixins: [BaseInput],
  data() {
    return {
      inputValue: null as boolean | null
    };
  },
  computed: {
    isChecked(): boolean {
      return this.inputValue as boolean;
    }
  }
});
