import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1460ba3a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["aria-describedby"]
const _hoisted_2 = { class: "content" }
const _hoisted_3 = {
  class: "heading-xs",
  id: "dialog_label"
}
const _hoisted_4 = {
  key: 0,
  id: "dialog_desc"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconAlertInfo = _resolveComponent("IconAlertInfo")!
  const _component_IconAlertWarn = _resolveComponent("IconAlertWarn")!
  const _component_IconAlertDanger = _resolveComponent("IconAlertDanger")!
  const _component_IconCheckMarkCircle = _resolveComponent("IconCheckMarkCircle")!
  const _component_IconClose = _resolveComponent("IconClose")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card notification", _ctx.notificationClass]),
    "data-cy": "notification-container",
    role: "status",
    "aria-live": "polite",
    "aria-modal": "true",
    "aria-labelledby": "dialog_label",
    "aria-describedby": _ctx.description ? 'dialog_desc' : null
  }, [
    (_ctx.type === _ctx.NOTIFICATION_TYPES.INFO)
      ? (_openBlock(), _createBlock(_component_IconAlertInfo, {
          key: 0,
          class: "notification-icon",
          "aria-label": "info"
        }))
      : _createCommentVNode("", true),
    (_ctx.type === _ctx.NOTIFICATION_TYPES.WARNING)
      ? (_openBlock(), _createBlock(_component_IconAlertWarn, {
          key: 1,
          class: "notification-icon",
          "aria-label": "warning"
        }))
      : _createCommentVNode("", true),
    (_ctx.type === _ctx.NOTIFICATION_TYPES.ERROR)
      ? (_openBlock(), _createBlock(_component_IconAlertDanger, {
          key: 2,
          class: "notification-icon",
          "aria-label": "error"
        }))
      : _createCommentVNode("", true),
    (_ctx.type === _ctx.NOTIFICATION_TYPES.SUCCESS)
      ? (_openBlock(), _createBlock(_component_IconCheckMarkCircle, {
          key: 3,
          class: "notification-icon",
          "aria-label": "success"
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.heading), 1),
      (_ctx.description)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.description), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("button", {
      class: "base-btn-plain",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeNotification && _ctx.closeNotification(...args))),
      "aria-label": "close"
    }, [
      _createVNode(_component_IconClose, {
        class: "close-icon",
        "aria-hidden": "true"
      })
    ])
  ], 10, _hoisted_1))
}