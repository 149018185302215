export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "links": {
        "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Use"])},
        "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
        "siteMap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site Map"])},
        "call911": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["call 911"])}
      },
      "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rights reserved."])},
      "text1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("brandName")), " application and the guides do not provide medical advice, diagnosis, or treatment. If you are currently thinking about or planning to harm yourself or someone else, please "])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([", go to the nearest hospital emergency room, or call the Suicide and Crisis Lifeline at "])},
      "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  or "])}
    }
  })
}
