interface ImageSrcFormats {
  [key: string]: string;
}

export enum ImageFormats {
  cardCircleImageReveal = 'cardCircleImageReveal',
  cardCircleMain = 'cardCircleMain',
  circleLarge = 'circleLarge',
  default = 'default',
  mobileMainCard = 'mobileMainCard',
  standard = 'standard',
  wide = 'wide'
}
/**
 * Image source service:
 *  Used to combine image source urls and format types
 *  to display static images
 *
 */
class ImgixSrcService {
  private sourceFormats: ImageSrcFormats = {
    cardCircleImageReveal:
      '?fit=crop&crop=center&w=90&h=90&auto=format,enhance',
    cardCircleMain: '?fit=crop&crop=center&w=92&h=92&auto=format,enhance',
    circleLarge: '?fit=crop&crop=center&w=900&h=900&auto=format,enhance',
    default: '',
    mobileMainCard: '?fit=crop&crop=center&w=1600&h=655&auto=format,enhance',
    standard: '?fit=crop&crop=center&w=1600&h=1200&auto=format,enhance',
    wide: '?fit=crop&crop=center&w=1600&h=900&auto=format,enhance'
  };

  /**
   * getImageSource
   *  Takes a source and format to construct a formatted url
   * @param source a url to an image on the web
   * @param format a format to render the image in
   * @returns a formatted url to the image
   */
  public getImageSource(
    source: string | null,
    format: ImageFormats | string
  ): string {
    const imgixCapableURL = source ? source.includes('imgix') : false;
    if (format !== null && imgixCapableURL && this.sourceFormats[format]) {
      return source + this.sourceFormats[format];
    } else {
      return source ? source : '';
    }
  }
}

export default new ImgixSrcService();
