
import { defineComponent, PropType, nextTick } from 'vue';
import { mapActions } from 'vuex';

import IconClose from '@svg/icon-close.svg';

export default defineComponent({
  name: 'BaseModal',
  emits: ['close', 'update:showModal'],
  components: {
    IconClose
  },
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    showCloseButton: {
      type: Boolean,
      default: false
    },
    hasCancelButton: {
      type: Boolean,
      default: false
    },
    primaryButton: {
      type: String,
      required: false,
      default: ''
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    modalAction: {
      type: Function as PropType<() => null>,
      required: false,
      default: null
    },
    underlineHeader: {
      type: Boolean,
      required: false,
      default: true
    },
    showModal: {
      type: Boolean,
      required: false,
      default: false
    },
    keepScrollPosition: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      visible: this.showModal
    };
  },
  watch: {
    showModal(value) {
      this.visible = value;
      this.handleBodyScroll();

      if (value) {
        this.focusModal();
      }
    },
    visible(value) {
      this.$emit('update:showModal', value);

      if (value) {
        this.focusModal();
      }
    }
  },
  mounted() {
    this.handleBodyScroll();

    if (this.visible) {
      this.focusModal();
    }
  },
  methods: {
    closeModal(): void {
      this.$emit('close');
      this.visible = false;

      if (!this.keepScrollPosition) {
        this.unblockBodyScroll();
      }
    },
    handleBodyScroll(): void {
      if (this.keepScrollPosition) {
        return;
      }

      if (this.visible) {
        this.blockBodyScroll();
      } else {
        this.unblockBodyScroll();
      }
    },
    maskClick() {
      if (this.modalAction === null) {
        this.closeModal();
      } else {
        this.modalAction();
      }
    },
    async focusModal() {
      await nextTick();
      this.$refs.modal?.focus();
    },
    ...mapActions(['blockBodyScroll', 'unblockBodyScroll'])
  }
});
