/**
 * @fileoverview gRPC-Web generated client stub for com.livongo.protobuf.grpc.external.bh.stepped_care
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.11.4
// source: livongo/protobuf/grpc/external/bh/stepped_care/stepped_care_service.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var livongo_protobuf_grpc_external_bh_stepped_care_stepped_care_pb = require('../../../../../../livongo/protobuf/grpc/external/bh/stepped_care/stepped_care_pb.js')

var livongo_protobuf_common_bh_programRecommendation_pb = require('../../../../../../livongo/protobuf/common/bh/programRecommendation_pb.js')
const proto = {};
proto.com = {};
proto.com.livongo = {};
proto.com.livongo.protobuf = {};
proto.com.livongo.protobuf.grpc = {};
proto.com.livongo.protobuf.grpc.external = {};
proto.com.livongo.protobuf.grpc.external.bh = {};
proto.com.livongo.protobuf.grpc.external.bh.stepped_care = require('./stepped_care_service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.SteppedCareServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.SteppedCareServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetProgramRecommendationRequest,
 *   !proto.com.livongo.protobuf.common.bh.ProgramRecommendation>}
 */
const methodDescriptor_SteppedCareService_GetProgramRecommendation = new grpc.web.MethodDescriptor(
  '/com.livongo.protobuf.grpc.external.bh.stepped_care.SteppedCareService/GetProgramRecommendation',
  grpc.web.MethodType.UNARY,
  livongo_protobuf_grpc_external_bh_stepped_care_stepped_care_pb.GetProgramRecommendationRequest,
  livongo_protobuf_common_bh_programRecommendation_pb.ProgramRecommendation,
  /**
   * @param {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetProgramRecommendationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  livongo_protobuf_common_bh_programRecommendation_pb.ProgramRecommendation.deserializeBinary
);


/**
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetProgramRecommendationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.livongo.protobuf.common.bh.ProgramRecommendation)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.livongo.protobuf.common.bh.ProgramRecommendation>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.SteppedCareServiceClient.prototype.getProgramRecommendation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.livongo.protobuf.grpc.external.bh.stepped_care.SteppedCareService/GetProgramRecommendation',
      request,
      metadata || {},
      methodDescriptor_SteppedCareService_GetProgramRecommendation,
      callback);
};


/**
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetProgramRecommendationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.livongo.protobuf.common.bh.ProgramRecommendation>}
 *     Promise that resolves to the response
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.SteppedCareServicePromiseClient.prototype.getProgramRecommendation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.livongo.protobuf.grpc.external.bh.stepped_care.SteppedCareService/GetProgramRecommendation',
      request,
      metadata || {},
      methodDescriptor_SteppedCareService_GetProgramRecommendation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetProgramRecommendationContentRequest,
 *   !proto.com.livongo.protobuf.grpc.external.bh.stepped_care.ProgramRecommendationContent>}
 */
const methodDescriptor_SteppedCareService_GetProgramRecommendationContent = new grpc.web.MethodDescriptor(
  '/com.livongo.protobuf.grpc.external.bh.stepped_care.SteppedCareService/GetProgramRecommendationContent',
  grpc.web.MethodType.UNARY,
  livongo_protobuf_grpc_external_bh_stepped_care_stepped_care_pb.GetProgramRecommendationContentRequest,
  livongo_protobuf_grpc_external_bh_stepped_care_stepped_care_pb.ProgramRecommendationContent,
  /**
   * @param {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetProgramRecommendationContentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  livongo_protobuf_grpc_external_bh_stepped_care_stepped_care_pb.ProgramRecommendationContent.deserializeBinary
);


/**
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetProgramRecommendationContentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.livongo.protobuf.grpc.external.bh.stepped_care.ProgramRecommendationContent)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.ProgramRecommendationContent>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.SteppedCareServiceClient.prototype.getProgramRecommendationContent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.livongo.protobuf.grpc.external.bh.stepped_care.SteppedCareService/GetProgramRecommendationContent',
      request,
      metadata || {},
      methodDescriptor_SteppedCareService_GetProgramRecommendationContent,
      callback);
};


/**
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetProgramRecommendationContentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.ProgramRecommendationContent>}
 *     Promise that resolves to the response
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.SteppedCareServicePromiseClient.prototype.getProgramRecommendationContent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.livongo.protobuf.grpc.external.bh.stepped_care.SteppedCareService/GetProgramRecommendationContent',
      request,
      metadata || {},
      methodDescriptor_SteppedCareService_GetProgramRecommendationContent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetAllPrimaryNeedsRequest,
 *   !proto.com.livongo.protobuf.grpc.external.bh.stepped_care.AllPrimaryNeedsContent>}
 */
const methodDescriptor_SteppedCareService_GetAllPrimaryNeeds = new grpc.web.MethodDescriptor(
  '/com.livongo.protobuf.grpc.external.bh.stepped_care.SteppedCareService/GetAllPrimaryNeeds',
  grpc.web.MethodType.UNARY,
  livongo_protobuf_grpc_external_bh_stepped_care_stepped_care_pb.GetAllPrimaryNeedsRequest,
  livongo_protobuf_grpc_external_bh_stepped_care_stepped_care_pb.AllPrimaryNeedsContent,
  /**
   * @param {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetAllPrimaryNeedsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  livongo_protobuf_grpc_external_bh_stepped_care_stepped_care_pb.AllPrimaryNeedsContent.deserializeBinary
);


/**
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetAllPrimaryNeedsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.livongo.protobuf.grpc.external.bh.stepped_care.AllPrimaryNeedsContent)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.AllPrimaryNeedsContent>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.SteppedCareServiceClient.prototype.getAllPrimaryNeeds =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.livongo.protobuf.grpc.external.bh.stepped_care.SteppedCareService/GetAllPrimaryNeeds',
      request,
      metadata || {},
      methodDescriptor_SteppedCareService_GetAllPrimaryNeeds,
      callback);
};


/**
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.GetAllPrimaryNeedsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.AllPrimaryNeedsContent>}
 *     Promise that resolves to the response
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.SteppedCareServicePromiseClient.prototype.getAllPrimaryNeeds =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.livongo.protobuf.grpc.external.bh.stepped_care.SteppedCareService/GetAllPrimaryNeeds',
      request,
      metadata || {},
      methodDescriptor_SteppedCareService_GetAllPrimaryNeeds);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.livongo.protobuf.grpc.external.bh.stepped_care.SelectPrimaryNeedRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_SteppedCareService_SelectPrimaryNeed = new grpc.web.MethodDescriptor(
  '/com.livongo.protobuf.grpc.external.bh.stepped_care.SteppedCareService/SelectPrimaryNeed',
  grpc.web.MethodType.UNARY,
  livongo_protobuf_grpc_external_bh_stepped_care_stepped_care_pb.SelectPrimaryNeedRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.SelectPrimaryNeedRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.SelectPrimaryNeedRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.SteppedCareServiceClient.prototype.selectPrimaryNeed =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.livongo.protobuf.grpc.external.bh.stepped_care.SteppedCareService/SelectPrimaryNeed',
      request,
      metadata || {},
      methodDescriptor_SteppedCareService_SelectPrimaryNeed,
      callback);
};


/**
 * @param {!proto.com.livongo.protobuf.grpc.external.bh.stepped_care.SelectPrimaryNeedRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.com.livongo.protobuf.grpc.external.bh.stepped_care.SteppedCareServicePromiseClient.prototype.selectPrimaryNeed =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.livongo.protobuf.grpc.external.bh.stepped_care.SteppedCareService/SelectPrimaryNeed',
      request,
      metadata || {},
      methodDescriptor_SteppedCareService_SelectPrimaryNeed);
};


module.exports = proto.com.livongo.protobuf.grpc.external.bh.stepped_care;

