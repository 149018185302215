import { ActionTree, GetterTree, MutationTree } from 'vuex';

import { sub } from 'date-fns';

import HTTP from '@/services/HttpService';
import apiRoutes from '@config/api-routes';
import PathLookupService from '@/services/PathLookupService';

import { RootState } from '@/types/storeTypes';
import { AxiosResponse } from 'axios';
import {
  TeletherapyConsultation,
  MessagesResponse
} from '@/types/TeletherapyTypes';
import WebViewService, { OneAppQueryParams } from '@/services/WebViewService';
import { oneAppSessionStorageValues } from '@/store/WebviewStore';

// Tell webpack to code split and dynamically import the teladoc webSDK module
const webSDKPromise = import(
  /* webpackChunkName: "td-websdk" */
  /* webpackPrefetch: true */
  '@td/websdk'
);

interface TeletherapyConfig {
  bundleIdentifier: string;
  baseUrl: string;
  baseIconUrl: string;
  baseHyUrl: string;
  baseHyNoApiUrl: string;
  apiToken: string;
  googleApiKey: string;
  browserNavigationEnabled: boolean;
  locale: string;
  initialState: object;
}

interface TeletherapyStore {
  unreadTeletherapyMessages: number | null;
  teletherapyAuthorization: {
    ssoToken: string | null;
    error: string | null;
  };
  teletherapyConfig: TeletherapyConfig;
  teletherapyConsultation: null | TeletherapyConsultation;
  teletherapyAuthorizationPromise: Promise<AxiosResponse | void> | null;
  teletherapyConsultationPromise: Promise<AxiosResponse | void> | null;
  teletherapyMessagePromise: Promise<AxiosResponse | void> | null;
  teleMsgPollingIntervalId: null | number;
  consultationCancelled: boolean;
}

const state: TeletherapyStore = {
  unreadTeletherapyMessages: null,
  teletherapyAuthorization: {
    ssoToken: null,
    error: null
  },
  teletherapyConfig: {
    bundleIdentifier: PathLookupService.getTeladocWebSdkBundleId(
      WebViewService.isOneApp(WebViewService.getQueryParams()) ||
        !!oneAppSessionStorageValues[OneAppQueryParams.IS_ONE_APP_QUERY_PARAM]
    ),
    baseUrl: PathLookupService.getTeladocWebSdkBaseUrl(
      WebViewService.isOneApp(WebViewService.getQueryParams()) ||
        !!oneAppSessionStorageValues[OneAppQueryParams.IS_ONE_APP_QUERY_PARAM]
    ),
    baseIconUrl: '',
    baseHyUrl: '',
    baseHyNoApiUrl: '',
    apiToken: PathLookupService.getTeladocWebSdkApiKey(
      WebViewService.isOneApp(WebViewService.getQueryParams()) ||
        !!oneAppSessionStorageValues[OneAppQueryParams.IS_ONE_APP_QUERY_PARAM]
    ),
    googleApiKey: PathLookupService.getTeladocWebSdkGoogleApiKey(),
    initialState: {
      consult_id: null
    },
    browserNavigationEnabled: false,
    locale: 'en'
  },
  teletherapyConsultation: null,
  teletherapyAuthorizationPromise: null,
  teletherapyConsultationPromise: null,
  teletherapyMessagePromise: null,
  teleMsgPollingIntervalId: null,
  consultationCancelled: false
};

const POLLING_INTERVAL = 60000;

const getters: GetterTree<TeletherapyStore, RootState> = {
  unreadTeletherapyMessages: (state: TeletherapyStore) =>
    state.unreadTeletherapyMessages,
  teletherapyConfig: (state: TeletherapyStore) => state.teletherapyConfig,
  teletherapyAuth: (state: TeletherapyStore) => state.teletherapyAuthorization,
  hasTeletherapyAuth: (state: TeletherapyStore) =>
    state.teletherapyAuthorization.ssoToken !== null,
  hasTeletherapyAuthError: (state: TeletherapyStore) =>
    state.teletherapyAuthorization.error !== null,
  teletherapyConsultation: (state: TeletherapyStore) =>
    state.teletherapyConsultation,
  consultationCancelled: (state: TeletherapyStore) =>
    state.consultationCancelled,
  teletherapyConsultationPromise: (state: TeletherapyStore) =>
    state.teletherapyConsultationPromise,
  teletherapyMessagePromise: (state: TeletherapyStore) =>
    state.teletherapyMessagePromise
};

const actions: ActionTree<TeletherapyStore, RootState> = {
  getTeletherapyAuthorization({
    commit
  }): Promise<AxiosResponse | void> | null {
    if (state.teletherapyAuthorizationPromise) {
      //return existing auth promise
      return state.teletherapyAuthorizationPromise;
    } else if (state.teletherapyAuthorization.ssoToken) {
      // return resolved promise if we already have a token
      return Promise.resolve();
    }
    const teleAuthPromise = HTTP.post<{ token: string }>(
      apiRoutes.user.getTeletherapyToken
    )
      .then(
        (response) => {
          const token = response.data.token;
          commit('setTeletherapyAuthorization', token);
          commit('setTeletherapyAuthorizationError', null);
        },
        (reason) => {
          commit('setTeletherapyAuthorizationError', reason);
        }
      )
      .finally(() => {
        commit('setTeletherapyAuthPromise', null);
      });
    commit('setTeletherapyAuthPromise', teleAuthPromise);
    return teleAuthPromise;
  },
  async getTeletherapyMessages({ commit }): Promise<AxiosResponse | void> {
    if (state.teletherapyMessagePromise) {
      return state.teletherapyMessagePromise;
    }
    const loadedWebSDK = await webSDKPromise;
    const teletherapyMessagesPromise = loadedWebSDK
      .getMessages({
        appConfiguration: state.teletherapyConfig,
        authorization: state.teletherapyAuthorization
      })
      .then((response: MessagesResponse) => {
        const mostRecentMessage = response?.screens[0].data.threads_list?.[0];
        if (
          mostRecentMessage?.message_type_code === 'MESSAGETYPE_CANCELLATION' &&
          mostRecentMessage?.viewed === false
        ) {
          commit('setConsultationCancelled', true);
        } else {
          commit('setConsultationCancelled', false);
        }
        commit(
          'setUnreadTeletherapyMessages',
          response?.response_data?.unviewed_messages
        );
      })
      .finally(() => {
        commit('setTeletherapyConsultationPromise', null);
      });
    commit('setTeletherapyMessagePromise', teletherapyMessagesPromise);
    return teletherapyMessagesPromise;
  },
  startTeleMsgPolling({ commit, state, dispatch }): void {
    this.dispatch('getTeletherapyMessages');
    if (!state.teleMsgPollingIntervalId) {
      const msgPollingIntervalId = setInterval(
        () => dispatch('getTeletherapyMessages'),
        POLLING_INTERVAL
      );
      commit('setTeleMsgPollingIntervalId', msgPollingIntervalId);
    }
  },
  stopTeleMsgPolling({ commit, state }): void {
    if (state.teleMsgPollingIntervalId) {
      clearInterval(state.teleMsgPollingIntervalId);
    }
    commit('setTeleMsgPollingIntervalId', null);
  },
  async getTeletherapyConsultations({ commit }): Promise<AxiosResponse | void> {
    if (state.teletherapyConsultationPromise) {
      return state.teletherapyConsultationPromise;
    }
    const loadedWebSDK = await webSDKPromise;
    const teletherapyConsultationPromise = loadedWebSDK
      .getConsultations({
        appConfiguration: state.teletherapyConfig,
        authorization: state.teletherapyAuthorization
      })
      .then(
        (response: TeletherapyConsultation[]) => {
          if (response && response.length > 0) {
            //get the soonest consultation
            const sortedConsultations = response.sort(
              (a: TeletherapyConsultation, b: TeletherapyConsultation) => {
                let dateA, dateB;

                if (a.requested_date) {
                  dateA = a.requested_date;
                } else if (a.requested_dates) {
                  dateA = a.requested_dates[0];
                } else {
                  return -1;
                }

                if (b.requested_date) {
                  dateB = b.requested_date;
                } else if (b.requested_dates) {
                  dateB = b.requested_dates[0];
                } else {
                  return 1;
                }
                return dateA > dateB ? 1 : -1;
              }
            );
            const filteredConsultations = sortedConsultations.filter(
              (consultation): boolean => {
                if (!consultation.requested_date) {
                  return false;
                }
                return (
                  new Date(consultation.requested_date) >=
                  sub(new Date(), { minutes: 10 })
                );
              }
            );
            if (filteredConsultations.length > 0) {
              commit('setTeletherapyConsultation', filteredConsultations[0]);
              commit('setSDKConsultId', filteredConsultations[0].consult_id);
            } else {
              commit('setTeletherapyConsultation', null);
            }
          } else {
            commit('setTeletherapyConsultation', null);
          }
        },
        () => {
          //set null status on error
          commit('setTeletherapyConsultation', null);
        }
      )
      .finally(() => {
        commit('setTeletherapyConsultationPromise', null);
      });
    commit('setTeletherapyConsultationPromise', teletherapyConsultationPromise);
    return teletherapyConsultationPromise;
  },
  async cancelTeletherapyConsultation(
    { commit },
    consultationId
  ): Promise<void> {
    const loadedWebSDK = await webSDKPromise;
    return loadedWebSDK
      .cancelConsultation({
        appConfiguration: state.teletherapyConfig,
        authorization: state.teletherapyAuthorization,
        consult_id: consultationId
      })
      .then(() => {
        commit('setTeletherapyConsultation', null);
        commit('setSDKConsultId', null);
      });
  },
  setWebSDKBaseURL({ commit }, baseUrl): void {
    return commit('setWebSDKBaseURL', baseUrl);
  }
};

const mutations: MutationTree<TeletherapyStore> = {
  setUnreadTeletherapyMessages(
    state: TeletherapyStore,
    unreadMessageCount: number
  ) {
    state.unreadTeletherapyMessages = unreadMessageCount;
  },
  setTeletherapyAuthorization(
    state: TeletherapyStore,
    teletherapyAuthorization: string
  ) {
    state.teletherapyAuthorization.ssoToken = teletherapyAuthorization;
  },
  setTeletherapyAuthorizationError(state: TeletherapyStore, authError: string) {
    state.teletherapyAuthorization.error = authError;
  },
  setTeletherapyConsultation(
    state: TeletherapyStore,
    consultation: TeletherapyConsultation
  ) {
    state.teletherapyConsultation = consultation;
  },
  setTeletherapyAuthPromise(
    state: TeletherapyStore,
    teleAuthPromise: Promise<AxiosResponse | void> | null
  ) {
    state.teletherapyAuthorizationPromise = teleAuthPromise;
  },
  setTeletherapyConsultationPromise(
    state: TeletherapyStore,
    teleConsultationPromise: Promise<AxiosResponse | void> | null
  ) {
    state.teletherapyConsultationPromise = teleConsultationPromise;
  },
  setTeletherapyMessagePromise(
    state: TeletherapyStore,
    teleMessagePromise: Promise<AxiosResponse | void> | null
  ) {
    state.teletherapyMessagePromise = teleMessagePromise;
  },
  setTeleMsgPollingIntervalId(
    state: TeletherapyStore,
    teleMsgPollingIntervalId: number
  ): void {
    state.teleMsgPollingIntervalId = teleMsgPollingIntervalId;
  },
  setConsultationCancelled(
    state: TeletherapyStore,
    cancelledStatus: boolean
  ): void {
    state.consultationCancelled = cancelledStatus;
  },
  setWebSDKBaseURL(state: TeletherapyStore, baseUrl: string): void {
    state.teletherapyConfig.baseUrl = baseUrl;
  },
  setSDKConsultId(state: TeletherapyStore, consultId: string): void {
    state.teletherapyConfig.initialState = { consult_id: consultId };
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
