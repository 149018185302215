import { App } from 'vue';

// add new base components here to import them globally within @/main.ts and unit tests
import BaseButton from '@/components/base/BaseButton.vue';
import BaseInputCheckbox from '@/components/base/BaseInputCheckbox.vue';
import BaseInputDate from '@/components/base/BaseInputDate.vue';
import BaseInputRadio from '@/components/base/BaseInputRadio.vue';
import BaseInputText from '@/components/base/BaseInputText.vue';
import BaseInputPhone from '@/components/base/BaseInputPhone.vue';

import BaseWrapper from '@/components/base/BaseWrapper.vue';
import BaseModal from '@/components/base/BaseModal.vue';
import BaseInputTextarea from '@/components/base/BaseInputTextarea.vue';

export default (app: App) => {
  app
    .component('BaseButton', BaseButton)
    .component('BaseInputCheckbox', BaseInputCheckbox)
    .component('BaseInputDate', BaseInputDate)
    .component('BaseInputRadio', BaseInputRadio)
    .component('BaseInputText', BaseInputText)
    .component('BaseWrapper', BaseWrapper)
    .component('BaseModal', BaseModal)
    .component('BaseInputTextarea', BaseInputTextarea)
    .component('BaseInputPhone', BaseInputPhone);
};

export const globalComponents = {
  BaseButton,
  BaseInputCheckbox,
  BaseInputDate,
  BaseInputRadio,
  BaseInputText,
  BaseWrapper,
  BaseModal,
  BaseInputTextarea,
  BaseInputPhone
};
