// Wrapper applied to all children of `/web-view`
const WebViewWrapper = () =>
  import(
    /* webpackChunkName: "web-view" */ '@/components/base/WebViewWrapper.vue'
  );

// Webview views
const ScheduleSetup = () =>
  import(
    /* webpackChunkName: "web-view" */ '@/views/Sleep/ScheduleSetup/ScheduleSetup.vue'
  );
const ScheduleEdit = () =>
  import(
    /* webpackChunkName: "web-view" */ '@/components/sleep/schedule/SleepScheduleEdit.vue'
  );
const SleepCheckIn = () =>
  import(
    /* webpackChunkName: "web-view" */ '@/components/sleep/SleepCheckIn.vue'
  );

const ActivityHistory = () =>
  import(
    /* webpackChunkName: "web-view" */ '@/views/WebView/ActivityHistory.vue'
  );
const ActivityResponse = () =>
  import(
    /* webpackChunkName: "web-view" */ '@/views/WebView/ActivityResponse.vue'
  );
export default [
  {
    path: '/web-view',
    component: WebViewWrapper,
    children: [
      {
        name: 'WebViewScheduleBase',
        path: 'sleep-schedule/',
        component: ScheduleSetup,
        props: {
          isWebView: true,
          requiresAuth: true
        }
      },
      {
        path: 'sleep-schedule/step/',
        redirect: 'sleep-schedule/step/1'
      },
      {
        name: 'WebViewScheduleSetup',
        path: 'sleep-schedule/step/:step',
        component: ScheduleSetup,
        props: {
          isWebView: true,
          requiresAuth: true
        }
      },
      {
        name: 'WebViewScheduleEdit',
        path: 'sleep-schedule/edit',
        component: ScheduleEdit,
        props: {
          isWebView: true,
          isEdit: true
        }
      },
      {
        path: 'sleep-check-in',
        component: SleepCheckIn,
        props: {
          isWebView: true,
          requiresAuth: true
        }
      },
      {
        path: 'sleep-check-in/:step',
        component: SleepCheckIn,
        props: {
          isWebView: true,
          requiresAuth: true
        },
        name: 'WebViewSleepCheckIn'
      },
      {
        path: 'activity-history',
        component: ActivityHistory,
        props: {
          isWebView: true,
          requiresAuth: true
        },
        name: 'WebViewActivityHistory'
      },
      {
        path: 'activity-response',
        component: ActivityResponse,
        props: {
          isWebView: true,
          requiresAuth: true
        },
        name: 'WebViewActivityResponse'
      }
    ]
  }
];
