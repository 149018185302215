
import { defineComponent } from 'vue';
import Alert, { ALERT_TYPES } from '@common/Alert.vue';
import BrandLogo from '@svg/brand-logo.svg';
import OnboardingRedirectService from '../services/OnboardingRedirectService';
import { mapGetters } from 'vuex';

export default defineComponent({
  name: 'SignUpError',
  components: {
    BrandLogo,
    Alert
  },
  data() {
    return {
      ALERT_TYPES,
      alertDestination: '/onboarding'
    };
  },
  computed: mapGetters(['user']),
  watch: {
    user() {
      this.alertDestination = OnboardingRedirectService(this.$route) || '/home';
    }
  },
  mounted() {
    this.alertDestination = OnboardingRedirectService(this.$route) || '/home';
  }
});
