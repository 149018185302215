export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "es",
    "resource": {
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Casa"])},
      "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectar"])},
      "progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progreso"])},
      "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explorar"])},
      "helpResource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recursos de ayuda"])},
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfil"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificaciones"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idioma"])},
      "personalization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalización"])},
      "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apoyo"])},
      "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historia"])},
      "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desconectar"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar"])},
      "oneApp": {
        "guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guía"])},
        "personalization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferencias de contenido"])},
        "helpResource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recursos"])},
        "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Casa de salud mental"])}
      }
    }
  })
}
