export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "es",
    "resource": {
      "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrarse"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrarse"])},
      "loginForm": {
        "emailField": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nombre", "@", "dominio.com"])}
        },
        "passwordField": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["password"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contraseña"])}
        },
        "signInBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrarse"])},
        "nextBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siguiente"])},
        "loginFailureMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No pudimos iniciar sesión. Verifique su correo electrónico y contraseña."])}
      },
      "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Se te olvidó tu contraseña?"])},
      "createNewAccountLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Nuevo usuario?"])},
      "createNewAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea una cuenta"])},
      "dupAccountErrorHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parece que ya tienes una cuenta."])},
      "dupAccountErrorDescr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ya existe una cuenta en myStrength que no se puede fusionar en este momento. Llame a soporte para miembros al (800) 945-4355."])},
      "errorHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algo salió mal"])},
      "errorDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error interno. Llame a soporte para miembros al (800) 945-4355."])},
      "accountUpgradeCompleteDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su cuenta ha sido actualizada!. Inicie sesión para continuar."])},
      "oneapp": {
        "dupAccountErrorHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todavía no hemos fusionado sus cuentas"])},
        "mobile": {
          "dupAccountErrorDescr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deberá iniciar sesión en su aplicación myStrength para acceder al programa de salud mental. Si sigue teniendo problemas, llame al 1-800-835-2362 o"])}
        },
        "web": {
          "dupAccountErrorDescr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deberá iniciar sesión en su cuenta myStrength para acceder al programa de salud mental. Si sigue teniendo problemas, llame al 1-800-835-2362 o"])}
        },
        "emailText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["envíe un correo electrónico a Soporte para miembros."])},
        "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir a miFuerza"])}
      },
      "redirect": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Te llevamos a Teladoc"])},
        "Teladoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Inicia sesión allí y estarás en camino!"])}
      }
    }
  })
}
