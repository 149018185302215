import {
  dynamicComponent,
  dynamicComponentPrefetched
} from '@/helpers/Webpack';

export default [
  {
    path: '/connect',
    name: 'Connect',
    component: dynamicComponent('Connect/ConnectFeatureTabs'),
    meta: { requiresAuth: true }
  },
  {
    path: '/connect/faq',
    name: 'TeletherapyFAQ',
    component: dynamicComponent('Connect/ConnectFeatureTabs'),
    meta: { requiresAuth: true }
  },
  {
    path: '/connect/adolescent/faq',
    name: 'AdolescentTeletherapyFAQ',
    component: dynamicComponent('Teletherapy/AdolescentTeletherapyFAQ'),
    meta: { requiresAuth: true }
  },
  {
    path: '/connect/teletherapy',
    name: 'Teletherapy',
    component: dynamicComponent('Connect/ConnectFeatureTabs'),
    meta: { requiresAuth: true }
  },
  {
    path: '/connect/clinical-coaching',
    name: 'ConnectClinicalCoaching',
    component: dynamicComponentPrefetched('Connect/ConnectFeatureTabs'),
    meta: { requiresAuth: true }
  },
  {
    path: '/connect/clinical-coaching/get-started',
    name: 'ClinicalCoachingGetStarted',
    component: dynamicComponent('ClinicalCoaching/GetStarted'),
    meta: { requiresAuth: true }
  },
  {
    path: '/connect/clinical-coaching/schedule',
    name: 'ClinicalCoachingSchedule',
    component: dynamicComponent('ClinicalCoaching/Schedule'),
    meta: { requiresAuth: true }
  },
  {
    path: '/connect/clinical-coaching/about',
    name: 'ClinicalCoachingAbout',
    component: dynamicComponent('ClinicalCoaching/About'),
    meta: { requiresAuth: true }
  },
  {
    path: '/connect/clinical-coaching/coach-bio',
    name: 'ClinicalCoachingCoachBio',
    component: dynamicComponent('ClinicalCoaching/CoachBio'),
    meta: { requiresAuth: true }
  },
  {
    path: '/connect/clinical-coaching/post-schedule',
    name: 'ClinicalCoachingPostSchedule',
    component: dynamicComponent('ClinicalCoaching/PostSchedule'),
    meta: {
      hideNav: true
    }
  }
];
