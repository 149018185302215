import { dynamicComponent } from '@/helpers/Webpack';

export default [
  {
    path: '/activity/:activitySlug',
    name: 'Activity',
    component: dynamicComponent('Activity/Activity'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/activity/responses/:activitySlug/:activityResponseId?',
    name: 'ActivityResponses',
    component: dynamicComponent('Activity/ActivityResponse'),
    props: true,
    meta: { requiresAuth: true }
  }
];
