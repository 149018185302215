import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d837f2f4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "description" }
const _hoisted_2 = { class: "counter" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseModal = _resolveComponent("BaseModal")!

  return (_openBlock(), _createBlock(_component_BaseModal, {
    class: "session-timer-modal",
    "aria-live": "assertive",
    primaryButton: _ctx.$t('button'),
    "modal-action": _ctx.closeModal,
    "underline-header": false,
    "show-modal": _ctx.showModal,
    title: _ctx.$t('title')
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('description', { brandName: _ctx.brandName })), 1),
      _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.finalTimerCount), 1)
    ]),
    _: 1
  }, 8, ["primaryButton", "modal-action", "show-modal", "title"]))
}