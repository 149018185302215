export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help resources"])},
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are currently thinking about or planning to harm yourself or someone else, please "])},
      "call911": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["call 911"])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([", go to the nearest hospital emergency room, or call the Suicide and Crisis Lifeline at "])},
      "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" or "])}
    }
  })
}
