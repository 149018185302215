
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';

import { format } from 'date-fns';

import { CardState } from '@/types/ClinicalCoachingTypes';

import CardCircleIcon from '@common/cards/CardCircleIcon.vue';

import IconChecklist from '@svg/icon-checklist.svg';
import IconInfo from '@svg/icon-alert-info.svg';
import IconStar from '@svg/icon-star.svg';
import IconCalendar from '@svg/therapy/calendar.svg';
import IconVideo from '@svg/therapy/video.svg';

// Join thresholds for showing join state before/after sessionTime
const JOIN_WINDOW = {
  beforeStart: 600000, // 10 min in ms
  postStart: 600000 // 10 min in ms
};

// Cancel threshold for showing cancel button
const CANCEL_WINDOW = {
  beforeStart: 4 * 60 * 60 * 1000 // 4 hours
};

export default defineComponent({
  name: 'ClinicalCoachingCard',
  emits: ['appointmentCancelled', 'appointmentNoShow'],
  inject: ['$analyticsTrack'],
  components: {
    CardCircleIcon,
    IconChecklist,
    IconCalendar,
    IconInfo,
    IconStar,
    IconVideo
  },
  props: {
    isHomePageCard: {
      type: Boolean,
      default: false
    },
    card: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      cardStateTypes: CardState,
      cancelModalShown: false,
      cancelSessionId: null as number | null,
      joinStateTickInterval: null as number | null,
      showCard: false,
      showCancel: true,
      hasEmittedNoShowEvent: false
    };
  },
  computed: {
    currentCardState(): CardState {
      return this.card.content['coachingCardType'];
    },
    cardCircleColor(): string {
      if (
        this.currentCardState === CardState.EXPIRED ||
        this.currentCardState === CardState.DEACTIVATED
      ) {
        return '#FFCCBF';
      }

      return '#B9E0FF';
    },
    startTime(): string {
      return this.card.content.sessionTime;
    },
    expiredContactMailto(): string {
      return `mailto:mystrengthcoaching@teladochealth.com?subject=${this.$t(
        'expired.contactSubject'
      )}&body=${this.$t('expired.contactBody')}`;
    }
  },
  watch: {
    card: {
      deep: true,
      handler() {
        this.init();
      }
    },
    showCard(value) {
      // If join session query param is present join session
      if (
        this.$route?.query?.joinSession &&
        this.currentCardState === CardState.STARTING_SOON &&
        value
      ) {
        this.joinSessionImmediately();
      }
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (
        [CardState.UPCOMING, CardState.STARTING_SOON].includes(
          this.currentCardState
        )
      ) {
        this.handleCardVisibility();
        // Start ticker for join state transition
        this.startJoinStateTick();
      } else {
        this.showCard = true;
      }
    },
    subStartTime(string: string, sessionTime: string) {
      const timeString = format(new Date(sessionTime || ''), 'h:mm a');
      return string.replace('{0}', timeString);
    },
    handleCardVisibility() {
      const now = new Date().valueOf();
      const startTime = new Date(this.startTime).valueOf();

      if (this.currentCardState === CardState.UPCOMING) {
        const cancelStopTime = startTime - CANCEL_WINDOW.beforeStart;
        const joinStartTime = startTime - JOIN_WINDOW.beforeStart;

        this.showCancel = now < cancelStopTime;
        this.showCard = now < joinStartTime;

        if (now > joinStartTime && this.joinStateTickInterval) {
          clearInterval(this.joinStateTickInterval);
        }
      } else if (this.currentCardState === CardState.STARTING_SOON) {
        const joinStartTime = startTime - JOIN_WINDOW.beforeStart;
        const joinEndTime = startTime + JOIN_WINDOW.postStart;
        const canJoin = now >= joinStartTime && now <= joinEndTime;

        this.showCard = this.card.content.hasJoined || canJoin;

        if (now > joinEndTime) {
          if (!this.hasEmittedNoShowEvent && !this.card?.content.hasJoined) {
            this.$emit('appointmentNoShow');
            this.hasEmittedNoShowEvent = true;
          }

          if (this.joinStateTickInterval) {
            clearInterval(this.joinStateTickInterval);
          }
        }
      }
    },
    startJoinStateTick() {
      if (this.joinStateTickInterval) {
        clearInterval(this.joinStateTickInterval);
      }

      this.joinStateTickInterval = setInterval(() => {
        this.handleCardVisibility();
      }, 1000);
    },
    showCancelModal() {
      this.cancelModalShown = true;
    },
    hideCancelModal() {
      this.cancelModalShown = false;
    },
    cancelUpcomingAppointment() {
      const sessionId = this.card?.content?.sessionId || null;
      if (!sessionId) {
        console.warn('Cancel session failed: no sessionId');
        return;
      }

      this.cancelCoachingSession(sessionId)
        .then(() => {
          this.addSuccessNotification({
            heading: this.$t('modal.cancelAppointment.successMessage')
          });
          if (this.joinStateTickInterval) {
            clearInterval(this.joinStateTickInterval);
          }
          this.showCard = false;
          this.$emit('appointmentCancelled');

          this.$analyticsTrack('MH.ClinicalCoaching.Session.Cancelled');
        })
        .finally(() => {
          this.hideCancelModal();
        });
    },
    longFormatDate(dateString: string) {
      // Sadly, we can't use this.$t() in a filter
      const localizedAt = this.$t('at');

      return format(
        new Date(dateString),
        "EEEE, MMM d '" + localizedAt + "' h:mm a"
      );
    },
    markConferenceAsJoined() {
      this.setAppointmentAsJoined(this.card.content.sessionId);
    },
    joinSessionImmediately() {
      this.markConferenceAsJoined();
      this.$router.push({ name: 'Conference' });
    },
    joinSessionClick() {
      this.markConferenceAsJoined();

      this.$analyticsTrack('MH.ClinicalCoaching.Session.Joined');
    },
    trackGetStartedClick() {
      const eventName = this.isHomePageCard
        ? 'MH.Home.ClinicalCoaching.GetStarted'
        : 'MH.Connect.ClinicalCoaching.GetStarted';

      this.$analyticsTrack(eventName);
    },
    trackScheduleClick() {
      const eventName = this.isHomePageCard
        ? 'MH.Home.ClinicalCoaching.Schedule'
        : 'MH.Connect.ClinicalCoaching.Schedule';

      this.$analyticsTrack(eventName);
    },
    ...mapActions({
      setAppointmentAsJoined: 'setAppointmentAsJoined',
      cancelCoachingSession: 'cancelClinicalCoachingSession',
      addSuccessNotification: 'addSuccessNotification'
    })
  }
});
