import { differenceInYears } from 'date-fns';

export class DateUtil {
  static getDaysInMonth(year: number, month: number): number {
    return new Date(year, month, 0).getDate();
  }

  /**
   * Returns an age in years from a given date of birth
   * @param dob date of birth string of the form 'mm/dd/yyyy'
   */
  static getAgeFromDob(dob: string) {
    const [month, day, year] = dob.split('/');
    return differenceInYears(
      new Date(),
      new Date(Date.UTC(parseInt(year), parseInt(month) - 1, parseInt(day)))
    );
  }

  static isValidDobString(dob: string): boolean {
    const regex =
      /((0[13578]|1[02])[/.]31[/.](18|19|20)[0-9]{2})|((01|0[3-9]|1[1-2])[/.](29|30)[/.](18|19|20)[0-9]{2})|((0[1-9]|1[0-2])[/.](0[1-9]|1[0-9]|2[0-8])[/.](18|19|20)[0-9]{2})|((02)[/.]29[/.](((18|19|20)(04|08|[2468][048]|[13579][26]))|2000))/g;
    return dob.match(regex) !== null;
  }
}
