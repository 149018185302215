export interface Provider {
  org: string | null;
  group: string | null;
  accessCode: string | null;
  referralToken: string | null;
}

export interface CoBrandingContent {
  replacement: string;
  target: string;
}

export interface CoBranding {
  id: number;
  isDefault: boolean;
  accessCodes: CoBrandingContent[];
  arenaApprovalNum: string;
  brandingUrl: string;
  content: string;
  htmlTemplate: string;
}

export interface OrgSignUpCollateral {
  id: number;
  isDefault: boolean;
  signUpFieldViews: SignUpFieldView[];
  accessCodeGroup: null;
}

export enum SignUpFieldType {
  TEXT = 'TEXT',
  DROP_DOWN = 'DROP_DOWN',
  CHECK_BOX = 'CHECK_BOX'
}

export interface SignUpFieldView {
  collectionId: number;
  priority: number;
  name: string;
  type: SignUpFieldType;
  choices: SignUpFieldViewChoices[];
  label: string;
  required: boolean;
  containsSensitiveData: boolean;
  positionOrdinal: number;
  helpTooltip: string;
  enabled: boolean;
  validationRule: string;
  validationFailureText: string;
}

export interface SignUpFieldResponse {
  signUpFieldCollectionId: number;
  signUpFieldName: string;
  response: string | number;
}

export interface SignUpFieldViewChoices {
  filterByValue: string;
  enabled: boolean;
  positionOrdinal: number;
}
