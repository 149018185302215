export enum CardType {
  GREETING = 'GREETING',
  EMOTIONAL_TRACKING = 'EMOTIONAL_TRACKING',
  ASSESSMENT = 'ASSESSMENT',
  TELEHEALTH = 'TELEHEALTH',
  SLEEP_LOG = 'SLEEP_LOG',
  DIGITAL_COURSES = 'DIGITAL_COURSES',
  RECOMMENDED_CONTENT = 'RECOMMENDED_CONTENT',
  PROGRAM = 'PROGRAM',
  FAVORITES = 'FAVORITES',
  IN_THE_MOMENT_TOOLS = 'IN_THE_MOMENT_TOOLS',
  CLINICAL_COACHING = 'CLINICAL_COACHING',
  CRISIS_PLAN = 'CRISIS_PLAN',
  ADOLESCENT_TELEHEALTH = 'ADOLESCENT_TELEHEALTH'
}

export interface HomeCard<CardContent> {
  type: string;
  state: string;
  title: string;
  description: string;
  destination: string;
  content: CardContent;
}
