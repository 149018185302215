
import { defineComponent } from 'vue';
import { mapMutations } from 'vuex';

import { NOTIFICATION_TYPES } from '@/types/NotificationTypes';

import IconAlertInfo from '@svg/icon-alert-info.svg';
import IconAlertWarn from '@svg/icon-alert-warn.svg';
import IconAlertDanger from '@svg/icon-alert-danger.svg';
import IconCheckMarkCircle from '@svg/icon-checkmark-circle.svg';
import IconClose from '@svg/icon-close.svg';

export default defineComponent({
  name: 'Alert',
  components: {
    IconAlertInfo,
    IconAlertWarn,
    IconAlertDanger,
    IconCheckMarkCircle,
    IconClose
  },
  props: {
    id: {
      type: Number,
      required: true
    },
    heading: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: false,
      default: ''
    },
    type: {
      type: String,
      required: false,
      default: NOTIFICATION_TYPES.INFO,
      validator: (value: string) =>
        Object.keys(NOTIFICATION_TYPES).includes(value)
    }
  },
  data() {
    return {
      NOTIFICATION_TYPES: NOTIFICATION_TYPES
    };
  },
  computed: {
    notificationClass(): { [key: string]: boolean } {
      return {
        'notification-info': this.type === NOTIFICATION_TYPES.INFO,
        'notification-success': this.type === NOTIFICATION_TYPES.SUCCESS,
        'notification-warning': this.type === NOTIFICATION_TYPES.WARNING,
        'notification-error': this.type === NOTIFICATION_TYPES.ERROR
      };
    }
  },
  methods: {
    closeNotification(): void {
      this.removeNotification(this.id);
    },
    ...mapMutations(['removeNotification'])
  }
});
