export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help is here"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whatever challenges you're facing, know you're not alone. Create a crisis plan or talk to someone right away."])},
      "crisisCard": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suicide and Crisis Lifeline"])},
        "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["800-273-8255"])},
        "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call"])}
      },
      "crisisPlanCard": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your crisis plan"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recognize your own distress, and learn strategies to get the help you need and the care you deserve."])}
      }
    }
  })
}
