export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "es",
    "resource": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progreso"])},
      "improveSleep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mejora tu sueño"])}
    }
  })
}
