import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-26c0eded"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "progress" }
const _hoisted_2 = { class: "heading-xl" }
const _hoisted_3 = { class: "card-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconSleep = _resolveComponent("IconSleep")!
  const _component_SleepMobileRedirect = _resolveComponent("SleepMobileRedirect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('title')), 1),
    (_ctx.isOneApp && _ctx.isMobileWebView)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "card sleep-card",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.sendUserToOneAppMobile && _ctx.sendUserToOneAppMobile(...args))),
          onKeyup: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.sendUserToOneAppMobile && _ctx.sendUserToOneAppMobile(...args)), ["enter"])),
          role: "button",
          tabindex: "0"
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createTextVNode(_toDisplayString(_ctx.$t('improveSleep')), 1),
            _createVNode(_component_IconSleep, { class: "sleep-icon" })
          ])
        ], 32))
      : (_openBlock(), _createBlock(_component_SleepMobileRedirect, { key: 1 }))
  ]))
}