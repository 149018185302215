export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two-factor authentication"])},
      "emailNoticeCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We just sent you an email with your authentication code. Enter the code below to verify your identity."])},
      "authenticatorNoticeCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please use the Google Authenticator application on your device to retrieve your authentication code. Enter the code in the field below to verify your identity."])},
      "emailSentHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success!"])},
      "emailSentCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A code has been sent to your email"])},
      "verificationForm": {
        "verificationCodeField": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your verification code"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])}
        },
        "submitBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify"])},
        "sendCodeBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send code again"])}
      },
      "returnToApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to myStrength"])},
      "invalidVerificationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid verification code"])}
    }
  })
}
