interface InternalLinkPaths {
  [key: string]: string;
}

/**
 * Internal link service:
 *  Used to convert a common language destinations into URL paths
 *
 * WIKI: https://wiki.livongo.com/x/fx-WBQ
 */
class InternalLinkService {
  private static FALLBACK_PATH = '/home';
  private static PATH_SUFFIX_TOKEN = '{{suffix}}';
  private static OPTIONAL_PATH_SUFFIX_TOKEN = '{{?suffix}}';

  private internalLinkToPath: InternalLinkPaths = {
    activity: '/activity/{{suffix}}',
    program: '/explore/program/{{suffix}}',
    category: '/explore/category/{{suffix}}',
    series: '/explore/navigate/series/{{suffix}}',
    topic: '/explore/navigate/topic/{{suffix}}',
    channel: '/explore/navigate/channel/{{suffix}}',
    search: '/explore/search/{{?suffix}}',
    favorite: '/explore/favorites',
    favorites: '/explore/favorites',
    explore: '/explore',
    'coaching:join_session': '/connect/clinical-coaching?joinSession=true',
    connect: '/connect',
    progress: '/progress',
    settings: '/profile',
    'settings:profile': '/profile',
    'settings:personalization': '/profile/personalization',
    'settings:notification': '/profile/notifications',
    'settings:notifications': '/profile/notifications',
    'settings:support': '/profile/support',
    'settings:language': '/profile/language',
    'settings:languages': '/profile/language',
    help: '/help-resources',
    getHelp: '/help-resources',
    guide: '/home?guideOpen=true',
    'guide:oneapp': '/guide',
    wellcheck: '/assessment/intro',
    //temporarily set all sleep internal links to sleep redirect
    sleep: '/sleep/redirect', //'/sleep',
    'sleep:diary': '/sleep/redirect', //'/sleep/diary',
    sleep_add_entry: '/sleep/redirect', //'/sleep/diary/entry/{{?suffix}}',
    'sleep:entries': '/sleep/redirect', //'/sleep/diary/entries',
    'sleep:schedule': '/sleep/redirect', //'/sleep/schedule',
    'sleep:edit_schedule': '/sleep/redirect', //'/sleep/schedule/edit',
    'sleep:checkin': '/sleep/redirect', //'/sleep/checkin',
    newfeatures: '/newfeatures',
    home: '/home',
    exploreall: '/explore/browse'
  };

  /**
   * getWebPathForInternalLink
   *  Takes a platform generic internalLink and returns web-ui supported path or fallback
   * @param internalLink a internal link string
   * @returns relative path to be followed in web-ui
   */
  public getWebPathForInternalLink(internalLink: string): string {
    const internalLinkParts = internalLink.split(':');
    const internalLinkBase = internalLinkParts[0];
    const internalLinkSuffix = internalLinkParts[1];

    // Handle simple internal links
    let linkPath = this.internalLinkToPath[internalLink] || null;

    // Enforce fallback for non optional suffix
    if (linkPath && linkPath.includes(InternalLinkService.PATH_SUFFIX_TOKEN)) {
      linkPath = null;
    }

    if (linkPath) {
      // Remove optional suffix from path
      linkPath = linkPath.replace(
        InternalLinkService.OPTIONAL_PATH_SUFFIX_TOKEN,
        ''
      );
    } else {
      // Handle dynamic suffix internal links
      const dynamicSuffixPath =
        this.internalLinkToPath[internalLinkBase] || null;
      if (dynamicSuffixPath) {
        const hasOptionalPathSuffix = dynamicSuffixPath.includes(
          InternalLinkService.OPTIONAL_PATH_SUFFIX_TOKEN
        );
        // Place suffix in linkPath
        linkPath = dynamicSuffixPath.replace(
          hasOptionalPathSuffix
            ? InternalLinkService.OPTIONAL_PATH_SUFFIX_TOKEN
            : InternalLinkService.PATH_SUFFIX_TOKEN,
          internalLinkSuffix
        );

        // If no suffix reset linkPath & return fallback
        if (!internalLinkSuffix) {
          linkPath = null;
        }
      }
    }

    return linkPath || InternalLinkService.FALLBACK_PATH;
  }
}

export default new InternalLinkService();
