import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-53192e6f"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "onboarding-wrapper",
  role: "main"
}
const _hoisted_2 = {
  key: 2,
  class: "no-progress"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BrandLogo = _resolveComponent("BrandLogo")!
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_Alert = _resolveComponent("Alert")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.inReassessment && !_ctx.isOneApp)
      ? (_openBlock(), _createBlock(_component_BrandLogo, {
          key: 0,
          class: "logo",
          role: "img",
          "aria-label": _ctx.$t('logoDescription')
        }, null, 8, ["aria-label"]))
      : _createCommentVNode("", true),
    (_ctx.showProgressBar && _ctx.progress)
      ? (_openBlock(), _createBlock(_component_ProgressBar, {
          key: 1,
          progress: _ctx.progress,
          labelText: _ctx.$t('progressBarLabel')
        }, null, 8, ["progress", "labelText"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2)),
    (_ctx.showAlert)
      ? (_openBlock(), _createBlock(_component_Alert, {
          key: 3,
          class: "alert",
          "data-cy": "wrong-chapter-alert",
          type: _ctx.ALERT_TYPES.WARN,
          title: _ctx.$t('wrapper.alertText'),
          externalDestination: _ctx.alertDestination
        }, null, 8, ["type", "title", "externalDestination"]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ]))
}