import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, mergeProps as _mergeProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5445e763"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input-group" }
const _hoisted_2 = ["for"]
const _hoisted_3 = { class: "small label-sub-text" }
const _hoisted_4 = ["id", "type", "name", "aria-invalid", "data-cy", "disabled", "placeholder"]
const _hoisted_5 = { class: "validation-error-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InlineError = _resolveComponent("InlineError")!
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Field, {
      ref: "field",
      modelValue: _ctx.inputValue,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.inputValue) = $event)),
      name: _ctx.inputName,
      rules: _ctx.validationRules
    }, {
      default: _withCtx(({ field, errors, meta }) => [
        _createElementVNode("label", {
          for: _ctx.computedInputId,
          class: _normalizeClass(`${_ctx.labelClass} ${_ctx.labelErrorClass(meta)}`)
        }, _toDisplayString(_ctx.labelText), 11, _hoisted_2),
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.labelSubText), 1),
        _createElementVNode("input", _mergeProps(field, {
          ref: "validatedInputGroupInput",
          maxlength: "10",
          id: _ctx.computedInputId,
          type: _ctx.inputType,
          name: _ctx.inputName,
          "aria-invalid": !meta.valid,
          class: _ctx.inputClass(meta),
          "data-cy": _ctx.dataCy,
          disabled: _ctx.disabled,
          placeholder: _ctx.placeholder,
          onKeydown: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onKeyDown && _ctx.onKeyDown(...args))),
          onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.updateModel && _ctx.updateModel(...args)))
        }), null, 16, _hoisted_4),
        _createElementVNode("div", _hoisted_5, [
          (errors && errors[0])
            ? _renderSlot(_ctx.$slots, "default", { key: 0 }, () => [
                _createVNode(_component_InlineError, {
                  message: errors && _ctx.$t(errors[0])
                }, null, 8, ["message"])
              ], true)
            : _createCommentVNode("", true)
        ])
      ]),
      _: 3
    }, 8, ["modelValue", "name", "rules"])
  ]))
}