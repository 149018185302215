export interface SleepSchedule {
  averages: {
    sleepEfficiencyPercent: number;
    minutesInBed: number;
    minutesAsleep: number;
    averageOutOfBedTime: [number, number];
    sleepQuality: string;
  };
  minimumMinutesAsleep: number;
  recommendedMinutesInBed: number;
  recommendedInBedTime: [number, number];
  averageOutOfBedTime: [number, number];
}

export interface TimeObject {
  hour: number;
  minute: number;
}

export interface TimeInputControl {
  canIncrement: boolean;
  canDecrement: boolean;
}

export enum RECCOMMENDED_RANGE_STATUS {
  INSIDE = 'INSIDE',
  OUTSIDE = 'OUTSIDE',
  IN_RANGE = 'IN_RANGE'
}

export enum TIME_PICKER_LIMIT {
  LESS_THAN_LIMIT = 'LESS_THAN_LIMIT',
  MORE_THAN_LIMIT = 'MORE_THAN_LIMIT',
  WITHIN_LIMIT = 'WITHIN_LIMIT'
}
