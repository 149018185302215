export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "initial": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talk with a coach"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schedule a video session with a certified coach."])},
        "buttonGetStarted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get started"])}
      },
      "upcoming": {
        "buttonCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
      },
      "starting_soon": {
        "buttonJoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join"])}
      },
      "schedule_next": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schedule your next coaching session"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stay on track with your program and schedule now."])},
        "buttonSchedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schedule"])}
      },
      "schedule_booster": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have a booster session available"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get some extra support from your coach."])},
        "buttonSchedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schedule"])}
      },
      "completed": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congratulations! You've completed your coaching program."])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bring your new skills to life with continued practice!"])}
      },
      "expired": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are no longer eligible for coaching"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your 12 weeks of eligibility have expired or you've been deactivated. If you believe this is an error, please reach out to the coaching team."])},
        "linkContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact coaching"])},
        "linkMoreInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More info"])},
        "contactSubject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching eligibility"])},
        "contactBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have a question about my eligibility for the coaching program."])}
      },
      "modal": {
        "cancelAppointment": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to cancel this session?"])},
          "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, cancel session"])},
          "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, don't cancel"])},
          "successMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your session was successfully canceled"])}
        }
      }
    }
  })
}
