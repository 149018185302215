import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';

import HTTP from '@/services/HttpService';
import apiRoutes from '@config/api-routes';

import { RootState } from '@/types/storeTypes';
import { AxiosResponse } from 'axios';
import { Status, Track, Coach, Card } from '@/types/ClinicalCoachingTypes';

export interface ClinicalCoachingStore {
  coach: Coach | null;
  status: Status | null;
  cards: Card[] | null;
  track: Track | null;
}

const state: ClinicalCoachingStore = {
  coach: null,
  status: null,
  cards: null,
  track: null
};

const getters: GetterTree<ClinicalCoachingStore, RootState> = {
  coach: (state: ClinicalCoachingStore) => state.coach,
  clinicalCoachingStatus: (state: ClinicalCoachingStore) => state.status,
  clinicalCoachingCards: (state: ClinicalCoachingStore) => state.cards,
  clinicalCoachingTrack: (state: ClinicalCoachingStore) => state.track
};

const actions: ActionTree<ClinicalCoachingStore, RootState> = {
  getUsersCoach({ commit }): void {
    HTTP.get(apiRoutes.clinicalCoaching.getUsersCoach).then((response) => {
      commit('setCoach', response.data);
    });
  },
  cancelClinicalCoachingSession(
    store,
    sessionId: number
  ): Promise<AxiosResponse> {
    return HTTP.delete(
      apiRoutes.clinicalCoaching.cancelSessionAppointment(sessionId)
    );
  },
  getClinicalCoachingStatus({ commit }): Promise<AxiosResponse> {
    return HTTP.get(apiRoutes.clinicalCoaching.getStatus).then((response) => {
      commit('setClinicalCoachingStatus', response.data);
      return response;
    });
  },
  getClinicalCoachingCards({ commit }): Promise<AxiosResponse> {
    return HTTP.get(apiRoutes.clinicalCoaching.getCards, {
      headers: { 'x-version': '2' }
    }).then((response) => {
      commit('setClinicalCoachingCards', response.data);
      return response;
    });
  },
  getClinicalCoachingTrack({ commit }): Promise<AxiosResponse> {
    return HTTP.get(apiRoutes.clinicalCoaching.getTrack, {
      headers: { 'x-version': '2' }
    }).then((response) => {
      commit('setClinicalCoachingTrack', response.data);
      return response;
    });
  },
  setAppointmentAsJoined(store, sessionId: number): Promise<AxiosResponse> {
    return HTTP.post(
      apiRoutes.clinicalCoaching.joinSessionAppointment(sessionId)
    );
  }
};

const mutations: MutationTree<ClinicalCoachingStore> = {
  setCoach(state: ClinicalCoachingStore, coach: Coach) {
    state.coach = coach;
  },
  setClinicalCoachingStatus(state: ClinicalCoachingStore, status: Status) {
    state.status = status;
  },
  setClinicalCoachingCards(state: ClinicalCoachingStore, cards: Card[]) {
    state.cards = cards;
  },
  setClinicalCoachingTrack(state: ClinicalCoachingStore, track: Track) {
    state.track = track;
  }
};

export const ClinicalCoachingStore: Module<ClinicalCoachingStore, RootState> = {
  state,
  actions,
  mutations,
  getters
};

export default ClinicalCoachingStore;
