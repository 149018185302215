import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, mergeProps as _mergeProps, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-52c29704"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input-group" }
const _hoisted_2 = ["for"]
const _hoisted_3 = {
  key: 1,
  class: "small"
}
const _hoisted_4 = {
  key: 0,
  class: "slot-icon-left"
}
const _hoisted_5 = ["autocomplete", "id", "data-cy", "type", "name", "aria-invalid", "disabled", "placeholder"]
const _hoisted_6 = {
  key: 1,
  class: "slot-icon-right"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InlineError = _resolveComponent("InlineError")!
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.labelText)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.computedInputId,
          class: _normalizeClass(_ctx.labelClass)
        }, _toDisplayString(_ctx.labelText), 11, _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.labelSubText)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.labelSubText), 1))
      : _createCommentVNode("", true),
    _createVNode(_component_Field, {
      class: "validation-provider",
      ref: "field",
      modelValue: _ctx.inputValue,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.inputValue) = $event)),
      mode: _ctx.validateMode,
      rules: _ctx.validationRules,
      name: _ctx.inputName,
      bails: !_ctx.getAllErrors
    }, {
      default: _withCtx(({ field, errors, meta }) => [
        (_ctx.$slots.iconLeft)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _renderSlot(_ctx.$slots, "iconLeft", {}, undefined, true)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("textarea", _mergeProps({ class: "input" }, field, {
          ref: "validatedInputGroupInput",
          autocomplete: _ctx.autocomplete,
          id: _ctx.computedInputId,
          "data-cy": _ctx.dataCy,
          type: _ctx.inputType,
          name: _ctx.inputName,
          "aria-invalid": !meta.valid,
          class: _ctx.inputClass(meta),
          disabled: _ctx.disabled,
          placeholder: _ctx.placeholder,
          onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateModel && _ctx.updateModel(...args)))
        }), null, 16, _hoisted_5),
        (_ctx.$slots.iconRight)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _renderSlot(_ctx.$slots, "iconRight", {}, undefined, true)
            ]))
          : _createCommentVNode("", true),
        (errors[0] && _ctx.displayValidationError)
          ? _renderSlot(_ctx.$slots, "error-message", { key: 2 }, () => [
              _createVNode(_component_InlineError, {
                "data-cy": `${_ctx.dataCy}-errors`,
                message: errors[0]
              }, null, 8, ["data-cy", "message"])
            ], true)
          : _createCommentVNode("", true)
      ]),
      _: 3
    }, 8, ["modelValue", "mode", "rules", "name", "bails"])
  ]))
}