
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import { OnboardingChapter } from '@/types/OnboardingTypes';

import LoadingSpinner from '@common/LoadingSpinner.vue';
import OnboardWrapper from '@/components/onboarding/OnboardWrapper.vue';

const TIMEOUT = 3000; // 3 Seconds

export default defineComponent({
  name: 'AgingUp',
  components: {
    LoadingSpinner,
    OnboardWrapper
  },
  data() {
    return {
      loading: false,
      timeoutId: null as null | number,
      OnboardingChapter
    };
  },
  computed: mapGetters(['isTeletherapyUser']),
  onBeforeUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  },
  methods: {
    onButtonClick() {
      this.deleteAdolescence();
      this.loading = true;
      this.timeoutId = setTimeout(() => {
        this.$router.push({ name: 'Home' });
      }, TIMEOUT);
    },
    ...mapActions(['deleteAdolescence'])
  }
});
