import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["aria-label", "aria-disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: "btn-pulse",
    "aria-label": _ctx.ariaLabel,
    "aria-disabled": _ctx.disabled,
    ref: "button",
    onFocus: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('focus', $event))),
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 40, _hoisted_1))
}