import { handleInvalidTokenFlow } from '@/services/HttpService';
import PathLookupService from '@/services/PathLookupService';
import AuthService from '@/services/AuthService';

import { Error, Request } from 'grpc-web';

// Proto bindings
import { AssessmentServicePromiseClient } from '@/generated-proto/livongo/protobuf/grpc/external/bh/wellness_assessments/wellness_assessments_service_grpc_web_pb';
import { SteppedCareServicePromiseClient } from '@/generated-proto/livongo/protobuf/grpc/external/bh/stepped_care/stepped_care_service_grpc_web_pb';

interface GRPCwindow {
  __GRPCWEB_DEVTOOLS__: unknown;
}
const grpcWindow = window as unknown as GRPCwindow;
const enableGRPCDevTools =
  (grpcWindow.__GRPCWEB_DEVTOOLS__ as (service: unknown[]) => void) ||
  (() => null);

interface Message {
  setParent?: (parent: string) => void;
}

const hostname = PathLookupService.getMicroserviceBaseUrl();
const authInterceptor = {
  intercept: function (
    request: Request<Message, never>,
    invoker: (request: Request<Message, never>) => Promise<never>
  ): Promise<unknown> {
    const metaData = request.getMetadata();
    metaData.Authorization = `Bearer ${AuthService.getAuthToken()}`;
    // After the RPC returns successfully, update the response.
    return invoker(request).then(
      (response: unknown) => {
        // You can also do something with response metadata here.
        return response;
      },
      (error: Error) => {
        if (error.code === 16) {
          let authToken: string | null = null;
          return handleInvalidTokenFlow()
            .then(
              () => {
                authToken = AuthService.getAuthToken();

                if (authToken) {
                  const requestMessage = request.getRequestMessage();
                  const userId = AuthService.getUserId();
                  if (requestMessage.setParent && userId) {
                    requestMessage.setParent(userId);
                  }

                  metaData.Authorization = `Bearer ${authToken}`;
                  return invoker(request);
                }
              },
              () => {
                return Promise.reject(error);
              }
            )
            .finally(() => {
              if (!authToken) {
                return handleInvalidTokenFlow();
              }
            });
        } else {
          throw error;
        }
      }
    );
  }
};

export async function getParentUser(): Promise<string | null> {
  const token = AuthService.getAuthToken();
  if (!token) {
    await handleInvalidTokenFlow();
  }
  return Promise.resolve(AuthService.getUserId() || null);
}

// Exporting Endpoint Clients
// TODO: Make this URL dynamic
export const assessmentServicePromiseClient =
  new AssessmentServicePromiseClient(hostname, null, {
    unaryInterceptors: [authInterceptor]
  });

export const steppedCareServicePromiseClient =
  new SteppedCareServicePromiseClient(hostname, null, {
    unaryInterceptors: [authInterceptor]
  });

enableGRPCDevTools([
  assessmentServicePromiseClient,
  steppedCareServicePromiseClient
]) as unknown;
