export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "es",
    "resource": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Encontraste algo que amas?"])},
      "headerToggled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aún no has añadido ningún favorito."])},
      "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toque el icono del corazón para agregarlo a sus favoritos."])},
      "instructionsToggled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toque el ícono del corazón para agregar algo a sus favoritos. Aparecerá aquí para facilitar el acceso."])}
    }
  })
}
