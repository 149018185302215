
import { defineComponent } from 'vue';
import { BackButton } from '@common/index';

export default defineComponent({
  name: 'ExploreTitle',
  components: {
    BackButton
  },
  props: {
    title: {
      type: String,
      required: false,
      default: null
    },
    description: {
      type: String,
      required: false,
      default: null
    },
    backPath: {
      type: [String, Object],
      required: false,
      default: ''
    }
  },
  data() {
    return {
      fromPath: this.$route.query?.from || ''
    };
  },
  methods: {
    goBack(): void {
      if (!this.backPath) {
        this.$router.go(-1);
      }
    }
  }
});
