
import { defineComponent } from 'vue';
import BaseWrapper from '@/components/base/BaseWrapper.vue';

export default defineComponent({
  name: 'GdprTerms',
  components: {
    BaseWrapper
  },
  methods: {
    acceptTerms() {
      this.$router.push({ name: 'AccountSetup' });
    }
  }
});
