export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "signUpError": {
        "alertText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration has already been completed. Please click here to return to the correct chapter."])}
      }
    }
  })
}
