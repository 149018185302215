
import { defineComponent } from 'vue';
import { defineRule } from 'vee-validate';

import { CountryCode } from 'libphonenumber-js';

import { parse } from '@/helpers/PhoneNumber';
import BaseInput from '@/components/base/mixin/BaseInput';

import CountryService from '@/services/CountryService';
import CountrySelector from '@common/CountrySelector.vue';

defineRule('validPhoneNumber', (value: string, [code]: CountryCode[]) => {
  const phoneNumber = parse(value, code);

  if (!phoneNumber) {
    return false;
  }

  return phoneNumber.isPossible() === true;
});

export default defineComponent({
  name: 'BaseInputPhone',
  emits: ['update:inputModel', 'selectCountry'],
  components: {
    CountrySelector
  },
  mixins: [BaseInput],
  props: {
    staticCountry: {
      type: String,
      default: null
    },
    isRequired: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      phone: '',
      countryCode: null as null | CountryCode,
      countryCallingCode: null as null | number
    };
  },
  watch: {
    phone() {
      this.$emit('update:inputModel', this.phone);
    },
    countryCode(value: CountryCode) {
      this.$emit('selectCountry', value);
      this.focusOnInput();
    }
  },
  computed: {
    phoneNumberValidationRules() {
      const required = 'required';
      const validPhoneNumber = `validPhoneNumber:${this.countryCode}`;

      if (this.isRequired) {
        return [required, validPhoneNumber].join('|');
      } else {
        return validPhoneNumber;
      }
    }
  },
  mount() {
    this.init();
  },
  methods: {
    init() {
      const startingCountry = CountryService.getDefaultCountry();
      this.countryCode = startingCountry.code;
      this.countryCallingCode = startingCountry.callingCode;
    },
    focusOnInput() {
      const input = this.$refs.PhoneNumberInput.$refs
        .validatedInputGroupInput as HTMLElement;

      if (input) {
        input.focus();
      }
    }
  }
});
