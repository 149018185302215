import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BrandLogo = _resolveComponent("BrandLogo")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_NavigationList = _resolveComponent("NavigationList")!

  return (!_ctx.isGuideMessageOnlyView)
    ? (_openBlock(), _createElementBlock("nav", {
        key: 0,
        id: "nav-side",
        class: _normalizeClass({ 'is-one-app': _ctx.isOneApp }),
        "aria-label": "Sidebar navigation"
      }, [
        _createVNode(_component_router_link, {
          class: "logo-link",
          to: { name: 'Home' },
          "aria-label": _ctx.$t('homeLinkAria')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BrandLogo, {
              class: "logo",
              role: "img",
              "aria-label": _ctx.$t('logoDescription')
            }, null, 8, ["aria-label"])
          ]),
          _: 1
        }, 8, ["aria-label"]),
        _createVNode(_component_NavigationList, { navList: _ctx.navItems }, null, 8, ["navList"])
      ], 2))
    : _createCommentVNode("", true)
}