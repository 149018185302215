
import { defineComponent } from 'vue';

import BaseButton from '@/components/base/BaseButton.vue';

export default defineComponent({
  name: 'NextButton',
  emits: ['click', 'clicked-next'],
  components: {
    BaseButton
  },
  props: {
    disabled: {
      type: Boolean
    },
    type: {
      type: String
    },
    buttonClass: {
      type: String,
      default: 'btn-ghost',
      required: false
    },
    buttonText: {
      type: String,
      default: null,
      required: false
    }
  },
  methods: {
    emitEvents(): void {
      this.$emit('click');
      this.$emit('clicked-next');
    }
  }
});
