export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "es",
    "resource": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valoramos su privacidad"])},
      "terms": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("brandName")), " se compromete a proteger y respetar su privacidad. Para que podamos brindarle acceso al servicio ", _interpolate(_named("brandName")), ", se le pedirá que proporcione ciertos datos personales. Puede retirar su consentimiento en cualquier momento. Para obtener más información sobre cómo retirar su consentimiento, nuestras prácticas de privacidad y cómo nos comprometemos a proteger y respetar su privacidad, consulte nuestro ", _interpolate(_named("privacyLink")), "."])},
      "terms2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["términos de prueba ", _interpolate(_named("brandName"))])},
      "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de privacidad"])},
      "acceptButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acepto"])}
    }
  })
}
