export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to a new and improved myStrength!"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover a more personalized level of emotional support to take on life’s evolving challenges."])},
      "features": {
        "home": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["home"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A single place to guide your next steps"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our new Home will help you focus on what’s next, guide you along your plan, and give recommendations based on your unique needs."])}
        },
        "explore": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["explore"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A library full of fresh, relevant resources to fit your needs"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover skill-building activities and tools to help you feel better in the moment, including updated programs for depression and anxiety."])}
        },
        "sleep": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sleep"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New and improved sleep tools"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From logging your sleep to getting a customized sleep schedule, we’ll help make tracking your sleep easier than ever."])}
        },
        "assessments": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["assessments"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A more powerful, personalized assessment"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Everything you share in our new assessment will help create a plan with recommendations just for you. We’ll also check in with you along the way so we can make sure you’re getting the right level of support. "])}
        },
        "trackers": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trackers and goal setting"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Track how you’re feeling day-to-day"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Easily check in with how you’re feeling each day on Home. We’ll be adding even more trackers and goal-setting tools in the future — stay tuned!"])}
        },
        "favorites": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["favorites"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quick access to your favorite activities"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Easily find and save your favorites. Don’t worry — all your previous favorites for existing activities will still be available."])}
        },
        "history": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["history"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your history at-a-glance"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find a list of the activities you’ve completed to help track your journey."])}
        },
        "reminders": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reminders"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Important reminders to help you stay on track"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We’ll notify you of important tasks to help you stay focused."])}
        }
      }
    }
  })
}
