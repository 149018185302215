import { RecommendedStep } from '@/generated-proto/livongo/protobuf/common/bh/programRecommendation_pb';

export enum OnboardingChapter {
  Chapter1,
  Chapter2,
  Chapter3
}

export const StepsOfCare = Object.keys(RecommendedStep.Values).reduce(
  (prev, curr) => {
    prev[curr] = curr;
    return prev;
  },
  {} as Record<string, string>
);
