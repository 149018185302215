import { App } from 'vue';

import mixpanel, { Dict } from 'mixpanel-browser';
import authService from '@/services/AuthService';

import {
  currentDateTimeUTC,
  currentURLWithNoActivitySlug,
  currentURLIncludesActivity
} from '@/helpers/Analytics';

import store from '@/store/index';

export interface VueMixpanelPlugin {
  install: (app: App) => void;
}

// Shows mixpanel data in browser console and uses token DEV_MODE_TOKEN
const IS_DEV_MODE = process.env.NODE_ENV !== 'production';

const DEV_MODE_TOKEN = 'test';

const mixpanelToken = IS_DEV_MODE
  ? DEV_MODE_TOKEN
  : process.env.VUE_APP_MIXPANEL_TOKEN ||
    window.MYSTRENGTH_GLOBAL_ENV_VARIABLES.VUE_APP_MIXPANEL_TOKEN ||
    null;

const canTrackAnalytics = mixpanelToken !== null;

// window.navigator
declare interface Navigator {
  readonly userAgentData?: { platform: string };
  readonly platform: string;
  readonly userAgent: string;
}

export const analyticsTrack = function (
  eventName: string,
  extraEventData?: Record<string, string | number | undefined>
) {
  /**
   * exposes mixpanel.track for use throughout web-ui
   * assumes that mixpanel has already been initialized
   *
   * @static
   * @function $analyticsTrack
   * @param {eventName} string - String used to identify a specific event
   * @param {extraEventData} Dictionary - <string, any> used to track details related to an event
   * @example
   * this.$analyticsTrack('Activity started', {activityId: 1234})
   * @returns {void}
   */
  const eventData = { ...extraEventData };
  const removeActivitySlugs = (): void => {
    if (currentURLIncludesActivity()) {
      eventData['$current_url'] = currentURLWithNoActivitySlug();
      eventData['$referrer'] = currentURLWithNoActivitySlug();
    }
  };

  const setEventTime = (): void => {
    eventData['$MH.EventTime'] = currentDateTimeUTC();
  };

  const adjustEventData = (): void => {
    setEventTime();
    removeActivitySlugs();
  };

  if (canTrackAnalytics) {
    adjustEventData();
    mixpanel.track(eventName, eventData);

    if (IS_DEV_MODE) {
      console.log(
        '🦉 Mixpanel event tracked 🦉',
        '\n',
        'eventName: ',
        eventName,
        '\n',
        'eventData: ',
        eventData
      );
    }
  } else {
    return;
  }
};

/**
 * exposes mixpanel.register for use throughout web-ui
 * @static
 * @function $analyticsRegisterGlobalFields
 * @param {globalFields} Dictionary - <string, any> used to register properties that should be a part of every event
 * @example
 * this.$analyticsRegisterGlobalFields({$organization_id: 1234})
 * @returns {void}
 */
export const analyticsRegisterGlobalFields = function (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  this: any,
  addedGlobalFields: Dict
) {
  const getOS = () => {
    const defaultValue = 'unknown';
    const navigator = window.navigator as Navigator;

    const userAgent = navigator.userAgent || defaultValue;
    const platform =
      navigator?.userAgentData?.platform || navigator?.platform || defaultValue;

    const iosPlatforms = ['iPhone', 'iPad', 'iPod'];

    let os = defaultValue;
    if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else {
      os = 'Web';
    }

    return os;
  };

  // Super properties required here:
  // https://docs.google.com/spreadsheets/d/1SQpBCc82A4M4pZx1-yZQQunY8L78BDnzYDtVOyuKPsw/edit#gid=1653108838
  const superProperties = {} as Record<
    string,
    string | number | boolean | null
  >;
  superProperties['$Environment'] = window.location.hostname;
  superProperties['$Platform'] = getOS();
  superProperties['$Language'] = store?.getters.user?.language || 'en-US';

  superProperties['$MSUserId'] =
    store?.getters.userId || authService.getMyStrengthUserId();
  superProperties['$LivongoUserId'] = authService.getLivongoUserId();

  superProperties['$ProgramVersion'] =
    store?.getters.clientOfferingVersion || null;
  superProperties['$OrganizationId'] = store?.getters.organizationId || null;
  superProperties['$AccessCode'] =
    store?.getters.userAccessCode?.accessCode || null;

  if (store?.getters.isOneApp) {
    superProperties['$TeladocId'] = store?.getters.teladocMemberId;
    superProperties['$OneApp'] = store?.getters.isOneApp;
  }

  if (canTrackAnalytics) {
    if (addedGlobalFields) {
      mixpanel.register({ ...superProperties, ...addedGlobalFields });
    } else {
      mixpanel.register(superProperties);
    }

    if (IS_DEV_MODE) {
      console.log(
        '🦉 mixpanel.register(): 🦉',
        '\n',
        'superProperties: ',
        superProperties
      );
    }
  }
};

/**
 * exposes mixpanel.reset for use throughout web-ui
 * @static
 */
export const analyticsReset = function () {
  if (canTrackAnalytics) {
    mixpanel.reset();

    if (IS_DEV_MODE) {
      console.log('🦉 mixpanel.reset() 🦉');
    }
  }
};

export default {
  /**
   * installs mixpanel plugin for use throughout web-ui
   * @static
   * @function install
   * @param {Vue} Vue - The global Vue instance.
   * @param {options} Dictionary = options are config options we can pass to mixpanel.
   * @example
   * app.use(VueMixpanel)
   * @returns {void}
   */
  install(app: App) {
    if (canTrackAnalytics) {
      mixpanel.init(mixpanelToken);

      if (IS_DEV_MODE) {
        console.log(
          '🦉 mixpanel.init(token) : 🦉',
          '\n',
          `token: ${mixpanelToken}`
        );
      }
    }

    app.provide(
      '$analyticsRegisterGlobalFields',
      analyticsRegisterGlobalFields
    );
    app.provide('$analyticsTrack', analyticsTrack);
    app.provide('$analyticsReset', analyticsReset);
  }
};
