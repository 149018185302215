import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withKeys as _withKeys, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-57d2d87e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "tabs",
  id: "profile-nav",
  role: "tablist"
}
const _hoisted_2 = ["onKeypress", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.profileTabItems, (navItem) => {
      return (_openBlock(), _createElementBlock("li", {
        role: "tab",
        tabindex: "0",
        "aria-controls": "profile-tab-panel",
        ref_for: true,
        ref: navItem.routeName,
        class: _normalizeClass(_ctx.tabClass(navItem.routeName)),
        key: navItem.routeName,
        onKeypress: _withKeys(_withModifiers(() => _ctx.onNavigate(navItem.routeName), ["prevent"]), ["enter"]),
        onClick: _withModifiers(() => _ctx.onNavigate(navItem.routeName), ["prevent"])
      }, [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t(navItem.i18nLabel)), 1)
      ], 42, _hoisted_2))
    }), 128))
  ]))
}