import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b5d79004"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home-wrapper" }
const _hoisted_2 = { class: "home" }
const _hoisted_3 = { class: "heading-md favorite-heading" }
const _hoisted_4 = { class: "favorites" }
const _hoisted_5 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MemberMessages = _resolveComponent("MemberMessages")!
  const _component_Tracker = _resolveComponent("Tracker")!
  const _component_CrisisPlanCard = _resolveComponent("CrisisPlanCard")!
  const _component_ClinicalCoachingCard = _resolveComponent("ClinicalCoachingCard")!
  const _component_IconClipboardCheckmark = _resolveComponent("IconClipboardCheckmark")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_CardCircleIcon = _resolveComponent("CardCircleIcon")!
  const _component_TelehealthCard = _resolveComponent("TelehealthCard")!
  const _component_CardCircleImg = _resolveComponent("CardCircleImg")!
  const _component_Hero = _resolveComponent("Hero")!
  const _component_SleepDiaryIcon = _resolveComponent("SleepDiaryIcon")!
  const _component_IconTherapy = _resolveComponent("IconTherapy")!
  const _component_CrisisFooter = _resolveComponent("CrisisFooter")!
  const _component_NoFavorites = _resolveComponent("NoFavorites")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MemberMessages),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cards, (card) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "home-card",
          key: card
        }, [
          (card.type === _ctx.CardType.GREETING)
            ? (_openBlock(), _createElementBlock("h1", {
                key: 0,
                class: "greeting-card heading-lg",
                "data-cy": "greeting-card",
                ref_for: true,
                ref: "homeTitle",
                tabindex: "-1"
              }, _toDisplayString(card.title), 513))
            : (card.type === _ctx.CardType.EMOTIONAL_TRACKING)
              ? (_openBlock(), _createBlock(_component_Tracker, {
                  key: 1,
                  class: "card-tracker",
                  title: card.title,
                  description: card.description,
                  rightText: card.content.rightText,
                  leftText: card.content.leftText,
                  moodSliderId: parseInt(card.content.trackerId)
                }, null, 8, ["title", "description", "rightText", "leftText", "moodSliderId"]))
              : (card.type === _ctx.CardType.CRISIS_PLAN && _ctx.crisisOutreachEnabled)
                ? (_openBlock(), _createBlock(_component_CrisisPlanCard, {
                    key: 2,
                    class: "card-crisis-plan",
                    currentLocation: "Home",
                    card: card
                  }, null, 8, ["card"]))
                : (card.type === _ctx.CardType.CLINICAL_COACHING && _ctx.displayClinicalCoachingCard(card))
                  ? (_openBlock(), _createBlock(_component_ClinicalCoachingCard, {
                      key: 3,
                      class: "card-clinical-coaching",
                      isHomePageCard: true,
                      card: card,
                      onAppointmentCancelled: _ctx.onClinicalCoachingAppointmentCancelled,
                      onAppointmentNoShow: _ctx.onClinicalCoachingAppointmentNoShow
                    }, null, 8, ["card", "onAppointmentCancelled", "onAppointmentNoShow"]))
                  : (card.type === _ctx.CardType.ASSESSMENT)
                    ? (_openBlock(), _createBlock(_component_CardCircleIcon, {
                        key: 4,
                        class: "card-assessment",
                        title: card.recommended === _ctx.RecommendationType.PRIMARY ? _ctx.$t('recommended') : null,
                        heading: card.title,
                        cardCircleColor: "#B9E0FF",
                        description: card.description
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_IconClipboardCheckmark, { class: "clipboard-icon" })
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_router_link, {
                            class: "btn-primary destination-button",
                            to: _ctx.getDestinationPath(card.destination)
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(card.content.buttonText), 1)
                            ]),
                            _: 2
                          }, 1032, ["to"])
                        ]),
                        _: 2
                      }, 1032, ["title", "heading", "description"]))
                    : (card.type === _ctx.CardType.TELEHEALTH)
                      ? (_openBlock(), _createBlock(_component_TelehealthCard, {
                          key: 5,
                          class: "card-teletherapy",
                          title: card.recommended === _ctx.RecommendationType.PRIMARY ? _ctx.$t('recommended') : null
                        }, null, 8, ["title"]))
                      : (card.type === _ctx.CardType.RECOMMENDED_CONTENT)
                        ? (_openBlock(), _createBlock(_component_CardCircleImg, {
                            key: 6,
                            class: "card-recommended",
                            title: card.recommended === _ctx.RecommendationType.PRIMARY ? _ctx.$t('recommended') : _ctx.$t('justForYou'),
                            imgUrl: card.content.imageUrl,
                            imgDescription: card.content.imageAccessibilityDescription,
                            routeLocation: { path: _ctx.getDestinationPath(card.destination) },
                            heading: card.title,
                            description: card.description,
                            onNavigate: () => _ctx.onNavigatingToRecommended(card.destination)
                          }, null, 8, ["title", "imgUrl", "imgDescription", "routeLocation", "heading", "description", "onNavigate"]))
                        : (card.type === _ctx.CardType.DIGITAL_COURSES)
                          ? (_openBlock(), _createBlock(_component_Hero, {
                              key: 7,
                              class: "card-digital-courses",
                              title: card.recommended === _ctx.RecommendationType.PRIMARY ? _ctx.$t('recommended') : null,
                              heading: card.title,
                              description: card.description,
                              imgUrl: card.content.imageUrl,
                              imgDescription: card.content.imageAccessibilityDescription
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_router_link, {
                                  class: "btn-primary destination-button",
                                  to: _ctx.getDestinationPath(card.destination)
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(card.content.buttonText), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["to"])
                              ]),
                              _: 2
                            }, 1032, ["title", "heading", "description", "imgUrl", "imgDescription"]))
                          : (card.type === _ctx.CardType.PROGRAM)
                            ? (_openBlock(), _createBlock(_component_CardCircleImg, {
                                key: 8,
                                class: "card-program",
                                title: card.recommended === _ctx.RecommendationType.PRIMARY ? _ctx.$t('recommended') : null,
                                imgUrl: card.content.imageUrl,
                                imgDescription: card.content.imageAccessibilityDescription,
                                description: card.description,
                                heading: card.title
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_router_link, {
                                    class: "btn-primary destination-button",
                                    to: `${_ctx.getDestinationPath(card.destination)}?from=/home`,
                                    onClick: () => _ctx.onNavigatingToProgram(card.destination)
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(card.content.buttonText), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["to", "onClick"])
                                ]),
                                _: 2
                              }, 1032, ["title", "imgUrl", "imgDescription", "description", "heading"]))
                            : (card.type === _ctx.CardType.SLEEP_LOG && _ctx.isOneApp && _ctx.isMobileWebView)
                              ? (_openBlock(), _createBlock(_component_CardCircleIcon, {
                                  key: 9,
                                  class: "card-sleep-diary",
                                  heading: card.title,
                                  description: card.description,
                                  onClick: () => _ctx.sendUserToOneAppMobile(card.destination)
                                }, {
                                  icon: _withCtx(() => [
                                    _createVNode(_component_SleepDiaryIcon, { class: "sleep-diary-icon" })
                                  ]),
                                  _: 2
                                }, 1032, ["heading", "description", "onClick"]))
                              : (card.type === _ctx.CardType.IN_THE_MOMENT_TOOLS)
                                ? (_openBlock(), _createBlock(_component_CardCircleImg, {
                                    key: 10,
                                    class: "card-imt",
                                    imgUrl: card.content.imageUrl,
                                    imgDescription: card.content.imageAccessibilityDescription,
                                    title: _ctx.$t('moment'),
                                    routeLocation: {path: _ctx.getDestinationPath(card.destination)},
                                    heading: card.title,
                                    description: card.description,
                                    onNavigate: () => _ctx.onNavigatingToITMActivity(card.destination)
                                  }, null, 8, ["imgUrl", "imgDescription", "title", "routeLocation", "heading", "description", "onNavigate"]))
                                : (card.type === _ctx.CardType.ADOLESCENT_TELEHEALTH)
                                  ? (_openBlock(), _createBlock(_component_CardCircleIcon, {
                                      key: 11,
                                      class: "card-adolecent-therapy",
                                      title: card.recommended === _ctx.RecommendationType.PRIMARY ? _ctx.$t('recommended-desc') : null,
                                      heading: card.title,
                                      cardCircleColor: "#5B2F91",
                                      description: card.description
                                    }, {
                                      icon: _withCtx(() => [
                                        _createVNode(_component_IconTherapy)
                                      ]),
                                      default: _withCtx(() => [
                                        _createVNode(_component_router_link, {
                                          class: "btn-primary destination-button",
                                          to: { name: 'AdolescentTeletherapyFAQ'}
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(card.content.buttonText), 1)
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1032, ["title", "heading", "description"]))
                                  : _createCommentVNode("", true)
        ]))
      }), 128)),
      (_ctx.crisisOutreachEnabled)
        ? (_openBlock(), _createBlock(_component_CrisisFooter, {
            key: 0,
            class: "crisis-footer"
          }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('favorites')), 1),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.favorites, (favorite, index) => {
          return (_openBlock(), _createBlock(_component_CardCircleImg, {
            class: "card-favorite home-card",
            key: index,
            imgUrl: favorite.content.imageUrl,
            imgDescription: favorite.content.imageAccessibilityDescription,
            heading: favorite.title,
            description: favorite.description,
            routeLocation: { path: _ctx.getDestinationPath(favorite.destination) },
            onNavigate: _ctx.onNavigatingToFavoriteActivity
          }, null, 8, ["imgUrl", "imgDescription", "heading", "description", "routeLocation", "onNavigate"]))
        }), 128))
      ]),
      (_ctx.noFavorites)
        ? (_openBlock(), _createBlock(_component_NoFavorites, {
            key: 1,
            class: "home-card"
          }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        (_ctx.showViewAllFavoritesLink)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              class: "favorites-link",
              to: {name: 'Favorites', query: { from: _ctx.$route.fullPath }}
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('viewAllFavorites')), 1)
              ]),
              _: 1
            }, 8, ["to"]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}