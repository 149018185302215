import { Dictionary } from '@/types/CommonTypes';
import EnumFromStringValue from '@/helpers/EnumFromStringValue';
import { LANGUAGE_TYPES, MOBILE_PLATFORM_TYPES } from '@/types/WebviewTypes';
import { OneAppPayload } from '@/types/OneAppTypes';

export enum OneAppQueryParams {
  IS_ONE_APP_QUERY_PARAM = 'isoneapp',
  IS_MOBILE_WEBVIEW_QUERY_PARAM = 'ismobilewebview',
  MOBILE_PLATFORM = 'mobileplatform',
  LANGUAGE = 'language',
  IS_GUIDE_MESSAGES_PARAM = 'isguidemessages'
}

// Convert strings to boolean if applicable
export const stringToBool = (
  string: string | null
): boolean | string | null => {
  let value: string | boolean | null = string;

  if (value === 'true') {
    value = true;
  } else if (value === 'false') {
    value = false;
  }

  return value;
};

// Collect search query params from window
const searchParams = Object.fromEntries(
  new URLSearchParams(window.location.search).entries()
);

class WebViewService {
  private static getNormalizeMap = (
    query: Dictionary<string | boolean | null | (string | null)[]>
  ) => {
    if (query == null) return {};
    return Object.keys(query).reduce((acc: Record<string, string>, key) => {
      acc[key.toLowerCase()] = key;
      return acc;
    }, {});
  };

  /**
   * returns a list of oneApp related query parameters used by webview service
   * @returns string[]
   */
  public allOneAppQueryParams = (): string[] => {
    return [
      OneAppQueryParams.IS_ONE_APP_QUERY_PARAM,
      OneAppQueryParams.IS_MOBILE_WEBVIEW_QUERY_PARAM,
      OneAppQueryParams.MOBILE_PLATFORM,
      OneAppQueryParams.LANGUAGE,
      OneAppQueryParams.IS_GUIDE_MESSAGES_PARAM
    ];
  };

  public getQueryParams() {
    return Object.keys(searchParams).reduce((acc, key) => {
      const value = stringToBool(searchParams[key]);
      acc[key] = value;
      return acc;
    }, {} as Dictionary<string | boolean | null>);
  }

  /**
   * Method returns true if query obj includes IS_ONE_APP_QUERY_PARAM
   * @param query a dictionary of query params
   * @returns boolean representing existence of IS_ONE_APP_QUERY_PARAM in supplied query param
   */
  public isOneApp = (
    query: Dictionary<string | boolean | null | (string | null)[]>
  ): boolean => {
    const normalizeMap = WebViewService.getNormalizeMap(query);
    return (
      OneAppQueryParams.IS_ONE_APP_QUERY_PARAM in normalizeMap &&
      query[normalizeMap[OneAppQueryParams.IS_ONE_APP_QUERY_PARAM]] !== false
    );
  };

  /**
   * Method returns true if query obj includes IS_MOBILE_WEBVIEW_QUERY_PARAM
   * @param query a dictionary of query params
   * @returns boolean representing existence of IS_MOBILE_WEBVIEW_QUERY_PARAM in supplied query param
   */
  public routeHasWebviewQueryParam = (
    query: Dictionary<string | boolean | null | (string | null)[]>
  ): boolean => {
    const normalizeMap = WebViewService.getNormalizeMap(query);
    return (
      OneAppQueryParams.IS_MOBILE_WEBVIEW_QUERY_PARAM in normalizeMap &&
      query[normalizeMap[OneAppQueryParams.IS_MOBILE_WEBVIEW_QUERY_PARAM]] !==
        false
    );
  };

  /**
   * Method returns an Enum for what is contained within the MOBILE_PLATFORM query param if it is equal
   * to 'android' or 'ios', and null otherwise
   * @param query a dictionary of query params
   * @returns MOBILE_PLATFORM_TYPE.IOS, MOBILE_PLATFORM_TYPE.ANDROID or null
   */
  public mobilePlatform = (
    query: Dictionary<string | boolean | null | (string | null)[]>
  ): MOBILE_PLATFORM_TYPES | null => {
    const normalizeMap = WebViewService.getNormalizeMap(query);
    if (OneAppQueryParams.MOBILE_PLATFORM in normalizeMap) {
      const platform = query[normalizeMap[OneAppQueryParams.MOBILE_PLATFORM]];
      if (typeof platform === 'string') {
        return EnumFromStringValue(MOBILE_PLATFORM_TYPES, platform);
      }
    }
    return null;
  };

  /**
   * Method returns what is contained within the language query param
   * @param query a dictionary of query params
   * @returns LANGUAGE_TYPES or null
   */
  public language = (
    query: Dictionary<string | boolean | null | (string | null)[]>
  ): LANGUAGE_TYPES | null => {
    const normalizeMap = WebViewService.getNormalizeMap(query);
    if (OneAppQueryParams.LANGUAGE in normalizeMap) {
      const language = query[normalizeMap[OneAppQueryParams.LANGUAGE]];
      if (typeof language === 'string') {
        return EnumFromStringValue(LANGUAGE_TYPES, language);
      }
    }
    return null;
  };

  /**
   * Method returns true if query obj includes IS_GUIDE_MESSAGES_PARAM
   * @param query a dictionary of query params
   * @returns boolean representing existence of IS_GUIDE_MESSAGES_PARAM in supplied query param
   */
  public guideMessagesParam = (
    query: Dictionary<string | boolean | null | (string | null)[]>
  ): boolean => {
    const normalizeMap = WebViewService.getNormalizeMap(query);
    return (
      OneAppQueryParams.IS_GUIDE_MESSAGES_PARAM in normalizeMap &&
      query[normalizeMap[OneAppQueryParams.IS_GUIDE_MESSAGES_PARAM]] !== false
    );
  };

  /**
   * Performs iOS and Android post messages in try catch then fires callback.
   * Callback intended to be used with route.push methods for fallback web navigation.
   * @param payload A string representing the payload to be pushed to mobile via postMessage
   * @param fallbackNavigation A function to be called after firing postMessage
   */
  public sendUserToMobileOrCallback = (
    payload: string | OneAppPayload,
    fallbackNavigation?: () => void
  ): void => {
    const isOneAppPayload = typeof payload !== 'string';
    const message = isOneAppPayload ? payload : { path: payload };

    // iOS
    try {
      // @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.webkit.messageHandlers.appInterface.postMessage(
        isOneAppPayload ? JSON.stringify(message) : message,
        window.location.origin
      );
    } catch (error) {
      // NO-OP
    }
    // Android
    try {
      // @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.appInterface.postMessage(JSON.stringify(message));
    } catch (error) {
      // NO-OP
    }

    if (fallbackNavigation) {
      fallbackNavigation();
    }
  };
}

export default new WebViewService();
