export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "es",
    "resource": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenido a un nuevo y mejorado myStrength!"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descubra un nivel más personalizado de apoyo emocional para afrontar los desafíos cambiantes de la vida."])},
      "features": {
        "home": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["home"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un solo lugar para guiar sus próximos pasos"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuestro nuevo hogar lo ayudará a concentrarse en lo que sigue, lo guiará a lo largo de su plan y le dará recomendaciones basadas en sus necesidades únicas."])}
        },
        "explore": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["explore"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una biblioteca llena de recursos nuevos y relevantes para satisfacer sus necesidades."])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descubra actividades y herramientas para desarrollar habilidades que lo ayudarán a sentirse mejor en el momento, incluidos programas actualizados para la depresión y la ansiedad."])}
        },
        "sleep": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sleep"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herramientas para dormir nuevas y mejoradas"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desde registrar su sueño hasta obtener un horario de sueño personalizado, lo ayudaremos a hacer que el seguimiento de su sueño sea más fácil que nunca."])}
        },
        "assessments": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["assessments"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una evaluación más potente y personalizada"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todo lo que comparta en nuestra nueva evaluación ayudará a crear un plan con recomendaciones solo para usted. También nos comunicaremos contigo durante el proceso para asegurarnos de que recibes el nivel de asistencia adecuado. "])}
        },
        "trackers": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trackers and goal setting"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realice un seguimiento de cómo se siente día a día"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifique fácilmente cómo se siente cada día en Home. Agregaremos aún más rastreadores y herramientas para establecer objetivos en el futuro. ¡Estén atentos!"])}
        },
        "favorites": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["favorites"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceso rápido a sus actividades favoritas"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encuentre y guarde fácilmente sus favoritos. No se preocupe, todos sus favoritos anteriores para las actividades existentes seguirán estando disponibles."])}
        },
        "history": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["history"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu historial de un vistazo"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encuentre una lista de las actividades que ha completado para ayudarlo a realizar un seguimiento de su viaje."])}
        },
        "reminders": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reminders"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recordatorios importantes para ayudarlo a mantenerse encaminado"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le notificaremos las tareas importantes para ayudarlo a mantenerse concentrado."])}
        }
      }
    }
  })
}
