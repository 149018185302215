export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time for a quick update"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before we get started, let’s review and update your information to make sure you’re getting the most complete experience."])},
      "nextBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
      "errorHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong"])},
      "migrationRegistrationErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please call member support at (800) 945-4355."])},
      "accessCodeMismatchErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There may be an issue with a duplicate email address in use. Please call member support at (800) 945-4355."])}
    }
  })
}
