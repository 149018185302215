export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "faceLabels": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Awful"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not okay"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okay"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Great"])}
      ],
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
      "submitNotification": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tracker Saved"])}
      },
      "submitErrorNotification": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not save mood. Please try again"])}
      }
    }
  })
}
