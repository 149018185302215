import { RouteRecordRaw } from 'vue-router';
import { dynamicComponent } from '@/helpers/Webpack';

const exploreRoutes: RouteRecordRaw[] = [
  {
    path: '/explore',
    name: 'Explore',
    component: dynamicComponent('Explore/Explore')
  },
  {
    path: '/explore/browse',
    name: 'Browse',
    component: dynamicComponent('Explore/Browse')
  },
  {
    path: '/explore/category/:categoryId',
    name: 'Category',
    props: true,
    component: dynamicComponent('Explore/Category')
  },
  {
    path: '/explore/category/:categoryId/topic/:topicId',
    name: 'Topic',
    props: true,
    component: dynamicComponent('Explore/Topic')
  },
  {
    path: '/explore/category/:categoryId/topic/:topicId/channel/:channelId',
    name: 'Channel',
    props: true,
    component: dynamicComponent('Explore/Channel')
  },
  {
    path: '/explore/program/:programId',
    name: 'Program',
    props: true,
    component: dynamicComponent('Explore/Program')
  },
  {
    path: '/explore/program/:programId/series/:seriesId',
    name: 'Series',
    props: true,
    component: dynamicComponent('Explore/Series')
  },
  {
    path: '/explore/search/:query?',
    name: 'Search',
    props: true,
    component: dynamicComponent('Explore/Search')
  },
  {
    path: '/explore/favorites',
    name: 'Favorites',
    component: dynamicComponent('Explore/Favorites')
  },
  {
    path: '/explore/navigate/:parent/:resourceId',
    name: 'FavoritesResourceNavigation',
    component: dynamicComponent('Explore/ResourceNavigator')
  }
].map((route: RouteRecordRaw) => {
  if (!route.meta) {
    route.meta = {};
  }

  route.meta.baseWrapperSize = 'lg';
  return route;
});

export default exploreRoutes;
