import { ActionTree, GetterTree, MutationTree } from 'vuex';

import HTTP from '@/services/HttpService';
import HTTPUnintercepted from '@/services/HttpUnintercepted';
import apiRoutes from '@config/api-routes';

import { AxiosResponse } from 'axios';
import { RootState } from '@/types/storeTypes';
import { DaCastVideoMetadata } from '@/types/DaCastTypes';
import { VideoSubtitles } from '@/types/VideoWidgetTypes';
import {
  Activity,
  ActivityWidgetType,
  CompletedActivityResponses,
  ActivityResponseSubmissions
} from '@/types/ActivityTypes';

export interface ActivityState {
  activityResponses: { [key: string]: string };
  activeModalStep: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputGroups: { [key: string]: any };
  userResponseMap: { [key: string]: number };
  activityCompletedDestinationRoute: string;
  activityId: number;
  activityVersion: number;
  completedActivityResponses: CompletedActivityResponses | null;
  completedActivityStateResetOn: null | number;
}

/**
 * This store module is for activity related data
 */
const initialState = {
  activityResponses: {},
  activeModalStep: null,
  inputGroups: {} as { [key: string]: number[] },
  userResponseMap: {} as { [key: string]: number },
  activityCompletedDestinationRoute: 'Home',
  activityId: null,
  activityVersion: null,
  completedActivityResponses: null
};

const state = { ...initialState };

const getters: GetterTree<ActivityState, RootState> = {
  getResponseForWidgetId:
    (state: ActivityState) =>
    (widgetId: number): unknown => {
      const response = state.activityResponses[widgetId];
      return typeof response !== 'undefined' && response !== null
        ? response
        : null;
    },
  getCompletedResponseForWidgetId:
    (state: ActivityState) =>
    (widgetId: number): unknown => {
      if (!state.completedActivityResponses) {
        return null;
      }

      const response = state.completedActivityResponses[widgetId];
      return typeof response !== 'undefined' && response !== null
        ? response.value
        : null;
    },
  activeModalStep: (state: ActivityState): string | null =>
    state.activeModalStep,
  //get score for input group
  getScoreForInputGroup: (state: ActivityState) => (inputGroup: string) => {
    let result = 0;
    if (
      state.inputGroups[inputGroup] &&
      typeof state.inputGroups[inputGroup] !== 'string'
    ) {
      Array.from(Object.keys(state.inputGroups[inputGroup])).forEach(
        (widgetId: string) => {
          if (Array.isArray(state.inputGroups[inputGroup][widgetId])) {
            (
              state.inputGroups[inputGroup][
                Number.parseInt(widgetId)
              ] as unknown as string[]
            ).forEach((value: string) => {
              result = result + Number.parseInt(value);
            });
          } else {
            result =
              result +
              Number.parseInt(
                state.inputGroups[inputGroup][widgetId] as string
              );
          }
        }
      );
    }
    return result;
  },
  getUserResponseByUUID: (state: ActivityState) => (uuid: string) => {
    return state.activityResponses[state.userResponseMap[uuid]];
  },
  activityCompletedDestinationRoute: (state: ActivityState): string =>
    state.activityCompletedDestinationRoute,
  activityId: (state: ActivityState) => state.activityId,
  activityVersion: (state: ActivityState) => state.activityVersion,
  getCompletedActivityResponseSubmission:
    (state: ActivityState) => (widgetId: number) => {
      if (!state.completedActivityResponses) {
        return null;
      }

      const response = state.completedActivityResponses[widgetId];
      return response ? response.value : null;
    },
  getWidgetIdForUUID: (state: ActivityState) => (widgetUUID: string) => {
    return state.userResponseMap[widgetUUID]
      ? state.userResponseMap[widgetUUID]
      : null;
  },
  completedActivityStateResetOn: (state: ActivityState): null | number =>
    state.completedActivityStateResetOn
};

const actions: ActionTree<ActivityState, RootState> = {
  getActivityForSlug(store, activitySlug) {
    return HTTP.get(apiRoutes.activity.getActivity(activitySlug));
  },
  getCompletedActivity(store, responseId) {
    return HTTP.get(apiRoutes.activity.getCompletedActivity(responseId));
  },
  clearActivityResponses({ commit }) {
    commit('resetActivityResponse');
    commit('resetInputGroups');
  },
  addActivityResponseUserInput(
    { commit },
    { widgetId, userInput, inputGroup, inputScore }
  ) {
    commit('setActivityUserInput', { widgetId, userInput });

    // Set score state for grouped inputs
    if (inputGroup !== null && inputGroup !== undefined) {
      commit('setScoreForInputGroup', { inputGroup, widgetId, inputScore });
    }
  },
  startActivity(store, activitySlug) {
    console.log('Starting activity: ', activitySlug);
  },
  endActivity(store, activitySlug) {
    console.log('Ending activity: ', activitySlug);
  },
  submitResponsesForActivity(store, { activitySlug, activityVersion }) {
    const submissions = [];
    for (const key of Object.keys(state.activityResponses)) {
      submissions.push({
        widgetId: key,
        value: (state.activityResponses as Record<string, never>)[key]
      });
    }
    return HTTP.post(apiRoutes.activity.responses(activitySlug), {
      activityVersion: activityVersion,
      submissions: submissions
    });
  },
  submitFeedback(store, { activitySlug, feedback }) {
    return HTTP.post(apiRoutes.activity.feedback(activitySlug), {
      feedback: feedback
    });
  },
  searchActivities(store, searchString: string) {
    return HTTP.post(apiRoutes.activity.search, {
      query: searchString,
      size: 200,
      from: 0
    });
  },
  getDaCastVttSource(store, contentId: string) {
    return HTTPUnintercepted.get(
      apiRoutes.activity.daCast.metadata(contentId)
    ).then((response: AxiosResponse) => {
      const info: DaCastVideoMetadata = response.data;
      const subtitles: VideoSubtitles[] = info.contentInfo.features.subtitles;
      return Promise.resolve({
        subtitles,
        posterUrl: info.contentInfo.splashscreenUrl
      });
    });
  },
  getResponseHistory(store, activitySlug: string) {
    return HTTP.get(apiRoutes.activity.responses(activitySlug));
  },
  getActivityResponses(store, { activitySlug, responseId }) {
    return HTTP.get(
      apiRoutes.activity.getCompletedActivityResponse(activitySlug, responseId)
    );
  },
  setCompletedActivityResponses(
    store,
    activityResponse: {
      submissions: ActivityResponseSubmissions[];
    }
  ) {
    const responses = activityResponse.submissions.reduce(
      (acc: CompletedActivityResponses, submission) => {
        acc[submission.widgetId] = {
          id: submission.id,
          value: submission.value,
          responseId: submission.responseId
        };
        return acc;
      },
      {}
    );

    store.commit('setCompletedActivityResponses', responses);
  },
  resetCompletedActivityResponse(store) {
    store.commit('resetCompletedActivityResponse');
  }
};

const mutations: MutationTree<ActivityState> = {
  resetActivityResponse(state) {
    state.activityResponses = {};
  },
  resetInputGroups(state) {
    state.inputGroups = {};
  },
  setActivityUserInput(state, { widgetId, userInput }) {
    state.activityResponses[widgetId] = userInput;
  },
  setScoreForInputGroup(state, { inputGroup, widgetId, inputScore }) {
    if (state.inputGroups[inputGroup]) {
      state.inputGroups[inputGroup][widgetId] = inputScore;
    } else {
      state.inputGroups[inputGroup] = { [widgetId]: inputScore };
    }
  },
  setActiveModalStep(state, activeModalStep): void {
    state.activeModalStep = activeModalStep;
  },
  setTextInputs: (state, activity: Activity) => {
    const setWidgetUserResponse = (widget: ActivityWidgetType) => {
      if (
        widget !== null &&
        widget.data !== null &&
        widget.type.toString() === 'textInput'
      ) {
        const widgetData = widget.data;
        const widgetId = widget.id;
        const uuid = widgetData?.uuid;

        if (uuid) {
          state.userResponseMap[uuid] = widgetId;
        }
      }
    };

    // Set user responses for steps
    if (activity?.steps) {
      activity.steps.map((step) => {
        if (step.content) {
          return step.content.forEach((widget: ActivityWidgetType) => {
            setWidgetUserResponse(widget);
          });
        }
      });
    }
    // Set user responses for modalSteps
    if (activity?.modalSteps) {
      for (const [, modalStep] of Object.entries(activity.modalSteps)) {
        modalStep.content.forEach((widget: ActivityWidgetType) => {
          setWidgetUserResponse(widget);
        });
      }
    }
  },
  setCompletedDestinationRoute: (state, origin: string) => {
    state.activityCompletedDestinationRoute = origin;
  },
  setActivityId: (state, activityId: number) => {
    state.activityId = activityId;
  },
  setActivityVersion: (state, activityVersion: number) => {
    state.activityVersion = activityVersion;
  },
  setCompletedActivityResponses: (state, response) => {
    state.completedActivityResponses = response;
  },
  resetCompletedActivityResponse: (state) => {
    state.completedActivityResponses = null;
    state.completedActivityStateResetOn = Date.now();
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
