export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "progressBarLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onboarding progress"])},
      "wrapper": {
        "alertText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This chapter is already completed. Please click here to return to the correct chapter"])}
      }
    }
  })
}
