export enum NOTIFICATION_TYPES {
  INFO = 'INFO',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  SUCCESS = 'SUCCESS'
}

export interface Notification {
  id: number;
  heading: string;
  description: string;
  type: NOTIFICATION_TYPES;
}
