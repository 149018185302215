
// TODO: Should Break this up into components. Potentially like:
// guide -> messageThread -> message -> messageAttachment
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import IconCloseCircle from '@svg/icon-close-circle.svg';
import HomeIcon from '@svg/Guide/home.svg';
import { format } from 'date-fns';
import { AxiosPromise } from 'axios';
import AttachmentInternalLink from '@/views/Guide/AttachmentInternalLink.vue';
import {
  GuideEvents,
  AttachmentTypes,
  AttachmentSubTypes,
  MessageType
} from '@/types/GuideTypes';
import InternalLinkService from '@/services/InternalLinkService';

interface CoachMessage {
  id: number;
  readOn: number;
  type: MessageType;
}

export default defineComponent({
  name: 'Guide',
  inject: ['$analyticsTrack'],
  components: {
    AttachmentInternalLink,
    IconCloseCircle,
    HomeIcon
  },
  data() {
    return {
      userInput: '',
      showBio: false,
      showMessageSentModal: false,
      disableSend: false,
      AttachmentTypes,
      retrieveMessagesError: false,
      messageSentModalTimeout: 0
    };
  },
  computed: {
    guideName(): string {
      return this.user.coachingAssignment.currentCoachName;
    },
    firstName(): string {
      return this.user?.firstName ? this.user.firstName : '';
    },
    showIntro(): boolean {
      return (
        !this.guideMessages.messages || this.guideMessages.messages.length === 0
      );
    },
    messages(): Record<string, string>[] {
      //TODO: remove placeholder, this is just for testing styles/behavior
      return this.guideMessages.messages;
    },
    guideProfile(): string {
      const profile = this.guideProfiles.filter(
        ({ id }: { id: number }) =>
          id === this.user.coachingAssignment.currentCoachId
      )[0];
      return profile ? profile.biography : this.$t('noGuideProfile');
    },
    userFirstLetter(): string {
      return this.user.firstName[0];
    },
    coachId(): number {
      return this.guideMessages.coachId;
    },
    ...mapGetters([
      'user',
      'guideMessages',
      'guideProfiles',
      'unreadMessages',
      'isGuideMessageOnlyView',
      'isMobileWebView'
    ])
  },
  watch: {
    messages: {
      deep: true,
      handler(messages) {
        const messageReadPromises: Array<Promise<AxiosPromise>> = [];
        messages
          .filter(
            ({ type, readOn }: CoachMessage) =>
              type === MessageType.FROM_COACH && !readOn
          )
          .forEach(({ id }: CoachMessage) =>
            messageReadPromises.push(this.markMessageRead(id))
          );
        Promise.all(messageReadPromises).then(this.getUnreadMessageCount);
      }
    },
    unreadMessages: {
      deep: true,
      handler(unreadMessages) {
        if (unreadMessages) {
          this.getMessages();
        }
      }
    }
  },
  mounted() {
    this.getGuideProfiles();
    this.getMessages();
    this.getGuideProfiles();
  },
  unmounted() {
    this.trackCoachingClosed();
  },
  methods: {
    newLineToHtml(string: string): string {
      return string.replace(/(?:\r\n|\r|\n)/g, '<br>');
    },
    toggleBio(): void {
      this.showBio = !this.showBio;

      if (this.showBio) {
        this.trackGuideBioViewed();
      }
    },
    sendMessage(): void {
      this.disableSend = true;
      this.sendMessageToGuide(this.userInput)
        .then(() => {
          this.showMessageSentModal = true;
          this.userInput = '';
          this.trackMessageSendEvent();

          this.messageSentModalTimeout = setTimeout(() => {
            this.showMessageSentModal = false;
            this.disableSend = false;
            this.trackSendMessageFeedbackFadeOut();
          }, 5000);
        })
        .catch(() => {
          this.addErrorNotification({
            heading: this.$t('messageFailed'),
            description: this.$t('messageFailedDescription')
          });

          this.disableSend = false;
        });
    },
    getMessages() {
      this.getGuideMessages()
        .then(() => {
          this.retrieveMessagesError = false;
          this.trackIntroEvent();
        })
        .catch(() => {
          this.retrieveMessagesError = true;
        });
    },
    isFromCoach(messageType: string): boolean {
      return messageType === MessageType.FROM_COACH;
    },
    getDate(date: number): string {
      return format(new Date(date), 'EEEE, MMM d, Y');
    },
    buildActivityURL(
      activityString: string,
      attachmentType: AttachmentSubTypes
    ) {
      if (attachmentType === AttachmentSubTypes.CORE_INSPIRATION) {
        return InternalLinkService.getWebPathForInternalLink(
          `inspiration:${activityString}`
        );
      }
      return InternalLinkService.getWebPathForInternalLink(
        `activity:${activityString}`
      );
    },
    async attachmentLinkClick(
      event: MouseEvent,
      attachment: {
        type: AttachmentTypes;
        id: number;
        content: string;
        attachmentMeta: Record<string, AttachmentSubTypes>;
      }
    ) {
      // Send ATTACHMENT_CLICKED coaching event
      this.sendCoachingEvent({
        eventType: GuideEvents.ATTACHMENT_CLICKED,
        description: attachment.id
      });

      // Send mix panel event
      this.trackAttachmentClicked(attachment.id);

      if (attachment.type === AttachmentTypes.INTERNAL_LINK) {
        this.closeDrawer();
        return;
      }

      if (attachment.type === AttachmentTypes.ACTIVITY) {
        await this.$router.push(
          this.buildActivityURL(
            attachment.content,
            attachment.attachmentMeta.attachmentType
          )
        );

        this.closeDrawer();
      }
    },
    isNotDeprecated(content: string) {
      const contentType = content.split(':')[0];
      switch (contentType) {
        case 'tools':
        case 'inspiration':
        case 'inspirations':
        case 'contact':
          return false;
        default:
          return true;
      }
    },
    hideMessageSentModal() {
      clearTimeout(this.messageSentModalTimeout);
      this.showMessageSentModal = false;
      this.disableSend = false;
      this.trackSendMessageFeedbackClicked();
    },
    trackIntroEvent() {
      if (this.showIntro) {
        this.$analyticsTrack('MH.Guide.Intro', {
          coachId: this.coachId
        });
        this.$analyticsTrack('MH.Guide.Intro.Start', {
          coachId: this.coachId
        });
      } else {
        this.$analyticsTrack('MH.Guide.Chat', {
          coachId: this.coachId
        });
        this.$analyticsTrack('MH.Guide.Chat.Start', {
          coachId: this.coachId
        });
      }
    },
    trackMessageSendEvent() {
      if (this.showIntro) {
        this.$analyticsTrack('MH.Guide.Intro.SentMessage', {
          coachId: this.coachId
        });
      } else {
        this.$analyticsTrack('MH.Guide.Chat.SentMessage', {
          coachId: this.coachId
        });
      }
    },
    trackCoachingClosed() {
      if (this.showIntro) {
        this.$analyticsTrack('MH.Guide.Intro.Cancel', {
          coachId: this.coachId
        });
      } else {
        this.$analyticsTrack('MH.Guide.Chat.Closed', {
          coachId: this.coachId
        });
      }
    },
    trackGuideBioViewed() {
      this.$analyticsTrack('MH.Guide.About', {
        coachId: this.coachId
      });
    },
    trackAttachmentClicked(attachmentId: number) {
      this.$analyticsTrack('MH.Guide.Chat.Attachment', {
        coachId: this.coachId,
        attachmentId
      });
    },
    trackSendMessageFeedbackFadeOut() {
      this.$analyticsTrack('MH.Guide.Feedback.FadeOut', {
        coachId: this.coachId
      });
    },
    trackSendMessageFeedbackClicked() {
      this.$analyticsTrack('MH.Guide.Feedback.Tapped', {
        coachId: this.coachId
      });
    },
    ...mapActions([
      'closeDrawer',
      'getUnreadMessageCount',
      'getGuideMessages',
      'getGuideProfiles',
      'markMessageRead',
      'sendMessageToGuide',
      'addErrorNotification',
      'sendCoachingEvent'
    ])
  }
});
