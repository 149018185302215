export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "es",
    "resource": {
      "faceLabels": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pobre"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No bueno"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okey"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bueno"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excelente"])}
      ],
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hecho"])},
      "submitNotification": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rastreador guardado"])}
      },
      "submitErrorNotification": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algo salió mal"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo salvar el estado de ánimo. Inténtalo de nuevo"])}
      }
    }
  })
}
