export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "es",
    "resource": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer su contraseña"])},
      "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para restablecer su contraseña, ingrese su dirección de correo electrónico"])},
      "accountLockedHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu cuenta esta bloqueada"])},
      "accountLockedInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha intentado iniciar sesión con una contraseña no válida demasiadas veces. Restablezca su contraseña usando el formulario a continuación para desbloquear su cuenta o contacto"])},
      "supportLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["support"])},
      "mailToSubject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I%20need%20help%20with%20myStrength%20web%20application%20Login"])},
      "form": {
        "emailField": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email"])}
        },
        "resetBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer la contraseña"])},
        "cancelLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])}
      },
      "createNewAccountLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Nuevo usuario?"])},
      "createNewAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea una cuenta"])},
      "confirmationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le enviamos un enlace para restablecer su contraseña"])},
      "confirmationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si no recibe este correo electrónico, verifique la carpeta de correo no deseado o llame al servicio de atención al miembro al:"])},
      "backToSignInBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver a Iniciar sesión"])}
    }
  })
}
