
import { defineComponent } from 'vue';
import IconSleep from '@svg/icon-sleep-quality.svg';
import SleepMobileRedirect from '@/views/Sleep/SleepMobileRedirect.vue';
import { mapGetters } from 'vuex';
import WebViewService from '@/services/WebViewService';

export default defineComponent({
  name: 'MHProgress',
  inject: ['$analyticsTrack'],
  components: {
    IconSleep,
    SleepMobileRedirect
  },
  computed: {
    ...mapGetters(['isOneApp', 'isMobileWebView'])
  },
  mounted() {
    this.$analyticsTrack('MH.Progress');
  },
  methods: {
    sendUserToOneAppMobile() {
      WebViewService.sendUserToMobileOrCallback('action:sleep:progress');
    }
  }
});
