import apiRoutes from '@config/api-routes';
import HTTP from '@/services/HttpService';

import { RootState } from '@/types/storeTypes';
import { GetterTree, Module, MutationTree, ActionTree } from 'vuex';
import { MetadataComponent, ActivityTag } from '@/types/ProfileTypes';
import { Activity } from '@/types/ActivityTypes';

/**
 * A store for personalizations
 */

export interface ProfileState {
  personalizationOptions: Array<PersonalizationGroup>;
  currentTags: Map<string, boolean>;
  updatedTags: Map<string, boolean>;
  spiritualityOptionsSelected: SpiritualityOptionsState;
  activityHistory: Activity[] | null;
  isActivityHistoryLoading: boolean;
}

export interface PersonalizationGroup {
  personalizationType: PersonalizationType;
  personalizations: Array<string>;
}

export enum PersonalizationType {
  PHYSICAL_WELLNESS,
  RELATIONSHIPS,
  SPIRITUALITY
}

export enum SpiritualityOptionsState {
  DISABLED,
  ENABLED,
  SELECTED
}

export interface Tag {
  tagName: string;
  active: boolean;
}

export const state: ProfileState = {
  personalizationOptions: [
    {
      personalizationType: PersonalizationType.PHYSICAL_WELLNESS,
      personalizations: [
        'health_topic:eating_well',
        'health_topic:getting_active',
        'health_topic:physical_fitness',
        'condition:nicotine',
        'health_topic:weight_management'
      ]
    },
    {
      personalizationType: PersonalizationType.RELATIONSHIPS,
      personalizations: [
        'condition:divorce',
        'lifestyle:friendship',
        'lifestyle:lgbt',
        'condition:relationships',
        'lifestyle:widow',
        'lifestyle:work_relations'
      ]
    },
    {
      personalizationType: PersonalizationType.SPIRITUALITY,
      personalizations: [
        'spiritual:buddhist',
        'spiritual:christian',
        'spiritual:hindu',
        'spiritual:muslim',
        'spiritual:jewish',
        'spiritual:other',
        'spiritual:spiritual_but_not_religious'
      ]
    }
  ],
  currentTags: new Map<string, boolean>(),
  updatedTags: new Map<string, boolean>(),
  spiritualityOptionsSelected: SpiritualityOptionsState.DISABLED,
  activityHistory: null,
  isActivityHistoryLoading: false
};

const getters: GetterTree<ProfileState, RootState> = {
  personalizationOptions: (state: ProfileState) => state.personalizationOptions,
  currentTags: (state: ProfileState) => state.currentTags,
  spiritualityOptionsSelected: (state: ProfileState) =>
    state.spiritualityOptionsSelected,
  activityHistory: (state: ProfileState) => state.activityHistory,
  isActivityHistoryLoading: (state: ProfileState) =>
    state.isActivityHistoryLoading
};

const actions: ActionTree<ProfileState, RootState> = {
  getPersonalizationTags(store) {
    return HTTP.get(apiRoutes.user.profile).then((response) =>
      store.commit('setTagState', response.data)
    );
  },
  updatePersonalizationTags(store) {
    if (state.updatedTags.size > 0) {
      return HTTP.post(
        apiRoutes.user.profile,
        Object.fromEntries(state.updatedTags)
      ).then((response) => {
        store.commit('setTagState', response.data);
        return { tagsUpdated: true };
      });
    } else {
      return new Promise((resolve) => {
        return resolve({ tagsUpdated: false });
      });
    }
  },
  getActivityHistory(store) {
    store.commit('setActivityHistoryLoading');
    return HTTP.get(apiRoutes.activity.history).then((response) => {
      store.commit('setActivityHistoryLoading', false);
      store.commit('setActivityHistory', response.data);
    });
  }
};

const mutations: MutationTree<ProfileState> = {
  setTagState(
    state: ProfileState,
    profileData: Array<MetadataComponent>
  ): void {
    state.currentTags.clear();
    state.updatedTags.clear();
    state.spiritualityOptionsSelected = SpiritualityOptionsState.DISABLED;
    profileData.forEach((category: MetadataComponent) => {
      category.tagList?.forEach((tag: ActivityTag) => {
        const tagName = tag.shortName;
        state.currentTags.set(tagName, true);
        if (tagName.includes('spiritual')) {
          state.spiritualityOptionsSelected = SpiritualityOptionsState.SELECTED;
        }
      });
    });
  },
  updateTagState(state: ProfileState, tagData: Tag): void {
    state.currentTags.set(tagData.tagName, tagData.active);
    let spiritualTagFound = false;
    for (const [key, value] of state.currentTags) {
      if (key.includes('spiritual') && value) {
        spiritualTagFound = true;
        break;
      }
    }
    if (spiritualTagFound) {
      state.spiritualityOptionsSelected = SpiritualityOptionsState.SELECTED;
    } else if (
      state.spiritualityOptionsSelected === SpiritualityOptionsState.SELECTED
    ) {
      state.spiritualityOptionsSelected = SpiritualityOptionsState.ENABLED;
    }
  },
  addToUpdatedTags(state: ProfileState, tagData: Tag): void {
    state.updatedTags.set(tagData.tagName, tagData.active);
  },
  updateSpiritualityToggle(state: ProfileState, val: boolean): void {
    if (!val) {
      state.spiritualityOptionsSelected = SpiritualityOptionsState.DISABLED;
      state.personalizationOptions.forEach(function (tagGroups) {
        if (
          tagGroups.personalizationType === PersonalizationType.SPIRITUALITY
        ) {
          tagGroups.personalizations.forEach((tagName: string) => {
            state.currentTags.set(tagName, false);
            state.updatedTags.set(tagName, false);
          });
        }
      });
    } else {
      state.spiritualityOptionsSelected = SpiritualityOptionsState.ENABLED;
    }
  },
  setActivityHistory(state: ProfileState, activityHistory: Activity[]): void {
    state.activityHistory = activityHistory;
  },
  setActivityHistoryLoading(state, activityHistoryLoading = true) {
    state.isActivityHistoryLoading = activityHistoryLoading;
  }
};

export const profileStore: Module<ProfileState, RootState> = {
  state,
  getters,
  actions,
  mutations
};

export default profileStore;
