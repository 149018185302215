
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';

import BrandLogo from '@svg/brand-logo.svg';

export default defineComponent({
  name: 'Styles',
  components: {
    BrandLogo
  },
  data() {
    return {
      radioSelected: 'selected',
      radioChecked: true,
      inputText: '',
      notificationHeader: '',
      notificationDescription: ''
    };
  },
  methods: {
    showInfoNotification() {
      this.addInfoNotification({
        heading: this.notificationHeader,
        description: this.notificationDescription
      });
    },
    showSuccessNotification() {
      this.addSuccessNotification({
        heading: this.notificationHeader,
        description: this.notificationDescription
      });
    },
    showWarningNotification() {
      this.addWarningNotification({
        heading: this.notificationHeader,
        description: this.notificationDescription
      });
    },
    showErrorNotification() {
      this.addErrorNotification({
        heading: this.notificationHeader,
        description: this.notificationDescription
      });
    },
    ...mapActions([
      'addInfoNotification',
      'addSuccessNotification',
      'addWarningNotification',
      'addErrorNotification'
    ])
  }
});
