export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of Birth"])},
      "monthLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
      "dayLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day"])},
      "yearLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])},
      "months": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["January"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["February"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["March"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["May"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["June"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["July"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["August"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["October"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["December"])}
      ],
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your date of birth"])},
      "ruleMessages": {
        "meetsMinimumAge": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["myStrength is only for users who are at least ", _interpolate(_named("minimumAge")), " years of age"])},
        "youngerThan150": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter an age under 150"])}
      }
    }
  })
}
