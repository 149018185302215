export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "guideButtonAriaOpen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open the myStrength guide panel"])},
      "guideButtonAriaClose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close the myStrength guide panel"])},
      "guideLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guide"])},
      "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])},
      "menuAriaOpen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open the menu"])},
      "menuAriaClose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close the menu"])},
      "guideImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image of myStrength Guide"])},
      "oneAppLabel": {
        "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mental Health"])},
        "Connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect"])},
        "Explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore"])},
        "Progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progress"])},
        "Personalization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferences"])},
        "HelpResources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resources"])},
        "History": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
        "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
        "Browse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browse"])},
        "Program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program"])},
        "Series": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Series"])},
        "Activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
        "Topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topic"])},
        "ActivityResponses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity Responses"])},
        "Category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
        "Channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel"])},
        "Teletherapy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teletherapy"])},
        "Guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guide"])},
        "Favorites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorites"])},
        "ConnectClinicalCoaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect"])},
        "ClinicalCoachingGetStarted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching: Get Started"])},
        "ClinicalCoachingSchedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schedule Coaching Sessions"])},
        "ClinicalCoachingAbout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Coaching"])},
        "ClinicalCoachingCoachBio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coach Bio"])},
        "ClinicalPortalConference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clinical Portal Conference"])}
      }
    }
  })
}
