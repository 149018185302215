import { GetterTree, Module, MutationTree, ActionTree } from 'vuex';

import apiRoutes from '@config/api-routes';
import HTTP from '@/services/HttpService';
import AuthService from '@/services/AuthService';

import { RootState } from '@/types/storeTypes';
import { AxiosPromise } from 'axios';

import { analyticsTrack } from '@/vueMixpanel';

const apiUrl = HTTP.defaults.baseURL ? HTTP.defaults.baseURL : '';

/**
 * The "core" store for global app related state
 */

export interface CoreState {
  apiUrl: string;
  apiConfig: {
    clients: { web: { featureFlags: Record<string, boolean> } };
  } | null;
  hasValidToken: boolean;
  activeReAuthRequest: boolean;
  reAuthRequestPromise: Promise<AxiosPromise> | null;
  scrollDisabled: boolean;
  mobileScrollDisabled: boolean;
}

export const state: CoreState = {
  apiUrl: apiUrl,
  apiConfig: null,
  hasValidToken: false,
  activeReAuthRequest: false,
  reAuthRequestPromise: null,
  scrollDisabled: false,
  mobileScrollDisabled: false
};

const getters: GetterTree<CoreState, RootState> = {
  apiUrl: (state: CoreState): string => state.apiUrl,
  apiConfig: (state: CoreState): string => state.apiUrl,
  hasValidToken: (state: CoreState): boolean => state.hasValidToken,
  hasActiveReAuthRequest: (state: CoreState): boolean =>
    state.activeReAuthRequest,
  reAuthRequestPromise: (state: CoreState): Promise<AxiosPromise> | null =>
    state.reAuthRequestPromise,
  scrollDisabled: (state: CoreState): boolean => state.scrollDisabled,
  mobileScrollDisabled: (state: CoreState): boolean =>
    state.mobileScrollDisabled,
  hasFeatureEnabled:
    (state: CoreState) =>
    (feature: string): boolean => {
      return state.apiConfig?.clients?.web?.featureFlags[feature] === true;
    }
};

const actions: ActionTree<CoreState, RootState> = {
  fetchApiConfig({ commit }): Promise<unknown> {
    return HTTP.get(apiRoutes.config).then(
      (response) => {
        commit('setApiConfig', response.data);
      },
      (err) => {
        console.warn('App failed to get api config: ', err);
      }
    );
  },
  reAuthenticateUser(
    { state, commit, dispatch, rootGetters },
    reAuthToken: string
  ): AxiosPromise | Promise<unknown> {
    // Return existing reauth promise if active reauth in process
    if (state.activeReAuthRequest) {
      return state.reAuthRequestPromise || Promise.reject('Reauth failed');
    } else if (!reAuthToken) {
      return Promise.reject('invalid reauth token');
    }

    commit('setActiveReAuthRequest');
    commit('setTokenInvalid');

    // TODO: Remove when web-ui is no longer in One App
    // Using this to hit mobile re-auth when web-ui is
    // rendered as a mobile webview for One App
    const { isMobileWebView, isOneApp } = rootGetters;
    const { mobile, user } = apiRoutes;
    const reAuthRoute =
      isMobileWebView && isOneApp ? mobile.reAuth : user.reAuth;

    const reAuthCall = HTTP.post(reAuthRoute, {
      reauthToken: reAuthToken
    })
      .then(
        (resp) => {
          // BH 3.0 users need to have cookie:token set to cookie:access_token on reauth
          commit('setBh30Token', resp.headers['x-session-token']);
          commit('setTokenValid');
          commit('setAuthenticated', true);

          dispatch('getUser');
          analyticsTrack('MH.Reauth.Succeed');

          return resp;
        },
        (error) => {
          commit('setTokenInvalid');
          analyticsTrack('MH.Reauth.Failed');

          return Promise.reject(error);
        }
      )
      .finally(() => {
        commit('clearActiveReAuthRequest');
      });

    commit('setReAuthPromise', reAuthCall);
    return reAuthCall;
  },
  sendCommunicationsEvent(store, event) {
    return HTTP.post(apiRoutes.eventAudit.communicationEvent, {
      ...event,
      platform: 'WEB'
    });
  },
  blockBodyScroll({ commit }): void {
    commit('setScrollDisabled', true);
  },
  unblockBodyScroll({ commit }): void {
    commit('setScrollDisabled', false);
  },
  blockMobileBodyScroll({ commit }): void {
    commit('setMobileScrollDisabled', true);
  },
  unblockMobileBodyScroll({ commit }): void {
    commit('setMobileScrollDisabled', false);
  }
};

const mutations: MutationTree<CoreState> = {
  setApiConfig(state: CoreState, config): void {
    state.apiConfig = config;
  },
  setTokenValid(state: CoreState) {
    state.hasValidToken = true;
  },
  setTokenInvalid(state: CoreState) {
    state.hasValidToken = false;
  },
  setBh30Token(state: CoreState, token: string) {
    AuthService.setLivongoAuthToken(token);
  },
  setActiveReAuthRequest(state: CoreState) {
    state.activeReAuthRequest = true;
  },
  clearActiveReAuthRequest(state: CoreState) {
    state.activeReAuthRequest = false;
    state.reAuthRequestPromise = null;
  },
  setReAuthPromise(state: CoreState, reAuthRequestPromise: Promise<never>) {
    state.reAuthRequestPromise = reAuthRequestPromise;
  },
  setScrollDisabled(state: CoreState, scrollDisabled: boolean): void {
    state.scrollDisabled = scrollDisabled;
  },
  setMobileScrollDisabled(
    state: CoreState,
    mobileScrollDisabled: boolean
  ): void {
    state.mobileScrollDisabled = mobileScrollDisabled;
  }
};

export const coreStore: Module<CoreState, RootState> = {
  state,
  getters,
  actions,
  mutations
};

export default coreStore;
