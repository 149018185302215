
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';

import { CrisisPlanState } from '@/types/CrisisTypes';

import CardCircleIcon from '@common/cards/CardCircleIcon.vue';
import HistoryChoiceModal from '@/components/modals/HistoryChoiceModal.vue';

import IconTasks from '@svg/icon-tasks.svg';

export default defineComponent({
  name: 'CrisisPlanCard',
  components: {
    CardCircleIcon,
    HistoryChoiceModal,
    IconTasks
  },
  props: {
    card: {
      type: Object,
      required: true
    },
    currentLocation: {
      type: String,
      default: 'Home'
    },
    showDismiss: {
      type: Boolean,
      default: true
    },
    activityRouteName: {
      type: String,
      default: 'Activity'
    }
  },
  data() {
    return {
      crisisActivitySlug: 'recognize_and_reach_out',
      showNavigationModal: false
    };
  },
  computed: {
    crisisPlanCompleted(): boolean {
      return this.card?.content?.crisisPlanState === CrisisPlanState.COMPLETE;
    },
    completedDateString(): string {
      const date = new Date(this.card?.content?.lastCompleted);

      return date.toLocaleString(this.$root.$i18n.locale, {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    }
  },
  methods: {
    showCompletedActivityModal() {
      this.showNavigationModal = true;
    },
    updateShowNavigationModal(value: boolean) {
      this.showNavigationModal = value;
    },
    doCardDismissal(type: string) {
      this.dismissCard(type).then(
        () => {
          // NOOP
        },
        () => {
          // Avoid uncaught error
          // NOOP
        }
      );
    },
    ...mapActions(['dismissCard'])
  }
});
