export enum CrisisProtocol {
  CRISIS_NONE = 'CRISIS_NONE',
  CRISIS_MANAGEMENT = 'CRISIS_MANAGEMENT',
  CRISIS_OUTREACH = 'CRISIS_OUTREACH'
}

export interface CrisisCardContent {
  crisisPlanState: CrisisPlanState;
  lastCompleted: string;
}

export enum CrisisPlanState {
  COMPLETE = 'COMPLETE',
  INCOMPLETE = 'INCOMPLETE'
}

export enum CrisisSeverity {
  HIGH = 'HIGH',
  LOW = 'LOW'
}
