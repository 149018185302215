export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "es",
    "resource": {
      "profileTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfil"])},
      "profileSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para actualizar su perfil, deberá visitar nuestro sitio web."])},
      "notificationsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificaciones"])},
      "languageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idioma"])},
      "personalizationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalización"])},
      "personalizationSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personaliza tu experiencia seleccionando temas que te interesen."])},
      "supportTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apoyo"])},
      "historyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historia"])}
    }
  })
}
