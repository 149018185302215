export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "es",
    "resource": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La ayuda está aquí"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Independientemente de los desafíos que enfrente, sepa que no está solo. Cree un plan de crisis o hable con alguien de inmediato."])},
      "crisisCard": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Línea de vida nacional para la prevención del suicidio"])},
        "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["800-273-8255"])},
        "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Llamar"])}
      },
      "crisisPlanCard": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu plan de crisis"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reconozca su propia angustia y aprenda estrategias para obtener la ayuda que necesita y la atención que merece."])}
      }
    }
  })
}
