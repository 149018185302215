import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      navItems: [
        {
          routeName: 'Home',
          i18nLabel: 'home'
        },
        {
          routeName: 'Connect',
          i18nLabel: 'connect'
        },
        {
          routeName: 'Explore',
          i18nLabel: 'explore'
        },
        {
          routeName: 'Progress',
          i18nLabel: 'progress'
        },
        {
          routeName: 'HelpResources',
          i18nLabel: 'helpResource'
        }
      ],
      profileItems: [
        {
          routeName: 'Profile',
          i18nLabel: 'profile',
          displayTab: true
        },
        {
          routeName: 'Notifications',
          i18nLabel: 'notifications',
          displayTab: true
        },
        {
          routeName: 'Language',
          i18nLabel: 'language',
          displayTab: true
        },
        {
          routeName: 'Personalization',
          i18nLabel: 'personalization',
          displayTab: true
        },
        {
          routeName: 'Support',
          i18nLabel: 'support',
          displayTab: true
        },
        {
          routeName: 'History',
          i18nLabel: 'history',
          displayTab: true
        },
        {
          routeName: 'Logout',
          i18nLabel: 'logOut',
          displayTab: false
        }
      ]
    };
  },
  computed: {
    profileTabItems(): { displayTab: boolean }[] {
      return this.profileItems.filter(
        (item: { displayTab: boolean }) => item.displayTab
      );
    }
  }
});
