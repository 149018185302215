import store from '@/store';
import { RouteRecordRaw } from 'vue-router';
import { dynamicComponent } from '@/helpers/Webpack';

export default [
  // Assessment Retake
  {
    path: '/assessment/intro',
    name: 'AssessmentRetakeIntro',
    component: dynamicComponent('Onboarding/Assessment/AssessmentIntro'),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      store.commit('setInReassessment', true);
      next();
    }
  },
  {
    path: '/assessment/questionnaire',
    name: 'AssessmentRetakeQuestionnaire',
    component: dynamicComponent(
      'Onboarding/Assessment/AssessmentQuestionnaire'
    ),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (from.name !== 'AssessmentRetakeIntro') {
        next({ name: 'AssessmentRetakeIntro' });
      } else {
        next();
      }
    }
  },
  {
    path: '/assessment/transition',
    name: 'AssessmentRetakeTransition',
    component: dynamicComponent('Onboarding/Assessment/AssessmentTransition'),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (
        from.name !== 'AssessmentRetakeQuestionnaire' &&
        from.name !== 'AssessmentRetakeCrisisIntervention'
      ) {
        next({ name: 'AssessmentRetakeIntro' });
      } else {
        next();
      }
    }
  },
  {
    path: '/assessment/get-help',
    name: 'AssessmentRetakeCrisisIntervention',
    component: dynamicComponent('Onboarding/CrisisIntervention'),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (from.name !== 'AssessmentRetakeQuestionnaire') {
        next({ name: 'AssessmentRetakeIntro' });
      } else {
        next();
      }
    }
  },
  {
    path: '/assessment/results',
    name: 'AssessmentRetakeResults',
    component: dynamicComponent('AssessmentRetake/AssessmentRetakeResults'),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const fromName = String(from.name);

      if (
        fromName !== 'AssessmentRetakeTransition' &&
        fromName !== 'AssessmentCrisisActivity' &&
        !fromName.includes('Crisis')
      ) {
        next({ name: 'AssessmentRetakeIntro' });
      } else {
        next();
      }
    }
  },
  {
    path: '/assessment/crisis-help-plan',
    name: 'AssessmentRetakeCrisisHelpPlan',
    component: dynamicComponent('Crisis/CrisisHelpPlan'),
    meta: { requiresAuth: true },
    props: (route) => ({ severity: route.query.severity }),
    beforeEnter: (to, from, next) => {
      if (
        from.name !== 'AssessmentRetakeTransition' &&
        from.fullPath !== '/activity/recognize_and_reach_out' &&
        !from.fullPath.includes(
          '/activity/responses/recognize_and_reach_out'
        ) &&
        from.fullPath !== '/assessment/crisis-help-request'
      ) {
        next({ name: 'AssessmentRetakeIntro' });
      } else {
        next();
      }
    }
  },
  {
    path: '/assessment/crisis-help-request',
    name: 'AssessmentRetakeCrisisHelpRequest',
    component: dynamicComponent('Crisis/CrisisHelpRequest'),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (from.name !== 'AssessmentRetakeTransition') {
        next({ name: 'AssessmentRetakeIntro' });
      } else {
        next();
      }
    }
  },
  {
    path: '/assessment/crisis-help-request-confirmation',
    name: 'AssessmentRetakeCrisisHelpRequestConfirmation',
    component: dynamicComponent('Crisis/CrisisHelpRequestConfirmation'),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (from.name !== 'AssessmentRetakeCrisisHelpRequest') {
        next({ name: 'AssessmentRetakeIntro' });
      } else {
        next();
      }
    }
  },
  {
    path: '/assessment/activity',
    name: 'AssessmentCrisisActivity',
    component: dynamicComponent('Crisis/CrisisPlanActivity'),
    props: {
      destinationRoute: '/assessment/results'
    },
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (from.name !== 'AssessmentRetakeCrisisHelpPlan') {
        next({ name: 'AssessmentRetakeIntro' });
      } else {
        next();
      }
    }
  },
  {
    path: '/assessment/activity/response',
    name: 'AssessmentCrisisActivityResponse',
    component: dynamicComponent('Activity/ActivityResponse'),
    props: {
      activitySlug: 'recognize_and_reach_out',
      backButtonLocation: '/assessment/crisis-help-plan'
    },
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (from.name !== 'AssessmentRetakeCrisisHelpPlan') {
        next({ name: 'AssessmentRetakeIntro' });
      } else {
        next();
      }
    }
  }
] as RouteRecordRaw[];
