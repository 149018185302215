export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset your password"])},
      "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To reset your password, enter your email address"])},
      "accountLockedHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Account is Locked"])},
      "accountLockedInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have attempted to login with an invalid password too many times. Reset your password using the form below to unlock your account or contact"])},
      "supportLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["support"])},
      "mailToSubject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I%20need%20help%20with%20myStrength%20web%20application%20Login"])},
      "form": {
        "emailField": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email"])}
        },
        "resetBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])},
        "cancelLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
      },
      "createNewAccountLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New user?"])},
      "createNewAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an account"])},
      "confirmationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We've sent you a link to reset your password"])},
      "confirmationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you do not receive this email, please check your email junk folder or else call Member support at:"])},
      "backToSignInBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to Sign In"])}
    }
  })
}
