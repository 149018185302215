import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-44605b23"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "new-features" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { class: "heading-xl" }
const _hoisted_4 = { class: "features" }
const _hoisted_5 = { class: "feature-content" }
const _hoisted_6 = { class: "feature-number small-eyebrow" }
const _hoisted_7 = { class: "feature-name small-eyebrow" }
const _hoisted_8 = { class: "heading-lg feature-title" }
const _hoisted_9 = { class: "description" }
const _hoisted_10 = { class: "feature-img" }
const _hoisted_11 = ["src", "alt"]
const _hoisted_12 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('title')), 1),
      _createElementVNode("span", null, _toDisplayString(_ctx.$t('description')), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.features, (feature, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["feature", `feature-${feature}`]),
          key: index
        }, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, _toDisplayString(`${index + 1}/${_ctx.featureCount}`), 1),
            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t(`features.${feature}.name`)), 1),
            _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t(`features.${feature}.title`)), 1),
            _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t(`features.${feature}.description`)), 1)
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("img", {
              class: "img-mobile",
              src: `/img/new_features/${feature}_mobile.png`,
              alt: _ctx.$t(`features.${feature}.title`)
            }, null, 8, _hoisted_11),
            _createElementVNode("img", {
              class: "img-full",
              src: `/img/new_features/${feature}_full.png`,
              alt: _ctx.$t(`features.${feature}.title`)
            }, null, 8, _hoisted_12)
          ])
        ], 2))
      }), 128))
    ])
  ]))
}