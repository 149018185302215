import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, withModifiers as _withModifiers, withKeys as _withKeys, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1a57c114"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "modal",
  ref: "modal",
  tabindex: "-1"
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered" }
const _hoisted_3 = { class: "modal-content scrollable-content" }
const _hoisted_4 = { class: "modal-header" }
const _hoisted_5 = { class: "modal-title heading-md" }
const _hoisted_6 = {
  key: 0,
  class: "modal-close"
}
const _hoisted_7 = {
  key: 0,
  class: "header-hr"
}
const _hoisted_8 = { class: "modal-body" }
const _hoisted_9 = { class: "modal-footer" }
const _hoisted_10 = { class: "button-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconClose = _resolveComponent("IconClose")!
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createBlock(_Transition, { name: "modal" }, {
    default: _withCtx(() => [
      (_ctx.visible)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "modal-mask",
            role: "button",
            tabindex: "0",
            onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.maskClick && _ctx.maskClick(...args)), ["self"])),
            onKeyup: _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => (_ctx.maskClick && _ctx.maskClick(...args)), ["enter"]))
          }, [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.title), 1),
                    (_ctx.showCloseButton)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                          _createElementVNode("button", {
                            class: "btn-close",
                            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
                          }, [
                            _createVNode(_component_IconClose)
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  (_ctx.underlineHeader)
                    ? (_openBlock(), _createElementBlock("hr", _hoisted_7))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_8, [
                    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      (_ctx.hasCancelButton)
                        ? (_openBlock(), _createBlock(_component_BaseButton, {
                            key: 0,
                            class: "btn-secondary modal-button cancel",
                            "data-cy": "cancel-modal-btn",
                            onClick: _ctx.closeModal
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('cancelButtonText')), 1)
                            ]),
                            _: 1
                          }, 8, ["onClick"]))
                        : _createCommentVNode("", true),
                      (!!_ctx.primaryButton)
                        ? (_openBlock(), _createBlock(_component_BaseButton, {
                            key: 1,
                            class: "modal-button primary",
                            "data-cy": "primary-modal-btn",
                            type: "solid-primary",
                            disabled: _ctx.disabled,
                            onClick: _ctx.modalAction
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.primaryButton), 1)
                            ]),
                            _: 1
                          }, 8, ["disabled", "onClick"]))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ])
              ])
            ], 512)
          ], 32))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}