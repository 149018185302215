
import { defineComponent } from 'vue';
import BrandLogoIcon from '@svg/brand-logo-icon.svg';
import BrandLogo from '@svg/brand-logo.svg';
import MHFooter from '@/components/Footer.vue';
import { mapGetters } from 'vuex';
import OneApp from '@/components/navigation/mixins/OneApp';

export default defineComponent({
  name: 'NewFeatures',
  components: {
    BrandLogoIcon,
    BrandLogo,
    MHFooter
  },
  mixins: [OneApp],
  computed: {
    ...mapGetters(['isAuthenticated'])
  }
});
