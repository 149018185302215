export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progress"])},
      "improveSleep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Improve your sleep"])}
    }
  })
}
