
import { defineComponent } from 'vue';

import InlineErrorIcon from '@svg/alert.svg';
/**
 * Component to display the provided message as
 * an inline error .
 */
export default defineComponent({
  name: 'InlineError',
  components: {
    InlineErrorIcon
  },
  props: {
    message: {
      type: String,
      default: '',
      required: true
    }
  }
});
