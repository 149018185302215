
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';

import CheckmarkCircleIcon from '@svg/icon-checkmark-circle.svg';
import ProgressIcon from '@svg/progress.svg';

import IconFace0 from '@svg/icon-face-0.svg';
import IconFace1 from '@svg/icon-face-1.svg';
import IconFace2 from '@svg/icon-face-2.svg';
import IconFace3 from '@svg/icon-face-3.svg';
import IconFace4 from '@svg/icon-face-4.svg';

export default defineComponent({
  name: 'Tracker',
  inject: ['$analyticsTrack'],
  components: {
    IconFace0,
    IconFace1,
    IconFace2,
    IconFace3,
    IconFace4,
    ProgressIcon,
    CheckmarkCircleIcon
  },
  props: {
    title: {
      type: String,
      required: false,
      default: null
    },
    description: {
      type: String,
      required: false,
      default: null
    },
    leftText: {
      type: String,
      required: false,
      default: null
    },
    rightText: {
      type: String,
      required: false,
      default: null
    },
    moodSliderId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      selectedMood: null as number | null,
      loading: false,
      submitted: false
    };
  },
  methods: {
    selectMood(moodLevel: number): void {
      if (this.submitted) {
        return;
      }
      if (this.selectedMood === moodLevel) {
        this.selectedMood = null;
      } else {
        this.selectedMood = moodLevel;
      }
      this.$analyticsTrack('MH.Home.Tracker.Start');
    },
    submitMood(): void {
      const payload = {
        moodSliderId: this.moodSliderId,
        value: (this.selectedMood as number) * 25,
        createdOn: new Date().toISOString()
      };
      this.loading = true;
      this.$analyticsTrack('MH.Home.Tracker.Done.NoText');
      this.addEmotionalMood(payload).then(
        () => {
          this.loading = false;
          this.submitted = true;
          this.addInfoNotification({
            heading: this.$t('submitNotification.heading')
          });
        },
        () => {
          this.loading = false;
          this.submitted = false;
          this.addErrorNotification({
            heading: this.$t('submitErrorNotification.heading'),
            description: this.$t('submitErrorNotification.description')
          });
        }
      );
    },
    ...mapActions([
      'addEmotionalMood',
      'addErrorNotification',
      'addInfoNotification'
    ])
  }
});
