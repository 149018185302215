export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "es",
    "resource": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es hora de una actualización rápida"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antes de comenzar, revisemos y actualicemos su información para asegurarnos de que está obteniendo la experiencia más completa."])},
      "nextBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siguiente"])},
      "errorHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algo salio mal"])},
      "migrationRegistrationErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor contacta soporte al (800) 945-4355."])},
      "accessCodeMismatchErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puede haber un problema con una dirección de correo electrónico duplicada en uso. Por favor contacta soporte al (800) 945-4355."])}
    }
  })
}
