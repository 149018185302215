import { RouteLocation } from 'vue-router';
import ErrorView from '@/views/Error.vue';
import { dynamicComponent } from '@/helpers/Webpack';

export const surveyRoutes = [
  {
    path: '/onboarding',
    redirect: { name: 'OnboardingIntro' },
    meta: { requiresAuth: true }
  },
  {
    path: '/onboarding/survey/intro',
    name: 'OnboardingIntro',
    component: dynamicComponent('Onboarding/Survey/SurveyIntro'),
    meta: { requiresAuth: true }
  },
  {
    path: '/onboarding/intro',
    name: 'Intro',
    component: dynamicComponent('Onboarding/Survey/SurveyIntro'),
    meta: { requiresAuth: true }
  },
  {
    path: '/onboarding/survey/questions',
    name: 'SurveyQuestion',
    component: dynamicComponent('Onboarding/Survey/SurveyQuestion'),
    meta: { requiresAuth: true }
  },
  {
    path: '/onboarding/survey/transition',
    name: 'SurveyTransition',
    component: dynamicComponent('Onboarding/Survey/SurveyTransition'),
    meta: { requiresAuth: true }
  },
  {
    path: '/onboarding/plan/transition',
    name: 'PlanTransition',
    component: dynamicComponent('Onboarding/PlanTransition'),
    meta: { requiresAuth: true }
  },
  {
    path: '/onboarding/survey/outro',
    name: 'SurveyOutro',
    component: dynamicComponent('Onboarding/Survey/SurveyOutro'),
    meta: { requiresAuth: true }
  }
];

export const assessmentRoutes = [
  {
    path: '/onboarding/assessment/questionnaire',
    name: 'AssessmentQuestionnaire',
    component: dynamicComponent(
      'Onboarding/Assessment/AssessmentQuestionnaire'
    ),
    meta: { requiresAuth: true }
  },
  {
    path: '/onboarding/assessment/intro',
    name: 'AssessmentIntro',
    component: dynamicComponent('Onboarding/Assessment/AssessmentIntro'),
    meta: { requiresAuth: true }
  },
  {
    path: '/onboarding/get-help',
    name: 'CrisisIntervention',
    component: dynamicComponent('Onboarding/CrisisIntervention')
  }
];

export const recommendationRoutes = [
  {
    path: '/onboarding/assessment/transition',
    name: 'AssessmentTransition',
    component: dynamicComponent('Onboarding/Assessment/AssessmentTransition'),
    meta: { requiresAuth: true }
  },
  {
    path: '/onboarding/crisis-help-plan',
    name: 'CrisisHelpPlan',
    component: dynamicComponent('Crisis/CrisisHelpPlan'),
    meta: { requiresAuth: true },
    props: (route: RouteLocation) => ({
      severity: route.query.severity
    })
  },
  {
    path: '/onboarding/crisis-help-request',
    name: 'CrisisHelpRequest',
    component: dynamicComponent('Crisis/CrisisHelpRequest'),
    meta: { requiresAuth: true }
  },
  {
    path: '/onboarding/crisis-help-request-confirmation',
    name: 'CrisisHelpRequestConfirmation',
    component: dynamicComponent('Crisis/CrisisHelpRequestConfirmation'),
    meta: { requiresAuth: true }
  },
  {
    path: '/onboarding/activity',
    name: 'OnboardingCrisisActivity',
    component: dynamicComponent('Crisis/CrisisPlanActivity'),
    props: {
      destinationRoute: '/onboarding/program-recommendation',
      displayHeader: true,
      canceledRoute: '/onboarding/crisis-help-plan'
    },
    meta: { requiresAuth: true }
  },
  {
    path: '/onboarding/program-recommendation',
    name: 'ProgramRecommendation',
    component: dynamicComponent('Onboarding/ProgramRecommendation'),
    meta: { requiresAuth: true }
  },
  {
    path: '/onboarding/change-primary-need',
    name: 'ChangePrimaryNeed',
    component: dynamicComponent('Onboarding/ChangePrimaryNeed'),
    meta: { requiresAuth: true }
  },
  {
    path: '/onboarding/plan-recommendation',
    name: 'PlanRecommendation',
    component: dynamicComponent('Onboarding/PlanRecommendation'),
    meta: { requiresAuth: true }
  }
];

export const eligibilityRoutes = [
  {
    path: '/onboarding/gathering-information',
    name: 'OnboardingIneligible',
    component: dynamicComponent('Onboarding/OnboardingIneligibleView')
  }
];

export const errorRoutes = [
  {
    path: '/onboarding/error',
    name: 'OnboardingError',
    component: ErrorView,
    props: true,
    meta: { requiresAuth: true }
  }
];

export default [
  ...surveyRoutes,
  ...assessmentRoutes,
  ...recommendationRoutes,
  ...eligibilityRoutes,
  ...errorRoutes
];
