import HTTP from '@/services/HttpService';
import apiRoutes from '@config/api-routes';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '@/types/storeTypes';

import { ActivitySlim } from '@/types/ActivityTypes';

export interface RecommendedState {
  recommendedActivities: Array<[ActivitySlim]> | null;
}

const state: RecommendedState = {
  recommendedActivities: null
};

const getters: GetterTree<RecommendedState, RootState> = {
  recommendedActivities: (state: RecommendedState) =>
    state.recommendedActivities
};

const actions: ActionTree<RecommendedState, RootState> = {
  getRecommendedActivities({ commit }): void {
    HTTP.get(apiRoutes.activity.recommend).then((response) => {
      commit('setRecommendedActivities', response.data);
    });
  }
};

const mutations: MutationTree<RecommendedState> = {
  setRecommendedActivities(
    state: RecommendedState,
    recommendedActivities: Array<[ActivitySlim]>
  ) {
    state.recommendedActivities = recommendedActivities;
  }
};

export const RecommendedStore: Module<RecommendedState, RootState> = {
  state,
  actions,
  mutations,
  getters
};

export default RecommendedStore;
