export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "es",
    "resource": {
      "initial": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hablar con un entrenador"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programe una sesión de video con un entrenador certificado."])},
        "buttonGetStarted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empezar"])}
      },
      "upcoming": {
        "buttonCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])}
      },
      "starting_soon": {
        "buttonJoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrar"])}
      },
      "schedule_next": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programe su próxima sesión de coaching"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manténgase al día con su programa y horario ahora."])},
        "buttonSchedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendario"])}
      },
      "schedule_booster": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tienes una sesión de refuerzo disponible"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtén apoyo adicional de tu entrenador."])},
        "buttonSchedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendario"])}
      },
      "completed": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Felicidades! Ha completado su programa de entrenamiento."])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Dale vida a tus nuevas habilidades con la práctica continua!"])}
      },
      "expired": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ya no es elegible para el entrenamiento"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sus 12 semanas de elegibilidad han expirado o ha sido desactivado. Si cree que se trata de un error, comuníquese con el equipo de entrenadores."])},
        "linkContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactar entrenamiento"])},
        "linkMoreInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más información"])},
        "contactSubject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elegibilidad de entrenador"])},
        "contactBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tengo una pregunta sobre mi elegibilidad para el programa de entrenamiento."])}
      },
      "modal": {
        "cancelAppointment": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Estás seguro de que deseas cancelar esta sesión?"])},
          "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí, cancelar sesión"])},
          "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, no canceles"])},
          "successMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu sesión fue cancelada exitosamente"])}
        }
      }
    }
  })
}
