export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We’ve updated your experience"])},
      "program": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore your new program"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Based on your age and what we’ve learned about you, we’ve updated your experience with new recommendations and features. You can still find the program you were working on in the “Explore all” section of Explore."])}
      },
      "therapy": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schedule therapy on your own"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can now schedule and manage therapy visits without the help of your parent or guardian."])}
      },
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See what’s new"])}
    }
  })
}
