
import { defineComponent } from 'vue';

import HomeIcon from '@svg/one-app/Home.svg';
import HistoryIcon from '@svg/one-app/History.svg';
import PreferencesIcon from '@svg/one-app/Preferences.svg';
import ConnectIcon from '@svg/one-app/Connect.svg';
import ResourcesIcon from '@svg/one-app/Resources.svg';
import GuideIcon from '@svg/one-app/Guide.svg';
import ExploreIcon from '@svg/one-app/Explore.svg';
import IconArrowRight from '@svg/icon-arrow-right.svg';
import RedBadgeCircle from '@svg/red-badge-circle.svg';
import { mapGetters } from 'vuex';

export default defineComponent({
  name: 'NavigationSide',
  inject: ['$analyticsTrack'],
  components: {
    HomeIcon,
    PreferencesIcon,
    HistoryIcon,
    ConnectIcon,
    ResourcesIcon,
    GuideIcon,
    ExploreIcon,
    IconArrowRight,
    RedBadgeCircle
  },
  props: {
    navList: {
      type: Array,
      required: true
    },
    underlineFirstItem: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    hasUnreadConnectMessages(): boolean {
      return this.unreadTeletherapyMessages > 0;
    },
    ...mapGetters([
      'isOneApp',
      'isTeletherapyUser',
      'unreadTeletherapyMessages',
      'isClinicalCoachingUser'
    ])
  },
  methods: {
    navClass(route: string) {
      return {
        'active-nav':
          route === this.$route.name ||
          this.$route.path.includes(route.toLowerCase())
      };
    },
    handleSettingsNavigationEvent(routeName: string) {
      const profileRouteNames = [
        'Home',
        'Connect',
        'Profile',
        'Notifications',
        'Language',
        'Personalization',
        'HelpResources',
        'Support',
        'History',
        'Guide'
      ];
      if (profileRouteNames.includes(routeName)) {
        switch (routeName) {
          case 'Personalization':
            this.$analyticsTrack('MH.Settings.ContentPreferences');
            break;
          case 'HelpResources':
            this.$analyticsTrack('MH.Settings.Resources');
            break;
          case 'Connect':
            this.$analyticsTrack('MH.Connect');
            break;
          case 'Home':
            this.$analyticsTrack('MH.Home');
            break;
          case 'Guide':
            this.$analyticsTrack('MH.Guide');
            break;
          default:
            this.$analyticsTrack('MH.Settings.' + routeName);
        }
      }
    },
    getNavName(routeName: string) {
      if (routeName !== 'Connect') {
        return routeName;
      } else {
        if (this.isClinicalCoachingUser) {
          return 'ConnectClinicalCoaching';
        } else if (this.isTeletherapyUser) {
          return 'Teletherapy';
        } else {
          return 'Connect';
        }
      }
    }
  }
});
