export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en",
    "resource": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination chooser"])},
      "subHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose your destination from the list below:"])},
      "destinationListLabels": {
        "USERS": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["myStrength App"])}
        ],
        "CONSOLE": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Dashboard"])}
        ],
        "COACH": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coach Dashboard"])}
        ],
        "PARTNER": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partner API"])}
        ],
        "ADMIN": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AdminV2"])}
        ],
        "CONTENT_READ_ONLY": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AdminV2"])}
        ],
        "SYSTEM_MANAGEMENT": [
          
        ],
        "REFERRAL_FORMS": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referral Forms"])}
        ]
      },
      "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])}
    }
  })
}
