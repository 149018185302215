export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "es",
    "resource": {
      "modal": {
        "buttonAriaLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opciones de navegación de actividades"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Que te gustaría hacer?"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puede retomar esta actividad desde cero o verla con sus respuestas previamente guardadas."])},
        "retakeActivityButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retomar la actividad"])},
        "viewSavedAnswersButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver respuestas guardadas"])}
      }
    }
  })
}
