import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '@/types/storeTypes';

export interface DrawerModalStore {
  drawerOpen: boolean;
  drawerComponent: string | null;
}

const state: DrawerModalStore = {
  drawerOpen: false,
  drawerComponent: null
};

const getters: GetterTree<DrawerModalStore, RootState> = {
  drawerOpen: (state: DrawerModalStore) => state.drawerOpen,
  drawerComponent: (state: DrawerModalStore) => state.drawerComponent
};

const actions: ActionTree<DrawerModalStore, RootState> = {
  openDrawer({ commit }): void {
    commit('setDrawer', true);
  },
  closeDrawer({ commit }): void {
    commit('setDrawer', false);
  },
  toggleDrawer({ commit, state }): void {
    commit('setDrawer', !state.drawerOpen);
  },
  setDrawerComponent({ commit }, componentName: string): void {
    commit('setDrawerComponent', componentName);
  }
};

const mutations: MutationTree<DrawerModalStore> = {
  setDrawer(state: DrawerModalStore, drawerState: boolean) {
    state.drawerOpen = drawerState;
  },
  setDrawerComponent(state: DrawerModalStore, componentName: string) {
    state.drawerComponent = componentName;
  }
};

export const DrawerModalStore: Module<DrawerModalStore, RootState> = {
  state,
  actions,
  mutations,
  getters
};

export default DrawerModalStore;
